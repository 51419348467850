import { Divider, Stack, Typography } from '@myrent/myrent-ui';
import { SecondaryButton } from 'app/components/button';
import { FieldArray } from 'formik';
import { useEffect } from 'react';

import { AddCompanyTenant } from './AddCompanyTenant';
import { AddPrivateTenant } from './AddPrivateTenants';

interface Props {
  formik: any;
}

interface Tenant {
  socialSecurityNumber: string;
  firstName: string;
  lastName: string;
  noEmail: boolean;
  noCellPhone: boolean;
}

const Component = ({ formik }: Props): JSX.Element => {
  const getDepositAccountWarnings = () => {
    if (formik.values.tenantType == 'company') return [];
    const warnings: string[] = [];
    if (formik.values.privateTenants[0]?.socialSecurityNumber.length == 0) {
      warnings.push('Personnummer');
    }
    if (formik.values.privateTenants[0]?.noEmail) {
      warnings.push('E-post');
    }
    if (formik.values.privateTenants[0]?.noCellPhone) {
      warnings.push('Telefonnummer');
    }
    return warnings;
  };

  return (
    <Stack p={{ xs: 2, sm: 10 }} pr={{ md: 55 }} pb={{ xs: 10 }} spacing={8}>
      <Stack spacing={2}>
        <Typography variant="h1">Leietaker(e)</Typography>
        <Divider
          sx={{
            borderBottomWidth: 3,
            backgroundColor: '#242424'
          }}
        />
      </Stack>
      <Stack spacing={2}>
        <Typography variant="body1" fontWeight="bold">
          Leier du ut til privatperson eller bedrift?
        </Typography>
        <Stack direction={'row'} spacing={4}>
          <SecondaryButton
            filled={formik.values.tenantType == 'private'}
            onClick={() => formik.setFieldValue('tenantType', 'private')}
          >
            Privat
          </SecondaryButton>
          <SecondaryButton
            filled={formik.values.tenantType == 'company'}
            onClick={() => formik.setFieldValue('tenantType', 'company')}
          >
            Bedrift
          </SecondaryButton>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h5" fontWeight="bold">
          Kontaktinformasjon
        </Typography>
        <Stack pr={{ md: 0, lg: '35%' }}>
          <Divider
            sx={{
              borderBottomWidth: 1.5,
              backgroundColor: '#003D43'
            }}
          />
        </Stack>
      </Stack>
      {formik.values.tenantType == 'private' ? (
        <FieldArray
          name="privateTenants"
          render={(arrayHelpers) => (
            <Stack spacing={8}>
              {formik.values.privateTenants.map(
                (tenant: Tenant, index: number) => (
                  <Stack key={index} spacing={4}>
                    <Stack key={index} spacing={4}>
                      <AddPrivateTenant index={index} formik={formik} />
                      {formik.values.privateTenants?.length != 1 ? (
                        <SecondaryButton
                          filled
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Fjern medleietaker
                        </SecondaryButton>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <Stack pr={{ md: 0, lg: '35%' }}>
                      <Divider
                        sx={{
                          borderBottomWidth: 1.5,
                          backgroundColor: '#003D43'
                        }}
                      />
                    </Stack>
                  </Stack>
                )
              )}
              {formik.values.privateTenants?.length < 6 ? (
                <SecondaryButton
                  addIcon
                  onClick={() =>
                    arrayHelpers.push({
                      company: 1,
                      socialSecurityNumber: '',
                      isCreditChecked: false,
                      creditScore: '',
                      firstName: '',
                      lastName: '',
                      noEmail: false,
                      email: '',
                      noCellPhone: false,
                      cellphone: ''
                    })
                  }
                >
                  Legg til medleietaker
                </SecondaryButton>
              ) : (
                <></>
              )}
            </Stack>
          )}
        />
      ) : (
        <FieldArray
          name="companyTenants"
          render={(arrayHelpers) => (
            <Stack spacing={8}>
              {formik.values.companyTenants.map(
                (tenant: Tenant, index: number) => (
                  <Stack key={index} spacing={4}>
                    <Stack key={index} spacing={4}>
                      <AddCompanyTenant index={index} formik={formik} />
                      {formik.values.companyTenants?.length != 1 ? (
                        <SecondaryButton
                          filled
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Fjern medleietaker
                        </SecondaryButton>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <Stack pr={{ md: 0, lg: '35%' }}>
                      <Divider
                        sx={{
                          borderBottomWidth: 1.5,
                          backgroundColor: '#003D43'
                        }}
                      />
                    </Stack>
                  </Stack>
                )
              )}
              {formik.values.companyTenants?.length < 6 ? (
                <SecondaryButton
                  addIcon
                  onClick={() =>
                    arrayHelpers.push({
                      company: 2,
                      companyName: '',
                      organizationNumber: '',
                      isCreditChecked: false,
                      creditScore: '',
                      address: '',
                      zipCode: '',
                      city: '',
                      firstName: '',
                      lastName: '',
                      noEmail: false,
                      email: '',
                      noCellPhone: false,
                      cellphone: ''
                    })
                  }
                >
                  Legg til medleietaker
                </SecondaryButton>
              ) : (
                <></>
              )}
            </Stack>
          )}
        />
      )}
      {getDepositAccountWarnings().length > 0 && (
        <Stack
          sx={{ backgroundColor: '#FFF3AE', borderRadius: '25px' }}
          spacing={2}
          padding={{ xs: 2, md: 5 }}
        >
          <Typography>
            For å kunne bestille MyRent depositumskonto levert av Sparebank 1
            SR-Bank så må følgende felter fylles ut:
          </Typography>
          {getDepositAccountWarnings().map((warning, index) => (
            <Typography key={index} pl="20px">
              {`- ${warning}`}
            </Typography>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default Component;
