import { apiSlice } from '../index';
import {
  ContractRequest,
  CpiContractsRegulationRequest,
  CpiInfoResponse,
  CpiPendingResponse,
  CpiPostponeRegulationRequest,
  CpiUnPostponeRegulationRequest,
  GjengsleieContractRegulationRequest,
  GjengsleieContractsRegulationRequest,
  GjengsleiePendingContractsResponse,
  MailTemplates,
  RegulatedContractsResponse,
  RegulatedOrPostponedContractRespone
} from './types';

const cpiApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCpiInfo: builder.query<CpiInfoResponse, void>({
      query: () => ({
        url: `v1/cpi/info`
      })
    }),
    getCpiTemplates: builder.query<MailTemplates[], void>({
      query: () => ({
        url: '/v1/templates',
        params: {
          type: 2
        }
      })
    }),
    getGjengsleieTemplates: builder.query<MailTemplates[], void>({
      query: () => ({
        url: '/v1/templates',
        params: {
          type: 3
        }
      })
    }),
    getPostponedContracts: builder.query<CpiPendingResponse, ContractRequest>({
      query: (options) => ({
        url: 'v1/cpi/contracts/postponed',
        params: options
      }),
      providesTags: ['CpiPostponedContracts']
    }),
    getPendingContracts: builder.query<CpiPendingResponse, ContractRequest>({
      query: (options) => ({
        url: `v1/cpi/contracts/pending`,
        params: options
      }),
      providesTags: ['CpiPendingContracts']
    }),
    getGjengeleiePendingContracts: builder.query<
      GjengsleiePendingContractsResponse,
      ContractRequest
    >({
      query: (options) => ({
        url: 'v1/cpi/contracts/pending/custom',
        params: options
      }),
      providesTags: ['CustomRegulationPendingContracts']
    }),
    getRegulatedContracts: builder.query<
      RegulatedContractsResponse,
      ContractRequest
    >({
      query: (options) => ({
        url: 'v1/cpi/contracts/regulated',
        params: options
      }),
      providesTags: ['RegulatedContracts']
    }),
    cpiRegulateContracts: builder.mutation<
      RegulatedOrPostponedContractRespone,
      CpiContractsRegulationRequest
    >({
      query: ({ templateId, contractIds }) => ({
        url: 'v1/cpi/contracts/batch/regulate',
        body: { templateId, contractIds },
        method: 'POST'
      }),
      invalidatesTags: [
        'CpiPendingContracts',
        'CustomRegulationPendingContracts'
      ]
    }),
    gjengsleieRegualteContracts: builder.mutation<
      RegulatedOrPostponedContractRespone,
      GjengsleieContractsRegulationRequest
    >({
      query: (options) => ({
        url: 'v1/cpi/contracts/batch/regulate/custom',
        body: options,
        method: 'POST'
      }),
      invalidatesTags: [
        'CpiPendingContracts',
        'CustomRegulationPendingContracts'
      ]
    }),
    gjengsLeieRegulateContract: builder.mutation<
      RegulatedOrPostponedContractRespone,
      GjengsleieContractRegulationRequest
    >({
      query: (options) => ({
        url: 'v1/cpi/contracts/regulate/custom',
        body: options,
        method: 'POST'
      }),
      invalidatesTags: [
        'CpiPendingContracts',
        'CustomRegulationPendingContracts'
      ]
    }),
    postponeContracts: builder.mutation<
      RegulatedOrPostponedContractRespone,
      CpiPostponeRegulationRequest
    >({
      query: (options) => ({
        url: 'v1/cpi/contracts/batch/postpone',
        body: options,
        method: 'POST'
      }),
      invalidatesTags: [
        'CpiPendingContracts',
        'CustomRegulationPendingContracts'
      ]
    }),
    unPostponeContracts: builder.mutation<
      RegulatedOrPostponedContractRespone,
      CpiUnPostponeRegulationRequest
    >({
      query: (options) => ({
        url: 'v1/cpi/contracts/batch/unpostpone',
        body: options,
        method: 'POST'
      }),
      invalidatesTags: [
        'CpiPendingContracts',
        'CustomRegulationPendingContracts',
        'CpiPostponedContracts'
      ]
    })
  })
});

export const {
  useGetCpiInfoQuery,
  useGetCpiTemplatesQuery,
  useGetGjengsleieTemplatesQuery,
  useGetPostponedContractsQuery,
  useGetPendingContractsQuery,
  useGetGjengeleiePendingContractsQuery,
  useGetRegulatedContractsQuery,
  useCpiRegulateContractsMutation,
  useGjengsLeieRegulateContractMutation,
  usePostponeContractsMutation,
  useUnPostponeContractsMutation,
  useGjengsleieRegualteContractsMutation
} = cpiApi;
