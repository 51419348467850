import { Divider, Stack, Typography } from '@mui/material';
import { SecondaryButton } from 'app/components/button';
import { useStateQuery } from 'app/context/api/integrations/fiken';

const Component = (): JSX.Element => {
  const { data: fikenState } = useStateQuery();
  const authUrl = fikenState?.authUrl;

  return (
    <Stack marginX={2} marginY={2} justifyContent="center" spacing={2}>
      <Typography variant="h1">Fiken</Typography>
      <Divider sx={{ height: '2px' }} />
      <Stack spacing={2}>
        <Typography variant="h3">Koble til Fiken</Typography>
        <Typography variant="body1">
          Ditt MyRent system kan kobles mot Fiken for sømløs overføring av
          regnskapsdata mellom alle selskapene dine.
        </Typography>
        <Stack width="30%" paddingBottom={2}>
          <SecondaryButton
            chevron="right"
            filled
            onClick={() => authUrl && (window.location.href = authUrl)}
          >
            Koble til Fiken
          </SecondaryButton>
        </Stack>
        <Typography variant="body1">
          {fikenState?.authStatus === 2 &&
            'Det skjedde en feil ved tilkoblingen til Fiken. Prøv igjen, eller kontakt support.'}
          {fikenState?.authStatus === 3 &&
            'Du må godta integrasjonen i Fiken. Prøv igjen.'}
          {fikenState?.authStatus === 5 &&
            'Fiken-integrasjonen er deaktivert. Kontakt support for å aktivere den igjen.'}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { Component as Connect };
