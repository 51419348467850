import styled from 'styled-components';

export const Container = styled('div')``;

export const TabContainer = styled('div')`
  display: flex;
`;

export const ContentContainer = styled('div')`
  /* display: flex; */
  /* position: relative; */
  /* height: 85vh; */
  width: 100%;
  background-color: #003d43;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* padding: 20px; */
`;

interface TabProps {
  active: boolean;
  left?: boolean;
}

export const Tab = styled('div')<TabProps>`
  display: flex;
  text-align: center;
  border-color: #003d43;
  border-width: 1px;
  border-style: solid;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${(props) => (props.active ? '#003D43' : '#FFFFFF')};
  text-decoration: ${(props) => (props.active ? 'none' : 'underline')};
  text-decoration-thickness: 1.5px;
  text-underline-offset: 6px;
  color: ${(props) => (props.active ? '#FFFFFF' : '#003D43')};
  padding: 10px;
  font-size: 15px;
  z-index: ${({ active }) => (active ? '1' : '0')};
  margin-right: ${({ left, active }) => (left ? '-17px' : '0px')};
  padding-right: ${({ left, active }) => (left && !active ? '25px' : '10px')};
  padding-left: ${({ left, active }) => (!left && !active ? '25px' : '10px')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  margin-bottom: -1px;

  &:hover {
    background-color: #003d43;
    cursor: pointer;
    color: white;
  }
`;

export const SizedText = styled('div');
