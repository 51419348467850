import { apiSlice } from '../index';
import {
  ContractTemplateList,
  ContractTemplateRequest,
  ContractTemplateResponse,
  CreateContractTemplate,
  KeyWords,
  NavGuaranteeExpiresResponse,
  UpdateContractTemplate
} from './types';

const contractApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNavGuaranteeExpires: builder.query<NavGuaranteeExpiresResponse[], any>({
      query: () => ({
        url: 'v1/Contract/GetNavGuaranteeExpires'
      })
    }),

    getContractTemplateList: builder.query<ContractTemplateList[], void>({
      query: () => ({
        url: 'ContractTemplate/GetList?standardType=1'
      })
    }),
    getContractTemplate: builder.query<
      ContractTemplateResponse,
      ContractTemplateRequest
    >({
      query: (payload) => ({
        url: 'ContractTemplate/Get',
        params: payload
      })
    }),
    createContractTemplate: builder.mutation<any, CreateContractTemplate>({
      query: (payload) => ({
        url: 'ContractTemplate/Create',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      })
    }),

    updateContractTemplate: builder.mutation<any, UpdateContractTemplate>({
      query: (payload) => ({
        url: 'ContractTemplate/Edit',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      })
    }),
    getContractTemplateKeyWords: builder.query<KeyWords[], void>({
      query: () => ({
        url: 'ContractTemplate/GetKeyWordsWithCategories'
      })
    }),
    getPreviewPdf: builder.mutation<any, any>({
      query: (payload) => ({
        url: 'ContractTemplate/HtmlToPdf',
        method: 'POST',
        body: payload,
        responseHandler: async (response) =>
          window.open(
            window.URL.createObjectURL(await response.blob()),
            '_blank'
          ),
        cache: 'no-cache'
      })
    }),
    getDownloadPdf: builder.mutation<any, any>({
      query: (payload) => ({
        url: 'ContractTemplate/HtmlToPdf',
        method: 'POST',
        body: payload,
        responseHandler: async (response) => {
          const hiddenElement = document.createElement('a');
          const url = window.URL || window.webkitURL;
          const blobPDF = url.createObjectURL(await response.blob());
          hiddenElement.href = blobPDF;
          hiddenElement.download = 'Kontrakt.pdf';
          hiddenElement.click();
          window.URL.revokeObjectURL(blobPDF);
          hiddenElement.remove();
        },
        cache: 'no-cache'
      })
    }),

    getContractByUserId: builder.mutation<any, any>({
      query: (payload) => ({
        url: 'v1/Contract/Contracts',
        method: 'POST',
        body: payload
      })
    }),
    getTenantsByContractId: builder.mutation<any, any>({
      query: (payload) => ({
        url: 'Contract/Get',
        method: 'POST',
        params: payload
      })
    }),
    signContract: builder.mutation<any, any>({
      query: (payload) => ({
        url: 'ContractTemplate/Sign',
        method: 'POST',
        body: payload
      })
    }),
    checkDuplicateContract: builder.mutation({
      query: (payload) => ({
        url: 'SimpleWizard/ContractDuplicateCheck',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      })
    }),
    uploadContract: builder.mutation({
      query: (payload) => ({
        url: 'Contract/UploadFile',
        method: 'POST',
        body: payload
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetNavGuaranteeExpiresQuery,
  useGetContractTemplateListQuery,
  useGetContractTemplateQuery,
  useCreateContractTemplateMutation,
  useUpdateContractTemplateMutation,
  useGetContractTemplateKeyWordsQuery,
  useGetPreviewPdfMutation,
  useGetDownloadPdfMutation,
  useGetContractByUserIdMutation,
  useGetTenantsByContractIdMutation,
  useSignContractMutation,
  useCheckDuplicateContractMutation,
  useUploadContractMutation
} = contractApi;
