import * as yup from 'yup';

export const socialSecurityNumber = yup
  .string()
  .min(11, 'Fyll inn personnnummer')
  .max(12, 'Fyll inn personnnummer')
  .test('is-valid', 'Fyll inn et gyldig personnummer', (kontonummer) => {
    const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    if (!kontonummer) {
      return false;
    }
    const kontonummerWithoutSpacesAndPeriods = kontonummer.replace(
      /[\s.]+/g,
      ''
    );
    if (kontonummerWithoutSpacesAndPeriods.length !== 11) {
      return false;
    } else {
      const sjekksiffer = parseInt(
        kontonummerWithoutSpacesAndPeriods.charAt(10),
        10
      );
      const kontonummerUtenSjekksiffer =
        kontonummerWithoutSpacesAndPeriods.substring(0, 10);
      let sum = 0;
      for (let index = 0; index < 10; index++) {
        sum +=
          parseInt(kontonummerUtenSjekksiffer.charAt(index), 10) *
          weights[index];
      }
      const remainder = sum % 11;
      return sjekksiffer === (remainder === 0 ? 0 : 11 - remainder);
    }
  });
//.required('Skriv inn riktig personnummer');

const date = yup
  .date()
  .typeError('Velg en gyldig dato')
  .min('01/01/1900', 'Velg en gyldig dato')
  .max('12/31/2099', 'Velg en gyldig dato')
  .required('Velg en gyldig dato');

export const validateAddProperty = yup.object().shape({
  propertyInfo: yup.object().shape({
    clientId: yup.string().required('Skriv inn utleier'),
    object: yup
      .object()
      .shape({
        label: yup.string().required('Velg leieobjekt')
      })
      .typeError('Velg et leieobjekt')
  })
});

export const privateTenant = yup.object().shape(
  {
    socialSecurityNumber: yup.string().when('socialSecurityNumber', {
      is: (ssn: string) => ssn && ssn.length > 0,
      then: socialSecurityNumber
    }),
    firstName: yup
      .string()
      .required('Skriv inn fornavn')
      .min(2, 'Skriv inn fornavn'),
    lastName: yup
      .string()
      .required('Skriv inn etternavn')
      .min(2, 'Skriv inn etternavn'),
    noEmail: yup.boolean(),
    email: yup.string().when('noEmail', {
      is: false,
      then: yup
        .string()
        .email('Skriv inn en gyldig e-post')
        .required('Skriv inn e-post')
    }),
    noCellPhone: yup.boolean(),
    cellphone: yup.string().when('noCellPhone', {
      is: false,
      then: yup
        .string()
        .min(10, 'Skriv inn mobilnummer')
        .required('Skriv inn mobilnummer')
    })
  },
  [['socialSecurityNumber', 'socialSecurityNumber']]
);

export const validateCompanyTenants = yup.object().shape({
  companyTenants: yup.array().of(
    yup.object().shape({
      companyName: yup
        .string()
        .required('Skriv inn bedriftsnavn')
        .min(2, 'Skriv inn bedriftsnavn'),
      organizationNumber: yup
        .number()
        .typeError('Skriv inn gyldig organisasjonsnummer')
        .required('Skriv inn organisasjonsnummer')
        .min(99999999, 'Organisasjonsnummer må ha 9 siffer')
        .max(999999999, 'Organisasjonsnummer må ha 9 siffer'),
      address: yup
        .string()
        .required('Skriv inn bedriftsadresse')
        .min(2, 'Skriv inn bedriftsadresse'),
      zipCode: yup
        .string()
        .required('Skriv inn postnummer')
        .min(2, 'Skriv inn postnummer'),
      city: yup
        .string()
        .required('Skriv inn poststed')
        .min(2, 'Skriv inn poststed'),
      firstName: yup
        .string()
        .required('Skriv inn fornavn')
        .min(2, 'Skriv inn fornavn'),
      lastName: yup
        .string()
        .required('Skriv inn etternavn')
        .min(2, 'Skriv inn etternavn'),
      noEmail: yup.boolean(),
      email: yup.string().when('noEmail', {
        is: false,
        then: yup
          .string()
          .email('Skriv inn en gyldig e-post')
          .required('Skriv inn e-post')
      }),
      noCellPhone: yup.boolean(),
      cellphone: yup.string().when('noCellPhone', {
        is: false,
        then: yup
          .string()
          .min(10, 'Skriv inn mobilnummer')
          .required('Skriv inn mobilnummer')
      })
    })
  )
});

export const validatePrivateTenants = yup.object().shape({
  privateTenants: yup.array().of(privateTenant)
});

export const validateAddContract = yup.object().shape({
  contractInfo: yup.object().shape({
    contractType: yup.number().required(),
    startDate: date,
    endDate: yup
      .date()
      .when('contractType', {
        is: (contractType: number) => contractType && contractType < 3,
        then: yup
          .date()
          .typeError('Velg en gyldig dato')
          .min(
            yup.ref('startDate'),
            'Kontraktslutt kan ikke være før kontraktstart'
          )
          .required('Velg en gyldig dato')
      })
      .nullable(true),
    noticePeriod: yup.number().required('Velg oppsigelsestid'),
    acquisitionDate: date
  })
});

export const validateAddRent = yup.object().shape({
  contractInfo: yup.object().shape({
    startDate: date
  }),
  rentInfo: yup.object().shape({
    rentAmount: yup
      .number()
      .min(1, 'Skriv inn en gyldig leiesum')
      .max(100000000, 'Skriv inn en lavere leiesum')
      .typeError('Skriv inn en gyldig leiesum')
      .required('Skriv inn en leiesum'),
    startDate: yup
      .date()
      .typeError('Velg en gyldig dato')
      .min(new Date(Date.now() - 86400000), 'Du kan ikke velge tilbake i tid'),
    invoiceInterval: yup.number().required('Velg et intervall'),
    paymentDay: yup.string().required('Velg en dag'),
    mainProduct: yup.string().required('Velg et produkt'),
    extraProduct: yup.array(),
    autoCalcFirstInvoice: yup.boolean(),
    isExternalRegulated: yup.boolean(),
    prevRegulationDate: yup.date().when('isExternalRegulated', {
      is: true,
      then: date
    }),
    nextRegulationDate: yup
      .date()
      .typeError('Velg en gyldig dato')
      .when('isExternalRegulated', {
        is: false,
        then: yup
          .date()
          .typeError('Velg en gyldig dato')
          .min(
            yup.ref('contractstartDate'),
            'Neste reguleringsdato kan ikke være mindre enn 12 måneder fra kontraktstart'
          )
      })
  })
});

export const validateAddSecurity = yup.object().shape({
  securityInfo: yup.object().shape({
    securityType: yup
      .number()
      .required('Velg en sikkerhetsløsning')
      .typeError('Velg en sikkerhetsløsning'),
    deposit: yup.number().when('securityType', {
      is: 2 || 4,
      then: yup
        .number()
        .min(0, 'Beløpet må være større enn 0')
        .typeError('Fyll inn gyldig nummer')
        .required('Fyll inn depositum')
    }),
    myrentOwner: yup.object().when('securityType', {
      is: 2,
      then: yup.object().shape({
        companyNumber: yup
          .number()
          .typeError('Fyll inn gyldig organisasjonsnummer')
          .required('Fyll inn organisasjonsnummer')
          .min(99999999, 'Organisasjonsnummer må ha 9 siffer')
          .max(999999999, 'Organisasjonsnummer må ha 9 siffer'),
        firstName: yup
          .string()
          .min(2, 'Fyll inn fornavn')
          .required('Fyll inn fornavn'),
        lastName: yup
          .string()
          .min(2, 'Fyll inn etternavn')
          .required('Fyll inn etternavn'),
        email: yup
          .string()
          .email('Fyll inn gyldig e-post')
          .required('Fyll inn e-post'),
        cellPhone: yup
          .string()
          .min(10, 'Fyll inn et gyldig telefonnummer')
          .required('Fyll inn telefonnummer'),
        socialSecurityNumber: socialSecurityNumber
      })
    }),
    tenantSigner: yup.object().when('securityType', {
      is: 2,
      then: yup.object().shape({
        companyName: yup.string().test({
          name: 'tenantCompanyName',
          message: 'Fyll inn bedriftsnavn',
          test: (value, context) => {
            const parents: any = context.options;
            const parent = parents.from.find(
              (p: any) => 'tenantType' in p.value
            );
            if (parent.value.tenantType == 'private') {
              return true;
            }
            if (!value) {
              return false;
            }
            return value.length > 2;
          }
        }),
        organizationNumber: yup.string().test({
          name: 'tenantCompanyNumber',
          message: 'Fyll inn organisasjonsnummer',
          test: (value, context) => {
            const parents: any = context.options;
            const parent = parents.from.find(
              (p: any) => 'tenantType' in p.value
            );
            if (parent.value.tenantType == 'private') {
              return true;
            }
            if (!value) {
              return false;
            }
            return value.length == 9;
          }
        }),
        firstName: yup
          .string()
          .min(2, 'Fyll inn fornavn')
          .required('Fyll inn fornavn'),
        lastName: yup
          .string()
          .min(2, 'Fyll inn etternavn')
          .required('Fyll inn etternavn'),
        email: yup
          .string()
          .email('Fyll inn gyldig e-post')
          .required('Fyll inn e-post'),
        cellphone: yup
          .string()
          .min(10, 'Fyll inn et gyldig telefonnummer')
          .required('Fyll inn telefonnummer'),
        socialSecurityNumber: socialSecurityNumber
      })
    }),

    SPCreditCheck: yup.boolean().when('securityType', {
      is: 3,
      then: yup.boolean().test('creditCheck', 'Utfør kredittsjekk', (value) => {
        if (!value) {
          return false;
        }
        return value;
      })
    }),
    monthSP: yup.string().when('securityType', {
      is: 3,
      then: yup.string().required('Velg antall måneder')
    }),
    navDate: yup.date().nullable().notRequired().when('securityType', {
      is: 5,
      then: date
    })
  })
});
