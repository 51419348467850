import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.json';
import no from './no/translation.json';

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'nb',
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en
      },
      no: {
        translation: no
      }
    }
  });
