import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BusinessIcon from '@mui/icons-material/Business';
import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { DashboardCard } from 'app/components/card';
import { Dialog } from 'app/components/dialog';
import { CountDisplay, SelectDisplay } from 'app/components/display';
import { ExternalLink } from 'app/components/link';
import { Table } from 'app/components/table';
import { TableColumn, TableRow } from 'app/components/table/component';
import {
  useGetObjectsVacancySummaryQuery,
  useGetSoonAvailableObjectsQuery,
  useGetVacantObjectsQuery
} from 'app/context/api/object';
import {
  SoonVacantObjectsResponse,
  VacantObjectsResponse
} from 'app/context/api/object/types';
import { red } from 'app/theme/colors';
import { useEffect, useState } from 'react';

import { ObjectStatusSelect } from './ObjectStatusSelect';

const vacantObjectColumns: TableColumn[] = [
  {
    title: 'Eiendom'
  },
  {
    title: 'Objekt'
  },
  {
    title: 'Ledig siden',
    order: 6
  },
  {
    title: 'Tidligere leie',
    align: 'right',
    order: 5
  },
  {
    title: 'Ny kontrakt fra',
    align: 'right',
    order: 3
  },
  {
    title: 'Status for objektet',
    order: 7
  },
  {
    title: '',
    align: 'right',
    order: 8
  }
];

const getVacantObjectRows = (data: VacantObjectsResponse[]) => {
  const rows: TableRow[][] = [];
  data.map((row) => {
    rows.push([
      {
        value: row.propertyAddress,
        order: 1
      },
      {
        value: row.objectDescription ?? '',
        order: 2
      },
      {
        value: row.currentContract?.leaseStopDate ?? '',
        order: 6
      },
      {
        value: row.currentContract?.monthlyFee + ' kr' ?? '',
        order: 5
      },
      {
        value: row.nextContract?.startsAt ?? 'Ingen ny kontrakt',
        order: 3
      },
      {
        value: (
          <ObjectStatusSelect
            id={row.objectId}
            currentValue={row.objectStatus}
          />
        ),
        order: 7
      },
      {
        value: (
          <Box display={'flex'} alignItems={'center'}>
            <ExternalLink to={`Property/Object?objectId=${row.objectId}`}>
              <ArrowForwardIcon
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  borderRadius: '25px'
                }}
              />
            </ExternalLink>
          </Box>
        ),
        order: 8
      }
    ]);
  });
  return rows;
};

const soonVacantObjectColumns: TableColumn[] = [
  {
    title: 'Eiendom'
  },
  {
    title: 'Objekt'
  },
  {
    title: 'Kontrakt utløper',
    order: 3
  },
  {
    title: 'Nåværende leie',
    align: 'right',
    order: 4
  },
  {
    title: 'Status',
    align: 'right',
    order: 5
  },
  {
    title: 'Ny kontrakt fra',
    align: 'right',
    order: 6
  },
  {
    title: 'Status for objektet',
    order: 7
  },
  {
    title: '',
    align: 'right',
    order: 8
  }
];

const getSoonVacantObjectRows = (data: SoonVacantObjectsResponse[]) => {
  const rows: TableRow[][] = [];
  data.map((row) => {
    rows.push([
      {
        value: row.propertyAddress,
        order: 1
      },
      {
        value: row.objectDescription ?? '',
        order: 2
      },
      {
        value: row.currentContract?.leaseStopDate ?? '',
        order: 3
      },
      {
        value: row.currentContract?.monthlyFee + ' kr' ?? '',
        order: 4
      },
      {
        value: row.currentContract?.terminated
          ? 'Kontrakt terminert'
          : 'Utløper',
        order: 5
      },
      {
        value: row.nextContract?.startsAt ?? 'Ingen ny kontrakt',
        order: 6
      },
      {
        value: (
          <ObjectStatusSelect
            id={row.objectId}
            currentValue={row.objectStatus}
          />
        ),
        order: 7
      },
      {
        value: (
          <Box display={'flex'} alignItems={'center'}>
            <ExternalLink to={`Property/Object?objectId=${row.objectId}`}>
              <ArrowForwardIcon
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  borderRadius: '25px'
                }}
              />
            </ExternalLink>
          </Box>
        ),
        order: 8
      }
    ]);
  });
  return rows;
};

const Component = (): JSX.Element => {
  const [option, setOption] = useState<number>(3);

  const { data, isLoading } = useGetObjectsVacancySummaryQuery(2);
  const { data: vacantObjects, isLoading: isLoadingVacantObjects } =
    useGetVacantObjectsQuery();
  const { data: soonVacantObjects, isLoading: isLoadingSoonVacantObjects } =
    useGetSoonAvailableObjectsQuery({
      numberOfMonths: option
    });

  const summaryOptions = [
    {
      value: 3,
      label: 'Neste 3 måneder'
    },
    {
      value: 6,
      label: 'Neste 6 måneder'
    },
    {
      value: 12,
      label: 'Neste år'
    }
  ];

  const [open, setOpen] = useState<number>(0);
  const [availableObjectsCount, setAvailableObjectsCount] = useState<number>(0);

  const updateFilter = (event: SelectChangeEvent) => {
    setOption(parseInt(event.target.value));
  };

  useEffect(() => {
    if (data) {
      switch (option) {
        case 3:
          setAvailableObjectsCount(data.payload.vacantIn3Months);
          break;
        case 6:
          setAvailableObjectsCount(data.payload.vacantIn6Months);
          break;
        case 12:
          setAvailableObjectsCount(data.payload.vacantInYear);
          break;
      }
    }
  }, [data, option]);

  return (
    <>
      {data && (
        <DashboardCard
          mainIcon={<BusinessIcon />}
          title={'Ledige objekter'}
          select={
            <SelectDisplay
              value={option.toString()}
              onChange={updateFilter}
              options={summaryOptions}
            />
          }
          firstColumn={
            <Stack spacing={1}>
              <CountDisplay
                title={'blir ledige'}
                count={availableObjectsCount - data.payload.vacantToday}
                link={
                  availableObjectsCount - data.payload.vacantToday == 0
                    ? undefined
                    : () => setOpen(2)
                }
              />
              <CountDisplay
                title={'ledige'}
                count={data.payload.vacantToday}
                countColor={red[40]}
                link={
                  data.payload.vacantToday == 0 ? undefined : () => setOpen(1)
                }
              />
            </Stack>
          }
        />
      )}
      <Dialog isOpen={open > 0} setIsOpen={() => setOpen(0)}>
        {open == 1 ? (
          <Stack spacing={2} py={10} px={{ xs: 1, sm: 3, md: 15 }}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              display={'flex'}
              flexWrap={'wrap'}
            >
              <Typography variant="h1" sx={{ pb: 2 }}>
                Ledig objekter
              </Typography>
            </Stack>
            {vacantObjects && (
              <Table
                data={{
                  columns: vacantObjectColumns,
                  rows: getVacantObjectRows(vacantObjects)
                }}
              />
            )}
          </Stack>
        ) : (
          <Stack spacing={2} py={10} px={{ xs: 1, sm: 3, md: 15 }}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              display={'flex'}
              flexWrap={'wrap'}
            >
              <Typography variant="h1" sx={{ pb: 2 }}>
                Snart ledig objekter
              </Typography>
            </Stack>
            {soonVacantObjects && (
              <Table
                data={{
                  columns: soonVacantObjectColumns,
                  rows: getSoonVacantObjectRows(soonVacantObjects)
                }}
              />
            )}
          </Stack>
        )}
      </Dialog>
    </>
  );
};

export default Component;
