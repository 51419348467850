import { KeyWordsCategories } from './component';

export const prefix = (color: string) => {
  return `<span contenteditable="false"><mark style="color: black; background-color: ${color}; padding: 3px; padding-left: 8px; padding-right: 8px; border: 1px solid ${color}; border-radius: 5px; letter-spacing: 1px;">`;
};

export const postfix = () => {
  return '</mark></span>';
};

export const mapKeywordToValue = (text: string, data: KeyWordsCategories[]) => {
  let newText = text;
  data.forEach((item) => {
    item.subitems.forEach((subitem) => {
      newText = newText.replaceAll(
        '{' + subitem.key + '}',
        prefix(item.color) + subitem.value.toUpperCase() + postfix()
      );
    });
  });
  return newText;
};

export const mapValueToKeyword = (text: string, data: KeyWordsCategories[]) => {
  let newText = text;
  data.forEach((item) => {
    item.subitems.forEach((subitem) => {
      newText = newText.replaceAll(
        prefix(item.color) + subitem.value.toUpperCase() + postfix(),
        '{' + subitem.key + '}'
      );
    });
  });

  return newText;
};
