import apisauce from 'apisauce';

import { createClient } from './contract';

const client = apisauce.create({
  baseURL: '',

  // here are some default headers
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    Accept: 'application/json'
  },

  // 30 second timeout...
  timeout: 30000
});

export default createClient(client);
