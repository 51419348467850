import { Box, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  value: number;
  valueColor?: string;
}

const Component = ({ title, value, valueColor }: Props): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        height: '50px',
        borderRadius: '100px',
        alignItems: 'center'
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          height: '100%',
          px: '25px'
        }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography>{title}</Typography>
        <Typography fontWeight={'bold'} color={valueColor ?? 'black'}>
          {value
            .toLocaleString(undefined, { maximumFractionDigits: 0 })
            .replaceAll(',', ' ') + ',-'}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Component;
