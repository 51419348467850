import { LoadingPopup } from 'app/components/loading';
import { useStateQuery } from 'app/context/api/integrations/fiken';

import { Connect } from './components/Connect';
import { Overview } from './components/Overview';

const Component = (): JSX.Element => {
  const { data: fikenState, isLoading } = useStateQuery();

  if (isLoading) {
    return <LoadingPopup open />;
  }

  if (fikenState?.authStatus === 4) {
    return <Overview />;
  }

  return <Connect />;
};

export default Component;
