import { Autocomplete, Grid, MenuItem, TextField } from '@mui/material';
import { Stack, Typography } from '@myrent/myrent-ui';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { Select, TextInput } from 'app/components/input';
import { LoadingPopup } from 'app/components/loading';
import {
  useCreatePropertyMutation,
  useGetPropertyInfoQuery
} from 'app/context/api/property';
import { white } from 'app/theme/colors';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { AddUnits } from './components/AddUnits';
import { EditUnit } from './components/EditUnit';
import { Unit } from './components/EditUnit/component';

interface Client {
  id: number;
  name: string;
  label?: string;
}

export interface PropertyValues {
  clientId: number | undefined;
  propertyType: number | undefined;
  address: string;
  postalCode: string;
  postalLocation: string;
  unitNumber: string;
  unitOptions: string[];
  units: any;
}

export interface PropertyType {
  id: number;
  name: string;
  type: 'property' | 'object';
  typeId: number;
  label: string;
  priority: boolean;
  backgroundColor?: string;
}

interface Address {
  id: number;
  adressetekst: string;
  postnummer: string;
  poststed: string;
  bruksenhetsnummer: string[];
}

interface AddressOption {
  id: number;
  label: string;
  postnummer: string;
  poststed: string;
  bruksenhetsnummer: string[];
}

const unitSchema = yup.object().shape({
  objectTypeId: yup.string().required('Velg enhetstype'),
  description: yup.string().required('Fyll inn enhetsnummer')
});

const validationObjectTypeSchema = yup.object().shape({
  clientId: yup.string().required('Velg utleier'),
  propertyType: yup.string().required('Velg eiendomstype'),
  address: yup.string().required('Fyll inn adresse').min(2, 'Fyll inn adresse'),
  postalCode: yup
    .string()
    .required('Fyll inn postnummer')
    .min(4, 'Fyll inn postnummer')
    .max(4, 'Fyll inn postnummer'),
  postalLocation: yup
    .string()
    .required('Fyll inn sted')
    .min(2, 'Fyll inn sted'),
  unitNumber: yup.string()
});

const validationPropertyTypeSchema = yup.object().shape({
  clientId: yup.string().required('Velg utleier'),
  propertyType: yup.string().required('Velg eiendomstype'),
  address: yup.string().required('Fyll inn adresse').min(2, 'Fyll inn adresse'),
  postalCode: yup
    .string()
    .required('Fyll inn postnummer')
    .min(4, 'Fyll inn postnummer')
    .max(4, 'Fyll inn postnummer'),
  postalLocation: yup
    .string()
    .required('Fyll inn sted')
    .min(2, 'Fyll inn sted'),
  units: yup.array().of(unitSchema).min(1).required()
});

const getObjectAndPropertyTypesList = (data: any) => {
  const objectAndPropertyList: PropertyType[] = [];
  data.prioritizedObjectTypes.forEach((i: PropertyType) =>
    objectAndPropertyList.push({
      id: objectAndPropertyList.length,
      name: i.name,
      type: 'object',
      typeId: i.id,
      label: i.name,
      priority: true,
      backgroundColor: '#E4EEEA'
    })
  );
  data.prioritizedPropertyTypes.forEach((i: PropertyType) =>
    objectAndPropertyList.push({
      id: objectAndPropertyList.length,
      name: i.name,
      type: 'property',
      typeId: i.id,
      label: i.name,
      priority: true,
      backgroundColor: '#E4EEEA'
    })
  );
  data.objectTypes.forEach((i: PropertyType) =>
    objectAndPropertyList.push({
      id: objectAndPropertyList.length,
      name: i.name,
      type: 'object',
      typeId: i.id,
      label: i.name,
      priority: true
    })
  );
  data.propertyTypes.forEach((i: PropertyType) =>
    objectAndPropertyList.push({
      id: objectAndPropertyList.length,
      name: i.name,
      type: 'property',
      typeId: i.id,
      label: i.name,
      priority: true
    })
  );

  return objectAndPropertyList;
};

const setLabel = (data: Client[]) => {
  return data.map((item) => ({
    ...item,
    label: item.name
  }));
};

interface Props {
  goBack?: () => void;
  callback?: () => void;
  isPrivate?: boolean;
  redirect?: string;
}

const Component = ({
  callback,
  goBack,
  isPrivate = false,
  redirect
}: Props): JSX.Element => {
  const { data: propertyInfo, isLoading } = useGetPropertyInfoQuery();
  const [sendForm, { isLoading: isSendingForm }] = useCreatePropertyMutation();
  const [isObjectType, setIsObjectType] = useState(true);
  const [validationSchema, setValidationSchema] = useState(
    validationObjectTypeSchema
  );

  const formik = useFormik({
    initialValues: {
      clientId: undefined,
      propertyType: undefined,
      address: '',
      postalCode: '',
      postalLocation: '',
      unitNumber: '',
      unitOptions: [],
      units: []
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const typeId =
        propertyInfo &&
        getObjectAndPropertyTypesList(propertyInfo)?.find(
          (t) => t.id === values.propertyType
        )?.typeId;

      const finalUnits = values.units.forEach((u: Unit) => {
        if (u.objectTypeId == '1') {
          u.objectTypeId = '10';
        }
        if (u.objectTypeId == '0') {
          u.objectTypeId = '1';
        }
      });

      console.log(values.units);
      console.log(finalUnits);

      const finalValues = {
        propertyViewModel: {
          clientId: values.clientId,
          address: values.address,
          zipCode: values.postalCode,
          city: values.postalLocation,
          propertyTypes: isObjectType
            ? null
            : [
                {
                  id: typeId
                }
              ],
          isMultiObject: !isObjectType
        },
        objectViewModels: isObjectType
          ? [{ objectTypeId: typeId, description: values.unitNumber }]
          : values.units
      };

      try {
        const formResult = await sendForm(finalValues).unwrap();

        if (!formResult.success) {
          throw 'Feil i opprettelse av eiendom.';
        }

        if (redirect) {
          window.location.replace(redirect);
        }
        callback && callback();
      } catch (err) {
        console.log(err);
      }
    }
  });

  useEffect(() => {
    if (
      isPrivate &&
      propertyInfo &&
      propertyInfo.clients &&
      setLabel(propertyInfo.clients).length > 0
    ) {
      formik.setFieldValue(
        'clientId',
        setLabel(propertyInfo.clients)[0].toString()
      );
    }
  }, [propertyInfo]);

  useEffect(() => {
    if (propertyInfo && formik.values.propertyType !== undefined) {
      const isObject = getObjectAndPropertyTypesList(propertyInfo)
        ?.filter((o) => o.type === 'object')
        ?.find((o) => o.id == formik.values.propertyType);
      setIsObjectType(!!isObject);
      const schema: any = isObject
        ? validationObjectTypeSchema
        : validationPropertyTypeSchema;
      setValidationSchema(schema);
    }
  }, [formik.values.propertyType]);

  const [addressOptions, setAddressOptions] = useState([]);

  useEffect(() => {
    async function getAdressInfo(searchAddress: string) {
      const result = await fetch(
        `https://ws.geonorge.no/adresser/v1/sok?sok=${encodeURIComponent(
          searchAddress
        )}&fuzzy=false&asciiKompatibel=true`
      );
      const parsed = await result.json();
      console.log(parsed);
      if (parsed.adresser && parsed.adresser.length > 0) {
        setAddressOptions(
          parsed.adresser.map((address: Address, index: number) => ({
            id: index,
            label: address.adressetekst,
            poststed: address.poststed,
            postnummer: address.postnummer,
            bruksenhetsnummer: address.bruksenhetsnummer
          }))
        );
      } else {
        setAddressOptions([]);
      }
    }

    if (formik.values.address.length > 3) {
      getAdressInfo(formik.values.address);
    }
  }, [formik.values.address]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction={{ xs: 'column', md: 'row' }}>
          <Stack padding={{ xs: 4, md: 7 }} width={{ xs: '100%', md: '35%' }}>
            <Typography variant="h5" fontWeight="bold">
              Legg til eiendom
            </Typography>
            <Typography>
              Registerer en eller flere eiendommer med tilhørende enheter.
            </Typography>
          </Stack>
          <Stack width={{ xs: '100%', md: '65%' }} alignItems="center">
            <Stack
              spacing={3}
              padding={{ xs: 4, md: 4 }}
              paddingTop={{ xs: 3, md: 8 }}
              width={{ xs: '100%', md: '75%' }}
              sx={{
                backgroundColor: '#E7E0DB'
              }}
            >
              {!isPrivate && (
                <Select
                  fullWidth
                  id="clientId"
                  name="clientId"
                  label="Utleier"
                  labelColor="black"
                  labelLeftMargin="0px"
                  value={formik.values.clientId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.clientId && Boolean(formik.errors.clientId)
                  }
                  helperText={formik.touched.clientId && formik.errors.clientId}
                  options={propertyInfo && setLabel(propertyInfo.clients)}
                />
              )}
              <Select
                fullWidth
                id="propertyType"
                name="propertyType"
                label="Eiendomstype"
                labelColor="black"
                labelLeftMargin="0px"
                placeholder="Velg"
                value={formik.values.propertyType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.propertyType &&
                  Boolean(formik.errors.propertyType)
                }
                helperText={
                  formik.touched.propertyType && formik.errors.propertyType
                }
                options={
                  propertyInfo && getObjectAndPropertyTypesList(propertyInfo)
                }
              />
              <Stack>
                <Typography fontWeight={'bold'}>Addresse</Typography>
                <Autocomplete
                  id="address"
                  disableClearable
                  size={'small'}
                  freeSolo
                  onBlur={() => formik.setFieldTouched('address', true)}
                  onChange={(e, value) => {
                    const address = value as unknown as Address;
                    formik.setFieldValue('postalCode', address.postnummer);
                    formik.setFieldValue('postalLocation', address.poststed);
                    formik.setFieldValue(
                      'unitOptions',
                      address.bruksenhetsnummer
                    );
                  }}
                  inputValue={formik.values.address}
                  onInputChange={(_, newInputValue) => {
                    formik.setFieldValue('address', newInputValue);
                  }}
                  sx={{
                    backgroundColor: white[20],
                    width: '80%',
                    borderRadius: '10px',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: '0 !important'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={'Søk på adresse'}
                      sx={{
                        backgroundColor: white[20],
                        height: '40px',
                        //py: '-5px',
                        borderRadius: '10px',
                        border:
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                            ? '2px solid red'
                            : 0
                      }}
                    />
                  )}
                  renderOption={(props, option: AddressOption) => (
                    <MenuItem
                      {...props}
                      key={option.id}
                      value={option.id}
                      sx={{
                        backgroundColor: '#ededed'
                      }}
                    >
                      <Grid container>
                        <Grid item xs={8}>
                          <Typography>{option.label}</Typography>
                        </Grid>
                        <Grid item xs={4} textAlign={'right'}>
                          <Typography variant="body2">
                            {option.poststed}
                          </Typography>
                          <Typography variant="body2">
                            {option.postnummer}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  )}
                  options={addressOptions}
                />
                <Typography pl="10px" color={'red'}>
                  {formik.touched.address && formik.errors.address}
                </Typography>
              </Stack>
              <Stack
                width={{ xs: 165, lg: 250 }}
                direction={{ xs: 'column', md: 'row' }}
                spacing={3}
              >
                <TextInput
                  id="postalCode"
                  name="postalCode"
                  label="Postnummer"
                  labelColor="black"
                  labelLeftMargin="0px"
                  placeholder="4 siffer"
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                />
                <TextInput
                  id="postalLocation"
                  name="postalLocation"
                  label="Sted"
                  labelColor="black"
                  labelLeftMargin="0px"
                  placeholder="Skriv inn"
                  value={formik.values.postalLocation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.postalLocation &&
                    Boolean(formik.errors.postalLocation)
                  }
                  helperText={
                    formik.touched.postalLocation &&
                    formik.errors.postalLocation
                  }
                />
              </Stack>
              {propertyInfo && isObjectType ? (
                <TextInput
                  id="unitNumber"
                  name="unitNumber"
                  label="Enhetsnummer"
                  labelColor="black"
                  labelLeftMargin="0px"
                  placeholder="Skriv inn"
                  value={formik.values.unitNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.unitNumber &&
                    Boolean(formik.errors.unitNumber)
                  }
                  helperText={
                    formik.touched.unitNumber && formik.errors.unitNumber
                  }
                />
              ) : (
                <>
                  <FieldArray
                    name="units"
                    render={(arrayHelpers) => (
                      <Stack spacing={8}>
                        {formik.values.units.map((unit, index) => (
                          <EditUnit
                            key={index}
                            formik={formik}
                            unitIndex={index}
                            options={
                              propertyInfo &&
                              getObjectAndPropertyTypesList(
                                propertyInfo
                              ).filter((o) => o.type == 'object')
                            }
                          />
                        ))}
                        {formik.values.address &&
                          formik.values.postalLocation &&
                          formik.values.postalCode?.length === 4 && (
                            <Stack direction="row" spacing={2}>
                              <AddUnits
                                formik={formik}
                                arrayHelpers={arrayHelpers}
                              />
                            </Stack>
                          )}
                      </Stack>
                    )}
                  />
                </>
              )}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <PrimaryButton disabled={!formik.isValid} filled type="submit">
                  Lagre
                </PrimaryButton>
              </Stack>
            </Stack>
          </Stack>
          <LoadingPopup open={isLoading || isSendingForm} />
        </Stack>
        {isPrivate && (
          <Stack
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              backgroundColor: '#D9CBC0',
              alignItems: 'flex-end',
              padding: 1
            }}
          >
            <Stack marginRight={20}>
              <PrimaryButton filled chevron="right" type="submit">
                Fullfør
              </PrimaryButton>
            </Stack>
          </Stack>
        )}
        {!isPrivate && goBack && (
          <Stack
            direction="row"
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              backgroundColor: '#D9CBC0',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 1
            }}
          >
            <Stack direction="row" spacing={2} marginLeft={20}>
              <Typography>Steg 3 av 3</Typography>
            </Stack>
            <Stack direction="row">
              <SecondaryButton
                filled
                chevron="left"
                onClick={() => goBack && goBack()}
              >
                Forrige steg
              </SecondaryButton>
            </Stack>
            <Stack direction="row" spacing={2} marginRight={20}>
              <SecondaryButton onClick={() => callback && callback()}>
                Hopp over steget
              </SecondaryButton>
              <PrimaryButton filled chevron="right" type="submit">
                Fullfør
              </PrimaryButton>
            </Stack>
          </Stack>
        )}
      </form>
    </FormikProvider>
  );
};

export default Component;
