// a library to wrap and simplify api calls
import { snackActions } from '@myrent/myrent-ui';
import apisauce, { ApiResponse } from 'apisauce';

import { Auth } from '../authentication';
import { apiConfig as config } from '../config';
import { error } from '../log';
import { createClient, ValidationProblem } from './contract';

const api = apisauce.create({
  // base URL is read from the "constructor"
  baseURL: config.host,

  // here are some default headers
  headers: {
    'Cache-Control': 'no-cache',
    Accept: 'application/json'
  },
  // 30 second timeout...
  timeout: 30000
});

api.addAsyncRequestTransform(async (request): Promise<void> => {
  const { loginRequest, msalInstance } = Auth.auth; // seems not possible to use hook useAuthentication() here
  if (!loginRequest || !msalInstance) {
    throw Error(
      'Attempted to call authenticated endpoint without authentication / MSAL components'
    );
  }

  //get the active account
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  //acquire token
  const tokenResponse = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  const accessToken = tokenResponse.accessToken;
  if (!accessToken) {
    return;
  }

  request.headers.Authorization = `Bearer ${accessToken}`;
});

const handleValidationProblem = (
  response: ApiResponse<ValidationProblem>,
  problem: string,
  status: number | undefined
) => {
  try {
    const errors = response.data?.errors;
    if (errors) {
      for (const value of Object.values(errors))
        if (value && value.length) {
          snackActions.error(`Validering feilet på server: ${value[0]}`);
        }
    }
  } catch (e) {
    snackActions.error(
      `En uventet feil oppstod - ${problem}${status ? ' : ' + status : ''}`
    );
  }
};

api.addMonitor((response): void => {
  if (!response.ok) {
    const { problem, status } = response;
    error('Request failed', problem);

    const isValidationError = response.status === 422 && response.data?.errors;
    const friendlyInfo = response.data['friendlyInfo'];
    if (isValidationError) {
      handleValidationProblem(response, problem, status);
    } else if (friendlyInfo) {
      snackActions.error(friendlyInfo.title);
    } else {
      snackActions.error(
        `En uventet feil oppstod - ${problem}${status ? ' : ' + status : ''}`
      );
    }
  }
});
export default createClient(api);
