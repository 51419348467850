import { apiSlice } from '../index';
import {
  Client,
  ClientContactInfo,
  CreateClientViewModel,
  GetComplementaryInfoNeededResponse,
  GetDaughterOrganizationsResponse,
  User
} from './types';

const clientApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientOverview: builder.query<Client, number>({
      query: (clientId) => ({
        url: `v1/Client/GetClientOverview`,
        params: {
          clientId
        }
      })
    }),
    createClient: builder.mutation<any, CreateClientViewModel>({
      query: (payload) => ({
        url: 'Client/CreateClientSubmit',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      }),
      invalidatesTags: ['Context']
    }),
    getComplementaryInfoNeeded: builder.query<
      GetComplementaryInfoNeededResponse,
      void
    >({
      query: () => 'v1/Client/GetComplementaryInfoNeeded'
    }),
    getClients: builder.query<User[], void>({
      query: () => ({
        url: 'v1/Client/GetClientNames'
      })
    }),
    getDaughterOrganizatons: builder.query<
      GetDaughterOrganizationsResponse,
      any
    >({
      query: (organizationNumber) => ({
        url: 'v1/Organization/GetDaughterOrganizations',
        params: {
          organizationNumber
        }
      })
    }),
    getClientContactInfo: builder.query<ClientContactInfo, number>({
      query: (id) => ({
        url: `v1/client/${id}/contact-person`
      })
    })
  }),
  overrideExisting: false
});

export const {
  useLazyGetClientOverviewQuery,
  useCreateClientMutation,
  useGetComplementaryInfoNeededQuery,
  useGetClientsQuery,
  useGetDaughterOrganizatonsQuery,
  useLazyGetDaughterOrganizatonsQuery,
  useGetClientContactInfoQuery
} = clientApi;
