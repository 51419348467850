import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';

import { setPath, setUploadSend, Tenant } from '../contractPathSlice';
import { addContractSteps, updatePath } from '../steps';

const noEmail = (tenants: Tenant[]) => {
  return tenants.findIndex((tenant) => tenant.noEmail == true) > -1;
};

interface Props {
  updateStep: (stepId: string) => Promise<void>;
}

const Component = ({ updateStep }: Props): JSX.Element => {
  const contract = useAppSelector((state) => state.contract);
  const dispath = useAppDispatch();
  dispath(setUploadSend(false));

  return (
    <Stack spacing={6}>
      <Stack direction={'row'} spacing={4}>
        <Stack>
          <Typography fontWeight="bold">For utleie av:</Typography>
          <Typography>{`${contract.object.propertyAddress} ${
            contract.object.description
              ? '- ' + contract.object.description
              : ''
          } `}</Typography>
        </Stack>
        <Stack>
          <Typography fontWeight="bold">Til:</Typography>
          {contract.tenants.map((tenant: Tenant, index: number) => {
            return <Typography key={index}>{tenant.name}</Typography>;
          })}
        </Stack>
      </Stack>
      <Stack>
        <Typography variant="h5" fontWeight="bold">
          Hva vil du gjøre?
        </Typography>
        <Typography variant="body1" fontWeight="normal" sx={{ opacity: 0.5 }}>
          Velg et av alternativene nedenfor
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Box>
          <SecondaryButton
            filled={contract.path == '1-upload'}
            onClick={() => {
              dispath(setPath('1-upload'));
              updateStep('1-upload');
            }}
          >
            Last opp eksisterende kontrakt
          </SecondaryButton>
        </Box>
        <Box>
          <SecondaryButton
            disabled={noEmail(contract.tenants)}
            filled={contract.path == '1-edit'}
            onClick={() => {
              dispath(setPath('1-edit'));
              updateStep('1-edit');
            }}
          >
            Opprett digital kontrakt
          </SecondaryButton>
        </Box>
        {noEmail(contract.tenants) && (
          <Typography color={'red'}>
            Leietaker har ikke e-post. Fyll inn epost for å opprette digital
            kontakt
          </Typography>
        )}
        <Box>
          <SecondaryButton
            filled={contract.path == '3-close'}
            onClick={() => {
              dispath(setPath('3-close'));
              updateStep('3-close');
            }}
          >
            Lagre leieforhold uten kontrakt
          </SecondaryButton>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Component;
