/* eslint-disable @typescript-eslint/no-explicit-any */
import { appConfig } from '../config';
/**
 * Centralized log point with options for multiple sinks
 *
 * @param params same as you'd provide to console.error
 */
export const log = (...params: any) => console.log(...params);
/**
 * Debug logs if log setting allows it
 * @param params
 */
export const debug = (...params: any) =>
  appConfig.logDebug && console.debug(...params);
/**
 * Centralized log point with options for multiple sinks
 *
 * @param params same as you'd provide to console.error
 */
export const error = (...params: any) => console.error(...params);
