import { Stack, TextField } from '@mui/material';

interface Props {
  label: string;
  onChange: (e: string) => void;
}

export default function Component({ label, onChange }: Props) {
  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        // value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          borderRadius: '20px'
        }}
      />
    </Stack>
  );
}
