import { ErrorMessage } from '@myrent/myrent-ui';
import React, { ErrorInfo } from 'react';

interface Props {
  children?: React.ReactNode | JSX.Element;
}

interface State {
  hasError: boolean;
}

export class LayoutErrorBoundary extends React.Component<Props, State> {
  state: State = { hasError: false };

  static getDerivedStateFromError(): object {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <ErrorMessage>Noe gikk galt</ErrorMessage>;
    }

    return children;
  }
}
