import './styles.css';

import { Stack, Typography } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';

import { Container, Input, Label } from './styles';

interface Props {
  id: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<any>) => void;
  onBlur: (event: React.ChangeEvent<any>) => void;
  error: boolean | undefined;
  helperText: string | false | undefined;
  type?: string;
  placeholder?: string;
  label: string;
  labelColor?: string;
  labelLeftMargin?: string;
  fullWidth?: boolean;
  maxLength?: number;
  labelWeight?: string;
  mask: string; // #### #### #### ####
  disabled?: boolean;
  bgColor?: string;
}

const Component = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  label,
  labelColor = 'white',
  labelLeftMargin = '10px',
  helperText,
  fullWidth = false,
  labelWeight = 'bold',
  mask,
  disabled = false,
  bgColor = 'white'
}: Props): JSX.Element => {
  return (
    <Container>
      <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
        {label && (
          <Label htmlFor={name}>
            <Typography
              color={labelColor}
              marginLeft={labelLeftMargin}
              fontWeight={labelWeight}
            >
              {label}
            </Typography>
          </Label>
        )}
        <NumberFormat
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          type="tel"
          placeholder={placeholder}
          disabled={disabled}
          style={{
            backgroundColor: bgColor
          }}
          customInput={Input}
          format={mask}
          className={`numberformat ${disabled ? 'disabled' : ''}`}
        />
        {helperText && (
          <Container>
            <Typography
              color="red"
              marginLeft="10px"
              sx={{ position: 'absolute', mt: '3px' }}
            >
              {helperText}
            </Typography>
          </Container>
        )}
      </Stack>
    </Container>
  );
};

export default Component;
