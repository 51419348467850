import { Divider, Stack, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import { Dialog } from 'app/components/dialog';
import { SubscriptionInput } from 'app/components/input/subscription';
import { Upsell } from 'app/components/upsell';
import { useAppSelector } from 'app/hooks';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { FormikRegisterValues } from '../../component';
import { ScrollableContainer } from './styles';

interface Props {
  formik: FormikProps<FormikRegisterValues>;
  nextPageTitle: string;
  nextPagePath: string;
  subscriptions?: string;
}

export const subscriptionValidationSchema = yup.object({
  subscription: yup.string().required('Velg abonnement')
});

const Component = ({
  formik,
  nextPageTitle,
  nextPagePath,
  subscriptions = 'private'
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const modal = useAppSelector((state) => state.modal.modal);

  return (
    <div>
      <Typography variant="h6" color="white">
        Ditt abonnement
      </Typography>
      <Divider color="white" />

      <Stack spacing={5} paddingTop={2} width={{ md: '100%' }}>
        <ScrollableContainer>
          <Stack direction="row" spacing={2} padding={1}>
            <SubscriptionInput
              id={'1'}
              name={'Privat'}
              value={formik.values.subscription}
              onChange={(e) =>
                formik.setFieldValue('subscription', e.target.id)
              }
              onBlur={formik.handleBlur}
              subscriptionTitle="Myrent Fri"
              subscriptionDescription=""
              subscriptionTennants="Inneholder basisfunksjoner som digital kontrakt med BankID signering, automatisk fakturering og innflyttingsprotokoll."
              subscriptionUnits=""
              subscriptionInfo="Les mer her"
              subscriptionPrice={0}
              helperText={undefined}
            />
            <SubscriptionInput
              id={'2'}
              name={'Business'}
              value={formik.values.subscription}
              onChange={(e) =>
                formik.setFieldValue('subscription', e.target.id)
              }
              onBlur={formik.handleBlur}
              subscriptionTitle="Myrent Pluss"
              subscriptionDescription=""
              subscriptionTennants="Tilgang til alle funksjoner i systemet, feks KPI-regulering av leie, fakturering med Vipps, EHF og regnskapsintegrasjon."
              subscriptionUnits=""
              subscriptionInfo="Les mer her"
              subscriptionPrice={39}
              helperText={undefined}
            />
          </Stack>
        </ScrollableContainer>
        <Typography color={'red'}>
          {formik.touched.subscription && formik.errors.subscription}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <PrimaryButton
            filled
            type="submit"
            onClick={() => {
              formik.setFieldTouched('subscription', true);
            }}
            chevron={'right'}
          >
            {nextPageTitle}
          </PrimaryButton>
        </Stack>
        <Typography color={'red'}>
          {formik.values.submitMsg && formik.errors.submitMsg}
        </Typography>
        <Dialog isOpen={modal === 'upsell'}>
          <Upsell />
        </Dialog>
      </Stack>
    </div>
  );
};

export default Component;
