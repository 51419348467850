import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Stack, Typography } from '@mui/material';
import { DashboardCard } from 'app/components/card';
import { Dialog } from 'app/components/dialog';
import { CountDisplay } from 'app/components/display';
import { ExternalLink } from 'app/components/link';
import { Table } from 'app/components/table';
import { TableColumn, TableRow } from 'app/components/table/component';
import { useGetWarningListQuery } from 'app/context/api/account';
import { useGetNavGuaranteeExpiresQuery } from 'app/context/api/contract';
import { NavGuaranteeExpiresResponse } from 'app/context/api/contract/types';
import { red } from 'app/theme/colors';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { useState } from 'react';

const columns: TableColumn[] = [
  {
    title: 'Utløpsdato for garanti',
    order: 3
  },
  {
    title: 'Leietaker',
    order: 1
  },
  {
    title: 'Objekt',
    order: 2
  },
  {
    title: '',
    align: 'right',
    order: 4
  }
];

const getRows = (data: NavGuaranteeExpiresResponse[]) => {
  const rows: TableRow[][] = [];
  data.map((row) => {
    rows.push([
      {
        value: lightFormat(parseISO(row.navGuaranteeEndDate), 'dd.MM.yyyy'),
        order: 3
      },
      {
        value: row.mainTenantName,
        order: 1
      },
      {
        value: row.objectDescription + ', ' + row.propertyAddress,
        order: 2
      },
      {
        value: (
          <Box display={'flex'} alignItems={'center'}>
            <ExternalLink
              to={`User/EditTenant?contractId=${row.contractId}&tab=contract&userId=${row.mainTenantId}`}
            >
              <ArrowForwardIcon
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  borderRadius: '25px'
                }}
              />
            </ExternalLink>
          </Box>
        ),
        order: 4
      }
    ]);
  });
  return rows;
};

interface Props {
  isWarnings: boolean;
}

const Component = ({ isWarnings }: Props): JSX.Element => {
  const { data } = useGetWarningListQuery();
  const { data: navData } = useGetNavGuaranteeExpiresQuery(2);
  const [open, setOpen] = useState(false);

  return (
    <>
      {data && isWarnings && (
        <DashboardCard
          mainIcon={<PriorityHighIcon />}
          iconColor={red[80]}
          title={'Viktige varsel'}
          firstColumn={
            <Stack spacing={1}>
              {data.CountUnsentDebtCollections > 0 && (
                <CountDisplay
                  title={'Forfalte inkassovarsel'}
                  count={data.CountUnsentDebtCollections}
                  link={'Invoice/InvoiceReadyForCollection'}
                  body={'body2'}
                />
              )}
              {data.CountNavGuaranteeExpires > 0 && (
                <CountDisplay
                  title={'NAV garantier som utløper eller har utløpt'}
                  count={data.CountNavGuaranteeExpires}
                  link={() => setOpen(true)}
                  body={'body2'}
                />
              )}
              {data.PreviousMonthUnpaidInvoicesCount > 0 && (
                <CountDisplay
                  title={'Utestående fakturaer (forrige mnd)'}
                  count={data.PreviousMonthUnpaidInvoicesCount}
                  link={
                    'Invoice/SentInvoices?excludeStatuses=True&period=0&statuses=7&statuses=9&statuses=13'
                  }
                  body={'body2'}
                />
              )}
            </Stack>
          }
        />
      )}
      <Dialog isOpen={open} setIsOpen={() => setOpen(!open)}>
        <Stack
          spacing={2}
          py={10}
          px={{ xs: 1, sm: 3, md: 15 }}
          display={'flex'}
          flexWrap={'wrap'}
        >
          <Typography variant="h1" sx={{ pb: 2 }}>
            Utløpende NAV-garantier
          </Typography>
          {navData && (
            <Table
              data={{
                columns: columns,
                rows: getRows(navData)
              }}
            />
          )}
        </Stack>
      </Dialog>
    </>
  );
};

export default Component;
