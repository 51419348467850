import { Stack, Typography } from '@mui/material';
import { green, white } from 'app/theme/colors';

export function DetailPill({
  label,
  value,
  valueColor
}: {
  label: string;
  value: string;
  valueColor?: string;
}) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        padding: 2,
        backgroundColor: white[100],
        borderRadius: 10
      }}
    >
      <Typography variant="body1">{label}</Typography>
      <Typography
        variant="body1"
        color={valueColor || green[100]}
        fontWeight="bold"
      >
        {value}
      </Typography>
    </Stack>
  );
}
