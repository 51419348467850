import CSS from 'csstype';

const aStyles: CSS.Properties = {
  color: 'inherit',
  textDecoration: 'none'
};

interface Props {
  to?: string;
  children?: React.ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

const Component = ({
  to = '',
  children,
  target = '_self'
}: Props): JSX.Element => {
  return (
    <a
      target={target}
      style={aStyles}
      href={process.env.REACT_APP_API_URL + to}
    >
      {children}
    </a>
  );
};

export default Component;
