import ApartmentIcon from '@mui/icons-material/Apartment';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Stack, Typography } from '@mui/material';

export function getPropertyIcon(
  propertyType: 'Bygård' | 'Leilighet' | 'Enebolig' | 'Bod' | string
) {
  const options = {
    Bygård: <LocationCityIcon />,
    Leilighet: <ApartmentIcon />,
    Enebolig: <HomeIcon />,
    Bod: <WarehouseIcon />,
    Lager: <WarehouseIcon />,
    Rekkehus: <HolidayVillageIcon />,
    Kjøpesenter: <StoreMallDirectoryIcon />
  };

  const existingOption = Object.keys(options).find(
    (key) => key === propertyType
  ) as keyof typeof options | undefined;

  const currentOption = existingOption ? options[existingOption] : <HomeIcon />;

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      sx={{
        alignItems: 'center'
      }}
    >
      {currentOption}
      <Typography fontSize={14}>{propertyType}</Typography>
    </Stack>
  );
}
