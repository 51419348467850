import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PrimaryButton } from 'app/components/button';
import { closeModal } from 'app/context/addModal/addModalSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

interface Props {
  onClose?: string;
}

const Component = ({ onClose }: Props): JSX.Element => {
  const contract = useAppSelector((state) => state.contract);
  const dispatch = useAppDispatch();

  return (
    <Stack spacing={2} padding={{ xs: 1, md: 10, lg: 20 }}>
      <Typography variant="h1">Godt gjort!</Typography>
      <Typography>{contract.closeMsg}</Typography>
      <Box>
        <PrimaryButton
          icon={<CloseIcon />}
          filled
          onClick={
            onClose
              ? () => window.location.replace(onClose)
              : () => dispatch(closeModal())
          }
        >
          Lukk dette vinduet
        </PrimaryButton>
      </Box>
    </Stack>
  );
};

export default Component;
