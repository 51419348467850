import { Box } from '@mui/system';
import { useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { LayoutErrorBoundary } from '../LayoutErrorBoundary';
import { Container, ContentContainer, Tab, TabContainer } from './styles';

interface Props {
  children?: React.ReactNode;
}

const Component = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user.isAuthenticated) {
      navigate('/');
    }
  }, [user]);

  return (
    <Container>
      <LayoutErrorBoundary>
        <Box sx={{ flexGrow: 1 }} padding={{ xs: 1, md: 4 }} maxWidth="xxl">
          <TabContainer>
            <Tab
              active={pathname.includes('login')}
              left
              onClick={() => navigate('/login')}
            >
              Logg inn
            </Tab>
            <Tab
              active={!pathname.includes('/login')}
              onClick={() => navigate('/register')}
            >
              Ny bruker +
            </Tab>
          </TabContainer>
          <ContentContainer>
            <Outlet />
          </ContentContainer>
        </Box>
      </LayoutErrorBoundary>
    </Container>
  );
};

export default Component;
