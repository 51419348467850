import { apiSlice } from '../index';
import {
  ArchivePropertyRequest,
  ObjectRequest,
  ObjectResponse,
  PropertiesParams,
  PropertyType,
  UnarchivePropertyRequest
} from './types';

const propertyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getObjects: builder.query<ObjectResponse, ObjectRequest>({
      query: (data) => ({
        url: 'v1/Property/GetObjects',
        params: {
          clientId: data.clientId,
          includeDisabled: false
        }
      })
    }),
    getAvaliableObjects: builder.query({
      query: (payload) => ({
        url: 'v1/Property/GetAvaliableObjects',
        params: payload
      })
    }),
    getPropertyInfo: builder.query<any, void>({
      query: () => ({
        url: 'SimplePropertyWizard/Property'
      }),
      providesTags: ['Property']
    }),
    createProperty: builder.mutation({
      query: (payload) => ({
        url: 'SimpleWizard/PropertySubmit',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      }),
      invalidatesTags: ['Property', 'Context']
    }),
    getPropertyNames: builder.query<any, void>({
      query: () => ({
        url: 'v1/Property/GetPropertyNames'
      })
    }),
    getProperties: builder.query<any, PropertiesParams>({
      query: (params: PropertiesParams) => ({
        url: '/v1/property/properties',
        params
      }),
      providesTags: ['Property']
    }),
    getPropertyTypes: builder.query<PropertyType[], void>({
      query: () => ({
        url: '/v1/property/types'
      })
    }),
    archiveProperty: builder.mutation<ArchivePropertyRequest, any>({
      query: (payload) => ({
        url: '/v1/property/archive',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      }),
      invalidatesTags: ['Property', 'Context']
    }),
    unarchiveProperty: builder.mutation<UnarchivePropertyRequest, any>({
      query: (payload) => ({
        url: '/v1/property/unarchive',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      }),
      invalidatesTags: ['Property', 'Context']
    })
  }),
  overrideExisting: false
});

export const {
  useGetPropertyTypesQuery,
  useGetPropertyNamesQuery,
  useLazyGetPropertiesQuery,
  useGetObjectsQuery,
  useGetAvaliableObjectsQuery,
  useCreatePropertyMutation,
  useGetPropertyInfoQuery,
  useArchivePropertyMutation,
  useUnarchivePropertyMutation
} = propertyApi;
