import { Container, Stack, Typography } from '@myrent/myrent-ui';
import { PrimaryButton } from 'app/components/button';
import { Dialog } from 'app/components/dialog';
import { LoadingPopup } from 'app/components/loading';
import { Upsell } from 'app/components/upsell';
import { useRegisterMutation } from 'app/context/api/account';
import { login } from 'app/context/user/userSlice';
import { useAppDispatch } from 'app/hooks';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

import { RegisterCompany, RegisterPrivate } from '.';
import {
  companyInfoValidationSchema,
  privateInfoValidationSchema
} from './components/forms/Info';
import { userValidationSchema } from './components/forms/User';

interface Props {
  url?: string;
  children?: React.ReactNode;
}

interface RegisterForm {
  Email: string;
  Password: string;
  FirstName: string;
  LastName: string;
  OrganizationType: number;
  Cellphone: string;
  CompanyEmail?: string;
  CompanyName?: string;
  OrganizationNumber?: number;
  SubscriptionType: number;
}

export interface FormikRegisterValues {
  userInfo: {
    email: string;
    password: string;
    acceptTerms: boolean;
  };
  contactInfo: {
    invoiceEmail: string;
    phone: string;
    firstName: string;
    lastName: string;
    companyName: string;
    organizationNumber: string;
  };
  subscription: string;
  OrganizationType: number;
  submitMsg: boolean;
}

const Component = ({ children }: Props): JSX.Element => {
  const { userType } = useParams();
  const navigate = useNavigate();
  const [sendForm, { isLoading }] = useRegisterMutation();
  const [schema, setSchema] = useState(2);
  const dispatch = useAppDispatch();

  const validationSchema = yup.object({
    userInfo: userValidationSchema,
    contactInfo:
      schema == 2 ? companyInfoValidationSchema : privateInfoValidationSchema,
    subscription: yup.string().required('Velg abonnement')
  });

  const formik = useFormik({
    initialValues: {
      userInfo: {
        email: '',
        password: '',
        acceptTerms: false
      },
      contactInfo: {
        invoiceEmail: '',
        phone: '',
        firstName: '',
        lastName: '',
        companyName: '',
        organizationNumber: ''
      },
      subscription: '',
      OrganizationType: 0,
      submitMsg: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const registerValues: RegisterForm = {
          Email: values.userInfo.email,
          Password: values.userInfo.password,
          FirstName: values.contactInfo.firstName,
          LastName: values.contactInfo.lastName,
          OrganizationType: values.OrganizationType,
          Cellphone: values.contactInfo.phone,
          SubscriptionType: parseInt(values.subscription)
        };
        if (values.OrganizationType == 2) {
          registerValues.CompanyEmail = values.contactInfo.invoiceEmail;
          registerValues.CompanyName = values.contactInfo.companyName;
          registerValues.OrganizationNumber = parseInt(
            values.contactInfo.organizationNumber.replaceAll(' ', '')
          );
          registerValues.FirstName = values.contactInfo.companyName;
          registerValues.LastName = 'AS';
        }
        const formResult = await sendForm(registerValues).unwrap();

        if (formResult.success) {
          dispatch(login());
          navigate('/');
        } else {
          formik.setFieldError(
            'submitMsg',
            formResult.errorMessage?.replace('_', ' ') || 'Noe gikk galt'
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  });

  useEffect(() => {
    setSchema(formik.values.OrganizationType);
    console.log(formik.values.OrganizationType);
  }, [formik.values.OrganizationType]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Routes>
          <Route
            index
            element={
              <Container>
                <Stack
                  spacing={4}
                  justifyContent="center"
                  alignItems="center"
                  height="70vh"
                >
                  <Typography color="white" fontSize={30}>
                    Leier du ut som privatperson eller bedrift?
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <PrimaryButton
                      onClick={() => {
                        formik.setFieldValue('OrganizationType', 4);
                        navigate('privatperson');
                      }}
                    >
                      Privatperson
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() => {
                        formik.setFieldValue('OrganizationType', 2);
                        navigate('bedrift');
                      }}
                    >
                      Bedrift
                    </PrimaryButton>
                  </Stack>
                </Stack>
              </Container>
            }
          />
          <Route
            path="privatperson/*"
            element={<RegisterPrivate formik={formik} />}
          />
          <Route
            path="bedrift/*"
            element={<RegisterCompany formik={formik} />}
          />
        </Routes>
      </form>
      <LoadingPopup open={isLoading} />
    </>
  );
};

export default Component;
