import { Stack } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { useGetContractTemplateKeyWordsQuery } from 'app/context/api/contract';
import { useEffect, useState } from 'react';

import {
  mapKeywordToValue,
  mapValueToKeyword,
  postfix,
  prefix
} from './editor-utils';

interface Props {
  initContent?: string;
  sendContent: (content: string) => void;
}

export interface KeyWordsCategories {
  text: string;
  color: string;
  subitems: {
    key: string;
    value: string;
  }[];
}

const Component = ({ initContent, sendContent }: Props): JSX.Element => {
  const { data: keyWords } = useGetContractTemplateKeyWordsQuery();

  const [content, setContent] = useState<string>('');

  const formatKeyWords = () => {
    const categories: KeyWordsCategories[] = [
      {
        text: 'Utleier',
        color: 'deepskyblue',
        subitems: []
      },
      {
        text: 'Leietaker',
        color: 'khaki',
        subitems: []
      },
      {
        text: 'Kontraktinfo',
        color: 'lightblue',
        subitems: []
      },
      {
        text: 'Utleieobjekt',
        color: 'lightgreen',
        subitems: []
      },
      {
        text: 'Økonomi',
        color: 'salmon',
        subitems: []
      },
      {
        text: 'Medleietaker',
        color: 'orange',
        subitems: []
      }
    ];
    if (keyWords) {
      keyWords.forEach((word) => {
        const index = categories.findIndex((obj) => {
          return obj.text === word.category;
        });
        categories[index].subitems.push({ key: word.key, value: word.value });
      });
    }
    return categories;
  };

  useEffect(() => {
    if (keyWords && content.length > 0) {
      sendContent(mapValueToKeyword(content, formatKeyWords()));
    }
  }, [content]);

  return (
    <Stack>
      {keyWords && (
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          initialValue={mapKeywordToValue(initContent ?? '', formatKeyWords())}
          init={{
            height: '75vh',
            entity_encoding: 'raw',
            plugins:
              'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
            toolbar:
              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | anchor',
            menu: {
              custom: {
                title: 'Sett inn data',
                items: formatKeyWords()
                  .map((word) => word.text)
                  .toString()
                  .replaceAll(',', ' ')
              }
            },
            menubar: 'edit custom',
            setup: (editor) => {
              formatKeyWords().forEach((word) => {
                editor.ui.registry.addNestedMenuItem(word.text, {
                  text: word.text,
                  getSubmenuItems: () => {
                    const subItems: any = [];
                    word.subitems.forEach((subitem) => {
                      subItems.push({
                        type: 'menuitem',
                        text: subitem.value,
                        onAction: () => {
                          editor.insertContent(
                            prefix(word.color) +
                              subitem.value.toUpperCase() +
                              postfix() +
                              ' '
                          );
                        }
                      });
                    });
                    return subItems;
                  }
                });
              });
            }
          }}
          onEditorChange={(newContent, editor) => setContent(newContent)}
        />
      )}
    </Stack>
  );
};
export default Component;
