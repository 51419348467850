import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Grid,
  Menu,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useGetContextQuery } from 'app/context/api/account';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { green, white } from 'app/theme/colors';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ContextItem } from './contextLevel';
import { setContext } from './contextSlice';

interface ContextMenuState {
  clientIndex: number;
  propertyIndex: number;
}

export interface ContextMenuItem {
  id: number;
  type: 'user' | 'client' | 'property' | 'object';
  label: string;
  items?: ContextMenuItem[];
}

const Component = (): JSX.Element => {
  const { data: context } = useGetContextQuery();

  const contextState = useAppSelector((state) => state.context);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>();

  const divRef = useRef<HTMLDivElement>(null);

  function handleClick() {
    setAnchorEl(divRef.current);
  }

  function handleClose(type?: string, id?: number) {
    setAnchorEl(undefined);
    if (type) {
      dispatch(setContext({ type: type, id: id }));
    }
  }

  const getContext = useCallback(
    (level: string) => {
      const contextItem: ContextMenuItem = {
        id: 0,
        label: '',
        type: 'user'
      };

      if (context) {
        if (context.clients.length > 0) {
          if (context.clients[0].clientType == 1) {
            contextItem.label = 'Vis alle';
            contextItem.items = context.clients.map((client) => ({
              id: client.clientId,
              label: client.clientName,
              type: 'client',
              items:
                level == 'client'
                  ? []
                  : client.properties.map((property) => ({
                      id: property.propertyId,
                      label: property.propertyName,
                      type: 'property',
                      items:
                        level == 'property'
                          ? []
                          : property.objects.map((object) => ({
                              id: object.id,
                              label: object.description ?? 'Uten navn',
                              type: 'object'
                            }))
                    }))
            }));
            return contextItem;
          } else {
            contextItem.items = context.clients[0].properties.map(
              (property) => ({
                id: property.propertyId,
                label: property.propertyName,
                type: 'property',
                items:
                  level == 'property'
                    ? []
                    : property.objects.map((object) => ({
                        id: object.id,
                        label: object.description ?? 'Uten navn',
                        type: 'object'
                      }))
              })
            );
          }
        }
        contextItem.label = context.firstName + ' ' + context.lastName;
      }
      return contextItem;
    },
    [context]
  );

  useEffect(() => {
    const contextItem: ContextMenuItem = {
      id: 0,
      label: 'Vis alle',
      type: 'user'
    };

    if (context) {
      if (context.clients.length > 0) {
        if (context.clients[0].clientType == 2) {
          contextItem.label = `${context.firstName} ${context.lastName}`;
        }
      }
    }
    dispatch(setContext(contextItem));
  }, [context]);

  const open = Boolean(anchorEl);

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div ref={divRef}>
      <Grid
        container
        sx={{
          backgroundColor: white[100],
          borderRadius: '25px',
          height: '40px',
          width: mobileScreen ? '280px' : '400px',
          flexShrink: 1,
          boxShadow: '0px 3px 6px lightgray'
        }}
        justifyContent="flex-end"
        alignItems={'center'}
      >
        <Grid item xs={9}>
          <Typography variant="body1">
            {context && contextState.context.label}
          </Typography>
        </Grid>
        <Grid item xs={2} height="40px">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={'center'}
            height="40px"
          >
            <ExpandMoreIcon
              onClick={(event) => handleClick()}
              sx={{
                backgroundColor: green[80],
                height: '40px',
                padding: '5px',
                width: '40px',
                borderRadius: '25px',
                cursor: 'pointer'
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Menu
        id="switch"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => handleClose()}
        MenuListProps={{
          disablePadding: true
        }}
        PaperProps={{
          style: {
            maxHeight: '50%',
            width: mobileScreen ? '280px' : '400px',
            backgroundColor: white[100],
            borderRadius: '25px'
          }
        }}
      >
        <Stack
          key={'velg'}
          sx={{
            backgroundColor: white[20],
            padding: '25px',
            pt: '35px',
            mb: '5px',
            top: 0
          }}
        >
          <Typography variant="h6" fontWeight={'bold'}>
            Velg
          </Typography>
          <Typography>hva du vil se informasjon om</Typography>
          <ExpandLessIcon
            onClick={(event) => handleClose()}
            sx={{
              backgroundColor: green[80],
              borderRadius: '25px',
              height: '40px',
              padding: '5px',
              width: '40px',
              cursor: 'pointer',
              position: 'absolute',
              top: 0,
              right: 0
            }}
          />
        </Stack>
        {context && <ContextItem item={getContext('property')} />}
      </Menu>
    </div>
  );
};

export default Component;
