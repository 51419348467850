import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Collapse,
  Container,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography
} from '@mui/material';
import MyRentLogo from 'app/assets/myrentLogo/myrent-logo-cropped.png';
import { useGetContextQuery, useGetMenuQuery } from 'app/context/api/account';
import { useGetComplementaryInfoNeededQuery } from 'app/context/api/client';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dark, green } from 'app/theme/colors';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useLocation } from 'react-use';

import { toggle } from './toggleMenuSlice';

export interface MenuItem {
  name: string;
  order: number;
  linkType: number;
  url?: string;
  controller?: string;
  action?: string;
  children: MenuItem[];
}

interface Props {
  anchor: 'left' | 'top' | 'right' | 'bottom';
}

const Component = ({ anchor }: Props): JSX.Element => {
  const { data: menuItems } = useGetMenuQuery(1);
  const [active, setActive] = useState(-1);

  const { data: complementaryInfoNeededResponse } =
    useGetComplementaryInfoNeededQuery();
  const { data: userContext } = useGetContextQuery();

  const navigate = useNavigate();
  const location = useLocation();
  const isCurrent = (menuItem: MenuItem) => {
    if (menuItem.controller == 'Home' && location.pathname == '/') return true;

    if (
      menuItem.name == 'Leieforhold' &&
      (location.pathname?.includes('cpi') ||
        location.pathname?.includes('customregulation'))
    ) {
      return true;
    }

    if (
      menuItem.name == 'KPI-regulering' &&
      location.pathname?.includes('cpi')
    ) {
      return true;
    }

    if (
      menuItem.name == 'Gjengsleie' &&
      location.pathname?.includes('customregulation')
    ) {
      return true;
    }

    const path = menuItem.controller + '/' + menuItem.action;
    if (location.pathname?.includes(path)) {
      return true;
    }

    return false;
  };

  const open = useAppSelector((state) => state.menu.open);
  const dispatch = useAppDispatch();
  const mobileView = anchor == 'top';
  function sortMenuItems(menuItems: MenuItem[]) {
    const unsortedItems = menuItems.slice();
    const sortedMenuItems = unsortedItems.sort(function (
      a: MenuItem,
      b: MenuItem
    ) {
      return a.order - b.order;
    });
    return sortedMenuItems;
  }

  function toggleMenu(index: number) {
    if (index == active) {
      setActive(-1);
    } else {
      setActive(index);
    }
  }

  const getInternalMenuPath = (item: MenuItem) => {
    if (item.controller == 'Home') return '/';
    return item.controller + '/' + item.action;
  };

  const getExternalHref = (subItem: MenuItem) => {
    if (subItem.linkType == 1) {
      return `${process.env.REACT_APP_API_URL}${subItem.controller}/${subItem.action}`;
    }
    if (subItem.linkType == 2) {
      return `${process.env.REACT_APP_API_URL}${subItem.url}`;
    }
    return '';
  };

  return (
    <Container
      sx={{
        backgroundColor: dark[100]
      }}
    >
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: dark[100]
          }
        }}
        color={dark[100]}
        sx={{
          width: mobileView ? '100%' : open ? '280px' : '50px',
          transition: 'all .1 ease-in-out',
          fontSize: '12.7px',
          fontFamily: 'Open Sans, sans-serif',
          fontWeight: 600,
          lineHeight: 1.42857143,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: mobileView ? '100%' : open ? '280px' : '50px',
            boxSizing: 'border-box',
            overflow: 'visible',
            transition: 'all .1s ease-in-out'
          },
          '& .MuiDrawer-paper.hover': {
            backgroundColor: 'red'
          },
          '&.Mui-selected': {
            backgroundColor: '#668B8E'
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#668B8E'
          }
        }}
        variant={'permanent'}
        anchor={anchor}
        open={open}
      >
        {mobileView ? (
          <>
            {!open ? (
              <CloseIcon
                fontSize="large"
                sx={{
                  m: '30px',
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  padding: '5px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  dispatch(toggle());
                }}
              />
            ) : (
              <Stack direction={'row'} alignItems={'center'}>
                <ListIcon
                  fontSize="large"
                  sx={{
                    m: '30px',
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    padding: '5px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    dispatch(toggle());
                  }}
                />
                <Typography fontWeight={'bold'} color="white">
                  {(menuItems &&
                    menuItems.find((item: MenuItem) => isCurrent(item))
                      ?.name) ??
                    'Meny'}
                </Typography>
              </Stack>
            )}
          </>
        ) : (
          <>
            {open ? (
              <ChevronLeftIcon
                sx={{
                  position: 'absolute',
                  width: '50px',
                  padding: '10px',
                  height: '50px',
                  backgroundColor: green[100],
                  borderRadius: '25px',
                  right: '-25px',
                  zIndex: 999999999,
                  top: '50px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  dispatch(toggle());
                }}
              />
            ) : (
              <MenuIcon
                sx={{
                  position: 'absolute',
                  width: '50px',
                  padding: '10px',
                  height: '50px',
                  backgroundColor: green[100],
                  borderRadius: '25px',
                  right: '-25px',
                  zIndex: 999999999,
                  top: '50px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  dispatch(toggle());
                }}
              />
            )}{' '}
          </>
        )}
        {(mobileView ? !open : open) && (
          <>
            {!mobileView && (
              <>
                <Box
                  key={'logo'}
                  sx={{
                    padding: '33px',
                    pl: '55px',
                    pb: '26px'
                  }}
                >
                  <img
                    src={MyRentLogo}
                    alt={'myrent_logo.png'}
                    width={'48px'}
                    height={'48px'}
                  />
                  {userContext &&
                    complementaryInfoNeededResponse &&
                    !complementaryInfoNeededResponse.complementaryInfoNeeded && (
                      <Typography
                        color={'white'}
                        fontWeight={600}
                        fontSize={'13px'}
                      >{`${userContext.firstName} ${userContext.lastName}`}</Typography>
                    )}
                </Box>
              </>
            )}

            <List
              sx={{
                backgroundColor: dark[100],
                color: 'white',
                px: '30px',
                overflowY: 'auto'
              }}
            >
              {menuItems &&
                sortMenuItems(menuItems).map(
                  (item: MenuItem, index: number) => (
                    <Stack key={index}>
                      <ListItem key={index} disablePadding sx={{ py: '3px' }}>
                        <ListItemButton
                          onClick={() => {
                            console.log(active == index || isCurrent(item));
                            if (item.children.length == 0) {
                              if (item.linkType == 3) {
                                navigate(getInternalMenuPath(item));
                              } else {
                                window.location.replace(getExternalHref(item));
                              }
                            } else {
                              toggleMenu(index);
                            }
                          }}
                          selected={active == index || isCurrent(item)}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#668B8E'
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#668B8E'
                            },
                            '&.Mui-selected:hover': {
                              backgroundColor: '#668B8E'
                            },
                            borderRadius: '10px',
                            py: '14px',
                            pl: '35px'
                          }}
                        >
                          <Grid
                            container
                            direction={'row'}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                            alignItems={'center'}
                          >
                            <Grid item>{item.name}</Grid>
                            <Grid item>
                              {item.children && item.children.length > 0 ? (
                                active == index ? (
                                  <ExpandLessIcon fontSize="inherit" />
                                ) : (
                                  <ExpandMoreIcon fontSize="inherit" />
                                )
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                      {item.children && item.children.length > 0 && (
                        <Collapse
                          in={
                            active == index ||
                            item.children.find((x) => isCurrent(x)) != undefined
                          }
                          timeout="auto"
                          unmountOnExit
                        >
                          <List
                            sx={{
                              py: '3px',
                              pb: '10px'
                            }}
                          >
                            {item.children &&
                              sortMenuItems(item.children).map(
                                (subItem: MenuItem) => (
                                  <ListItem
                                    key={subItem.name}
                                    disablePadding
                                    sx={{ py: '1px' }}
                                  >
                                    <ListItemButton
                                      selected={isCurrent(subItem)}
                                      sx={{
                                        '&:hover': {
                                          backgroundColor: '#668B8E'
                                        },
                                        borderRadius: '10px',
                                        pl: '52px'
                                      }}
                                      onClick={() => {
                                        if (item.linkType == 3) {
                                          navigate(
                                            getInternalMenuPath(subItem)
                                          );
                                        } else {
                                          window.location.replace(
                                            getExternalHref(subItem)
                                          );
                                        }
                                      }}
                                    >
                                      {subItem.name}
                                    </ListItemButton>
                                  </ListItem>
                                )
                              )}
                          </List>
                        </Collapse>
                      )}
                      <Divider
                        sx={{
                          height: '2px',
                          backgroundColor: '#ADADAD',
                          borderRadius: '3px',
                          opacity: 0.5
                        }}
                      />
                    </Stack>
                  )
                )}
            </List>
          </>
        )}
      </Drawer>
    </Container>
  );
};

export default Component;
