import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';

import { Container, Label } from './styles';

interface Option {
  id: string | number;
  label: string;
  backgroundColor?: string;
  disabled?: boolean;
}

interface Props {
  id: string;
  name: string;
  value: number | string | undefined;
  onChange: (event: SelectChangeEvent<any>) => void;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  error: boolean | undefined;
  helperText?: string | false | undefined;
  placeholder?: string;
  label?: string;
  labelColor?: string;
  labelWeight?: string;
  labelLeftMargin?: string;
  fullWidth?: boolean;
  maxLength?: number;
  options: Option[] | undefined;
  bgColor?: string;
  multiple?: boolean;
  variant?: 'body1' | 'body2';
}

const Component = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  error,
  placeholder = 'Velg',
  label,
  labelColor = 'white',
  labelWeight = 'bold',
  labelLeftMargin = '10px',
  bgColor = 'white',
  helperText,
  fullWidth = false,
  multiple = false,
  variant = 'body1',
  options
}: Props): JSX.Element => {
  return (
    <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
      {label && (
        <Label htmlFor={name}>
          <Typography
            color={labelColor}
            marginLeft={labelLeftMargin}
            fontWeight={labelWeight}
          >
            {label}
          </Typography>
        </Label>
      )}
      <FormControl size="small">
        <Select
          id={id}
          name={name}
          value={value ?? ''}
          onChange={onChange}
          onBlur={onBlur && onBlur}
          size={'small'}
          multiple={multiple}
          displayEmpty={true}
          renderValue={(selected) => (
            <Typography variant={variant} overflow={'hidden'}>
              {options?.find((x) => x.id === selected)?.label || placeholder}
            </Typography>
          )}
          error={error}
          sx={{
            backgroundColor: bgColor,
            borderRadius: '10px',
            '.MuiOutlinedInput-notchedOutline': {
              border: 0
            },
            paddingY: 1,
            paddingRight: 1,
            height: 40,
            opacity: 0.9
          }}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300
              }
            }
          }}
        >
          {options?.map(({ label, backgroundColor, id, disabled }) => (
            <MenuItem
              key={id}
              value={id}
              disabled={disabled ? true : false}
              disableRipple
              sx={{ backgroundColor: backgroundColor }}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {helperText && (
        <Container>
          <Typography
            color="red"
            marginLeft="10px"
            sx={{ position: 'absolute', mt: '3px' }}
          >
            {helperText}
          </Typography>
        </Container>
      )}
    </Stack>
  );
};

export default Component;
