import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { AuthResponse } from '../api/account/types';

export enum OrganizationSubScriptionType {
  Free = 1,
  Business = 2,
  Enterprise = 3
}

export enum OrganizationOrganizationType {
  Undefined = 0,
  BusinessType = 2,
  EnterpriseType = 3,
  PrivateType = 4
}

export interface UserState {
  isAuthenticated: boolean;

  id?: number;
  email?: string;

  Client?: {
    id: number;
    name: string;

    Organization: {
      id: number;
      name: string;

      SubscriptionType: OrganizationSubScriptionType;
      OrganizationType: OrganizationOrganizationType;
    };
  };
}

const initialState: UserState = {
  isAuthenticated: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthResponse>) => {
      state.isAuthenticated = action.payload.IsAuthenticated;

      if (action.payload.IsAuthenticated) {
        state.id = action.payload?.Id;
        state.email = action.payload?.Email;
        state.Client = {
          id: action.payload?.Client?.Id,
          name: action.payload?.Client?.Name,

          Organization: {
            id: action.payload?.Client?.Organization?.Id,
            name: action.payload?.Client?.Organization?.Name,

            OrganizationType:
              action.payload?.Client?.Organization?.OrganizationType,
            SubscriptionType:
              action.payload?.Client?.Organization?.SubscriptionType
          }
        };
      } else {
        state.id = undefined;
        state.email = undefined;
        state.Client = undefined;
      }
    },
    login: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;

      state.id = undefined;
      state.email = undefined;
      state.Client = undefined;
    }
  }
});

export const { setUser, login, logout } = userSlice.actions;

export const getUser = (state: RootState) => state.user;

export const isPaidSubscription = (state: RootState) =>
  state.user.Client?.Organization.SubscriptionType !==
  OrganizationSubScriptionType.Free;

export default userSlice.reducer;
