import KeyIcon from '@mui/icons-material/Key';
import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import { DashboardCard } from 'app/components/card';
import { Dialog } from 'app/components/dialog';
import { Numberdisplay, SelectDisplay } from 'app/components/display';
import { ProgressBar } from 'app/components/progress-bar';
import { Table } from 'app/components/table';
import { TableColumn, TableRow } from 'app/components/table/component';
import {
  useGetKeyNumbersQuery,
  useGetPaymentsQuery
} from 'app/context/api/invoice';
import { PaymentsResponse } from 'app/context/api/invoice/types';
import { useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';

interface Filter {
  period: number;
  clientId?: number;
  propertyId?: number;
}

const columns: TableColumn[] = [
  {
    title: 'Leietaker',
    order: 1
  },
  {
    title: 'Adresse',
    order: 2
  },
  {
    title: 'Objekt',
    order: 21
  },
  {
    title: 'Fakturanr',
    align: 'right',
    order: 5
  },
  {
    title: 'Forfall',
    align: 'right',
    order: 3
  },
  {
    title: 'Betalt',
    align: 'right',
    order: 4
  },
  {
    title: 'Rest',
    align: 'right',
    order: 6
  }
];

const getRows = (data: PaymentsResponse[]) => {
  const rows: TableRow[][] = [];
  data.map((payment) => {
    rows.push([
      {
        value: payment.user,
        order: 1
      },
      {
        value: payment.address,
        order: 2
      },
      {
        value: payment.objectDescription,
        order: 21
      },
      {
        value: payment.invoiceNumber,
        order: 5
      },
      {
        value: payment.invoiceDueDate,
        order: 3
      },
      {
        value: payment.amount + ' kr',
        order: 4
      },
      {
        value: payment.leftToPay + ' kr',
        order: 6
      }
    ]);
  });
  return rows;
};

const Component = (): JSX.Element => {
  const keyNumberOptions = [
    {
      value: 0,
      label: 'Forrige måned'
    },
    {
      value: 1,
      label: 'Forrige uke'
    },
    {
      value: 2,
      label: 'I går'
    },
    {
      value: 3,
      label: 'I dag'
    },
    {
      value: 4,
      label: 'Denne uken'
    },
    {
      value: 5,
      label: 'Denne måneden'
    },
    {
      value: 6,
      label: 'I år'
    }
  ];
  const [filter, setFilter] = useState<Filter>({
    period: 6
  });
  const [open, setOpen] = useState(false);
  const context = useAppSelector((state) => state.context);
  const { data } = useGetKeyNumbersQuery(filter);
  const { data: paymentData } = useGetPaymentsQuery({
    paymentsTab: filter.period
  });

  const updateFilter = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const newFilter: Filter = { period: parseInt(value) };
    if (context.context.type == 'client') {
      newFilter.clientId = context.context.id;
    }
    if (context.context.type == 'property') {
      newFilter.propertyId = context.context.id;
    }
    setFilter(newFilter);
  };

  useEffect(() => {
    const newFilter: Filter = { period: filter.period };
    if (context.context.type == 'client') {
      newFilter.clientId = context.context.id;
    }
    if (context.context.type == 'property') {
      newFilter.propertyId = context.context.id;
    }
    setFilter(newFilter);
  }, [context.context]);

  return (
    <>
      {data && (
        <DashboardCard
          mainIcon={<KeyIcon />}
          title={'Nøkkeltall'}
          select={
            <SelectDisplay
              value={filter.period.toString()}
              onChange={updateFilter}
              options={keyNumberOptions}
            />
          }
          firstColumn={
            <Stack sx={{ pb: 4 }}>
              <ProgressBar
                title=",- betalt"
                subTitle=",- for valgt periode"
                numerator={data.paidTotal}
                denomerator={data.invoiceTotal}
              />
            </Stack>
          }
          secondColumn={
            <Stack spacing={1}>
              <Numberdisplay
                value={data.invoiceTotal}
                title={'Totalt fakutrert for perioden'}
                link={`Invoice/SentInvoices?period=${filter.period}`}
              />
              <Numberdisplay
                value={data.paidTotal}
                title={'Totalt betalt innen perioden'}
                link={() => {
                  updateFilter;
                  setOpen(true);
                }}
              />
              <Numberdisplay
                value={data.paymentRemainingTotal}
                title={'Totalt utestående for perioden'}
                link={`Invoice/SentInvoices?statuses=6&statuses=4&statuses=2&statuses=3&statuses=5&statuses=12&statuses=8&period=${filter.period}`}
              />
            </Stack>
          }
        />
      )}
      <Dialog isOpen={open} setIsOpen={() => setOpen(!open)}>
        <Stack spacing={2} py={10} px={{ xs: 1, sm: 3, md: 15 }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            display={'flex'}
            flexWrap={'wrap'}
          >
            <Typography variant="h1" sx={{ pb: 2 }}>
              Innbetalinger
            </Typography>
            <SelectDisplay
              value={filter.period.toString()}
              onChange={updateFilter}
              options={keyNumberOptions}
              backgroundColor={'#F0F0F0'}
            />
          </Stack>
          {paymentData && (
            <Table
              data={{
                columns: columns,
                rows: getRows(paymentData)
              }}
            />
          )}
        </Stack>
      </Dialog>
    </>
  );
};

export default Component;
