import { Box, Divider, Grid, Hidden, Stack, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { LoadingPopup } from 'app/components/loading';
import { closeModal } from 'app/context/addModal/addModalSlice';
import {
  useGetContractByUserIdMutation,
  useGetDownloadPdfMutation,
  useGetTenantsByContractIdMutation,
  useSignContractMutation,
  useUploadContractMutation
} from 'app/context/api/contract';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';

import {
  contractState,
  setClientName,
  setCloseMsg,
  setContractId,
  setError,
  setObject,
  setTenants,
  setUploadSend
} from './contractPathSlice';
import { addContractSteps, ContractStep } from './steps';
import Choose from './steps/0-choose';
import Edit from './steps/1-edit';
import Upload from './steps/1-upload';
import Sign from './steps/2-sign';
import Close from './steps/3-close';

interface Contract {
  id: number;
}

interface Props {
  tenantId?: number;
  contractId?: number;
  onClose?: string;
}

const Component = ({ tenantId, contractId, onClose }: Props): JSX.Element => {
  const [step, setStep] = useState<ContractStep>(addContractSteps[0]);
  const contract = useAppSelector((state) => state.contract);
  const dispatch = useAppDispatch();

  const [getContract, { isLoading: getContractIsLoading }] =
    useGetContractByUserIdMutation();
  const [getTenants, { isLoading: getTenantsIsLoading }] =
    useGetTenantsByContractIdMutation();

  const [getDownloadPdf] = useGetDownloadPdfMutation();
  const [signContract, { isLoading: signContractIsLoading }] =
    useSignContractMutation();
  const [uploadContract, { isLoading: uploadContractIsLoading }] =
    useUploadContractMutation();
  const [file, setFile] = useState<Blob | string>('');
  const [editorContent, setEditorContent] = useState('');

  const updateStep = async (stepId: string) => {
    const nextStep = addContractSteps?.find((step) => step.stepId == stepId);
    dispatch(setError(''));
    if (nextStep) {
      if (step.send == 'file' && nextStep.stepId == '3-close') {
        try {
          if (file.toString().length == 0) throw 'Du må velge en fil';
          if (contract.upload.contractId == 0) throw 'Kunne ikke laste opp fil';
          const data = new FormData();
          data.append('contractId', contract.upload.contractId.toString());
          data.append('file', file);
          const result = await uploadContract(data).unwrap();

          if (!result.success) throw 'Kunne ikke laste opp fil';
          dispatch(
            setCloseMsg(
              'Nye leietakere vil nå motta SMS og E-post med logginn informasjon. Alle leietakere som har konto fra før må du gi beskjed om å logge inn med sin logginn info.'
            )
          );
          setStep(nextStep);
        } catch (err) {
          dispatch(setError(err));
          console.log(err, typeof err);
        }
      } else if (step.send == 'sign' && nextStep.stepId == '3-close') {
        try {
          const requestData: contractState['sign'] = {
            EmailMessage: contract.sign.EmailMessage,
            contractId: contract.contractId,
            Html: editorContent,
            currentUserBankIdType: contract.sign.currentUserBankIdType,
            tenantBankIdTypes: contract.sign.tenantBankIdTypes
          };
          const result = await signContract(requestData).unwrap();

          if (!result.success) throw 'Kunne ikke sende til BankID.';
          dispatch(
            setCloseMsg(
              'Nye leietakere vil nå motta SMS og E-post med logginn informasjon. Kontrakten vil ligge til signering i MyRent MinSide. Alle leietakere som har konto fra før må du gi beskjed om å logge inn for å finne og signere sin nye kontrakt.'
            )
          );
          setStep(nextStep);
        } catch (err) {
          dispatch(setError(err));
        }
      } else if (step.stepId == '0-choose' && nextStep.stepId == '3-close') {
        if (onClose) {
          window.location.replace(onClose);
        }
        dispatch(closeModal());
      } else {
        setStep(nextStep);
      }
    }
  };

  const getTenantsAsync = async (currentContractId: number) => {
    try {
      const result = await getTenants({
        contractId: currentContractId
      }).unwrap();

      if (!result) throw 'Fant ikke kontrakt';

      dispatch(setContractId(currentContractId));
      dispatch(setTenants(result.contract.tenants));
      dispatch(setObject(result.contract.object));
      dispatch(setClientName(result.contract.object.clientName));
    } catch (err) {
      dispatch(setError(err));
    }
  };

  const getContractIdAsync = async () => {
    try {
      const result = await getContract({ tenantId: tenantId }).unwrap();

      if (result.data.length == 0) throw 'Fant ikke leietaker';

      const currentContract = [...result.data].sort(function (
        a: Contract,
        b: Contract
      ) {
        return -(a.id - b.id);
      });

      getTenantsAsync(currentContract[0].id);
    } catch (err) {
      dispatch(setError(err));
    }
  };

  useEffect(() => {
    if (contract.contractId > -1) {
      getTenantsAsync(contract.contractId);
    } else if (contractId) {
      getTenantsAsync(contractId);
    } else if (tenantId) {
      getContractIdAsync();
    } else {
      dispatch(setError('Kunne ikke laste inn kontrakt.'));
    }
  }, [tenantId, contractId]);

  useEffect(() => {
    if (
      step.stepId == addContractSteps[0].stepId &&
      addContractSteps[0].nextStep
    ) {
      addContractSteps[0].nextStep.to = contract.path;
    }
  }, [contract]);

  const getFile = (file: Blob | string) => {
    setFile(file);
  };

  const downloadPdf = async () => {
    try {
      const result = await getDownloadPdf({
        html: editorContent,
        contractId: contract.contractId,
        download: 1
      }).unwrap();
    } catch (err) {
      dispatch(setError(err));
    }
  };

  const sendContent = (content: string) => {
    setEditorContent(content);
  };

  return (
    <Stack spacing={2} padding={{ xs: 2, sm: 5, md: 10 }}>
      <Typography variant="h1">{step.title}</Typography>

      {step.stepId == '0-choose' && <Choose updateStep={updateStep} />}
      {step.stepId == '1-upload' && <Upload getFile={getFile} />}
      {step.stepId == '1-edit' && (
        <Edit editorContent={editorContent} sendContent={sendContent} />
      )}
      {step.stepId == '2-sign' && <Sign />}
      {step.stepId == '3-close' && <Close onClose={onClose} />}
      {contract.error.length > 0 && (
        <Typography color={'red'}>{contract.error}</Typography>
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {step.prevStep && (
              <PrimaryButton
                onClick={() => updateStep(step.prevStep?.to ?? '')}
                filled
              >
                {step.prevStep.text}
              </PrimaryButton>
            )}
          </Grid>
          <Grid item xs={8} display="flex" sx={{ justifyContent: 'flex-end' }}>
            {step.nextStep && (
              <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                {step.stepId == '1-edit' && (
                  <SecondaryButton filled onClick={() => downloadPdf()}>
                    Last ned og signer manuelt
                  </SecondaryButton>
                )}
                <PrimaryButton
                  onClick={() => updateStep(step.nextStep?.to ?? '')}
                  filled
                >
                  {step.nextStep.text}
                </PrimaryButton>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Box>
      <LoadingPopup
        open={
          getContractIsLoading ||
          getTenantsIsLoading ||
          signContractIsLoading ||
          uploadContractIsLoading
        }
      />
    </Stack>
  );
};

export default Component;
