import { Box, Typography } from '@mui/material';
import { dark } from 'app/theme/colors';

interface Props {
  title: string;
}

const Component = ({ title }: Props): JSX.Element => {
  return (
    <Box
      sx={{
        padding: 1,
        paddingX: 2,
        border: `1px solid black`,
        borderRadius: '10px',
        backgroundColor: dark[100],
        color: '#9AEABC',
        width: '100%'
      }}
    >
      <Typography variant="body1">{title}</Typography>
    </Box>
  );
};

export { Component as TextPill };
