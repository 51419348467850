import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  Collapse,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography
} from '@mui/material';
import { TextInput } from 'app/components/input';
import { white } from 'app/theme/colors';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { TableColumn, TableRow } from '../../component';

interface Props {
  row: TableRow[];
  index: number;
  column: TableColumn[];
}

const Component = ({ row, index, column }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const getColumnByOrder = (order: number) => {
    const col = column?.find((c) => c.order == order);
    if (col) {
      return col.title;
    }
    return '';
  };

  const getValueByOrder = (row: TableRow[], order: number) => {
    const cell = row?.find((r) => r.order == order);
    if (cell) {
      return cell.value;
    }
    return '';
  };

  return (
    <Stack
      key={index}
      spacing={2}
      sx={{
        backgroundColor: white[100],
        borderRadius: '25px',
        padding: '25px',
        pb: 0
      }}
    >
      <Stack>
        <Typography fontWeight={'bold'}>{getValueByOrder(row, 1)}</Typography>
        <Typography>{getValueByOrder(row, 2)}</Typography>
        <Typography>{getValueByOrder(row, 21)}</Typography>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <Typography fontWeight={'bold'}>{getColumnByOrder(3)}</Typography>
          <Typography>{getValueByOrder(row, 3)}</Typography>
        </Stack>
        <Stack>
          <Typography align="right" fontWeight={'bold'}>
            {getColumnByOrder(4)}
          </Typography>
          <Typography align="right">{getValueByOrder(row, 4)}</Typography>
        </Stack>
      </Stack>
      <Collapse in={open}>
        <Stack spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack>
              <Typography fontWeight={'bold'}>{getColumnByOrder(5)}</Typography>
              {typeof getValueByOrder(row, 5) == 'string' ? (
                <Typography>{getValueByOrder(row, 5)}</Typography>
              ) : (
                getValueByOrder(row, 5)
              )}
            </Stack>
            <Stack>
              <Typography align="right" fontWeight={'bold'}>
                {getColumnByOrder(6)}
              </Typography>

              {typeof getValueByOrder(row, 6) == 'string' ? (
                <Typography align="right">{getValueByOrder(row, 6)}</Typography>
              ) : (
                getValueByOrder(row, 6)
              )}
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack>
              <Typography fontWeight={'bold'}>{getColumnByOrder(7)}</Typography>
              {typeof getValueByOrder(row, 7) == 'string' ? (
                <Typography>{getValueByOrder(row, 7)}</Typography>
              ) : (
                getValueByOrder(row, 7)
              )}
            </Stack>
            <Stack>
              <Typography align="right" fontWeight={'bold'}>
                {getColumnByOrder(8)}
              </Typography>
              {typeof getValueByOrder(row, 8) == 'string' ? (
                <Typography align="right">{getValueByOrder(row, 8)}</Typography>
              ) : (
                getValueByOrder(row, 8)
              )}
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack>
              <Typography fontWeight={'bold'}>{getColumnByOrder(9)}</Typography>
              {typeof getValueByOrder(row, 9) == 'string' ? (
                <Typography>{getValueByOrder(row, 9)}</Typography>
              ) : (
                getValueByOrder(row, 9)
              )}
            </Stack>
            <Stack>
              <Typography align="right" fontWeight={'bold'}>
                {getColumnByOrder(10)}
              </Typography>
              {typeof getValueByOrder(row, 10) == 'string' ? (
                <Typography align="right">
                  {getValueByOrder(row, 10)}
                </Typography>
              ) : (
                getValueByOrder(row, 10)
              )}
            </Stack>
          </Stack>
        </Stack>
      </Collapse>
      <Stack direction={'row'} justifyContent={'center'}>
        {open ? (
          <KeyboardArrowUpOutlinedIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
          />
        ) : (
          <KeyboardArrowDownOutlinedIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Component;
