import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import GellixBoldWoff from '../assets/fonts/gellix/Gellix-Bold.woff';
import GellixWoff from '../assets/fonts/gellix/Gellix-Regular.woff';
import GellixWoffSemiBold from '../assets/fonts/gellix/Gellix-SemiBold.woff';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    ingress: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    ingress?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    ingress: true;
    button1: true;
    button2: true;
  }
}

interface Props {
  children?: React.ReactNode;
}

const Component = ({ children }: Props): JSX.Element => {
  const theme = createTheme({
    typography: {
      fontFamily: 'MyrentGellix',
      h1: {
        fontSize: 40,
        fontWeight: 700
      },
      h2: {
        fontSize: 32,
        fontWeight: 600
      },
      h3: {
        fontSize: 24,
        fontWeight: 600
      },
      h4: {
        fontSize: 20,
        fontWeight: 600
      },
      ingress: {
        fontSize: 20,
        fontWeight: 400
      },
      button1: {
        fontSize: 20,
        fontWeight: 700
      },
      button2: {
        fontSize: 16,
        fontWeight: 700
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: MyrentGellix;
            src: url(${GellixWoff}) format("truetype");
            font-weight: 400;
          }

          @font-face {
            font-family: MyrentGellix;
            src: url(${GellixBoldWoff}) format("truetype");
            font-weight: 700;
          }

          @font-face {
            font-family: MyrentGellix;
            src: url(${GellixWoffSemiBold}) format("truetype");
            font-weight: 600;
          }

          ::-webkit-scrollbar {
            width: 10px;
          }

          *:hover::-webkit-scrollbar {
            width: 5px;
        }

          ::-webkit-scrollbar-track {
            width: 10px;
          }

          ::-webkit-scrollbar-thumb {
            background: lightgray;
            border-radius: 10px;
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background: gray;
          }

          body {
            background-color: #f5f5f0;
          }
        `
      }
    }
  });

  theme.typography.body1 = {
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  };

  theme.typography.body2 = {
    fontSize: '12px',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    }
  };

  const responsiveTheme = responsiveFontSizes(theme);

  return (
    <>
      <ThemeProvider theme={responsiveTheme}>
        <>
          <CssBaseline />
          {children}
        </>
      </ThemeProvider>
    </>
  );
};

export default Component;
