import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  Collapse,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { white } from 'app/theme/colors';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { TableCards } from './table-card';

export interface TableRow {
  value: number | string | JSX.Element;
  order?: number;
}

export interface TableColumn {
  title: string;
  align?: 'left' | 'right' | 'center';
  order?: number;
}

interface Props {
  data: {
    columns: TableColumn[];
    rows: TableRow[][];
  };
}

const Component = ({ data }: Props): JSX.Element => {
  return (
    <>
      <TableCards data={data} />
      <TableContainer
        sx={{ display: { xs: 'none', md: 'block' }, maxHeight: '65vh' }}
      >
        <Table
          stickyHeader
          padding="normal"
          sx={{
            minWidth: 650,
            borderCollapse: 'separate',
            borderSpacing: '0px 5px',
            '& .MuiTableCell-head': {
              border: 0,
              fontWeight: '700'
            },
            '& .MuiTableCell-body': {
              border: 0,
              backgroundColor: white[100],
              py: '10px'
            },
            '& .MuiTableCell-stickyHeader': {
              backgroundColor: white[20]
            }
          }}
        >
          <TableHead>
            <TableRow>
              {data.columns.map((column, index) => (
                <TableCell key={index} align={column.align}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((row, index: number) => (
              <TableRow key={index}>
                {row.map((cell, index) => (
                  <TableCell
                    key={index}
                    align={data.columns[index].align}
                    sx={{
                      '&.MuiTableCell-root': {
                        borderTopRightRadius:
                          index == row.length - 1 ? '40px' : 0,
                        borderBottomRightRadius:
                          index == row.length - 1 ? '40px' : 0,
                        borderTopLeftRadius: index == 0 ? '40px' : 0,
                        borderBottomLeftRadius: index == 0 ? '40px' : 0
                      }
                    }}
                  >
                    {cell.value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Component;
