import * as yup from 'yup';

function validateKontonummerMod11(kontonummer: string) {
  const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  const kontonummerWithoutSpacesAndPeriods = kontonummer.replace(/[\s.]+/g, '');
  if (kontonummerWithoutSpacesAndPeriods.length !== 11) {
    return false;
  } else {
    const sjekksiffer = parseInt(
      kontonummerWithoutSpacesAndPeriods.charAt(10),
      10
    );
    const kontonummerUtenSjekksiffer =
      kontonummerWithoutSpacesAndPeriods.substring(0, 10);
    let sum = 0;
    for (let index = 0; index < 10; index++) {
      sum +=
        parseInt(kontonummerUtenSjekksiffer.charAt(index), 10) * weights[index];
    }
    const remainder = sum % 11;
    return sjekksiffer === (remainder === 0 ? 0 : 11 - remainder);
  }
}

export const accountNumberSchema = yup
  .string()
  .test('is-valid', 'Skriv inn et gyldig kontonummer', (value) => {
    if (value) {
      return validateKontonummerMod11(value);
    } else {
      return false;
    }
  });
