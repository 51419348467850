import { apiSlice } from '../../index';
import {
  FikenAccountsDto,
  FikenMapAccountDto,
  FikenMapCompanyDto
} from './dto';
import {
  FikenAccountMapping,
  FikenAccountResponse,
  FikenCompany,
  FikenCompanyMapping,
  FikenState
} from './types';

const fikenIntegrationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    companies: builder.query<FikenCompany[], void>({
      query: () => ({
        url: 'v1/fiken/companies'
      })
    }),
    companyMappings: builder.query<FikenCompanyMapping[], void>({
      query: () => ({
        url: 'v1/fiken/companies/mapping'
      })
    }),
    companyMapping: builder.mutation<any, FikenMapCompanyDto>({
      query: (payload) => ({
        url: 'v1/fiken/companies/mapping',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      })
    }),
    companyConnect: builder.mutation<any, number>({
      query: (clientId: number) => ({
        url: 'v1/fiken/companies/connect',
        method: 'POST',
        params: { clientId }
      })
    }),
    companyDisconnect: builder.mutation<any, number>({
      query: (clientId: number) => ({
        url: 'v1/fiken/companies/disconnect',
        method: 'POST',
        params: { clientId }
      })
    }),
    accounts: builder.query<FikenAccountResponse, any>({
      query: (params: FikenAccountsDto) => ({
        url: 'v1/fiken/accounts',
        params
      })
    }),
    accountMappings: builder.query<FikenAccountMapping[], any>({
      query: (clientId: number) => ({
        url: 'v1/fiken/accounts/mapping',
        params: { clientId, PageSize: 10 }
      })
    }),
    accountMapping: builder.mutation<any, FikenMapAccountDto>({
      query: (payload) => ({
        url: 'v1/fiken/accounts/mapping',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      })
    }),
    state: builder.query<FikenState, void>({
      query: () => ({
        url: 'v1/fiken/state'
      })
    })
  }),
  overrideExisting: false
});

export const {
  useCompaniesQuery,
  useCompanyMappingsQuery,
  useCompanyMappingMutation,
  useCompanyConnectMutation,
  useCompanyDisconnectMutation,
  useAccountsQuery,
  useAccountMappingsQuery,
  useAccountMappingMutation,
  useStateQuery
} = fikenIntegrationApi;
