import { Dialog } from 'app/components/dialog';
import { AddCompany } from 'app/features/AddCompany';

const Component = (): JSX.Element => {
  return (
    <Dialog isOpen={true} removeIcon>
      <AddCompany redirect={process.env.REACT_APP_API_URL + 'Client/Clients'} />
    </Dialog>
  );
};

export default Component;
