import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.label``;

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0px 16px 24px 16px;
  box-sizing: border-box;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  box-sizing: border-box;
`;

export const RadioButtonView = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 40%;
  background: white;
  border: 1px solid #ccc;
`;
export const RadioButtonRef = styled.input`
  position: absolute;
  top: 20%;
  left: 4px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonView} {
    background: #ccc;
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${RadioButtonView} {
    background: #9aeabc;
    border: 1px solid #9aeabc;
    box-shadow: 0 0 0 3px #003d43, 0 0 0 6px #9aeabc;
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`;

interface InputProps {
  error: boolean | undefined;
}

export const Input = styled.input<InputProps>`
  -webkit-appearance: none;
  opacity: 90%;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border-width: 0px;
  box-shadow: ${({ error }) => error && '0 0 0 2px red'};
  &:focus {
    box-shadow: 0 0 0 2px #9aeabc;
  }
`;
