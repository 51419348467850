import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Stack, Typography } from '@mui/material';

const color = {
  filled: {
    base: '#9AEABC',
    hover: '#003D43'
  },
  unfilled: {
    base: 'white',
    hover: '#003D43'
  }
};

interface Props {
  children: string;
  icon?: JSX.Element;
  filled?: boolean;
  addIcon?: boolean;
  chevron?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

const Component = ({
  children,
  filled = false,
  addIcon = false,
  chevron = '',
  disabled = false,
  fullWidth = false,
  onClick,
  type = 'button',
  icon
}: Props): JSX.Element => (
  <Button
    type={type}
    onClick={onClick}
    variant={'contained'}
    disabled={disabled}
    fullWidth={fullWidth}
    disableElevation
    sx={{
      backgroundColor: filled ? color.filled.base : 'rgba(0, 0, 0, 0)',
      color: filled ? color.filled.hover : color.filled.base,
      borderRadius: 4,
      borderColor: color.filled.base,
      borderWidth: '2px',
      borderStyle: 'solid',
      boxShadow: 2,
      '&:hover': {
        color: filled ? color.filled.base : color.unfilled.hover,
        backgroundColor: filled ? color.filled.hover : color.filled.base,
        borderColor: filled ? color.filled.hover : color.filled.base
      },
      '&:disabled': {
        backgroundColor: filled ? color.filled.base : color.unfilled.base,
        color: filled ? color.filled.hover : color.filled.base,
        opacity: '50%'
      }
    }}
  >
    <Stack direction="row" spacing={1} alignItems={'center'}>
      {chevron == 'left' && <ChevronLeftIcon />}
      {icon ?? undefined}
      <Typography fontWeight={'bold'}>{children}</Typography>
      {chevron == 'right' && <ChevronRightIcon />}
      {addIcon && <AddIcon />}
    </Stack>
  </Button>
);

export default Component;
