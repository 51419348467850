import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Divider, Stack, Typography } from '@mui/material';
import { SecondaryButton } from 'app/components/button';
import { Dialog } from 'app/components/dialog';
import { Select } from 'app/components/input';
import { closeModal } from 'app/context/addModal/addModalSlice';
import {
  useAccountMappingMutation,
  useAccountMappingsQuery,
  useAccountsQuery
} from 'app/context/api/integrations/fiken';
import {
  FikenAccount,
  FikenAccountMapping
} from 'app/context/api/integrations/fiken/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { green, red } from 'app/theme/colors';

import { TextPill } from './TextPill';

interface Props {
  clientId: number | undefined;
  name: string | undefined;
}

const Component = ({ clientId, name }: Props): JSX.Element => {
  const { data: fikenAccounts } = useAccountsQuery({
    clientId,
    pageSize: 1000
  });
  const { data: accountMappings, refetch } = useAccountMappingsQuery(clientId);
  const [mutate] = useAccountMappingMutation();

  const modal = useAppSelector((state) => state.modal.modal);
  const dispatch = useAppDispatch();

  if (!clientId) {
    return <></>;
  }

  return (
    <Dialog isOpen={modal === 'fikenAccounts'}>
      <Stack marginX={10} marginY={10} justifyContent="center" spacing={2}>
        <Typography variant="h1">Fiken - sammenkobling av kontoer</Typography>
        <Stack width="30%">
          <TextPill title={name || ''} />
        </Stack>
        <Stack spacing={2}>
          {/* <Typography variant="h3">Dine selskaper</Typography> */}
          <Typography variant="body1">
            For å kunne føre regnskapet riktig må vi synkronisere kontoene du
            bruker på tvers av MyRent og Fiken. - På MyRent sin side vil vi vise
            alle kontoer som har blitt brukt. - På Fiken sin side vil vi vise
            kontoer som har vært i bruk de siste 18 månedene. Om du mangler en
            eller flere kontoer, ta kontakt med support.
          </Typography>
          <Typography variant="body1" color={red[100]}>
            Advarsel: Ikke gjør endringer her om du er usikker på hva du gjør.
            Det kan føre til store feil i regnskapet. Ta kontakt med din
            regnskapsfører eller revisor om nødvendig.
          </Typography>
          <Divider sx={{ height: '2px' }} />

          <Stack spacing={2} paddingTop={4}>
            {(!accountMappings || accountMappings?.length === 0) && (
              <Typography variant="body1">
                Du har ingen aktive kontoer i MyRent.
              </Typography>
            )}
            {accountMappings?.length &&
              accountMappings?.map((x: FikenAccountMapping, idx: number) => (
                <Stack
                  key={x.account.id}
                  direction="row"
                  spacing={2}
                  alignItems={'center'}
                >
                  <Stack width={'44%'} position={'relative'}>
                    {idx === 0 && (
                      <Typography
                        variant="h4"
                        sx={{
                          top: '-40px',
                          position: 'absolute'
                        }}
                      >
                        MyRent
                      </Typography>
                    )}
                    <TextPill title={`${x.account.code} : ${x.account.name}`} />
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems={'center'}
                    justifyContent={'start'}
                    width="6%"
                  >
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: 40
                      }}
                    />
                  </Stack>
                  <Stack width="44%" position={'relative'}>
                    {idx === 0 && (
                      <Typography
                        variant="h4"
                        sx={{
                          top: '-40px',
                          position: 'absolute'
                        }}
                      >
                        Fiken
                      </Typography>
                    )}
                    <Select
                      id={x.account.id.toString()}
                      name={x.account.id.toString()}
                      value={x.mappedTo?.code || ''}
                      onChange={async (e) => {
                        await mutate({
                          clientId,
                          mapping: [
                            {
                              ledgerAccountId: x.account.id,
                              mappingCode: e.target.value
                            }
                          ]
                        });
                        refetch();
                      }}
                      error={false}
                      bgColor={green[100]}
                      options={
                        fikenAccounts?.records?.map((x: FikenAccount) => {
                          return {
                            id: x.code,
                            label: `${x.code} : ${x.name}`
                          };
                        }) || []
                      }
                      fullWidth
                    />
                  </Stack>
                </Stack>
              ))}
          </Stack>
        </Stack>
        <Stack direction={'row'} justifyContent={'right'}>
          <Stack width="25%">
            <SecondaryButton
              filled={true}
              disabled={false}
              onClick={() => dispatch(closeModal())}
            >
              Bekreft
            </SecondaryButton>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export { Component as AccountMappingDialog };
