import { Divider, List, Stack, Typography } from '@mui/material';
import { Summary } from 'app/components/summary';
import { useGetClientsQuery } from 'app/context/api/client';
import { User } from 'app/context/api/client/types';
import { Product } from 'app/context/api/product/types';
import { format } from 'date-fns';
import React from 'react';

import { contractTypeOptions } from '../AddContract/component';
import { invoiceIntervalOptions } from '../AddRent/component';

interface Props {
  formik: any;
}

export interface privateTenant {
  socialSecurityNumber: string;
  firstName: string;
  lastName: string;
  noEmail: boolean;
  noCellPhone: boolean;
  email: string;
  cellphone: string;
}

export interface companyTenant {
  companyName: string;
  organizationNumber: string;
  address: string;
  zipCode: string;
  city: string;
  firstName: string;
  lastName: string;
  noEmail: boolean;
  noCellPhone: boolean;
  email: string;
  cellphone: string;
}
const Component = ({ formik }: Props): JSX.Element => {
  const [open, setOpen] = React.useState([true, false, false, false]);

  const handleClick = (index: number) => {
    const newArray = [...open];
    newArray[index] = !newArray[index];
    setOpen(newArray);
  };

  const { data: clients } = useGetClientsQuery();

  function isClient(client: User) {
    if (client.id == formik.values?.propertyInfo?.clientId) {
      return client;
    }
  }

  const getSecurityText = () => {
    const text = ['Sikkerhetsløsning'];
    switch (formik.values.securityInfo.securityType) {
      case 1:
        break;
      case 2:
        text.push(
          'Depositum (i NOK)',
          'Bedrift utleier signatar',
          'Org.nr utleier signatar',
          'Fornavn utleier signatar',
          'Etternavn utleier signatar',
          'E-post utleier signatar',
          'Telefonnummer utleier signatar',
          'Personnummer utleier signatar',
          'Bedrift leietaker signatar',
          'Org.nr leietaker signatar',
          'Fornavn leietaker signatar',
          'Etternavn leietaker signatar',
          'E-post leietaker signatar',
          'Telefonnummer leietaker signatar',
          'Personnummer leietaker signatar',
          'Bestilling'
        );
        break;
      case 3:
        text.push('Garantiperiode (antall måneder)');
        break;
      case 4:
        text.push('Depositum (i NOK)');
        break;
      case 5:
        text.push('Utløpsdato for garanti');
        break;
      case 6:
        text.push('Beskrivelse');
        break;
      default:
        break;
    }
    return text;
  };

  const getSecurityValues = () => {
    switch (formik.values.securityInfo.securityType) {
      case 1:
        return ['Ingen sikkerhetsløsning'];
      case 2:
        return [
          'MyRent Depositumskonto',
          formik.values.securityInfo.deposit,
          formik.values.securityInfo.myrentOwner.companyName.length > 0
            ? formik.values.securityInfo.myrentOwner.companyName
            : '',
          formik.values.securityInfo.myrentOwner.companyNumber.length > 0
            ? formik.values.securityInfo.myrentOwner.companyNumber
            : '',
          formik.values.securityInfo.myrentOwner.firstName,
          formik.values.securityInfo.myrentOwner.lastName,
          formik.values.securityInfo.myrentOwner.email,
          formik.values.securityInfo.myrentOwner.cellPhone,
          formik.values.securityInfo.myrentOwner.socialSecurityNumber,
          formik.values.securityInfo.tenantSigner.companyName.length > 0
            ? formik.values.securityInfo.tenantSigner.companyName
            : '',
          formik.values.securityInfo.tenantSigner.organizationNumber.length > 0
            ? formik.values.securityInfo.tenantSigner.organizationNumber
            : '',
          formik.values.securityInfo.tenantSigner.firstName,
          formik.values.securityInfo.tenantSigner.lastName,
          formik.values.securityInfo.tenantSigner.email,
          formik.values.securityInfo.tenantSigner.cellphone,
          formik.values.securityInfo.tenantSigner.socialSecurityNumber,
          formik.values.securityInfo.doNotSendOutAuthenticationInfo
            ? 'Det ikke sendes ut bestilling før kontrakt er signert.'
            : 'Det sendes ut bestilling før kontrakt er signert.'
        ];
      case 3:
        return ['Leiegaranti S&P', formik.values.securityInfo.monthSP];
      case 4:
        return ['Annen depositumskonto', formik.values.securityInfo.deposit];
      case 5:
        return [
          'NAV-garanti',
          format(formik.values.securityInfo.navDate, 'dd.MM.yyyy')
        ];
      case 6:
        return [
          'Annen sikkerhetsløsning',
          formik.values.securityInfo.otherSecurity
        ];
      default:
        return ['Ingen sikkerhetsløsning valgt'];
    }
  };

  return (
    <Stack p={{ xs: 2, sm: 10 }} pr={{ md: 55 }} pb={{ xs: 10 }} spacing={8}>
      <Stack spacing={2}>
        <Typography variant="h1">Oppsummering</Typography>
        <Divider
          sx={{
            borderBottomWidth: 3,
            backgroundColor: '#003D43'
          }}
        />
      </Stack>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Stack spacing={1}>
          <Summary
            title={'1. Leieobjektet'}
            valueText={['Utleier', 'Leieobjektet']}
            values={[
              [
                clients && clients.findIndex(isClient) != -1
                  ? clients[clients.findIndex(isClient)].name
                  : '',
                formik.values?.propertyInfo?.object?.label
              ]
            ]}
            open={open}
            onClick={() => handleClick(0)}
            index={0}
          />
          {formik.values.tenantType == 'private' ? (
            <Summary
              title={'2. Leietaker(e)'}
              valueText={[
                'Personnummer',
                'Fornavn',
                'Etternavn',
                'Epost',
                'Mobil'
              ]}
              values={formik.values?.privateTenants?.map(
                (tenant: privateTenant) => [
                  tenant.socialSecurityNumber.length > 0
                    ? tenant.socialSecurityNumber
                    : 'Ikke angitt',
                  tenant.firstName,
                  tenant.lastName,
                  tenant.noEmail ? 'Mangler e-post' : tenant.email,
                  tenant.noCellPhone ? 'Mangler mobilnummer' : tenant.cellphone
                ]
              )}
              open={open}
              onClick={() => handleClick(1)}
              index={1}
            />
          ) : (
            <Summary
              title={'2. Leietaker(e)'}
              valueText={[
                'Bedriftsnavn',
                'Org.nr',
                'Firmaadresse',
                'Postnummer',
                'Poststed',
                'Fornavn',
                'Etternavn',
                'Epost',
                'Mobil'
              ]}
              values={formik.values?.companyTenants?.map(
                (tenant: companyTenant) => [
                  tenant.companyName,
                  tenant.organizationNumber,
                  tenant.address,
                  tenant.zipCode,
                  tenant.city,
                  tenant.firstName,
                  tenant.lastName,
                  tenant.noEmail ? 'Mangler e-post' : tenant.email,
                  tenant.noCellPhone ? 'Mangler mobilnummer' : tenant.cellphone
                ]
              )}
              open={open}
              onClick={() => handleClick(1)}
              index={1}
            />
          )}
          <Summary
            title={'3. Kontrakt'}
            valueText={[
              'Kontraktstype',
              'Startdato for kontrakt',
              formik.values.contractInfo.contractType < 3
                ? 'Stoppdato for kontrakt'
                : '',
              'Oppsigelsestid (antall måneder)',
              'Overtakelsesdato',
              'Innflytting'
            ]}
            values={[
              [
                contractTypeOptions?.find(
                  (c) => c.id == formik.values.contractInfo.contractType
                )?.label,
                format(formik.values.contractInfo.startDate, 'dd.MM.yyyy'),
                formik.values.contractInfo.contractType < 3
                  ? format(formik.values.contractInfo.endDate, 'dd.MM.yyyy')
                  : '',
                formik.values.contractInfo.contractType == 2
                  ? ''
                  : formik.values.contractInfo.noticePeriod,
                format(
                  formik.values.contractInfo.acquisitionDate,
                  'dd.MM.yyyy'
                ),
                formik.values.contractInfo.acquistied
                  ? 'Leietaker har flyttet inn'
                  : 'Leietaker har ikke flyttet inn'
              ]
            ]}
            open={open}
            onClick={() => handleClick(2)}
            index={2}
          />
          <Summary
            title={'4. Husleie'}
            valueText={[
              'Leiesum per måned (i NOK)',
              'Startdato for fakurering (fom)',
              'Fakturaintervall',
              'Leie betales forskuddsvis hver',
              'Hovedprodukt på faktura',
              'Ekstraprodukt på faktura',
              'Første faktura',
              'Har regulering blitt gjort utenom Myrent for dette leieforholdet?',
              'Neste reguleringsdato'
            ]}
            values={[
              [
                formik.values.rentInfo.rentAmount,
                format(formik.values.rentInfo.startDate, 'dd.MM.yyyy'),
                invoiceIntervalOptions?.find(
                  (o) => o.id == formik.values.rentInfo.invoiceInterval
                )?.label,
                `${formik.values.rentInfo.paymentDay} ..dag i måneden.`,
                formik.values.rentInfo.products.find(
                  (p: Product) => p.id == formik.values.rentInfo.mainProduct
                )?.name,
                formik.values.rentInfo.products.length > 0
                  ? formik.values.rentInfo.chosenExtraProduct
                      .map((index: number) => {
                        if (formik.values.rentInfo.products[index]) {
                          return ` ${formik.values.rentInfo.products[index].name}: ${formik.values.rentInfo.products[index].unitPrice} kr`;
                        }
                      })
                      .join(', ')
                  : 'Ingen andre produkter vises på faktura',
                formik.values.rentInfo.autoCalcFirstInvoice
                  ? formik.values.rentInfo.firstInvoiceAmount
                  : 'Ikke automatisk beregnet',
                formik.values.rentInfo.isExternalRegulated
                  ? format(
                      formik.values.rentInfo.prevRegulationDate,
                      'dd.MM.yyyy'
                    )
                  : 'Nei',
                format(formik.values.rentInfo.nextRegulationDate, 'dd.MM.yyyy')
              ]
            ]}
            open={open}
            onClick={() => handleClick(3)}
            index={3}
          />
          <Summary
            title={'5. Sikkerhet'}
            valueText={getSecurityText()}
            values={[getSecurityValues()]}
            open={open}
            onClick={() => handleClick(4)}
            index={4}
          />
          <Summary
            title={'6. Administrativt'}
            valueText={[
              'Send leietakerprofil',
              'Opprett overtakelsesprotokoll',
              'Send faktura før kontrakten er signert'
            ]}
            values={[
              [
                formik.values.administrativeInfo.sendTenantProfile
                  ? 'Ja'
                  : 'Nei',
                formik.values.administrativeInfo.createAqusitionForm
                  ? 'Ja'
                  : 'Nei',
                formik.values.administrativeInfo.sendInvoiceBeforeSignedContract
                  ? 'Ja'
                  : 'Nei'
              ]
            ]}
            open={open}
            onClick={() => handleClick(5)}
            index={5}
          />
        </Stack>
      </List>
    </Stack>
  );
};
export default Component;
