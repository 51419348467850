import { Divider, Stack, Typography } from '@myrent/myrent-ui';
import { Checkbox, Select } from 'app/components/input';
import { DatePicker } from 'app/components/input/date-picker';
import { isPast, subDays } from 'date-fns';
import { useEffect } from 'react';

interface Props {
  formik: any;
}

export const contractTypeOptions = [
  { label: 'Tidsbestemt kontrakt m/oppsigelse', id: 1 },
  { label: 'Tidsbestemt kontrakt', id: 2 },
  { label: 'Tidsubestemt kontrakt', id: 3 }
];

const Component = ({ formik }: Props): JSX.Element => {
  const contractNoticeOptions = [
    { label: '1', id: 1 },
    { label: '2', id: 2 },
    { label: '3', id: 3 },
    { label: '4', id: 4 },
    { label: '5', id: 5 },
    { label: '6', id: 6 },
    { label: '7', id: 7 },
    { label: '8', id: 8 },
    { label: '9', id: 9 },
    { label: '10', id: 10 },
    { label: '11', id: 11 },
    { label: '12', id: 12 }
  ];

  useEffect(() => {
    if (formik.values.contractInfo.startDate instanceof Date) {
      const nextYear = new Date(
        formik.values.contractInfo.startDate.getFullYear() + 1,
        formik.values.contractInfo.startDate.getMonth(),
        formik.values.contractInfo.startDate.getDate()
      );
      if (!formik.values.rentInfo.isExternalRegulated) {
        formik.setFieldValue('rentInfo.nextRegulationDate', nextYear);
        formik.setFieldValue('rentInfo.contractstartDate', nextYear);
      }
    }
  }, [formik.values.contractInfo.startDate]);

  return (
    <Stack p={{ xs: 2, sm: 10 }} pr={{ md: 55 }} pb={{ xs: 10 }} spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Kontrakt</Typography>
        <Divider
          sx={{
            borderBottomWidth: 3,
            backgroundColor: '#003D43'
          }}
        />
      </Stack>
      <Select
        fullWidth={false}
        id="contractInfo-contractType"
        name="contractInfo.contractType"
        label="Kontraktstype"
        placeholder="Velg kontraktstype"
        value={formik.values.contractInfo.contractType}
        onChange={formik.handleChange}
        options={contractTypeOptions}
        error={
          formik.touched.contractInfo?.contractType &&
          Boolean(formik.errors.contractInfo?.contractType)
        }
        helperText={
          formik.touched.contractInfo?.contractType &&
          formik.errors.contractInfo?.contractType
        }
        labelColor="#242424"
        onBlur={formik.handleBlur}
        labelLeftMargin={'0'}
        labelWeight={'0'}
        bgColor={'#EDEDED'}
      />
      <DatePicker
        value={formik.values.contractInfo.startDate}
        setFormik={formik}
        name={'contractInfo.startDate'}
        labelColor="#242424"
        labelWeight="normal"
        labelLeftMargin="0"
        error={
          formik.touched.contractInfo?.startDate &&
          Boolean(formik.errors.contractInfo?.startDate)
        }
        helperText={
          formik.touched.contractInfo?.startDate &&
          formik.errors.contractInfo?.startDate
        }
        label={'Startdato for kontrakt'}
      />
      {!(formik.values.contractInfo.contractType == 3) && (
        <DatePicker
          value={formik.values.contractInfo.endDate}
          setFormik={formik}
          name={'contractInfo.endDate'}
          labelColor="#242424"
          labelWeight="normal"
          labelLeftMargin="0"
          error={
            formik.touched.contractInfo?.endDate &&
            Boolean(formik.errors.contractInfo?.endDate)
          }
          helperText={
            formik.touched.contractInfo?.endDate &&
            formik.errors.contractInfo?.endDate
          }
          label={'Sluttdato for kontrakt'}
        />
      )}
      {!(formik.values.contractInfo.contractType == 2) && (
        <Select
          fullWidth={false}
          id="contractInfo-noticePeriod"
          name="contractInfo.noticePeriod"
          label="Oppsigelsestid (antall måneder)"
          placeholder="Velg oppsigelsestid"
          value={formik.values.contractInfo.noticePeriod}
          onChange={formik.handleChange}
          options={contractNoticeOptions}
          error={
            formik.touched.contractInfo?.noticePeriod &&
            Boolean(formik.errors.contractInfo?.noticePeriod)
          }
          helperText={
            formik.touched.contractInfo?.noticePeriod &&
            formik.errors.contractInfo?.noticePeriod
          }
          labelColor="#003D43"
          onBlur={formik.handleBlur}
          labelLeftMargin={'0'}
          labelWeight={'0'}
          bgColor={'#EDEDED'}
        />
      )}
      <DatePicker
        value={formik.values.contractInfo.acquisitionDate}
        setFormik={formik}
        name={'contractInfo.acquisitionDate'}
        labelColor="#242424"
        labelWeight="normal"
        labelLeftMargin="0"
        error={
          formik.touched.contractInfo?.acquisitionDate &&
          Boolean(formik.errors.contractInfo?.acquisitionDate)
        }
        helperText={
          formik.touched.contractInfo?.acquisitionDate &&
          formik.errors.contractInfo?.acquisitionDate
        }
        label={'Overtakelsesdato'}
      />
      <Checkbox
        id={'contractInfo.acquistied'}
        name={'contractInfo.acquistied'}
        value={
          isPast(formik.values.contractInfo.acquisitionDate)
            ? true
            : formik.values.contractInfo.acquistied
        }
        disabled={isPast(formik.values.contractInfo.acquisitionDate)}
        fontSize={'16px'}
        fontColor={'#003D43'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={undefined}
        helperText={undefined}
        label={'Leietaker har allerede flyttet inn'}
      />
    </Stack>
  );
};

export default Component;
