import { ApiResponse, ApisauceInstance } from 'apisauce';
import { ProblemDocument } from 'http-problem-details';
import { Deployment } from 'types';

export interface ValidationProblem extends ProblemDocument {
  errors?: {
    [key: string]: string[];
  };
}

const throwOnErrorGuard = (
  apiResponse: ApiResponse<unknown, ProblemDocument>,
  rejectWithValue: (error: ProblemDocument) => unknown
) => {
  if (!apiResponse.ok && apiResponse.data) {
    throw rejectWithValue(apiResponse.data);
  }
};

export interface UserResponse {
  username?: string;
  fullname?: string;
}

export interface ExampleResponse {
  id: string;
  title: string;
}

export interface DeleteRequest {
  id: string;
}

export interface AddExampleRequest {
  title: string;
}

export interface UpdateExampleRequest {
  id: string;
  title: string;
}

class UserClient {
  private readonly apiInstance: ApisauceInstance;

  constructor(apiInstance: ApisauceInstance) {
    this.apiInstance = apiInstance;
  }

  async getUser(): Promise<UserResponse> {
    const r = await this.apiInstance.get(`/user`);
    return r.data as UserResponse;
  }
}

class ExampleClient {
  private readonly apiInstance: ApisauceInstance;

  constructor(apiInstance: ApisauceInstance) {
    this.apiInstance = apiInstance;
  }

  async fetch(
    id: string,
    rejectWithValue: (message: ProblemDocument) => unknown
  ): Promise<ExampleResponse | undefined> {
    const r = await this.apiInstance.get<ExampleResponse, ProblemDocument>(
      `/example/${id}`
    );
    throwOnErrorGuard(r, rejectWithValue);
    return r.data as ExampleResponse;
  }

  async add(
    request: AddExampleRequest,
    rejectWithValue: (message: ProblemDocument) => unknown
  ): Promise<ExampleResponse | undefined> {
    const r = await this.apiInstance.post<ExampleResponse, ProblemDocument>(
      `/example`,
      request
    );
    throwOnErrorGuard(r, rejectWithValue);
    return r.data as ExampleResponse;
  }

  async update(
    request: UpdateExampleRequest,
    rejectWithValue: (message: ProblemDocument) => unknown
  ) {
    const r = await this.apiInstance.put<ExampleResponse, ProblemDocument>(
      `/example/${request.id}`,
      request
    );

    throwOnErrorGuard(r, rejectWithValue);
    return r.data as ExampleResponse;
  }

  async fetchDeployment() {
    const response = await this.apiInstance.get<Deployment, ProblemDocument>(
      `/deployment`
    );
    if (response.ok) {
      return response.data as Deployment;
    }
    if (response.status === 404) return null;
  }
}

export interface ApiInterface {
  user: UserClient;
  example: ExampleClient;
}

export const createClient = (apiInstance: ApisauceInstance): ApiInterface => {
  return {
    user: new UserClient(apiInstance),
    example: new ExampleClient(apiInstance)
  };
};
