import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';

import { Container, Label } from './styles';

interface Option {
  id: number;
  label: string;
  backgroundColor?: string;
}

interface Props {
  id: string;
  name: string;
  value: number[];
  onChange: (event: SelectChangeEvent<any>) => void;
  onBlur: (event: React.ChangeEvent<any>) => void;
  error: boolean | undefined;
  helperText: string | false | undefined;
  placeholder?: string;
  label: string;
  labelColor?: string;
  labelWeight?: string;
  labelLeftMargin?: string;
  fullWidth?: boolean;
  maxLength?: number;
  options: Option[];
  bgColor?: string;
}

const Component = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  error,
  placeholder = 'Velg',
  label,
  labelColor = 'white',
  labelWeight = 'bold',
  labelLeftMargin = '10px',
  bgColor = 'white',
  helperText,
  fullWidth = false,
  options
}: // sx
Props): JSX.Element => {
  return (
    <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
      {label && (
        <Label htmlFor={name}>
          <Typography
            color={labelColor}
            marginLeft={labelLeftMargin}
            fontWeight={labelWeight}
          >
            {label}
          </Typography>
        </Label>
      )}
      <FormControl>
        <Select
          id={id}
          name={name}
          value={value ?? ''}
          onChange={onChange}
          multiple={true}
          displayEmpty={true}
          renderValue={(selected) => (
            //options?.find((x) => x.id === selected)?.label || placeholder
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {options
                .filter((o: Option) => selected.includes(o.id))
                .map(({ id, label, backgroundColor }) => (
                  <Chip key={id} label={label} />
                ))}
            </Box>
          )}
          error={error}
          sx={{
            backgroundColor: bgColor,
            paddingY: 1,
            paddingRight: 1,
            opacity: 0.9,
            minHeight: 50
          }}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 500
              }
            }
          }}
        >
          {options?.map(({ label, backgroundColor, id }) => (
            <MenuItem
              key={id}
              value={id}
              disableRipple
              sx={{ backgroundColor: backgroundColor }}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {helperText && (
        <Container>
          <Typography
            color="red"
            marginLeft="10px"
            sx={{ position: 'absolute', mt: '3px' }}
          >
            {helperText}
          </Typography>
        </Container>
      )}
    </Stack>
  );
};

export default Component;
