import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';

import { Container, Label, Option } from './styles';

function ChevronDown() {
  return <KeyboardArrowDownIcon fontSize="large" />;
}

interface Option {
  label: string;
  backgroundColor?: string;
}

interface Props {
  id: string;
  name: string;
  value: string[];
  onChange: (event: React.SetStateAction<any>) => void;
  error: boolean | undefined;
  helperText: string | false | undefined;
  placeholder?: string;
  label?: string;
  labelColor?: string;
  labelLeftMargin?: string;
  fullWidth?: boolean;
  maxLength?: number;
  options: Option[] | null | undefined;
}

const Component = ({
  id,
  name,
  value,
  onChange,
  error,
  placeholder,
  label,
  labelColor = 'white',
  labelLeftMargin = '10px',
  helperText,
  fullWidth = false,
  options
}: Props): JSX.Element => {
  const currentValue = value;
  const isAllSelected = !!(
    options &&
    options.length > 0 &&
    currentValue.length === options.length
  );
  const handleChange = (event: SelectChangeEvent<typeof currentValue>) => {
    const newValue = event.target.value;
    if (newValue[newValue.length - 1] === 'all') {
      onChange(newValue.length > 1 ? [] : options?.map((o) => o.label));
      return;
    }
    onChange(typeof newValue === 'string' ? newValue.split(',') : newValue);
  };
  return (
    <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
      {label && (
        <Label htmlFor={name}>
          <Typography
            color={labelColor}
            marginLeft={labelLeftMargin}
            fontWeight="bold"
          >
            {label}
          </Typography>
        </Label>
      )}
      <FormControl size="small">
        <InputLabel shrink={false}>
          {value ? undefined : placeholder}
        </InputLabel>
        <Select
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          error={error}
          sx={{
            backgroundColor: 'white'
          }}
          IconComponent={ChevronDown}
          multiple={true}
          renderValue={(selected) => selected.join(', ')}
        >
          <MenuItem value="all">
            <Checkbox checked={isAllSelected} />
            <ListItemText primary={'Velg alle'} />
          </MenuItem>
          {options?.map((option) => (
            <MenuItem key={option.label} value={option.label} disableRipple>
              <Checkbox checked={value.indexOf(option.label) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {helperText && (
        <Container>
          <Typography
            color="red"
            marginLeft="10px"
            sx={{ position: 'absolute', mt: '3px' }}
          >
            {helperText}
          </Typography>
        </Container>
      )}
    </Stack>
  );
};

export default Component;
