import ArchiveIcon from '@mui/icons-material/Archive';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';

interface Option {
  label: string;
  onClick: () => void;
}

interface Props {
  options: Option[];
}

export function ArchiveOption({ options }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {options.map((option, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              handleClose();
              option.onClick();
            }}
          >
            <ArchiveIcon />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
