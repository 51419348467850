import { Stack, Typography } from '@myrent/myrent-ui';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { MaskedInput, TextInput } from 'app/components/input';
import { LoadingPopup } from 'app/components/loading';
import {
  useGetContextQuery,
  useSubmitComplementaryInfoMutation
} from 'app/context/api/account';
import { ClientContext } from 'app/context/api/account/types';
import {
  useCreateClientMutation,
  useLazyGetClientOverviewQuery
} from 'app/context/api/client';
import {
  Client,
  CreateClientViewModel,
  PersonalInfo
} from 'app/context/api/client/types';
import { accountNumberSchema } from 'app/utils/schemas/accountNumber';
import { organizationNumberSchema } from 'app/utils/schemas/organizationNumber';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { AddSub } from './components/AddSub';
import { EditSub } from './components/EditSub';
import { enhetsregisterQuery } from './utils/enhetsregisterQuery';

const companySchema = yup.object().shape({
  name: yup.string().required('Skriv inn selskapsnavn'),
  organizationNumber: organizationNumberSchema.required(
    'Skriv inn organisasjonsnummer'
  ),
  address: yup.string().required('Skriv inn addresse'),
  city: yup.string().required('Skriv inn poststed'),
  zipCode: yup.string().required('Skriv inn postnummer'),
  invoiceAccountNumber: accountNumberSchema.required('Skriv inn kontonummer')
});

const validationSchema = yup
  .object({
    subs: yup.array().of(companySchema)
  })
  .concat(companySchema);

interface Props {
  goBack?: () => void;
  callback?: () => void;
  initialData?: PersonalInfo | null;
  redirect?: string;
  isComplementary?: boolean;
}

const Component = ({
  goBack,
  callback,
  initialData,
  redirect,
  isComplementary = false
}: Props): JSX.Element => {
  const [submitCreateClient, { isLoading: isCreatingClient }] =
    useCreateClientMutation();
  const [submitComplementaryInfo, { isLoading: isUpdatingComplementaryInfo }] =
    useSubmitComplementaryInfoMutation();
  const { data: userContext } = useGetContextQuery();
  const [getClientOverview, { data: clientOverview }] =
    useLazyGetClientOverviewQuery();

  useEffect(() => {
    if (userContext) {
      const mainClient = userContext.clients.find(
        (c: ClientContext) => c.isMainClient
      );
      if (userContext.clients.length > 0 && mainClient) {
        getClientOverview(mainClient.clientId);
      }
    }
  }, [userContext]);

  const formik = useFormik({
    initialValues: {
      ...(initialData || {}),
      name: clientOverview?.name || '',
      organizationNumber: clientOverview?.organizationNumber || '',
      address: clientOverview?.address || '',
      city: clientOverview?.city || '',
      zipCode: clientOverview?.zipCode || '',
      invoiceAccountNumber: clientOverview?.invoiceAccountNumber || '',
      subs: []
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const complementaryInfo = {
        ...initialData,
        clientType: 1,
        organizationNumber: values.organizationNumber,
        clientAddress: values.address,
        clientCity: values.city,
        clientZipCode: values.zipCode,
        clientName: values.name,
        invoiceAccountNumber: values.invoiceAccountNumber,
        clientVatRegistered: false
      };

      if (isComplementary) {
        try {
          const formResult = await submitComplementaryInfo(
            complementaryInfo
          ).unwrap();
          if (!formResult.success) {
            throw 'Feil i oppdatering av kontonummer.';
          }

          callback && callback();
        } catch (err) {
          console.log(err);
        }
      }

      const submitArray: CreateClientViewModel[] = [];

      values.subs.forEach((sub: Client) => {
        const subCompany = {
          createClientViewModel: {
            client: {
              clientType: 1,
              organizationNumber: sub.organizationNumber,
              address: sub.address,
              city: sub.city,
              zipCode: sub.zipCode,
              name: sub.name,
              invoiceAccountNumber: sub.invoiceAccountNumber
            }
          }
        };

        submitArray.push(subCompany);
      });

      try {
        for (const company of submitArray) {
          const result = await submitCreateClient(company).unwrap();
          if (!result.success) {
            throw 'Feil i opprettelse av leietakerforhold.';
          }
        }

        if (redirect) {
          window.location.replace(redirect);
        } else {
          callback && callback();
        }
      } catch (err) {
        console.log(err);
      }
    }
  });

  useEffect(() => {
    async function fetchData() {
      if (formik.values.organizationNumber) {
        try {
          const brregData = await enhetsregisterQuery(
            formik.values.organizationNumber.replace(/\s/g, '')
          );
          if (!brregData.status) {
            const address = brregData?.forretningsadresse;
            const company = {
              address: address?.adresse[0],
              name: brregData.navn,
              zipCode: address?.postnummer,
              city: address?.poststed,
              invoiceAccountNumber: ''
            };
            formik.setFieldValue('address', address.adresse[0]);
            formik.setFieldValue('zipCode', address.postnummer);
            formik.setFieldValue('city', address.poststed);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
    if (isComplementary) {
      fetchData();
    }
  }, [formik.values.organizationNumber]);

  const [showSelector, setShowSelector] = useState(false);
  const [minifiedMainClient, setMinifiedMainClient] = useState(
    !isComplementary
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <Stack padding={{ xs: 4, md: 7 }} width={{ xs: '100%', md: '35%' }}>
          <Typography variant="h5" fontWeight="bold" marginBottom={1}>
            Mine selskap
          </Typography>
          {isComplementary && (
            <Typography marginBottom={2}>
              Vennligst sjekk at informasjonen vi har stemmer, og fyll inn det
              som mangler.
            </Typography>
          )}
          <Typography>
            Du kan legge til datterselskap eller andre, uavhengige selskap.
          </Typography>
        </Stack>
        <Stack width={{ xs: '100%', md: '65%' }} alignItems="center">
          <Stack
            spacing={2}
            padding={{ xs: 4, md: 4 }}
            paddingTop={{ xs: 3, md: 8 }}
            width={{ xs: '100%', md: '75%' }}
            sx={{
              backgroundColor: '#E7E0DB'
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              Hovedselskap
            </Typography>
            <MaskedInput
              mask="### ### ###"
              fullWidth
              id="name"
              name="organizationNumber"
              label="Organisasjonsnummer"
              labelColor="black"
              labelLeftMargin="0px"
              placeholder="9 siffer"
              value={formik.values.organizationNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.organizationNumber &&
                Boolean(formik.errors.organizationNumber)
              }
              helperText={
                formik.touched.organizationNumber &&
                formik.errors.organizationNumber
              }
              disabled={true}
            />
            <TextInput
              id="name"
              name="name"
              label="Selskapsnavn"
              labelColor="black"
              labelLeftMargin="0px"
              placeholder="Eksempel AS"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              disabled={true}
            />
            {!minifiedMainClient && (
              <>
                <TextInput
                  id="address"
                  name="address"
                  label="Addresse"
                  labelColor="black"
                  labelLeftMargin="0px"
                  placeholder="Gatenavn 1"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  disabled={!isComplementary}
                />
                <Stack
                  width={200}
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                >
                  <TextInput
                    id="zipCode"
                    name="zipCode"
                    label="Postnummer"
                    labelColor="black"
                    labelLeftMargin="0px"
                    placeholder="4 siffer"
                    value={formik.values.zipCode.toString()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.zipCode && Boolean(formik.errors.zipCode)
                    }
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                    disabled={!isComplementary}
                  />
                  <TextInput
                    id="city"
                    name="city"
                    label="Sted"
                    labelColor="black"
                    labelLeftMargin="0px"
                    placeholder="Skriv inn"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    disabled={!isComplementary}
                  />
                </Stack>
                <MaskedInput
                  mask="#### ## #####"
                  id="invoiceAccountNumber"
                  name="invoiceAccountNumber"
                  label="Fakturakontonummer"
                  labelColor="black"
                  labelLeftMargin="0px"
                  placeholder="11 siffer"
                  value={formik.values.invoiceAccountNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.invoiceAccountNumber &&
                    Boolean(formik.errors.invoiceAccountNumber)
                  }
                  helperText={
                    formik.touched.invoiceAccountNumber &&
                    formik.errors.invoiceAccountNumber
                  }
                  disabled={!isComplementary}
                />
              </>
            )}
            <Stack>
              <div
                onClick={() => setMinifiedMainClient(!minifiedMainClient)}
                style={{ cursor: 'pointer', marginLeft: '5px', top: '-10px' }}
              >
                <Typography fontSize={14}>
                  {minifiedMainClient ? 'Vis flere detaljer' : 'Vis mindre'}
                </Typography>
              </div>
            </Stack>
            {showSelector && (
              <Stack direction="row" spacing={2}>
                <AddSub formik={formik} setAddUnitSelector={setShowSelector} />
              </Stack>
            )}
            {formik.values.subs && (
              <Stack spacing={2}>
                {formik.values.subs.map((unit, index) => (
                  <EditSub key={index} formik={formik} unitIndex={index} />
                ))}
              </Stack>
            )}
            <Stack spacing={2} padding={3} sx={{ backgroundColor: '#F5F2F0' }}>
              <SecondaryButton
                disabled={!formik.values.organizationNumber}
                addIcon
                onClick={() => setShowSelector(!showSelector)}
              >
                Velg fra konsernstruktur
              </SecondaryButton>
              <SecondaryButton
                addIcon
                onClick={() =>
                  formik.setFieldValue('subs', [
                    ...(formik.values.subs || []),
                    {
                      name: '',
                      organizationNumber: '',
                      address: '',
                      zipCode: '',
                      city: '',
                      invoiceAccountNumber: ''
                    }
                  ])
                }
              >
                Legg til selskap manuelt
              </SecondaryButton>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            padding={3}
            paddingBottom={6}
            spacing={2}
          >
            {/* <SecondaryButton addIcon filled>
              Legg til nytt selskap
            </SecondaryButton> */}
            {!goBack && (
              <SecondaryButton type="submit" filled>
                Lagre
              </SecondaryButton>
            )}
          </Stack>
        </Stack>
      </Stack>
      {goBack && (
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#D9CBC0',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 1
          }}
        >
          <Stack direction="row" spacing={2} marginLeft={{ xs: 0, md: 20 }}>
            <Typography>Steg 2 av 3</Typography>
          </Stack>
          <Stack direction="row" marginBottom={{ sm: 1, md: 0 }}>
            <SecondaryButton
              filled
              chevron="left"
              onClick={() => goBack && goBack()}
            >
              Forrige steg
            </SecondaryButton>
          </Stack>
          <Stack direction="row" spacing={2} marginRight={{ xs: 0, md: 20 }}>
            <PrimaryButton filled chevron="right" type="submit">
              Neste steg
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      <LoadingPopup open={isCreatingClient || isUpdatingComplementaryInfo} />
    </form>
  );
};

export default Component;
