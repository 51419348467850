import { createSlice } from '@reduxjs/toolkit';
import { number } from 'yup';

import type { RootState } from '../../store';

interface TenantBankIdType {
  userId: number;
  bankIdType: number;
}

export interface Tenant {
  id: number;
  name: string;
  noEmail: boolean;
}

export interface contractState {
  path: string;
  contractId: number;
  clientName: string;
  object: {
    propertyAddress: string;
    description?: string;
  };
  upload: {
    contractId: number;
    send: boolean;
  };
  sign: {
    EmailMessage: string;
    contractId: number;
    Html: string;
    currentUserBankIdType: number;
    tenantBankIdTypes: TenantBankIdType[];
  };
  closeMsg: string;
  contractTemplateId: number;
  tenants: Tenant[];
  error: string;
}

const initialState: contractState = {
  path: '',
  contractId: -1,
  clientName: '',
  object: {
    propertyAddress: '',
    description: undefined
  },
  upload: {
    contractId: 0,
    send: false
  },
  sign: {
    EmailMessage:
      'Vennligst les igjennom kontrakt og ta kontakt om det skulle være noen spørsmål.',
    contractId: 0,
    Html: '',
    currentUserBankIdType: 1,
    tenantBankIdTypes: []
  },
  contractTemplateId: -1,
  closeMsg: '',
  tenants: [],
  error: ''
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setPath: (state, action) => {
      state.path = action.payload;
    },
    setContractId: (state, action) => {
      state.upload.contractId = action.payload;
      state.contractId = action.payload;
      state.upload.contractId = action.payload;
    },
    setUploadSend: (state, action) => {
      state.upload.send = action.payload;
    },
    setEmailMsg: (state, action) => {
      state.sign.EmailMessage = action.payload;
    },
    setCurrentUserBankIdType: (state, action) => {
      state.sign.currentUserBankIdType = action.payload;
    },
    initTenantBankIdTypes: (state, action) => {
      state.sign.tenantBankIdTypes = action.payload;
    },
    setTenantBankIdType: (state, action) => {
      const newArray = [...state.sign.tenantBankIdTypes]; //making a new array
      newArray[action.payload.index].bankIdType = action.payload.bankIdType; //changing value in the new array
      state.sign.tenantBankIdTypes = newArray;
    },
    setContractToSign: (state, action) => {
      state.sign.contractId = action.payload.contractId;
      state.sign.Html = action.payload.Html;
    },
    setHtmlContent: (state, action) => {
      state.sign.Html = action.payload;
    },
    setContractTemplateId: (state, action) => {
      state.contractTemplateId = action.payload;
    },
    setTenants: (state, action) => {
      state.tenants = action.payload;
    },
    setObject: (state, action) => {
      state.object = action.payload;
    },
    setCloseMsg: (state, action) => {
      state.closeMsg = action.payload;
    },
    setClientName: (state, action) => {
      state.clientName = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const {
  setPath,
  setContractId,
  setUploadSend,
  setError,
  setEmailMsg,
  setCurrentUserBankIdType,
  initTenantBankIdTypes,
  setTenantBankIdType,
  setContractToSign,
  setHtmlContent,
  setContractTemplateId,
  setTenants,
  setObject,
  setCloseMsg,
  setClientName
} = contractSlice.actions;

export const getPath = (state: RootState) => state.contract;

export default contractSlice.reducer;
