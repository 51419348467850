import { Stack, Typography } from '@mui/material';

import {
  ClickableContainer,
  Container,
  StepConnector,
  StepConnectorContainer,
  StepContainer,
  StepIcon
} from './styles';

interface Props {
  steps: string[];
  active: number;
  popActive?: boolean;
  backgroundColor?: string;
  color?: string;
  labelColor?: string;
}

// "#242424"

const Component = ({
  active,
  steps,
  popActive = false,
  backgroundColor = '#003d43',
  color = 'white',
  labelColor = 'white'
}: Props): JSX.Element => {
  // TODO?: Add navigation to clicks on progresstracker. Not sure if this is smart as it interferes with the register flow.

  return (
    <Container>
      {steps?.map((step, index) => (
        <ClickableContainer key={index}>
          <Stack direction="row" spacing={2} sx={{ position: 'relative' }}>
            <div>
              <StepContainer key={index}>
                <StepIcon
                  active={active === index}
                  color={color}
                  backgroundColor={backgroundColor}
                />
              </StepContainer>
              {index < steps.length - 1 && (
                <StepConnectorContainer active={active === index}>
                  <StepConnector color={color} />
                </StepConnectorContainer>
              )}
            </div>
            <Typography
              sx={{
                position: 'absolute',
                left: 18,
                top: popActive && active === index ? -6 : -3
              }}
              color={active === index ? '#9aeabc' : labelColor}
              fontWeight={active === index ? 'bold' : 'normal'}
              variant="body1"
              fontSize={popActive && active === index ? 20 : 16}
            >
              {index + 1}. {step}
            </Typography>
          </Stack>
        </ClickableContainer>
      ))}
    </Container>
  );
};

export default Component;
