import { Stack, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import { TextInput } from 'app/components/input';
import { LoadingPopup } from 'app/components/loading';
import { useAddLoginMutation } from 'app/context/api/account';
import { login } from 'app/context/user/userSlice';
import { useAppDispatch } from 'app/hooks';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Container } from './styles';

interface Props {
  children?: JSX.Element | string;
}

const validationSchema = yup.object({
  email: yup.string().required('Skriv inn brukernavn'),
  password: yup.string().required('Skriv inn passord')
});

const Component = ({ children }: Props): JSX.Element => {
  const [loginCall, { isLoading }] = useAddLoginMutation();
  const [loginFailed, setLoginFalied] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const apiUrl = process.env.REACT_APP_API_URL;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await loginCall({
          UserName: values.email,
          Password: values.password
        }).unwrap();

        if (result.success) {
          dispatch(login());
          if (apiUrl) {
            window.location.replace(apiUrl);
          }
        } else {
          setLoginFalied(true);
        }
      } catch (err) {
        setLoginFalied(true);
      }
    }
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="row"
          spacing={0}
          padding={2}
          // paddingTop={{ xs: '50%', md: '15%' }}
          alignItems="center"
          justifyContent="center"
          // style={{ minHeight: '80vh' }}
        >
          <Stack
            spacing={3}
            minWidth={{ xs: '40vh', md: 400 }}
            alignItems="center"
            justifyContent="center"
            minHeight="80vh"
          >
            <TextInput
              fullWidth
              id="email"
              name="email"
              label="Brukernavn"
              placeholder="Skriv inn brukernavn"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextInput
              fullWidth
              id="password"
              name="password"
              label="Passord"
              type="password"
              placeholder="Skriv inn passord"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Stack
              direction="row"
              // justifyContent="space-between"
              alignItems="center"
              spacing={{ xs: 6, md: 16 }}
            >
              <Typography
                sx={{
                  color: 'white',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() => navigate('/ForgotPassword')}
              >
                {'Glemt passord?'}
              </Typography>
              <PrimaryButton filled type="submit">
                Logg inn
              </PrimaryButton>
            </Stack>
            <Typography color={'red'}>
              {loginFailed && 'Feil brukernavn eller passord. Prøv igjen.'}
            </Typography>
          </Stack>
          <LoadingPopup open={isLoading} />
        </Stack>
      </form>
    </Container>
  );
};

export default Component;
