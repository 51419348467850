import { color } from '@mui/system';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  margin: 10px;
  padding-left: 20%;
`;

export const ClickableContainer = styled.div`
  /* cursor: pointer; */
`;

export const StepContainer = styled.div`
  width: 1.2rem;
  height: 1.2rem;
`;

interface StepProps {
  active?: boolean;
  color: string;
  backgroundColor: string;
}

export const StepIcon = styled.div<StepProps>`
  background: ${({ active, color }) => (active ? '#9aeabc' : color)};
  border-radius: 50%;
  height: 100%;
  width: 100%;
  box-shadow: ${({ active, backgroundColor }) =>
    active ? `0 0 0 3px ${backgroundColor}, 0 0 0 6px #9aeabc` : ''};
`;

export interface StepConnectorContainerProps {
  active?: boolean;
}

export const StepConnectorContainer = styled.div<StepConnectorContainerProps>`
  margin-top: ${({ active }) => (active ? '6px' : '0')};
  width: 1.2rem;
  height: ${({ active }) => (active ? '1.2rem' : '1.5rem')};
`;

interface StepConnectorProps {
  color?: string;
}

export const StepConnector = styled.div<StepConnectorProps>`
  height: 100%;
  width: 10%;
  margin-left: 45%;
  background-color: ${({ color }) => color};
`;
