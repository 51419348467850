import { Stack, Typography } from '@mui/material';
import { white } from 'app/theme/colors';

import { DataGridColumns, DataGridRow } from '../../types';
import { CountTypeStyledCell } from './CountTypeStyledCell';
import { PillStyledCell } from './PillStyledCell';

interface CardProps {
  columns: DataGridColumns;
  row: DataGridRow;
  onRowClick?: (row: DataGridRow) => void;
}

export function Card({ columns, row, onRowClick }: CardProps) {
  const curatedColumns: { [key: string]: Array<string> } = {
    text: [],
    count: [],
    option: [],
    pill: []
  };

  Object.keys(columns).forEach((key) => {
    const column = columns[key];
    switch (column.style) {
      case 'count':
        curatedColumns['count'].push(key);
        break;
      case 'option':
        curatedColumns['option'].push(key);
        break;
      case 'pill':
        curatedColumns['pill'].push(key);
        break;
      default:
        curatedColumns['text'].push(key);
        break;
    }
  });

  return (
    <Stack
      sx={{
        backgroundColor: white[100],
        borderRadius: '15px',
        padding: '20px'
      }}
      spacing={1}
    >
      {curatedColumns['pill'].map((column, i) => (
        <Stack
          key={`${i}-header`}
          direction="row"
          spacing={1}
          justifyContent={'space-between'}
        >
          <PillStyledCell key={`${i}-pill`}>{row[column]}</PillStyledCell>
          {curatedColumns['option'][i] && (
            <Stack key={`${i}-option`}>
              {row[curatedColumns['option'][i]]}
            </Stack>
          )}
        </Stack>
      ))}
      <Stack
        spacing={1}
        onClick={() => onRowClick && onRowClick(row)}
        sx={{ cursor: 'pointer' }}
      >
        {curatedColumns['text'].map((column, i) => (
          <Typography key={`${i}-text`}>{row[column]}</Typography>
        ))}
        {curatedColumns['count'].map((column, i) => (
          <CountTypeStyledCell key={`${i}-count`} title={columns[column].label}>
            {row[column]}
          </CountTypeStyledCell>
        ))}
      </Stack>
    </Stack>
  );
}
