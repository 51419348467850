import { Divider, Grid, Stack, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import { Dialog } from 'app/components/dialog';
import { Checkbox, TextInput } from 'app/components/input';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { FormikRegisterValues } from '../../component';
import { Terms } from './index';

interface Props {
  formik: FormikProps<FormikRegisterValues>;
  nextPageTitle: string;
}

export const userValidationSchema = yup.object({
  email: yup
    .string()
    .email('Skriv inn en gyldig e-post adresse')
    .required('Skriv inn epost'),
  password: yup
    .string()
    .min(8, 'Du må ha minst 8 siffer i passordet')
    .required('Skriv inn passord'),
  acceptTerms: yup.boolean().oneOf([true], 'Du må akseptere våre vilkår')
});

const Component = ({ formik, nextPageTitle }: Props): JSX.Element => {
  const navigate = useNavigate();
  const [openTerms, setOpenTerms] = useState(false);

  return (
    <div>
      <Typography variant="h6" color="white">
        Din brukerinformasjon
      </Typography>
      <Divider color="white" />
      <Grid container spacing={0} paddingTop={2}>
        <Stack spacing={5} width={{ sm: '100%', md: '70%' }}>
          <TextInput
            id="email"
            name="userInfo.email"
            label="E-post"
            placeholder="For eksempel navn@epost.no"
            value={formik.values.userInfo.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.errors.userInfo &&
              formik.touched.userInfo &&
              formik.touched.userInfo.email &&
              Boolean(formik.errors.userInfo.email)
            }
            helperText={
              formik.errors.userInfo &&
              formik.touched.userInfo &&
              formik.touched.userInfo.email &&
              formik.errors.userInfo.email
            }
            fullWidth
          />
          <TextInput
            id="password"
            name="userInfo.password"
            label="Passord"
            placeholder="Minimum 8 tegn"
            type="password"
            value={formik.values.userInfo.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.errors.userInfo &&
              formik.touched.userInfo &&
              formik.touched.userInfo.password &&
              Boolean(formik.errors.userInfo.password)
            }
            helperText={
              formik.errors.userInfo &&
              formik.touched.userInfo &&
              formik.touched.userInfo.password &&
              formik.errors.userInfo.password
            }
          />
          <Stack spacing={1}>
            <Checkbox
              id="acceptTerms"
              name="userInfo.acceptTerms"
              label="Jeg aksepterer Myrents vilkår og lagring og behandling av personopplysninger som beskrevet i personvernerklæringen."
              value={formik.values.userInfo.acceptTerms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.errors.userInfo &&
                formik.touched.userInfo &&
                formik.touched.userInfo.acceptTerms &&
                Boolean(formik.errors.userInfo.acceptTerms)
              }
              helperText={
                formik.errors.userInfo &&
                formik.touched.userInfo &&
                formik.touched.userInfo.acceptTerms &&
                formik.errors.userInfo.acceptTerms
              }
              fullWidth
            />
            <Typography
              variant="body2"
              color={'white'}
              onClick={() => setOpenTerms(true)}
            >
              Klikk{' '}
              <Typography
                variant="body2"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                display="inline"
              >
                her
              </Typography>{' '}
              for å lese vilkårene
            </Typography>
            <Dialog isOpen={openTerms} setIsOpen={setOpenTerms}>
              <Terms />
            </Dialog>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <PrimaryButton
              filled
              onClick={() => {
                if (
                  Boolean(formik.errors.userInfo) ||
                  !formik.touched.userInfo
                ) {
                  formik.setFieldTouched('userInfo.acceptTerms', true);
                } else {
                  navigate('kontaktinformasjon');
                }
              }}
              chevron={'right'}
            >
              {nextPageTitle}
            </PrimaryButton>
          </Stack>
        </Stack>
      </Grid>
    </div>
  );
};

export default Component;
