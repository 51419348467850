import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';

export interface contextState {
  context: {
    type: 'user' | 'client' | 'property' | 'object';
    id: number;
    label: string;
  };
}

const initialState: contextState = {
  context: {
    type: 'user',
    id: 0,
    label: ''
  }
};

export const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext: (state, action) => {
      state.context = action.payload;
    }
  }
});

export const { setContext } = contextSlice.actions;

export const getContext = (state: RootState) => state.context;

export default contextSlice.reducer;
