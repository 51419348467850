import { apiSlice } from '../index';
import {
  ObjectVacancySummaryResponse,
  SoonVacantObjectsRequest,
  SoonVacantObjectsResponse,
  VacantObjectsResponse
} from './types';

const objectApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVacantObjects: builder.query<VacantObjectsResponse[], void>({
      query: () => ({
        url: 'v1/Object/VacantObjects'
      })
    }),
    getSoonAvailableObjects: builder.query<
      SoonVacantObjectsResponse[],
      SoonVacantObjectsRequest
    >({
      query: (data) => ({
        url: 'v1/Object/SoonAvailableObjects',
        params: data
      }),
      providesTags: ['SoonVacantObjects']
    }),
    updateObjectStatus: builder.mutation({
      query: (payload) => ({
        url: 'v1/Object/ObjectStatus',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      }),
      invalidatesTags: ['SoonVacantObjects']
    }),
    getObjectsVacancySummary: builder.query<ObjectVacancySummaryResponse, any>({
      query: () => ({
        url: 'v1/Object/GetObjectsVacancySummary'
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetObjectsVacancySummaryQuery,
  useGetVacantObjectsQuery,
  useGetSoonAvailableObjectsQuery,
  useUpdateObjectStatusMutation
} = objectApi;
