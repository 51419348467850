import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { useAppDispatch } from 'app/hooks';

import { decrement, increment } from '../tenantStepSlide';

interface Props {
  formik: any;
}

interface Tenant {
  firstName: string;
  lastName: string;
}

const Component = ({ formik }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  return (
    <Stack position={'relative'}>
      <Stack
        p={{ xs: 2, sm: 14 }}
        pt={{ sm: 4, md: 14 }}
        pb={{ xs: 10 }}
        spacing={6}
      >
        <Stack spacing={1}>
          <Typography variant="h4" fontWeight="bold">
            Kontrakt
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: 3,
              backgroundColor: '#242424'
            }}
          />
        </Stack>
        <Stack direction={'row'} spacing={4}>
          <Stack>
            <Typography fontWeight="bold">For utleie av:</Typography>
            <Typography>{formik.values.propertyInfo.object?.label}</Typography>
          </Stack>
          <Stack>
            <Typography fontWeight="bold">Til:</Typography>
            {formik.values.tenantType == 'private'
              ? formik?.values?.privateTenants?.map(
                  (tenant: Tenant, idx: number) => (
                    <Typography key={idx}>
                      {tenant.firstName + ' ' + tenant.lastName}
                    </Typography>
                  )
                )
              : formik?.values?.companyTenants?.map(
                  (tenant: Tenant, idx: number) => (
                    <Typography key={idx}>
                      {tenant.firstName + ' ' + tenant.lastName}
                    </Typography>
                  )
                )}
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="h5" fontWeight="bold">
            Hva vil du gjøre?
          </Typography>
          <Typography variant="body1" fontWeight="normal" sx={{ opacity: 0.5 }}>
            Velg et av alternativene nedenfor
          </Typography>
        </Stack>
        <Stack spacing={2} sx={{ width: 300 }}>
          <SecondaryButton
            filled={formik.values.finalAction == 'existingContract'}
            onClick={() =>
              formik.setFieldValue('finalAction', 'existingContract')
            }
          >
            Last opp eksisterende kontrakt
          </SecondaryButton>
          <SecondaryButton
            filled={formik.values.finalAction == 'digitalContract'}
            onClick={() =>
              formik.setFieldValue('finalAction', 'digitalContract')
            }
          >
            Opprett digital kontrakt
          </SecondaryButton>
          <SecondaryButton
            filled={formik.values.finalAction == 'noContract'}
            onClick={() => formik.setFieldValue('finalAction', 'noContract')}
          >
            Lagre leieforhold uten kontrakt
          </SecondaryButton>
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{
          backgroundColor: '#242424',
          padding: 2,
          paddingX: 14
        }}
      >
        <PrimaryButton filled onClick={() => dispatch(decrement())}>
          Tilbake til oppsummering
        </PrimaryButton>
        <PrimaryButton
          filled
          disabled={formik.values.finalAction == ''}
          onClick={() => dispatch(increment())}
        >
          Bekreft
        </PrimaryButton>
      </Stack>
    </Stack>
  );
};

export default Component;
