import { Box, colors, Link, Stack, Typography } from '@mui/material';
import React, { ErrorInfo } from 'react';

interface Props {
  children?: React.ReactNode | JSX.Element;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = { hasError: false };

  static getDerivedStateFromError(): object {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Stack
          justifyContent="center"
          height="80vh"
          padding={25}
          direction="column"
          spacing={3}
        >
          <Stack>
            <Typography fontSize={70} fontWeight="semibold">
              Oops, noe gikk galt!
            </Typography>
            <Typography fontSize={30} fontWeight="normal">
              Her skjedde det dessverre en feil hos oss. Prøv gjerne igjen om en
              liten stund.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography>
              Hvis problemet vedvarer kan du kontakte oss på
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography>e-post:</Typography>
              <Link href="mailto: kundeservice@myrent.no">
                <Typography>kundeservice@myrent.no</Typography>
              </Link>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography>eller telefon:</Typography>
              <Link href="tel:+4721492077">
                <Typography>+47 214 92 077</Typography>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      );
    }

    return children;
  }
}
