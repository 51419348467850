import { DatagridSearch } from './search';
import { DatagridSelect } from './select';
import { DatagridView } from './view';

interface DatagridProps {
  View: typeof DatagridView;
  Search: typeof DatagridSearch;
  Select: typeof DatagridSelect;
}

export const Datagrid: DatagridProps = {
  View: DatagridView,
  Search: DatagridSearch,
  Select: DatagridSelect
};
