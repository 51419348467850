import { ApisauceInstance } from 'apisauce';
import { ProblemDocument } from 'http-problem-details';
import { Deployment } from 'types';

export const createClient = (apiInstance: ApisauceInstance): Client => {
  return new Client(apiInstance);
};

class Client {
  private readonly apiInstance: ApisauceInstance;
  private deployment?: Deployment;

  constructor(apiInstance: ApisauceInstance) {
    this.apiInstance = apiInstance;
  }

  async fetchDeployment(): Promise<Deployment | undefined> {
    // keep instance cache
    if (this.deployment) {
      return this.deployment;
    }
    const r = await this.apiInstance.get<Deployment, ProblemDocument>(
      `/deployment.json`
    );
    this.deployment = r.data as Deployment;
    return this.deployment;
  }
}
