import { Stack, Typography } from '@myrent/myrent-ui';
import { SecondaryButton } from 'app/components/button';
import { MultiSelect } from 'app/components/input/select';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { useEffect, useState } from 'react';

import { Unit } from '../EditUnit/component';

interface Props {
  formik: FormikProps<any>;
  arrayHelpers: FieldArrayRenderProps;
}

const Component = ({ formik, arrayHelpers }: Props): JSX.Element => {
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    if (formik.values.address.length > 3) {
      setUnits(
        formik.values.unitOptions.map((item: string) => {
          return { id: item, label: item };
        })
      );
    }
  }, [formik.values.unitOptions]);

  function handleClick() {
    const updatedUnits = [
      ...formik.values.units,
      ...selectedUnits.map((item) => {
        if (!formik?.values?.units?.find((x: Unit) => x.description === item)) {
          return { objectTypeId: 0, description: item };
        }
      })
    ];
    formik.setFieldValue('units', updatedUnits);
  }

  function addUnit() {
    arrayHelpers.push({ objectTypeId: 0, description: '' });
  }

  return (
    <Stack
      width="100%"
      padding={2}
      sx={{
        backgroundColor: '#F3EFED'
      }}
    >
      <Typography fontWeight="bold">
        Legg til enhet {formik.values.address && `i ${formik.values.address}`}
      </Typography>
      {units.length > 0 && (
        <>
          <Typography>Det finnes flere enheter på denne adressen</Typography>
          <Stack spacing={3} paddingTop={1}>
            <MultiSelect
              fullWidth
              id="units"
              name="units"
              labelLeftMargin="0px"
              placeholder="Legg til enheter fra Kartverket"
              value={selectedUnits}
              onChange={setSelectedUnits}
              error={false}
              options={units}
              helperText={false}
            />
            <SecondaryButton
              disabled={selectedUnits.length <= 0}
              filled
              onClick={() => handleClick()}
            >
              Legg til valgte enheter
            </SecondaryButton>
          </Stack>
        </>
      )}
      <Stack marginTop={1}>
        <SecondaryButton addIcon onClick={() => addUnit()}>
          Legg til manuelt
        </SecondaryButton>
      </Stack>
    </Stack>
  );
};

export default Component;
