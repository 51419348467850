import { Dialog } from 'app/components/dialog';
import { AddProperty } from 'app/features/AddProperty';

const Component = (): JSX.Element => {
  const redirectLink = process.env.REACT_APP_API_URL + 'Property/Properties';
  return (
    <Dialog isOpen={true} redirect={redirectLink}>
      <AddProperty redirect={redirectLink} />
    </Dialog>
  );
};

export default Component;
