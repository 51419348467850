import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tab
} from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { useGetCpiInfoQuery } from 'app/context/api/cpi';
import { red } from 'app/theme/colors';
import { white } from 'app/theme/colors';
import moment from 'moment';
import { useMemo, useState } from 'react';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();

  // Dialogs
  const [showHowRegulateDialog, setShowHowRegulateDialog] = useState(false);

  const cpiDates = useMemo(() => {
    const currentDate = moment();
    const monthIn2Months = moment().add(2, 'months').startOf('month');
    const lastDayInMonth = moment().endOf('month');

    return {
      deadline: lastDayInMonth.format('DD.MM.YYYY'),
      firstDateOfFutureMonth: monthIn2Months
        .startOf('month')
        .format('DD.MM.YYYY'),
      daysLefInMonth: lastDayInMonth.diff(currentDate, 'days')
    };
  }, []);

  const handleHowRegulateClicedk = () => {
    setShowHowRegulateDialog(true);
  };

  const {
    data: cpiInfo,
    isError: isCpiError,
    isLoading: isLoadingCpiInfo
  } = useGetCpiInfoQuery();

  return (
    <>
      <Stack spacing={2}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <h1>KPI regulering</h1>
          <LogoutOutlinedIcon
            sx={{
              backgroundColor: white[100],
              height: '40px',
              width: '40px',
              padding: '10px',
              borderRadius: '25px',
              cursor: 'pointer',
              boxShadow: '0px 3px 6px lightgray'
            }}
            onClick={() => navigate('/logout')}
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="flex-start"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          spacing={2}
          justifyItems="flex-start"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            justifyItems="flex-start"
            spacing={2}
          >
            <p>
              {' '}
              <span style={{ fontWeight: 'bold' }}>Sist oppdatert:</span>{' '}
              {cpiInfo &&
                cpiInfo.nextUpdateDate !== null &&
                new Date(cpiInfo.lastUpdateDate).toLocaleDateString('nb-NO', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                })}
              {isLoadingCpiInfo && 'Laster...'}
              {isCpiError && 'Kunne ikke laste data'}
            </p>
            <p>
              {' '}
              <span style={{ fontWeight: 'bold' }}>
                Neste oppdatering:
              </span>{' '}
              {cpiInfo &&
                cpiInfo.nextUpdateDate !== null &&
                new Date(cpiInfo.nextUpdateDate).toLocaleDateString('nb-NO', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                })}
              {isLoadingCpiInfo && 'Laster...'}
              {isCpiError && 'Kunne ikke laste data'}
            </p>
          </Stack>
          <SecondaryButton onClick={handleHowRegulateClicedk} type="button">
            Hvordan regulere leie ?
          </SecondaryButton>
        </Stack>
        <Stack>
          <p style={{ textDecoration: 'italic' }}>
            Du må huske å godkjenne og sende disse innen{' '}
            <strong> {cpiDates.deadline} </strong> før å få leieøkning fra{' '}
            <strong> {cpiDates.firstDateOfFutureMonth}</strong> . Du har{' '}
            <span style={{ color: red[100] }}>
              {cpiDates.daysLefInMonth} dager igjen
            </span>
            , ellers må du vente til neste måned med reguleringen.
          </p>
        </Stack>

        <TabContext value={location.pathname}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              aria-label="lab API tabs example"
            >
              <Tab
                onClick={() => navigate('pending')}
                label="Til regulering"
                value="/cpi/pending"
              />
              <Tab
                onClick={() => navigate('postponed')}
                label="Utsatte"
                value="/cpi/postponed"
              />
              <Tab
                onClick={() => navigate('regulated')}
                label="Fullførte"
                value="/cpi/regulated"
              />
            </TabList>
          </Box>
        </TabContext>
        <Outlet />
        <Dialog
          open={showHowRegulateDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Hvordan regulere leie</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Når en leietaker har bodd i 12 måneder kan prisen økes etter
              konsumprisindeksen. Dette må varsles 30 dager før justering.
              Statistisk sentralbyrå gir oss satser vi kan justere etter.
              Leietaker blir varslet skriftlig etter husleielovens bestemmelser
              og du kan forhåndsvise brevmal på hva som blir sendt. Endringer i
              malen gjøres i dokumentsenter. Koster 59,- pr regulering. Huk av
              alle du ønsker å regulere og trykk &quot;Utfør regulering&ldquo;
              nederst til venstre.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              filled={true}
              onClick={() => setShowHowRegulateDialog(false)}
            >
              Ok
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
}
