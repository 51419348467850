import * as yup from 'yup';

async function checkCompany(orgNumber: string) {
  try {
    const result = await fetch(
      `https://data.brreg.no/enhetsregisteret/api/enheter/${orgNumber}`
    );
    await result.json();
    return true;
  } catch (err) {
    return false;
  }
}

export const organizationNumberSchema = yup
  .string()
  .test('valid-orgnr', 'Fant ikke organisasjonsnummer', (value) => {
    if (value && value.replaceAll(' ', '').length == 9) {
      return checkCompany(value.replaceAll(' ', ''));
    }
    return false;
  });
