import { TablePagination } from '@mui/material';

import { PaginationActions } from './PaginationActions';

interface Props {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  columnCount?: number;
}

export function Pagination({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  columnCount
}: Props) {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: count }]}
      colSpan={columnCount || 1}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page'
        },
        native: true
      }}
      labelRowsPerPage="Rader per side"
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      ActionsComponent={PaginationActions}
    />
  );
}
