import { Box, Hidden } from '@mui/material';
import { Stack, Typography } from '@myrent/myrent-ui';
import { BackButton } from 'app/components/button';
import { ProgressTracker } from 'app/components/progress-tracker';
import { FormikProps } from 'formik';
import { Route, Routes, useLocation } from 'react-router-dom';

import { FormikRegisterValues } from '../component';
import {
  RegisterInfo,
  RegisterSubscription,
  RegisterUser
} from '../components/forms';
import { TitleCard } from '../components/title-card';

interface Props {
  formik: FormikProps<FormikRegisterValues>;
}

const steps = [
  'Din brukerinformasjon',
  'Bedriftens kontaktinformasjon',
  'Bedriftens abonnement'
];

const Component = ({ formik }: Props): JSX.Element => {
  const { pathname } = useLocation();

  function getActivePage() {
    if (pathname.includes('/abonnement')) {
      return 2;
    }
    if (pathname.includes('/kontaktinformasjon')) {
      return 1;
    }
    if (pathname.includes('/bedrift')) {
      return 0;
    }
    return 0;
  }

  return (
    <Stack
      spacing={3}
      direction={{ xs: 'column', md: 'row' }}
      paddingTop={{ xs: 3 }}
    >
      <Stack width="35%" spacing={5}>
        {getActivePage() == 0 && (
          <BackButton to="/register">Privat eller bedrift</BackButton>
        )}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <ProgressTracker steps={steps} active={getActivePage()} />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <TitleCard
            title=""
            subtitle=""
            contactinfo="Ta kontakt med oss på
              kundeservice@myrent.no eller på telefon +47 21492077 dersom du
              står fast."
          >
            MyRent er utleierens beste venn og vi gleder oss til å få deg i gang
            hos oss! For å opprette ditt eget MyRent-system trenger du bare å
            fylle inn litt informasjon.
          </TitleCard>
        </Box>
      </Stack>
      <Stack
        spacing={5}
        width={{ xs: '100%', md: '65%' }}
        padding={{ xs: 3, md: '5%' }}
        marginTop={{ xs: 0, md: '10%' }}
      >
        <Typography variant="h4" color="white" fontWeight={'bold'}>
          Registrer deg som utleier
        </Typography>
        <Routes>
          <Route
            path="/"
            element={
              <RegisterUser
                formik={formik}
                nextPageTitle="Bedriftens kontaktinformasjon"
              />
            }
          />
          <Route
            path="kontaktinformasjon"
            element={
              <RegisterInfo
                invoiceEmail
                formik={formik}
                title="Bedriftens kontaktinformasjon"
                nextPageTitle="Bedriftens abonnement"
                nextPagePath="../abonnement"
              />
            }
          />
          <Route
            path="abonnement"
            element={
              <RegisterSubscription
                formik={formik}
                subscriptions="company"
                nextPageTitle="Registrer utleier"
                nextPagePath="/"
              />
            }
          />
        </Routes>
      </Stack>
    </Stack>
  );
};

export default Component;
