import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { green, white } from 'app/theme/colors';

interface Option {
  value: number;
  label: string;
}

interface Props {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: Option[];
  backgroundColor?: string;
  fullWidth?: boolean;
  labelId?: string;
}

const Component = ({
  value,
  options,
  onChange,
  backgroundColor = white[20],
  fullWidth = false,
  labelId
}: Props): JSX.Element => {
  return (
    <Select
      variant="outlined"
      IconComponent={KeyboardArrowDownIcon}
      {...(labelId && { labelId: labelId })}
      {...(fullWidth && { fullWidth: true })}
      {...(!fullWidth && { autoWidth: true })}
      sx={{
        height: '40px',
        backgroundColor: backgroundColor,
        borderRadius: '40px',
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': {
          border: 0
        },
        '& .MuiSelect-outlined': {
          pr: '60px !important'
        },
        '& .MuiSvgIcon-root': {
          backgroundColor: green[80],
          height: '40px',
          width: '40px',
          borderRadius: '40px',
          padding: '5px',
          top: 0,
          right: 0
        }
      }}
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Component;
