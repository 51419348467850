import { Divider } from '@mui/material';
import { Stack, Typography } from '@myrent/myrent-ui';
import { Select, TextInput } from 'app/components/input';
import { FormikProps, getIn } from 'formik';
import { useState } from 'react';

import { PropertyType, PropertyValues } from '../../component';
import { XIcon } from './styles';

interface Props {
  formik: FormikProps<any>;
  unitIndex: number;
  options: PropertyType[];
}

export interface Unit {
  objectTypeId: string;
  description: string;
  floorNumber?: string;
  prom?: string;
  srom?: string;
  bta?: string;
  bra?: string;
  snr?: string;
  maximumNumberOfTenants?: string;
  bedroomCount?: string;
  bathroomCount?: string;
  kitchenCount?: string;
  electricMeterNumber?: string;
  budgetRent?: string;
  sectionNumber?: string;
  shareNumber?: string;
}

const Component = ({ formik, unitIndex, options }: Props): JSX.Element => {
  const [expand, setExpand] = useState(0);
  const unit = formik.values.units[unitIndex];
  return (
    <Stack
      width="100%"
      padding={2}
      spacing={2}
      sx={{
        backgroundColor: '#F3EFED',
        position: 'relative'
      }}
    >
      <XIcon
        xmlns="http://www.w3.org/2000/svg"
        width="30.406"
        height="30.406"
        viewBox="0 0 30.406 30.406"
        onClick={() =>
          formik.setFieldValue(
            'units',
            formik.values.units.filter(
              (item: any, index: number) => index !== unitIndex
            )
          )
        }
      >
        <g
          id="Group_79"
          data-name="Group 79"
          transform="translate(5154.703 16853.203)"
        >
          <rect
            id="Rectangle_350"
            data-name="Rectangle 350"
            width="3"
            height="40"
            rx="1.5"
            transform="translate(-5126.418 -16853.203) rotate(45)"
          />
          <rect
            id="Rectangle_351"
            data-name="Rectangle 351"
            width="3"
            height="40"
            rx="1.5"
            transform="translate(-5154.703 -16851.082) rotate(-45)"
          />
        </g>
      </XIcon>
      <Typography>Enhet i {formik.values?.address}</Typography>
      <Select
        fullWidth
        id={`units[${unitIndex}].objectTypeId`}
        name={`units[${unitIndex}].objectTypeId`}
        label="Enhetstype"
        labelColor="black"
        labelLeftMargin="0px"
        placeholder="Velg"
        value={unit.objectTypeId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          getIn(formik.touched, `units[${unitIndex}].objectTypeId`) &&
          getIn(formik.errors, `units[${unitIndex}].objectTypeId`)
        }
        options={options}
        helperText={
          getIn(formik.touched, `units[${unitIndex}].objectTypeId`) &&
          getIn(formik.errors, `units[${unitIndex}].objectTypeId`)
        }
      />

      <TextInput
        fullWidth
        id={`units[${unitIndex}].description`}
        name={`units[${unitIndex}].description`}
        label="Enhetsnummer"
        labelColor="black"
        labelLeftMargin="0px"
        placeholder="..."
        value={formik.values.units[unitIndex].description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          getIn(formik.touched, `units[${unitIndex}].description`) &&
          getIn(formik.errors, `units[${unitIndex}].description`)
        }
        helperText={
          getIn(formik.touched, `units[${unitIndex}].description`) &&
          getIn(formik.errors, `units[${unitIndex}].description`)
        }
      />

      {expand == 1 && (
        <Stack spacing={2}>
          <Divider
            sx={{
              marginTop: '10px',
              marginBottom: '10px'
            }}
          />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            width={{ xs: '100%', md: 190 }}
          >
            <TextInput
              fullWidth
              id={`units[${unitIndex}].sectionNumber`}
              name={`units[${unitIndex}].sectionNumber`}
              label="Seksjonnummer"
              labelColor="black"
              labelLeftMargin="0px"
              placeholder=""
              value={formik.values.units[unitIndex].sectionNumber ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                getIn(formik.touched, `units[${unitIndex}].sectionNumber`) &&
                getIn(formik.errors, `units[${unitIndex}].sectionNumber`)
              }
              helperText={
                getIn(formik.touched, `units[${unitIndex}].sectionNumber`) &&
                getIn(formik.errors, `units[${unitIndex}].sectionNumber`)
              }
            />
            <TextInput
              fullWidth
              id={`units[${unitIndex}].shareNumber`}
              name={`units[${unitIndex}].shareNumber`}
              label="Andelsnummer"
              labelColor="black"
              labelLeftMargin="0px"
              placeholder=""
              value={formik.values.units[unitIndex].shareNumber ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                getIn(formik.touched, `units[${unitIndex}].shareNumber`) &&
                getIn(formik.errors, `units[${unitIndex}].shareNumber`)
              }
              helperText={
                getIn(formik.touched, `units[${unitIndex}].shareNumber`) &&
                getIn(formik.errors, `units[${unitIndex}].shareNumber`)
              }
            />
          </Stack>
          <TextInput
            fullWidth
            id={`units[${unitIndex}].maximumNumberOfTenants`}
            name={`units[${unitIndex}].maximumNumberOfTenants`}
            label="Maks antall leietakere"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder=""
            value={formik.values.units[unitIndex].maximumNumberOfTenants ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(
                formik.touched,
                `units[${unitIndex}].maximumNumberOfTenants`
              ) &&
              getIn(formik.errors, `units[${unitIndex}].maximumNumberOfTenants`)
            }
            helperText={
              getIn(
                formik.touched,
                `units[${unitIndex}].maximumNumberOfTenants`
              ) &&
              getIn(formik.errors, `units[${unitIndex}].maximumNumberOfTenants`)
            }
          />
          <TextInput
            fullWidth
            id={`units[${unitIndex}].kitchenCount`}
            name={`units[${unitIndex}].kitchenCount`}
            label="Antall kjøkken"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder=""
            value={formik.values.units[unitIndex].kitchenCount ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, `units[${unitIndex}].kitchenCount`) &&
              getIn(formik.errors, `units[${unitIndex}].kitchenCount`)
            }
            helperText={
              getIn(formik.touched, `units[${unitIndex}].kitchenCount`) &&
              getIn(formik.errors, `units[${unitIndex}].kitchenCount`)
            }
          />
          <TextInput
            fullWidth
            id={`units[${unitIndex}].bathroomCount`}
            name={`units[${unitIndex}].bathroomCount`}
            label="Antall bad"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder=""
            value={formik.values.units[unitIndex].bathroomCount ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, `units[${unitIndex}].bathroomCount`) &&
              getIn(formik.errors, `units[${unitIndex}].bathroomCount`)
            }
            helperText={
              getIn(formik.touched, `units[${unitIndex}].bathroomCount`) &&
              getIn(formik.errors, `units[${unitIndex}].bathroomCount`)
            }
          />
          <TextInput
            fullWidth
            id={`units[${unitIndex}].bedroomCount`}
            name={`units[${unitIndex}].bedroomCount`}
            label="Antall soverom"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder=""
            value={formik.values.units[unitIndex].bedroomCount ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, `units[${unitIndex}].bedroomCount`) &&
              getIn(formik.errors, `units[${unitIndex}].bedroomCount`)
            }
            helperText={
              getIn(formik.touched, `units[${unitIndex}].bedroomCount`) &&
              getIn(formik.errors, `units[${unitIndex}].bedroomCount`)
            }
          />
          <TextInput
            fullWidth
            id={`units[${unitIndex}].prom`}
            name={`units[${unitIndex}].prom`}
            label="P-rom"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder=""
            value={formik.values.units[unitIndex].prom ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, `units[${unitIndex}].prom`) &&
              getIn(formik.errors, `units[${unitIndex}].prom`)
            }
            helperText={
              getIn(formik.touched, `units[${unitIndex}].prom`) &&
              getIn(formik.errors, `units[${unitIndex}].prom`)
            }
          />
          <TextInput
            fullWidth
            id={`units[${unitIndex}].srom`}
            name={`units[${unitIndex}].srom`}
            label="S-rom"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder=""
            value={formik.values.units[unitIndex].srom ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, `units[${unitIndex}].srom`) &&
              getIn(formik.errors, `units[${unitIndex}].srom`)
            }
            helperText={
              getIn(formik.touched, `units[${unitIndex}].srom`) &&
              getIn(formik.errors, `units[${unitIndex}].srom`)
            }
          />
          <TextInput
            id={`units[${unitIndex}].bta`}
            name={`units[${unitIndex}].bta`}
            label="BTA"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder=""
            value={formik.values.units[unitIndex].bta ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, `units[${unitIndex}].bta`) &&
              getIn(formik.errors, `units[${unitIndex}].bta`)
            }
            helperText={
              getIn(formik.touched, `units[${unitIndex}].bta`) &&
              getIn(formik.errors, `units[${unitIndex}].bta`)
            }
          />
          <TextInput
            id={`units[${unitIndex}].bra`}
            name={`units[${unitIndex}].bra`}
            label="BRA"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder=""
            value={formik.values.units[unitIndex].bra ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, `units[${unitIndex}].bra`) &&
              getIn(formik.errors, `units[${unitIndex}].bra`)
            }
            helperText={
              getIn(formik.touched, `units[${unitIndex}].bra`) &&
              getIn(formik.errors, `units[${unitIndex}].bra`)
            }
          />
        </Stack>
      )}
      <Stack direction="row" justifyContent="end">
        <div onClick={() => setExpand(expand == 0 ? 1 : 0)}>
          <Typography sx={{ cursor: 'pointer' }}>
            {expand == 0 ? 'Vis flere detaljer' : 'Vis mindre'}
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
};

export default Component;
