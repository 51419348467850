import './locales/i18n';
import './overrides';

import {
  DialogProvider,
  SnackbarProvider,
  StylesProvider,
  ThemeProvider
} from '@myrent/myrent-ui';
import { MyrentThemeProvider } from 'app/theme';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from './app';
import { persistor, store } from './app/store';
import { ErrorBoundary } from './ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

interface Props {
  Component: typeof App;
}

const ConnectedApp = ({ Component }: Props) => (
  <ErrorBoundary>
    <HelmetProvider>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StylesProvider>
              <ThemeProvider>
                <DialogProvider>
                  <SnackbarProvider>
                    <MyrentThemeProvider>
                      <Component />
                    </MyrentThemeProvider>
                  </SnackbarProvider>
                </DialogProvider>
              </ThemeProvider>
            </StylesProvider>
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </HelmetProvider>
  </ErrorBoundary>
);

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
