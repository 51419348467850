import { ClientConfig, Environment } from 'types';

const config = (env: Environment): ClientConfig => {
  if (env === Environment.Development || env === Environment.Dev) {
    return {
      clientId: 'dd93413c-cece-4b69-9668-d97ee21280c0',
      scopes: ['api://f2ec94c4-f53f-41f5-9149-9f087319f13f/Landbruk.Valuation']
    };
  }
  if (env === Environment.Test) {
    return {
      clientId: '37b259bf-9012-43e8-a44c-c10cc0322e4b',
      scopes: ['api://2fbff047-9b22-4d22-b83f-f37e15ddad56/Landbruk.Valuation']
    };
  }
  if (env === Environment.QA) {
    return {
      clientId: '3300a666-dde9-4e0e-8762-128ec021f5f7',
      scopes: ['api://e403f739-93bd-4dd5-9c6b-9aeadcf04d2e/Landbruk.Valuation']
    };
  }
  if (env === Environment.Prod) {
    return {
      clientId: '3194b277-668d-460e-8d3c-672af05acbd0',
      scopes: ['api://660aa8ac-4b5b-413b-8c25-1669a6598960/Landbruk.Valuation']
    };
  }
  throw Error('Unable to resolved client for environment ' + env);
};

export default config;
