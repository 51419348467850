import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Stack
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import CustomSelect from 'app/components/display/Select';
import { ExternalLink } from 'app/components/link';
import {
  useGetGjengeleiePendingContractsQuery,
  useGetGjengsleieTemplatesQuery,
  useGjengsleieRegualteContractsMutation
} from 'app/context/api/cpi';
import { useContractsColumns } from 'app/screens/cpi/useContractColumns';
import { PendingAndRegulatedUnion } from 'app/screens/cpi/useContractColumns';
import { useTableParams } from 'app/screens/cpi/useTableParms';
import { formatCurrency } from 'app/utils/helpers';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { type MRT_Cell, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_NO } from 'material-react-table/locales/no';
import { useEffect, useState } from 'react';

export default function GjengsleiePending() {
  // Custom hooks
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    queryParams
  } = useTableParams();

  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  // Data loading
  const {
    data: contracts,
    isError,
    isLoading: isContractsLoading
  } = useGetGjengeleiePendingContractsQuery(queryParams, {
    refetchOnMountOrArgChange: true
  });

  const [
    regulateContracts,
    { isLoading: isRegulatingContracts, isError: isErrorWithRegulation }
  ] = useGjengsleieRegualteContractsMutation();

  const {
    data: customRegulationTemplates,
    isError: errorWithLoadingTemplates
  } = useGetGjengsleieTemplatesQuery();

  const handleTemplateChange = (event: SelectChangeEvent) => {
    setSelectedTemplate(event.target.value as string);
  };

  useEffect(() => {
    if (customRegulationTemplates && customRegulationTemplates.length > 0) {
      setSelectedTemplate(customRegulationTemplates[0].id.toString());
    }
  }, [customRegulationTemplates]);

  // Testing
  const [showRegulateDialog, setShowRegulateDialog] = useState(false);

  const [editedContract, setEditedContract] = useState<
    Record<string, { amount: number; contractId: string }>
  >({});

  const [amountValidationError, setAmountValidationError] =
    useState<boolean>(false);

  const newSumColumn: MRT_ColumnDef<
    PendingAndRegulatedUnion & { customRegulationAmount: number }
  > = {
    id: 'customRegulationAmount', //simple recommended way to define a column
    header: 'Ny sum',
    enableEditing: true,
    enableColumnFilter: false,
    enableSorting: false,
    muiTableBodyCellProps: {
      align: 'right'
    },
    muiTableFooterCellProps: {
      align: 'right'
    },
    muiTableHeadCellProps: {
      align: 'right'
    },
    enableHiding: true, //disable a feature for this column
    accessorFn: (row) => {
      if ('customRegulationAmount' in row) {
        return row.customRegulationAmount ?? 0;
      }
      return 0;
    },
    Cell: ({
      cell
    }: {
      cell: MRT_Cell<
        PendingAndRegulatedUnion & { customRegulationAmount: number },
        unknown
      >;
    }) => formatCurrency(cell.getValue<number>() ?? 0),
    muiEditTextFieldProps: ({ row }) => ({
      type: 'number',
      required: true,
      error: amountValidationError,
      onBlur: (event) => {
        const newSum = parseInt(event.currentTarget.value, 10);
        const contractId = row.original.id;
        if (isNaN(newSum) || newSum < 0) {
          setAmountValidationError(true);
          return;
        } else {
          setAmountValidationError(false);
        }
        if (newSum === 0) {
          setEditedContract((currentEditedContracts) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [contractId]: _, ...rest } = currentEditedContracts;
            return rest;
          });
        } else {
          setEditedContract((currentEditedContracts) => ({
            ...currentEditedContracts,
            [contractId]: {
              amount: newSum,
              contractId: contractId
            }
          }));
        }
      }
    })
  };

  //UPDATE action
  const handleRegulateContracts = async () => {
    if (selectedTemplate === null) return;
    await regulateContracts({
      templateId: selectedTemplate,
      contracts: Object.values(editedContract)
    })
      .unwrap()
      .then(() => setShowRegulateDialog(false));
    setEditedContract({});
  };

  // Testing
  const { columns } = useContractsColumns(
    {
      applyFromDate: true,
      regulationTypeAvaialable: true,
      regulationTypePerformed: false,
      lastRegulationDate: false,
      productName: true,
      clientName: true,
      tenantName: true,
      propertyAddress: true,
      propertyDescription: true,
      leaseStart: true,
      leaseStop: true,
      currentPrice: true,
      periodStartDate: false,
      periodEndDate: false,
      increasePercent: false,
      newPrice: false,
      increasePrice: false,
      document: true,
      contractUpdated: false,
      oldPrice: false,
      lastCpiRegulationDate: true,
      lastCustomRegulationDate: true
    },
    contracts?.records ?? [],
    selectedTemplate,
    [newSumColumn],
    editedContract
  );

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data: contracts?.records ?? [],
    manualPagination: true,
    manualFiltering: true,
    rowCount: contracts?.totalCount ?? 0, // total records in backend data -> not in current table view
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableEditing: true,
    getRowId: (row) => row.id?.toString() ?? undefined,
    state: {
      columnFilters,
      globalFilter,
      isLoading: isContractsLoading,
      isSaving: isRegulatingContracts,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isContractsLoading,
      sorting,
      rowSelection
    },
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell); //set editing cell
        //optionally, focus the text field
        queueMicrotask(() => {
          const textField = table.refs.editInputRefs.current[column.id];
          if (textField) {
            textField.focus();
            textField.select?.();
          }
        });
      }
    }),
    enableRowSelection: false,
    enableColumnOrdering: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableStickyFooter: true,
    editDisplayMode: 'cell', //editDisplayMode: 'modal',
    localization: {
      ...MRT_Localization_NO,
      selectedCountOfRowCountRowsSelected:
        '{selectedCount} av {rowCount} rader valgt',
      noRecordsToDisplay: 'Ingen kontrakter å regulere'
    }
  });

  if (isError) {
    return (
      <Alert severity="error">
        Kunne ikke laste — sjekk din nettforbindelse!
      </Alert>
    );
  }

  return (
    <Stack spacing={2}>
      {customRegulationTemplates && customRegulationTemplates.length > 0 && (
        <CustomSelect
          value={selectedTemplate ?? ''}
          onChange={handleTemplateChange}
          labelId="select-customRegulation-template"
          backgroundColor="#F0F0F0"
          options={customRegulationTemplates.map((template) => ({
            value: template.id,
            label: template.title
          }))}
        />
      )}
      <MaterialReactTable table={table} />
      {Object.keys(editedContract).length > 0 && (
        <Stack direction="row">
          <PrimaryButton
            onClick={() => setShowRegulateDialog(true)}
            filled={true}
          >
            Reguler
          </PrimaryButton>
        </Stack>
      )}

      <Dialog fullWidth open={showRegulateDialog}>
        <DialogTitle>Gjengsleie regulering</DialogTitle>
        <DialogContent>
          {isErrorWithRegulation && (
            <Alert severity="error">
              Kunne ikke regulere — sjekk din internetforbindelse
            </Alert>
          )}
          {isRegulatingContracts && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
          {!isRegulatingContracts && !isErrorWithRegulation && (
            <Stack direction="column" spacing={2}>
              <InputLabel id="select-customRegulation-template">
                Velg regulerings mal
              </InputLabel>

              {errorWithLoadingTemplates && (
                <Alert severity="error">
                  Kunne ikke laste maler — sjekk din internetforbindelse
                </Alert>
              )}
              {customRegulationTemplates &&
                customRegulationTemplates.length > 0 && (
                  <CustomSelect
                    value={selectedTemplate ?? ''}
                    onChange={handleTemplateChange}
                    labelId="select-customRegulation-template"
                    backgroundColor="#F0F0F0"
                    options={customRegulationTemplates.map((template) => ({
                      value: template.id,
                      label: template.title
                    }))}
                  />
                )}

              <Stack spacing={1} direction="row">
                <ExternalLink to="ContractTemplate/Index">
                  Rediger maler
                </ExternalLink>
                {selectedTemplate !== null && <p>|</p>}
                {selectedTemplate !== null && (
                  <ExternalLink
                    target="_blank"
                    to={`ContractTemplate/Download?contractTemplateId=${selectedTemplate}&standardType=2`}
                  >
                    Vis pdf
                  </ExternalLink>
                )}
              </Stack>
            </Stack>
          )}
        </DialogContent>
        {!isRegulatingContracts && (
          <DialogActions>
            {isErrorWithRegulation && (
              <SecondaryButton onClick={() => setShowRegulateDialog(false)}>
                Avbryt
              </SecondaryButton>
            )}
            {!isErrorWithRegulation && (
              <SecondaryButton onClick={() => setShowRegulateDialog(false)}>
                Avbryt
              </SecondaryButton>
            )}
            {selectedTemplate !== null && !isErrorWithRegulation && (
              <PrimaryButton filled={true} onClick={handleRegulateContracts}>
                Reguler
              </PrimaryButton>
            )}
          </DialogActions>
        )}
      </Dialog>
    </Stack>
  );
}
