import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Hidden, Stack, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import { ProgressTracker } from 'app/components/progress-tracker';
import { closeModal } from 'app/context/addModal/addModalSlice';
import {
  decrement,
  increment,
  reset
} from 'app/features/AddTenant/tenantStepSlide';
import { useAppDispatch, useAppSelector } from 'app/hooks';

interface Props {
  formik: any;
  showTitle: boolean;
  titleSteps: string[];
  validationObject: string;
  redirect?: string;
}

const Component = ({
  formik,
  showTitle,
  titleSteps,
  validationObject,
  redirect
}: Props): JSX.Element => {
  const step = useAppSelector((state) => state.tenantStep.step);
  const dispatch = useAppDispatch();

  function isStepDisabled() {
    const currentValidationObject: string = validationObject;
    if (currentValidationObject == '') {
      return false;
    }
    return (
      Object.keys(formik.errors).some(
        (key) => key === currentValidationObject
      ) ||
      !Object.keys(formik.touched).some(
        (key) => key === currentValidationObject
      )
    );
  }

  return (
    <Stack>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Stack alignItems={'flex-end'}>
          <CloseIcon
            sx={{
              fontSize: '50px',
              backgroundColor: '#ededed',
              borderRadius: '25px',
              padding: '5px',
              cursor: 'pointer',
              margin: '20px'
            }}
            onClick={() => {
              if (redirect) {
                window.location.replace(redirect);
              } else {
                dispatch(reset());
                dispatch(closeModal());
              }
            }}
          />
        </Stack>
      </Box>
      {showTitle && (
        <>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Stack
              padding={5}
              spacing={4}
              sx={{
                position: 'fixed',
                top: 0,
                right: '5vw',
                maxWidth: 450,
                backgroundColor: 'white',
                boxShadow: 3,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                zIndex: 1
              }}
            >
              <Stack spacing={2}>
                <Stack alignItems={'flex-end'}>
                  <CloseIcon
                    sx={{
                      fontSize: '50px',
                      backgroundColor: '#ededed',
                      borderRadius: '25px',
                      padding: '5px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      if (redirect) {
                        window.location.replace(redirect);
                      } else {
                        dispatch(reset());
                        dispatch(closeModal());
                      }
                    }}
                  />
                </Stack>
                <Typography variant="h4" fontWeight="bold">
                  Legg til leietaker
                </Typography>
                <Typography variant="body1">
                  Fyll ut de nødvendige detaljene og kom i gang med ditt
                  leieforhold
                </Typography>
              </Stack>
              <ProgressTracker
                steps={titleSteps}
                active={step}
                popActive={true}
                labelColor={'#242424'}
                color="#242424"
                backgroundColor="white"
              />
              <Grid container>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  {step > 0 && (
                    <PrimaryButton
                      chevron="left"
                      onClick={() => {
                        dispatch(decrement());
                        if (titleSteps.length == step + 1) {
                          formik.setFieldError('formDuplicate', null);
                        }
                      }}
                    >
                      Forrige steg
                    </PrimaryButton>
                  )}
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  {showTitle &&
                    (titleSteps.length == step + 1 ? (
                      <PrimaryButton
                        filled
                        chevron="right"
                        disabled={Boolean(formik.errors.formDuplicate)}
                        onClick={formik.handleSubmit}
                      >
                        Opprett leietakerforhold
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        filled
                        chevron="right"
                        disabled={isStepDisabled()}
                        onClick={() => {
                          dispatch(increment());
                        }}
                      >
                        Neste steg
                      </PrimaryButton>
                    ))}
                </Grid>
              </Grid>
              {Boolean(formik.errors.formDuplicate) && (
                <Typography sx={{ color: 'red' }}>
                  {formik.errors.formDuplicate}
                </Typography>
              )}
            </Stack>
          </Box>
          <Hidden mdUp>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              position="fixed"
              bottom={0}
              left={0}
              padding={2}
              px={5}
              width="100%"
              zIndex={1}
              sx={{
                backgroundColor: '#242424'
              }}
            >
              <Stack direction={'row'} alignItems={'center'}>
                {step > 0 && (
                  <Box
                    onClick={() => dispatch(decrement())}
                    sx={{ alignContent: 'center' }}
                  >
                    <ChevronLeftIcon
                      sx={{
                        color: 'white',
                        fontSize: '32px',
                        cursor: 'pointer'
                      }}
                    />
                  </Box>
                )}
                <Typography sx={{ color: 'white' }}>{`Steg ${step + 1} av ${
                  titleSteps.length
                }`}</Typography>
              </Stack>
              {showTitle &&
                (titleSteps.length == step + 1 ? (
                  <PrimaryButton
                    filled
                    chevron="right"
                    disabled={Boolean(formik.errors.formDuplicate)}
                    onClick={formik.handleSubmit}
                  >
                    Opprett leietakerforhold
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    filled
                    chevron="right"
                    disabled={isStepDisabled()}
                    onClick={() => {
                      dispatch(increment());
                    }}
                  >
                    Neste steg
                  </PrimaryButton>
                ))}
            </Stack>
          </Hidden>
        </>
      )}
    </Stack>
  );
};

export default Component;
