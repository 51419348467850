import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'app/infrastructure/api';
import { client } from 'app/infrastructure/client';
import { RootState } from 'app/store';
import { Deployment } from 'types';

const SLICE_NAME = 'global';

export interface State {
  client?: Client;
  deployments?: Deployment[];
}

const initialState: State = {};

export const getDeployments = createAsyncThunk(
  `${SLICE_NAME}/getDeployments/`,
  async () => {
    return await Promise.all([
      await client.fetchDeployment(), //frontend
      await api.example.fetchDeployment() //backend
    ]);
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDeployments.fulfilled, (state, action) => {
      const formatDeployment = (
        d: Deployment | null | undefined
      ): Deployment => {
        return {
          commit: d?.commit ?? '?',
          branch: d?.branch ?? '?',
          environment: d?.environment ?? '?'
        };
      };
      state.deployments = [
        formatDeployment(action.payload[0]),
        formatDeployment(action.payload[1])
      ];
    });
  }
});

export const selectBranch = (state: RootState): State => state.global;

export const selectDeployments = (state: RootState) =>
  selectBranch(state).deployments;

export default slice.reducer;
