import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import { Checkbox, PhoneInput, TextInput } from 'app/components/input';
import { PersonalNumberInput } from 'app/components/input/personal-number';
import { LoadingPopup } from 'app/components/loading';
import { usePrivateCreditCheckMutation } from 'app/context/api/tenant';
import { OrganizationSubScriptionType } from 'app/context/user/userSlice';
import { RootState } from 'app/store';
import { dark, green, netural, red, white } from 'app/theme/colors';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface Props {
  formik: any;
  index: number;
}

const Component = ({ formik, index }: Props): JSX.Element => {
  const [getCreditCheck, { isLoading }] = usePrivateCreditCheckMutation();
  const [creditCheckError, setCreditCheckError] = useState('');

  const subscriptionType = useSelector(
    (state: RootState) => state.user.Client?.Organization.SubscriptionType
  );

  return (
    <>
      <Stack spacing={3}>
        <PersonalNumberInput
          id={`privateTenants[${index}].socialSecurityNumber`}
          name={`privateTenants[${index}].socialSecurityNumber`}
          value={formik.values.privateTenants[
            index
          ].socialSecurityNumber.replaceAll(' ', '')}
          placeholder="11 siffer"
          onChange={(e) =>
            formik.setFieldValue(
              `privateTenants[${index}].socialSecurityNumber`,
              e.target.value.replaceAll(' ', '')
            )
          }
          onBlur={formik.handleBlur}
          error={
            formik.errors.privateTenants &&
            formik.errors.privateTenants[index] &&
            formik.touched.privateTenants &&
            formik.touched.privateTenants[index] &&
            typeof formik.errors.privateTenants[index].socialSecurityNumber ===
              'string'
              ? Boolean(
                  formik.errors.privateTenants[index].socialSecurityNumber
                ) && formik.touched.privateTenants[index].socialSecurityNumber
              : undefined
          }
          helperText={
            formik.errors.privateTenants &&
            formik.errors.privateTenants[index] &&
            formik.touched.privateTenants &&
            formik.touched.privateTenants[index] &&
            formik.touched.privateTenants[index].socialSecurityNumber &&
            typeof formik.errors.privateTenants[index].socialSecurityNumber ===
              'string'
              ? formik.errors.privateTenants[index].socialSecurityNumber
              : undefined
          }
          labelColor="#242424"
          labelLeftMargin="0"
          fontWeight="normal"
          label={'Personnummer'}
        />
        <Stack>
          <Typography variant="h6">Kredittsjekk</Typography>
          <Tooltip
            title={
              formik?.values?.privateTenants[
                index
              ]?.socialSecurityNumber?.replaceAll(' ', '').length == 11
                ? ''
                : 'Legg inn personnummer for å gjøre kredittsjekk'
            }
          >
            <Grid container alignItems={'center'} rowGap={2}>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <Typography variant="body1">
                  For din sikkerhet kan vi foreta en kredittsjekk av leietaker,
                  via samarbeidspartner. Vi gir deg da en rapport på scoring.
                </Typography>
                {subscriptionType === OrganizationSubScriptionType.Free && (
                  <Typography variant="body1">
                    Det koster 49,- for hver kredittsjekk på ditt abonnement.
                  </Typography>
                )}
              </Grid>
              {formik.values.privateTenants[index].isCreditChecked ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      display: 'inline-flex',
                      borderRadius: '15px',
                      height: '100%',
                      p: '10px',
                      px: '25px',
                      backgroundColor: white[100]
                    }}
                  >
                    {formik.values.privateTenants[index].creditScore > 19 &&
                    formik.values.privateTenants[index].creditScore < 101 ? (
                      <Typography fontWeight={'bold'} color={green[100]}>
                        Godkjent
                      </Typography>
                    ) : (
                      <Typography fontWeight={'bold'} color={red[100]}>
                        Ikke godkjent
                      </Typography>
                    )}
                    <Typography>{`Score: ${formik.values.privateTenants[index].creditScore}`}</Typography>
                  </Stack>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <PrimaryButton
                    filled
                    disabled={
                      formik.values.privateTenants[
                        index
                      ].socialSecurityNumber?.replaceAll(' ', '').length != 11
                    }
                    onClick={async () => {
                      try {
                        const result = await getCreditCheck({
                          socialSecurityNumber: formik.values.privateTenants[
                            index
                          ].socialSecurityNumber?.replace(' ', '')
                        }).unwrap();

                        if (!result.success) throw result?.errorMessage;

                        setCreditCheckError('');

                        formik.setFieldValue(
                          `privateTenants[${index}].isCreditChecked`,
                          true
                        );
                        formik.setFieldValue(
                          `privateTenants[${index}].creditScore`,
                          result.payload.score
                        );
                        formik.setFieldValue(
                          `privateTenants[${index}].firstName`,
                          result.payload.firstName
                        );
                        formik.setFieldValue(
                          `privateTenants[${index}].lastName`,
                          result.payload.lastName
                        );
                      } catch (err) {
                        setCreditCheckError('' + err);
                      }
                    }}
                  >
                    Ta kredittsjekk
                  </PrimaryButton>
                </Grid>
              )}
            </Grid>
          </Tooltip>
          {creditCheckError.length > 0 && (
            <Typography color="red">{creditCheckError}</Typography>
          )}
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
          spacing={3}
        >
          <TextInput
            name={`privateTenants[${index}].firstName`}
            value={formik.values.privateTenants[index].firstName}
            onChange={formik.handleChange}
            id={`privateTenants[${index}].firstName`}
            onBlur={formik.handleBlur}
            bgColor="#ededed"
            error={
              formik.errors.privateTenants &&
              formik.errors.privateTenants[index] &&
              formik.touched.privateTenants &&
              formik.touched.privateTenants[index] &&
              typeof formik.errors.privateTenants[index].firstName === 'string'
                ? Boolean(formik.errors.privateTenants[index].firstName) &&
                  formik.touched.privateTenants[index].firstName
                : undefined
            }
            helperText={
              formik.errors.privateTenants &&
              formik.errors.privateTenants[index] &&
              formik.touched.privateTenants &&
              formik.touched.privateTenants[index] &&
              formik.touched.privateTenants[index].firstName &&
              typeof formik.errors.privateTenants[index].firstName === 'string'
                ? formik.errors.privateTenants[index].firstName
                : undefined
            }
            label={'Fornavn'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
          />
          <TextInput
            name={`privateTenants[${index}].lastName`}
            value={formik.values.privateTenants[index].lastName}
            onChange={formik.handleChange}
            id={`privateTenants[${index}].lastName`}
            onBlur={formik.handleBlur}
            error={
              formik.errors.privateTenants &&
              formik.errors.privateTenants[index] &&
              formik.touched.privateTenants &&
              formik.touched.privateTenants[index] &&
              typeof formik.errors.privateTenants[index].lastName === 'string'
                ? formik.errors.privateTenants[index].lastName &&
                  formik.touched.privateTenants[index].lastName
                : undefined
            }
            helperText={
              formik.errors.privateTenants &&
              formik.errors.privateTenants[index] &&
              formik.touched.privateTenants &&
              formik.touched.privateTenants[index] &&
              formik.touched.privateTenants[index].lastName &&
              typeof formik.errors.privateTenants[index].lastName === 'string'
                ? formik.errors.privateTenants[index].lastName
                : undefined
            }
            label={'Etternavn'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor="#ededed"
          />
        </Stack>
        <Stack spacing={2}>
          <TextInput
            name={`privateTenants[${index}].email`}
            value={
              !formik.values.privateTenants[index].noEmail
                ? formik.values.privateTenants[index].email
                : ''
            }
            onChange={formik.handleChange}
            id={`privateTenants[${index}].email`}
            onBlur={formik.handleBlur}
            disabled={formik.values.privateTenants[index].noEmail}
            error={
              formik.errors.privateTenants &&
              formik.errors.privateTenants[index] &&
              formik.touched.privateTenants &&
              formik.touched.privateTenants[index] &&
              typeof formik.errors.privateTenants[index].email === 'string'
                ? formik.errors.privateTenants[index].email &&
                  formik.touched.privateTenants[index].email
                : undefined
            }
            helperText={
              formik.errors.privateTenants &&
              formik.errors.privateTenants[index] &&
              formik.touched.privateTenants &&
              formik.touched.privateTenants[index] &&
              formik.touched.privateTenants[index].email &&
              typeof formik.errors.privateTenants[index].email === 'string'
                ? formik.errors.privateTenants[index].email
                : undefined
            }
            label={'E-post'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor="#ededed"
          />
          <Checkbox
            id={`privateTenants[${index}].noEmail`}
            name={`privateTenants[${index}].noEmail`}
            value={formik.values.privateTenants[index].noEmail}
            fontSize={'14px'}
            fontColor={'#003D43'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={undefined}
            helperText={undefined}
            label={'Mangler e-post'}
          />
        </Stack>

        <Stack spacing={2}>
          <PhoneInput
            id={`privateTenants[${index}].cellphone`}
            name={`privateTenants[${index}].cellphone`}
            placeholder=""
            value={
              !formik.values.privateTenants[index].noCellPhone
                ? formik.values.privateTenants[index].cellphone
                : ''
            }
            onChange={(e) => {
              formik.setFieldValue(
                `privateTenants[${index}].cellphone`,
                `+${e}`
              );
            }}
            onBlur={() => {
              formik.setFieldTouched(
                `privateTenants[${index}].cellphone`,
                true
              );
            }}
            disabled={formik.values.privateTenants[index].noCellPhone}
            error={
              formik.errors.privateTenants &&
              formik.errors.privateTenants[index] &&
              formik.touched.privateTenants &&
              formik.touched.privateTenants[index] &&
              typeof formik.errors.privateTenants[index].cellphone === 'string'
                ? formik.errors.privateTenants[index].cellphone &&
                  formik.touched.privateTenants[index].cellphone
                : undefined
            }
            helperText={
              formik.errors.privateTenants &&
              formik.errors.privateTenants[index] &&
              formik.touched.privateTenants &&
              formik.touched.privateTenants[index] &&
              formik.touched.privateTenants[index].cellphone &&
              typeof formik.errors.privateTenants[index].cellphone === 'string'
                ? formik.errors.privateTenants[index].cellphone
                : undefined
            }
            label={'Mobilnummer'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor="#ededed"
          />
          <Checkbox
            id={`privateTenants[${index}].noCellPhone`}
            name={`privateTenants[${index}].noCellPhone`}
            value={formik.values.privateTenants[index].noCellPhone}
            fontSize={'14px'}
            fontColor={'#003D43'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={undefined}
            helperText={undefined}
            label={'Mangler mobilnummer'}
          />
        </Stack>
      </Stack>
      <LoadingPopup open={isLoading} />
    </>
  );
};
export default Component;
