import { Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Button, IconContainer } from './styles';

interface Props {
  children?: string;
  to?: string;
  onClick?: () => void;
}

const Component = ({ children, to, onClick }: Props): JSX.Element => {
  return (
    <Stack
      direction="row"
      height="10px"
      alignItems="center"
      sx={{
        paddingLeft: '10%',
        paddingTop: '10%'
      }}
    >
      <IconContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </IconContainer>
      {to ? (
        <Link
          component={RouterLink}
          to={to}
          sx={{
            color: 'white',
            textDecoration: 'underline',
            textUnderlineOffset: '5px',
            textDecorationThickness: '2px'
          }}
        >
          {children}
        </Link>
      ) : (
        <Button onClick={onClick}>{children}</Button>
      )}
    </Stack>
  );
};

export default Component;
