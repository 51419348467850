import Choose from './steps/0-choose';
import Upload from './steps/1-upload';

interface StepButton {
  text: string;
  to: string;
}

export interface ContractStep {
  stepId: string;
  title: string;
  send?: 'file' | 'sign';
  prevStep?: StepButton;
  nextStep?: StepButton;
}

export function updatePath(path: string) {
  if (addContractSteps[0].nextStep) {
    addContractSteps[0].nextStep.to = path;
  }
}

export function getPath() {
  return addContractSteps[0].nextStep?.to;
}

export const addContractSteps: ContractStep[] = [
  {
    stepId: '0-choose',
    title: 'Kontrakt'
  },
  {
    stepId: '1-upload',
    title: 'Last opp eksiterende kontrakt',
    send: 'file',
    prevStep: {
      text: 'Tilbake',
      to: '0-choose'
    },
    nextStep: {
      text: 'Last opp',
      to: '3-close'
    }
  },
  {
    stepId: '1-edit',
    title: 'Lag kontrakt',
    prevStep: {
      text: 'Tilbake',
      to: '0-choose'
    },
    nextStep: {
      text: 'Velg BankID',
      to: '2-sign'
    }
  },
  {
    stepId: '2-sign',
    title: 'Til BankID signering',
    send: 'sign',
    prevStep: {
      text: 'Tilbake',
      to: '1-edit'
    },
    nextStep: {
      text: 'Send til BankID',
      to: '3-close'
    }
  },
  {
    stepId: '3-close',
    title: ''
  }
];
