import { Stack, Typography } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';

import { Container, Label } from './styles';

interface Props {
  id: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<any>) => void;
  onBlur: (event: React.ChangeEvent<any>) => void;
  error: boolean | undefined;
  helperText: string | false | undefined;
  type?: string;
  placeholder?: string;
  label: string;
  labelColor?: string;
  labelLeftMargin?: string;
  fontWeight?: string;
  fullWidth?: boolean;
  maxLength?: number;
}

const Component = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  label,
  labelColor = 'white',
  labelLeftMargin = '10px',
  fontWeight = 'bold',
  helperText,
  error,
  fullWidth = false
}: Props): JSX.Element => {
  const mask = '###### #####';

  return (
    <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
      {label && (
        <Label htmlFor={name}>
          <Typography
            color={labelColor}
            marginLeft={labelLeftMargin}
            fontWeight={fontWeight}
          >
            {label}
          </Typography>
        </Label>
      )}
      <Container error={error}>
        <Stack>
          <NumberFormat
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            format={mask}
            className="numberformat"
          />
        </Stack>
      </Container>
      {helperText && (
        <Stack>
          <Typography
            color="red"
            marginLeft="10px"
            sx={{ position: 'absolute', mt: '3px' }}
          >
            {helperText}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Component;
