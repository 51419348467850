import { Stack, Typography } from '@myrent/myrent-ui';
import { LoadingPopup } from 'app/components/loading';
import { AlternatingProgressTracker } from 'app/components/progress-tracker/alternate';
import { openModal } from 'app/context/addModal/addModalSlice';
import { useCheckDuplicateContractMutation } from 'app/context/api/contract';
import { useSendFormMutation } from 'app/context/api/tenant';
import { Contract } from 'app/context/api/tenant/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format, startOfTomorrow } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { setContractId } from '../AddContract/contractPathSlice';
import { AddAdministrative } from './AddAdministrative';
import { AddBuilding } from './AddBuilding';
import { AddContract } from './AddContract';
import { AddRent } from './AddRent';
import { AddSecurity } from './AddSecurity';
import { AddTenantInfo } from './AddTenantInfo';
import { ContractType } from './ContractType';
import { EditContract } from './EditContract';
import { reset } from './tenantStepSlide';
import { TenantSummary } from './TenantSummary';
import {
  validateAddContract,
  validateAddProperty,
  validateAddRent,
  validateAddSecurity,
  validateCompanyTenants,
  validatePrivateTenants
} from './validationschemas';

export interface TenantStep {
  title: string;
  optional?: boolean;
  showTitle: boolean;
  validationObject: string;
  validationSchema: any;
  render: JSX.Element;
}

interface PropertyObject {
  id: number;
}

interface Props {
  prefillObject?: Contract;
  redirect?: string;
}

const Component = ({ prefillObject, redirect }: Props): JSX.Element => {
  const [validationSchema, setValidationSchema] = useState(validateAddProperty);
  const step = useAppSelector((state) => state.tenantStep.step);
  const [sendForm, { isLoading: isSendingForm }] = useSendFormMutation();
  const [checkDuplicate, { isLoading: isCheckingDuplicate }] =
    useCheckDuplicateContractMutation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialTouched: {
      contractInfo: {
        contractType: true
      }
    },
    initialValues: {
      propertyInfo: {
        clientId: prefillObject ? prefillObject.object.clientId : '',
        object: prefillObject
          ? {
              id: prefillObject.object.id,
              label:
                prefillObject.object.propertyAddress +
                ' - ' +
                prefillObject.object.description
            }
          : null,
        lateFeeProductId: 0
      },
      tenantType:
        prefillObject && prefillObject.tenants[0].organizationNumber
          ? 'company'
          : 'private',
      privateTenants: [
        {
          company: 1,
          socialSecurityNumber:
            prefillObject && prefillObject.tenants[0].socialSecurityNumber
              ? prefillObject.tenants[0].socialSecurityNumber.replaceAll(
                  '-',
                  ''
                )
              : '',
          isCreditChecked: false,
          creditScore: '',
          firstName: prefillObject
            ? prefillObject.tenants[0].firstName ?? ''
            : '',
          lastName: prefillObject
            ? prefillObject.tenants[0].lastName ?? ''
            : '',
          noEmail: prefillObject ? prefillObject.tenants[0].noEmail : false,
          email: prefillObject ? prefillObject.tenants[0].email ?? '' : '',
          noCellPhone: prefillObject
            ? prefillObject.tenants[0].noCellPhone
            : false,
          cellphone: prefillObject
            ? prefillObject.tenants[0].cellphone ?? ''
            : ''
        }
      ],
      companyTenants: [
        {
          company: 2,
          companyName: prefillObject
            ? prefillObject.tenants[0].companyName ?? ''
            : '',
          organizationNumber: prefillObject
            ? prefillObject.tenants[0].organizationNumber ?? ''
            : '',
          isCreditChecked: false,
          creditScore: '',
          address: prefillObject ? prefillObject.tenants[0].address ?? '' : '',
          zipCode: prefillObject ? prefillObject.tenants[0].zipCode ?? '' : '',
          city: prefillObject ? prefillObject.tenants[0].city ?? '' : '',
          firstName: prefillObject
            ? prefillObject.tenants[0].firstName ?? ''
            : '',
          lastName: prefillObject
            ? prefillObject.tenants[0].lastName ?? ''
            : '',
          noEmail: prefillObject ? prefillObject.tenants[0].noEmail : false,
          email: prefillObject ? prefillObject.tenants[0].email ?? '' : '',
          noCellPhone: prefillObject
            ? prefillObject.tenants[0].noCellPhone
            : false,
          cellphone: prefillObject
            ? prefillObject.tenants[0].cellphone ?? ''
            : ''
        }
      ],
      contractInfo: {
        contractType: prefillObject ? prefillObject.contractType.id : 1,
        startDate: new Date(),
        endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        noticePeriod: 3,
        acquisitionDate: startOfTomorrow(),
        acquistied: false
      },
      rentInfo: {
        rentAmount: '',
        startDate: new Date(),
        invoiceInterval: 0,
        paymentDay: 1,
        mainProduct: 0,
        products: [],
        extraProduct: [],
        chosenExtraProduct: [],
        autoCalcFirstInvoice: true,
        firstInvoiceAmount: '',
        isExternalRegulated: false,
        prevRegulationDate: new Date(),
        contractstartDate: '',
        nextRegulationDate: new Date()
      },
      securityInfo: {
        securityType: null,
        deposit: '',
        SPCreditCheck: false,
        myrentTenantsAreValid: false,
        myrentOwner: {
          clientType: '2',
          companyName: '',
          companyNumber: '',
          firstName: '',
          lastName: '',
          email: '',
          cellPhone: '',
          socialSecurityNumber: ''
        },
        tenantSigner: {
          companyName: '',
          organizationNumber: '',
          firstName: '',
          lastName: '',
          email: '',
          cellphone: '',
          socialSecurityNumber: ''
        },
        monthSP: '',
        navDate: '',
        otherSecurity: '',
        doNotSendOutAuthenticationInfo: false,
        isSecurityAccountDisabled: true
      },
      administrativeInfo: {
        sendTenantProfile: true,
        createAqusitionForm: true,
        sendInvoiceBeforeSignedContract: true
      },
      finalAction: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const contractSubmit = {
        simpleWizardTenantsViewModel: {
          applicationId: -1,
          applicationType: -1,
          contracts: [
            {
              id: 0,
              tenants:
                values.tenantType == 'private'
                  ? values.privateTenants
                  : values.companyTenants,
              invoiceRentProductId: values.rentInfo.mainProduct,
              invoiceLateFeeProductId: values.propertyInfo.lateFeeProductId,
              invoiceAdditionalProducts:
                values.rentInfo.chosenExtraProduct.length > 0
                  ? values.rentInfo.chosenExtraProduct.map((index) => {
                      if (formik.values.rentInfo.products[index]) {
                        return formik.values.rentInfo.products[index];
                      }
                    })
                  : null,
              object: values.propertyInfo.object,
              contractType: {
                id: values.contractInfo.contractType
              },
              leaseStart: format(values.contractInfo.startDate, 'yyyy-MM-dd'),
              leaseStop:
                values.contractInfo.contractType != 3
                  ? format(values.contractInfo.endDate, 'yyyy-MM-dd')
                  : null,
              moveOutDate: null,
              moveInDate: values.contractInfo.acquisitionDate
                ? values.contractInfo.acquisitionDate
                : null,
              alreadyMovedIn: values.contractInfo.acquistied,
              nextInvoiceDate: format(values.rentInfo.startDate, 'yyyy-MM-dd'),
              invoiceDayOfMonth: values.rentInfo.paymentDay,
              periodOfNotice:
                values.contractInfo.contractType == 2
                  ? 0
                  : values.contractInfo.noticePeriod,
              nextLeaseRegulation: format(
                values.rentInfo.nextRegulationDate,
                'yyyy-MM-dd'
              ),
              monthlyFee: values.rentInfo.rentAmount,
              invoicePeriodType: values.rentInfo.invoiceInterval,
              invoiceDayOfMonthOffset: 0,
              useProrate: values.rentInfo.autoCalcFirstInvoice,
              prorateWarning: false,
              hasFile: false,
              doNotSendOutAuthenticationInfo:
                !values.administrativeInfo.sendTenantProfile,
              doNotSendOutContractProtocol:
                !values.administrativeInfo.createAqusitionForm,
              roomNumber: null,
              showRoomNumber: false,
              securityOption: values.securityInfo.securityType,
              deposit: values.securityInfo.deposit,
              leaseGuarantee:
                values.securityInfo.securityType == 3 ? true : false,
              leaseGuaranteeMonths:
                values.securityInfo.securityType == 3
                  ? values.securityInfo.monthSP
                  : null,
              navGuarantee:
                values.securityInfo.securityType == 5 ? true : false,
              navGuaranteeEndDate:
                values.securityInfo.securityType == 3
                  ? values.securityInfo.navDate
                  : null,
              depositOtherOptionComment:
                values.securityInfo.securityType == 6
                  ? values.securityInfo.otherSecurity
                  : null,
              mainProductCpiRegulationDate: values.rentInfo.isExternalRegulated
                ? format(values.rentInfo.prevRegulationDate, 'yyyy-MM-dd')
                : null,
              dontSendInvoiceUntilContractIsSigned:
                !values.administrativeInfo.sendInvoiceBeforeSignedContract,
              isSecurityAccountDisabled:
                values.securityInfo.isSecurityAccountDisabled,
              depositAccount:
                values.securityInfo.securityType == 2
                  ? {
                      amount: values.securityInfo.deposit,
                      waitOnSigning:
                        values.securityInfo.doNotSendOutAuthenticationInfo,
                      landlordSigner: {
                        companyName:
                          values.securityInfo.myrentOwner.companyName.length ==
                          0
                            ? undefined
                            : values.securityInfo.myrentOwner.companyName,
                        companyNumber:
                          values.securityInfo.myrentOwner.companyNumber
                            .length == 0
                            ? undefined
                            : values.securityInfo.myrentOwner.companyNumber,
                        email: values.securityInfo.myrentOwner.email,
                        firstName: values.securityInfo.myrentOwner.firstName,
                        lastName: values.securityInfo.myrentOwner.lastName,
                        phoneNumber: values.securityInfo.myrentOwner.cellPhone,
                        ssn: values.securityInfo.myrentOwner
                          .socialSecurityNumber
                      },
                      tenantSigner: {
                        companyName:
                          values.securityInfo.tenantSigner.companyName.length ==
                          0
                            ? undefined
                            : values.securityInfo.tenantSigner.companyName,
                        companyNumber:
                          values.securityInfo.tenantSigner.organizationNumber
                            .length == 0
                            ? undefined
                            : values.securityInfo.tenantSigner
                                .organizationNumber,
                        email: values.securityInfo.tenantSigner.email,
                        firstName: values.securityInfo.tenantSigner.firstName,
                        lastName: values.securityInfo.tenantSigner.lastName,
                        phoneNumber: values.securityInfo.tenantSigner.cellphone,
                        ssn: values.securityInfo.tenantSigner
                          .socialSecurityNumber
                      }
                    }
                  : null
            }
          ]
        }
      };
      try {
        if (!values.propertyInfo.object) throw 'Objekt er ikke valgt';
        const propertyObject: PropertyObject = values.propertyInfo.object;
        const isDuplicateCheck = await checkDuplicate({
          ObjectId: propertyObject && propertyObject.id,
          firstName:
            values.tenantType == 'private'
              ? values.privateTenants[0].firstName
              : values.companyTenants[0].firstName,
          lastName:
            values.tenantType == 'private'
              ? values.privateTenants[0].lastName
              : values.companyTenants[0].lastName,
          leaseStart: format(values.rentInfo.startDate, 'yyyy-MM-dd')
        }).unwrap();
        if (isDuplicateCheck.isDuplicateData) {
          formik.setFieldError(
            'formDuplicate',
            'Leietakerforhold er allerede opprettet.'
          );
        } else {
          try {
            const formResult = await sendForm(contractSubmit).unwrap();
            if (!formResult.status.success) {
              if (formResult.status.errorMessage)
                throw formResult.status.errorMessage;
              throw 'Feil i opprettelse av leietakerforhold';
            }
            dispatch(setContractId(formResult.createdContractIds[0]));
            dispatch(openModal('contract'));
            dispatch(reset());
            formik.resetForm();
          } catch (error) {
            formik.setFieldError('formDuplicate', `${error}`);
          }
        }
      } catch (err) {
        formik.setFieldError('formDuplicate', `${err}`);
      }
    }
  });

  const formSteps: TenantStep[] = [
    {
      title: 'Leieobjektet',
      showTitle: true,
      validationObject: 'propertyInfo',
      validationSchema: validateAddProperty,
      render: <AddBuilding formik={formik} />
    },
    {
      title: 'Leietaker(e)',
      showTitle: true,
      validationObject:
        formik.values.tenantType == 'private'
          ? 'privateTenants'
          : 'companyTenants',
      validationSchema:
        formik.values.tenantType == 'private'
          ? validatePrivateTenants
          : validateCompanyTenants,
      render: <AddTenantInfo formik={formik} />
    },
    {
      title: 'Kontrakt',
      showTitle: true,
      validationObject: 'contractInfo',
      validationSchema: validateAddContract,
      render: <AddContract formik={formik} />
    },
    {
      title: 'Husleie',
      showTitle: true,
      validationObject: 'rentInfo',
      validationSchema: validateAddRent,
      render: <AddRent formik={formik} />
    },
    {
      title: 'Sikkerhet',
      showTitle: true,
      validationObject: 'securityInfo',
      validationSchema: validateAddSecurity,
      render: <AddSecurity formik={formik} />
    },
    {
      title: 'Administrativt',
      showTitle: true,
      validationObject: '',
      optional: true,
      validationSchema: null,
      render: <AddAdministrative formik={formik} />
    },
    {
      title: 'Oppsummering',
      showTitle: true,
      validationObject: '',
      optional: true,
      validationSchema: null,
      render: <TenantSummary formik={formik} />
    },
    {
      title: 'Kontrakt',
      showTitle: false,
      validationObject: '',
      optional: true,
      validationSchema: null,
      render: <ContractType formik={formik} />
    },
    {
      title: 'Alternativ',
      showTitle: false,
      validationObject: '',
      optional: true,
      validationSchema: null,
      render: <EditContract formik={formik} />
    },
    {
      title: 'Bekreftelse',
      showTitle: false,
      validationObject: '',
      optional: true,
      validationSchema: null,
      render: (
        <Stack textAlign={'center'}>
          <Typography variant="h4">Du er ferdig!</Typography>
        </Stack>
      )
    }
  ];

  useEffect(() => {
    setValidationSchema(formSteps[step].validationSchema);
  }, [step, formik.values.tenantType]);

  useEffect(() => {
    if (prefillObject) {
      formik.setFieldTouched('propertyInfo.clientId', true);
      formik.setFieldTouched('privateTenants[0].firstName', true);
      formik.setFieldTouched('companyTenants[0].firstName', true);
    }
  }, [prefillObject]);

  return (
    <Stack>
      <Stack>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <AlternatingProgressTracker
              formik={formik}
              validationObject={formSteps[step].validationObject}
              showTitle={formSteps[step].showTitle}
              titleSteps={formSteps
                .filter((step) => step.showTitle)
                .map((step) => step.title)}
              redirect={redirect}
            />
            {formSteps[step].render}
          </form>
        </FormikProvider>
      </Stack>
      <LoadingPopup open={isSendingForm || isCheckingDuplicate} />
    </Stack>
  );
};

export default Component;
