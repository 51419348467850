import styled from 'styled-components';

export const Container = styled.div`
  height: 250px;
  width: 220px;
  background-color: white;
  opacity: 0.9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
`;

export const ContentContainer = styled.div`
  font-size: 30px;
  position: absolute;
  right: 12%;
  top: 6%;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 5%;
  bottom: 2%;
`;

export const Checkmark = styled.div`
  opacity: 0;
  font-size: 30px;
  position: absolute;
  right: 8%;
  top: 1%;
`;

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0px 16px 24px 16px;
  box-sizing: border-box;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const RadioContainerView = styled.label`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: transparent;
`;

export const RadioButtonView = styled.label`
  position: absolute;
  /* z-index: 2; */
  top: 7%;
  right: 8%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #c87c6c;
  /* background-color: red; */
  color: red;
`;

export const RadioButtonRef = styled.input`
  position: absolute;
  top: 0;
  left: 4px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin-right: 10px;
  &:hover ~ ${RadioContainerView} {
    box-shadow: 0 0 0 4px #c87c6c;
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${RadioContainerView} {
    /* border: 1px solid #9aeabc; */
    box-shadow: 0 0 0 4px #c87c6c;
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }

  &:checked ~ ${Checkmark} {
    opacity: 1;
  }
`;

interface InputProps {
  error: boolean | undefined;
}

export const Input = styled.input<InputProps>`
  -webkit-appearance: none;
  opacity: 90%;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border-width: 0px;
  box-shadow: ${({ error }) => error && '0 0 0 2px red'};
  &:focus {
    box-shadow: 0 0 0 2px #9aeabc;
  }
`;
