import { Divider, Stack, Typography } from '@mui/material';
import { ContextSwitcher } from 'app/components/context-switcher';
import { Datagrid } from 'app/components/data-grid';
import { red } from 'app/theme/colors';
import { useParams } from 'react-router';

import { DetailPill } from './DetailPill';
import { TEST_COLUMNS, TEST_ROWS } from './test-data';

export default function Component() {
  const { view } = useParams();

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Eiendommer</Typography>
        <ContextSwitcher />
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Typography variant="h2">
          {view ? view.charAt(0).toUpperCase() + view.slice(1) : 'Feil'}
        </Typography>
        <Stack direction="row" spacing={3}>
          <DetailPill label="Fakturert i år" value="104.147,-" />
          <DetailPill label="Fakturert denne måneden" value="1.147,-" />
          <DetailPill
            label="Utestående"
            value="137.147,-"
            valueColor={red[100]}
          />
        </Stack>
      </Stack>
      <Datagrid.View
        columns={TEST_COLUMNS}
        rows={TEST_ROWS}
        totalRows={TEST_ROWS.length}
        onRowClick={(e) => console.log(e)}
      />
    </Stack>
  );
}
