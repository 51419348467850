import { Box, ErrorMessage, LinkText } from '@myrent/myrent-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';

const Component = (): JSX.Element => {
  const { t } = useTranslation();
  const nav = useNavigate();

  const handleHomeClick = () => {
    nav('home', { replace: true });
  };

  return (
    <Container>
      <Box>
        <LinkText onClick={handleHomeClick}>
          {t('goHomeFromNotFoundMessage')}
        </LinkText>
      </Box>
      <ErrorMessage>{t('notFoundMessage')}</ErrorMessage>
    </Container>
  );
};

export default Component;
