export async function enhetsregisterQuery(
  searchAddress: number | string | undefined
) {
  if (!searchAddress) return;
  const result = await fetch(
    `https://data.brreg.no/enhetsregisteret/api/enheter/${encodeURIComponent(
      searchAddress
    )}`
  );
  const parsed = await result.json();
  return parsed;
}
