import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Stack, Typography } from '@mui/material';
import { DataGridColumns } from 'app/components/data-grid/types';
import { getPropertyIcon } from 'app/components/data-grid/view/functions/getPropertyIcon';

export function createData(
  id: number,
  type: string,
  propertyType: React.ReactNode,
  unitNumber: string,
  rentStatus: string,
  tenantCount: number,
  archived?: boolean
) {
  return {
    id,
    type,
    propertyType,
    unitNumber,
    rentStatus,
    tenantCount,
    archived: archived ?? false
  };
}

export const TEST_ROWS = [
  createData(
    1,
    'Bod',
    getPropertyIcon('Bod'),
    'H201',
    'Utleid til: 02.03.2024',
    2
  ),
  createData(
    2,
    'Bod',
    getPropertyIcon('Bod'),
    'H202',
    'Utleid til: 02.03.2024',
    2
  ),
  createData(
    3,
    'Leilighet',
    getPropertyIcon('Leilighet'),
    'H203',
    'Utleid til: 02.03.2024',
    2
  ),
  createData(
    4,
    'Leilighet',
    getPropertyIcon('Leilighet'),
    'Uten navn',
    'Utleid til: 02.03.2024',
    2
  ),
  createData(
    4,
    'Leilighet',
    getPropertyIcon('Leilighet'),
    'H205',
    'Utleid til: 02.03.2024',
    2,
    true
  )
];

export const TEST_COLUMNS: DataGridColumns = {
  propertyType: {
    label: 'Type',
    style: 'pill'
  },
  unitNumber: {
    label: 'Enhetsnummer'
  },
  rentStatus: {
    label: 'Leiestatus'
  },
  tenantCount: {
    label: 'Leietakere',
    style: 'count'
  },
  options: {
    label: ''
  }
};
