import { Stack, Typography } from '@myrent/myrent-ui';
import { SecondaryButton } from 'app/components/button';
import { MultiSelect } from 'app/components/input/select';
import { useLazyGetDaughterOrganizatonsQuery } from 'app/context/api/client';
import { useEffect, useState } from 'react';

import { enhetsregisterQuery } from '../../utils/enhetsregisterQuery';

interface Props {
  formik: any;
  setAddUnitSelector: (value: boolean) => void;
}

const Component = ({ formik, setAddUnitSelector }: Props): JSX.Element => {
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [options, setOptions] = useState(null);

  const [fetchDaughterOrganizations, { data: daughterOrganizations }] =
    useLazyGetDaughterOrganizatonsQuery();

  useEffect(() => {
    async function fetchData() {
      if (formik.values.organizationNumber?.length >= 9) {
        try {
          await fetchDaughterOrganizations(
            formik.values.organizationNumber.replace(/\s/g, '')
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
    fetchData();
  }, [formik.values.organizationNumber]);

  async function handleClick() {
    const array: any = [];
    for (const name of selectedUnits) {
      const organizationNumber = daughterOrganizations?.sub.filter(
        (x) => x.name === name
      )[0].organisationNumber;
      const brregData = await enhetsregisterQuery(organizationNumber);
      const address = brregData?.forretningsadresse;
      const company = {
        address: address?.adresse[0],
        organizationNumber,
        name,
        zipCode: address?.postnummer,
        city: address?.poststed,
        invoiceAccountNumber: ''
      };
      array.push(company);
    }
    formik.setFieldValue('subs', [...formik.values.subs, ...array]);
    setAddUnitSelector(false);
  }

  return (
    <Stack
      width="100%"
      padding={2}
      sx={{
        backgroundColor: '#f7edd2'
      }}
    >
      <Typography fontWeight="bold">Datterselskap:</Typography>
      <Stack spacing={3} paddingTop={1}>
        {daughterOrganizations && daughterOrganizations?.sub.length > 0 ? (
          <>
            <MultiSelect
              fullWidth
              id="units"
              name="units"
              labelLeftMargin="0px"
              placeholder="Leilighet"
              value={selectedUnits}
              onChange={setSelectedUnits}
              error={false}
              options={daughterOrganizations?.sub.map((item) => {
                return { label: item.name };
              })}
              helperText={false}
            />
            <SecondaryButton filled onClick={() => handleClick()}>
              Legg til valgte selskap
            </SecondaryButton>
          </>
        ) : (
          <Typography>Ingen datterselskaper funnet</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default Component;
