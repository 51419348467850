import styled from 'styled-components';

export const Container = styled.div``;

export const ClickableContainer = styled.div`
  /* cursor: pointer; */
`;

export const StepContainer = styled.div`
  width: 1.2rem;
  height: 1.2rem;
`;

interface StepProps {
  active: boolean;
}

export const StepIcon = styled.div<StepProps>`
  background: ${({ active }) => (active ? '#C9C9C9' : '#242424')};
  border-radius: 50%;
  height: 100%;
  width: 100%;
`;

export const StepConnectorContainer = styled.div<StepProps>`
  width: 1.2rem;
  height: 2rem;
`;

export const StepConnector = styled.div<StepProps>`
  height: 100%;
  width: 10%;
  margin-left: 45%;
  background-color: ${({ active }) => (active ? '#C9C9C9' : '#242424')};
`;
