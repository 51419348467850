import { Box } from '@mui/material';
import { Stack, Typography } from '@myrent/myrent-ui';
import SRBankLogo from 'app/assets/imgs/sparebank1.svg';
import {
  Checkbox,
  MaskedInput,
  PhoneInput,
  TextInput
} from 'app/components/input';
import { PersonalNumberInput } from 'app/components/input/personal-number';
import { LoadingPopup } from 'app/components/loading';
import { useGetClientContactInfoQuery } from 'app/context/api/client';
import { red } from 'app/theme/colors';
import { useEffect } from 'react';

interface Props {
  formik: any;
  clientId: number;
  errors: string[];
  errorMsg: string;
}

const Component = ({
  formik,
  clientId,
  errors,
  errorMsg
}: Props): JSX.Element => {
  const { data: clientInfo, isLoading } =
    useGetClientContactInfoQuery(clientId);

  const prefillClientInfo = (field: string, value?: string) => {
    if (value) {
      formik.setFieldTouched(`securityInfo.myrentOwner.${field}`, true);
      formik.setFieldValue(`securityInfo.myrentOwner.${field}`, value);
    }
  };

  useEffect(() => {
    if (clientInfo && !formik.touched?.securityInfo?.myrentOwner) {
      if (clientInfo.clientType == '1') {
        formik.setFieldValue('securityInfo.myrentOwner.clientType', '1');
      }

      prefillClientInfo('companyName', clientInfo.companyName);
      prefillClientInfo('companyNumber', clientInfo.companyNumber);
      prefillClientInfo(
        'socialSecurityNumber',
        clientInfo.ssn?.replaceAll('-', '')
      );
      prefillClientInfo('cellPhone', clientInfo.phoneNumber);
      prefillClientInfo('firstName', clientInfo.firstName);
      prefillClientInfo('lastName', clientInfo.lastName);
      prefillClientInfo('email', clientInfo.email);
    }
  }, [clientInfo]);

  if (isLoading) {
    return (
      <>
        <LoadingPopup open={isLoading} />
      </>
    );
  }
  if (
    errors.length > 0 ||
    formik.values.securityInfo.myrentOwner.companyNumber == 0
  ) {
    return (
      <Stack
        sx={{ backgroundColor: red[40], borderRadius: '25px' }}
        spacing={2}
        padding={{ xs: 2, md: 5 }}
      >
        <Typography>
          For å kunne bestille MyRent depositumskonto levert av Sparebank 1
          SR-Bank så må følgende felter være fylt ut:
        </Typography>
        {formik.values.securityInfo.myrentOwner.companyNumber == 0 && (
          <>
            <Typography>Utleier:</Typography>
            <Typography pl="20px">
              {`- ${formik.errors?.securityInfo?.myrentOwner?.companyNumber}`}
            </Typography>
          </>
        )}
        {errors.length > 0 && (
          <>
            <Typography>Leietaker:</Typography>
            {errors.map((warning, index) => (
              <Typography key={index} pl="20px">
                {`- ${warning}`}
              </Typography>
            ))}
          </>
        )}
        <Typography variant="body2">{errorMsg}</Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} alignItems={'flex-start'}>
      <Box
        component={'img'}
        src={SRBankLogo}
        sx={{
          height: 53,
          width: 183
        }}
        alt="Sparebank 1"
      />
      <Typography variant="body2">
        MyRent tilbyr depositumskonto via SR-Bank til Kr. 149,-. Klikk her for å
        lese mer.
      </Typography>
      <Stack>
        <TextInput
          id={'securityInfo.deposit'}
          name={'securityInfo.deposit'}
          value={formik.values.securityInfo.deposit}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.securityInfo?.deposit &&
            Boolean(formik.errors.securityInfo?.deposit)
          }
          helperText={
            formik.touched.securityInfo?.deposit &&
            formik.errors.securityInfo?.deposit
          }
          label={'Depositum i NOK'}
          labelColor={'#242424'}
          labelLeftMargin={'0'}
          labelWeight={'normal'}
          bgColor={'#ededed'}
          autofocus
        />
      </Stack>
      <Checkbox
        id={'securityInfo.doNotSendOutAuthenticationInfo'}
        name={'securityInfo.doNotSendOutAuthenticationInfo'}
        value={formik.values.securityInfo.doNotSendOutAuthenticationInfo}
        fontSize={'14px'}
        fontColor={'#242424'}
        onChange={formik.handleChange}
        fullWidth
        onBlur={formik.handleBlur}
        error={undefined}
        helperText={undefined}
        label={'Ikke send bestilling før kontrakt er signert i MyRent'}
      />
      <Typography variant="h6" fontWeight={'bold'}>
        Utleier signatar
      </Typography>
      <Stack
        spacing={1}
        sx={{
          backgroundColor: '#BCF6E0',
          padding: '10px',
          borderRadius: '15px'
        }}
      >
        <Typography variant="body2">
          NB: Den som signerer på vegne av utleier må ha signaturrett i
          selskapet.
        </Typography>
        <Typography variant="body2">Sjekk www.brreg.no.</Typography>
        <Typography variant="body2">
          Ved spørsmål, kontakt kundeservice.
        </Typography>
      </Stack>
      <Stack spacing={4} alignItems={'flex-start'}>
        {formik.values.securityInfo.myrentOwner.clientType === '1' && (
          <>
            <TextInput
              name={`securityInfo.myrentOwner.companyName`}
              value={formik.values.securityInfo.myrentOwner.companyName}
              onChange={formik.handleChange}
              id={`securityInfo.myrentOwner.companyName`}
              onBlur={formik.handleBlur}
              bgColor="#ededed"
              error={
                formik.errors.securityInfo &&
                formik.touched.securityInfo &&
                formik.errors.securityInfo.myrentOwner &&
                formik.touched.securityInfo.myrentOwner &&
                typeof formik.errors.securityInfo.myrentOwner.companyName ===
                  'string'
                  ? Boolean(
                      formik.errors.securityInfo.myrentOwner.companyName
                    ) && formik.touched.securityInfo.myrentOwner.companyName
                  : undefined
              }
              helperText={
                formik.errors.securityInfo &&
                formik.touched.securityInfo &&
                formik.errors.securityInfo.myrentOwner &&
                formik.touched.securityInfo.myrentOwner &&
                formik.touched.securityInfo.myrentOwner.companyName &&
                typeof formik.errors.securityInfo.myrentOwner.companyName ===
                  'string'
                  ? formik.errors.securityInfo.myrentOwner.companyName
                  : undefined
              }
              label={'Bedrift'}
              labelColor={'#242424'}
              labelLeftMargin={'0'}
              labelWeight={'normal'}
              disabled
            />
            <MaskedInput
              id={`securityInfo.myrentOwner.companyNumber`}
              name={`securityInfo.myrentOwner.companyNumber`}
              mask="### ### ###"
              label="Organisasjonsnummer"
              placeholder="Fyll inn org.nr"
              value={formik.values.securityInfo.myrentOwner.companyNumber}
              onChange={(e) => {
                formik.setFieldValue(
                  `securityInfo.myrentOwner.companyNumber`,
                  e.target.value.replaceAll(' ', '')
                );
              }}
              onBlur={() => {
                formik.setFieldTouched(
                  `securityInfo.myrentOwner.companyNumber`,
                  true
                );
              }}
              error={
                formik.errors.securityInfo &&
                formik.errors.securityInfo.myrentOwner &&
                formik.touched.securityInfo &&
                formik.touched.securityInfo.myrentOwner &&
                typeof formik.errors.securityInfo.myrentOwner.companyNumber ===
                  'string'
                  ? Boolean(
                      formik.errors.securityInfo.myrentOwner.companyNumber
                    ) && formik.touched.securityInfo.myrentOwner.companyNumber
                  : undefined
              }
              helperText={
                formik.errors.securityInfo &&
                formik.errors.securityInfo.myrentOwner &&
                formik.touched.securityInfo &&
                formik.touched.securityInfo.myrentOwner &&
                formik.touched.securityInfo.myrentOwner.companyNumber &&
                typeof formik.errors.securityInfo.myrentOwner.companyNumber ===
                  'string'
                  ? formik.errors.securityInfo.myrentOwner.companyNumber
                  : undefined
              }
              labelColor={'#242424'}
              labelLeftMargin={'0'}
              bgColor="#ededed"
              labelWeight={'normal'}
              disabled
            />
          </>
        )}

        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
          spacing={2}
        >
          <TextInput
            name={`securityInfo.myrentOwner.firstName`}
            value={formik.values.securityInfo.myrentOwner.firstName}
            onChange={formik.handleChange}
            id={`securityInfo.myrentOwner.firstName`}
            onBlur={formik.handleBlur}
            bgColor="#ededed"
            error={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.myrentOwner &&
              formik.touched.securityInfo.myrentOwner &&
              typeof formik.errors.securityInfo.myrentOwner.firstName ===
                'string'
                ? Boolean(formik.errors.securityInfo.myrentOwner.firstName) &&
                  formik.touched.securityInfo.myrentOwner.firstName
                : undefined
            }
            helperText={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.myrentOwner &&
              formik.touched.securityInfo.myrentOwner &&
              formik.touched.securityInfo.myrentOwner.firstName &&
              typeof formik.errors.securityInfo.myrentOwner.firstName ===
                'string'
                ? formik.errors.securityInfo.myrentOwner.firstName
                : undefined
            }
            label={'Fornavn utleier signatar'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
          />
          <TextInput
            name={`securityInfo.myrentOwner.lastName`}
            value={formik.values.securityInfo.myrentOwner.lastName}
            onChange={formik.handleChange}
            id={`securityInfo.myrentOwner.lastName`}
            onBlur={formik.handleBlur}
            bgColor="#ededed"
            error={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.myrentOwner &&
              formik.touched.securityInfo.myrentOwner &&
              typeof formik.errors.securityInfo.myrentOwner.lastName ===
                'string'
                ? Boolean(formik.errors.securityInfo.myrentOwner.lastName) &&
                  formik.touched.securityInfo.myrentOwner.lastName
                : undefined
            }
            helperText={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.myrentOwner &&
              formik.touched.securityInfo.myrentOwner &&
              formik.touched.securityInfo.myrentOwner.lastName &&
              typeof formik.errors.securityInfo.myrentOwner.lastName ===
                'string'
                ? formik.errors.securityInfo.myrentOwner.lastName
                : undefined
            }
            label={'Etternavn utleier signatar'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
          />
        </Stack>
        <TextInput
          name={`securityInfo.myrentOwner.email`}
          value={formik.values.securityInfo.myrentOwner.email}
          onChange={formik.handleChange}
          id={`securityInfo.myrentOwner.email`}
          onBlur={formik.handleBlur}
          bgColor="#ededed"
          error={
            formik.errors.securityInfo &&
            formik.touched.securityInfo &&
            formik.errors.securityInfo.myrentOwner &&
            formik.touched.securityInfo.myrentOwner &&
            typeof formik.errors.securityInfo.myrentOwner.email === 'string'
              ? Boolean(formik.errors.securityInfo.myrentOwner.email) &&
                formik.touched.securityInfo.myrentOwner.email
              : undefined
          }
          helperText={
            formik.errors.securityInfo &&
            formik.touched.securityInfo &&
            formik.errors.securityInfo.myrentOwner &&
            formik.touched.securityInfo.myrentOwner &&
            formik.touched.securityInfo.myrentOwner.email &&
            typeof formik.errors.securityInfo.myrentOwner.email === 'string'
              ? formik.errors.securityInfo.myrentOwner.email
              : undefined
          }
          label={'Epost utleier signatar'}
          labelColor={'#242424'}
          labelLeftMargin={'0'}
          labelWeight={'normal'}
        />
        <PhoneInput
          id="securityInfo.myrentOwner.cellPhone"
          name="securityInfo.myrentOwner.cellPhone"
          label="Telefonnummer utleier signatar"
          labelColor={'#242424'}
          labelWeight={'normal'}
          bgColor="#ededed"
          labelLeftMargin={'0'}
          placeholder="Minimum 8 tegn"
          value={formik.values.securityInfo.myrentOwner.cellPhone}
          onChange={(e) => {
            formik.setFieldValue('securityInfo.myrentOwner.cellPhone', `+${e}`);
          }}
          onBlur={() => {
            formik.setFieldTouched('securityInfo.myrentOwner.cellPhone', true);
          }}
          error={
            formik.errors.securityInfo &&
            formik.touched.securityInfo &&
            formik.errors.securityInfo.myrentOwner &&
            formik.touched.securityInfo.myrentOwner &&
            typeof formik.errors.securityInfo.myrentOwner.cellPhone === 'string'
              ? Boolean(formik.errors.securityInfo.myrentOwner.cellPhone) &&
                formik.touched.securityInfo.myrentOwner.cellPhone
              : undefined
          }
          helperText={
            formik.errors.securityInfo &&
            formik.touched.securityInfo &&
            formik.errors.securityInfo.myrentOwner &&
            formik.touched.securityInfo.myrentOwner &&
            formik.touched.securityInfo.myrentOwner.cellPhone &&
            typeof formik.errors.securityInfo.myrentOwner.cellPhone === 'string'
              ? formik.errors.securityInfo.myrentOwner.cellPhone
              : undefined
          }
        />
        <PersonalNumberInput
          id={`securityInfo.myrentOwner.socialSecurityNumber`}
          name={`securityInfo.myrentOwner.socialSecurityNumber`}
          value={formik.values.securityInfo.myrentOwner.socialSecurityNumber}
          placeholder="11 siffer"
          onChange={(e) =>
            formik.setFieldValue(
              'securityInfo.myrentOwner.socialSecurityNumber',
              e.target.value.replaceAll(' ', '')
            )
          }
          onBlur={formik.handleBlur}
          error={
            formik.errors.securityInfo &&
            formik.errors.securityInfo.myrentOwner &&
            formik.touched.securityInfo &&
            formik.touched.securityInfo.myrentOwner &&
            typeof formik.errors.securityInfo.myrentOwner
              .socialSecurityNumber === 'string'
              ? Boolean(
                  formik.errors.securityInfo.myrentOwner.socialSecurityNumber
                ) &&
                formik.touched.securityInfo.myrentOwner.socialSecurityNumber
              : undefined
          }
          helperText={
            formik.errors.securityInfo &&
            formik.errors.securityInfo.myrentOwner &&
            formik.touched.securityInfo &&
            formik.touched.securityInfo.myrentOwner &&
            formik.touched.securityInfo.myrentOwner.socialSecurityNumber &&
            typeof formik.errors.securityInfo.myrentOwner
              .socialSecurityNumber === 'string'
              ? formik.errors.securityInfo.myrentOwner.socialSecurityNumber
              : undefined
          }
          labelColor="#242424"
          labelLeftMargin="0"
          fontWeight="normal"
          label={'Pers.nr. utleier signatar '}
        />
      </Stack>
      {formik.values.tenantType == 'company' && (
        <Stack spacing={4} alignItems={'flex-start'}>
          <Typography variant="h6" fontWeight={'bold'} sx={{ pt: 5 }}>
            Leietaker signatar
          </Typography>
          <Stack
            spacing={1}
            sx={{
              backgroundColor: '#BCF6E0',
              padding: '10px',
              borderRadius: '15px'
            }}
          >
            <Typography variant="body2">
              NB: Den som signerer på vegne av leietaker må ha signaturrett i
              selskapet.
            </Typography>
            <Typography variant="body2">Sjekk www.brreg.no.</Typography>
            <Typography variant="body2">
              Ved spørsmål, kontakt kundeservice.
            </Typography>
          </Stack>
          <TextInput
            name={`securityInfo.tenantSigner.companyName`}
            value={formik.values.securityInfo.tenantSigner.companyName}
            onChange={formik.handleChange}
            id={`securityInfo.tenantSigner.companyName`}
            onBlur={formik.handleBlur}
            bgColor="#ededed"
            error={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner &&
              typeof formik.errors.securityInfo.tenantSigner.companyName ===
                'string'
                ? Boolean(
                    formik.errors.securityInfo.tenantSigner.companyName
                  ) && formik.touched.securityInfo.tenantSigner.companyName
                : undefined
            }
            helperText={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner.companyName &&
              typeof formik.errors.securityInfo.tenantSigner.companyName ===
                'string'
                ? formik.errors.securityInfo.tenantSigner.companyName
                : undefined
            }
            label={'Bedrift'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            disabled
          />
          <MaskedInput
            id={`securityInfo.tenantSigner.organizationNumber`}
            name={`securityInfo.tenantSigner.organizationNumber`}
            mask="### ### ###"
            label="Organisasjonsnummer"
            placeholder="Fyll inn org.nr"
            value={formik.values.securityInfo.tenantSigner.organizationNumber}
            onChange={(e) => {
              formik.setFieldValue(
                `securityInfo.tenantSigner.organizationNumber`,
                e.target.value.replaceAll(' ', '')
              );
            }}
            onBlur={() => {
              formik.setFieldTouched(
                `securityInfo.tenantSigner.organizationNumber`,
                true
              );
            }}
            error={
              formik.errors.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo &&
              formik.touched.securityInfo.tenantSigner &&
              typeof formik.errors.securityInfo.tenantSigner
                .organizationNumber === 'string'
                ? Boolean(
                    formik.errors.securityInfo.tenantSigner.organizationNumber
                  ) &&
                  formik.touched.securityInfo.tenantSigner.organizationNumber
                : undefined
            }
            helperText={
              formik.errors.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo &&
              formik.touched.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner.organizationNumber &&
              typeof formik.errors.securityInfo.tenantSigner
                .organizationNumber === 'string'
                ? formik.errors.securityInfo.tenantSigner.organizationNumber
                : undefined
            }
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            bgColor="#ededed"
            labelWeight={'normal'}
            disabled
          />

          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
            spacing={2}
            alignItems={{ md: 'flex-start', lg: 'flex-end' }}
          >
            <TextInput
              name={`securityInfo.tenantSigner.firstName`}
              value={formik.values.securityInfo.tenantSigner.firstName}
              onChange={formik.handleChange}
              id={`securityInfo.tenantSigner.firstName`}
              onBlur={formik.handleBlur}
              bgColor="#ededed"
              error={
                formik.errors.securityInfo &&
                formik.touched.securityInfo &&
                formik.errors.securityInfo.tenantSigner &&
                formik.touched.securityInfo.tenantSigner &&
                typeof formik.errors.securityInfo.tenantSigner.firstName ===
                  'string'
                  ? Boolean(
                      formik.errors.securityInfo.tenantSigner.firstName
                    ) && formik.touched.securityInfo.tenantSigner.firstName
                  : undefined
              }
              helperText={
                formik.errors.securityInfo &&
                formik.touched.securityInfo &&
                formik.errors.securityInfo.tenantSigner &&
                formik.touched.securityInfo.tenantSigner &&
                formik.touched.securityInfo.tenantSigner.firstName &&
                typeof formik.errors.securityInfo.tenantSigner.firstName ===
                  'string'
                  ? formik.errors.securityInfo.tenantSigner.firstName
                  : undefined
              }
              label={'Fornavn leietaker signatar'}
              labelColor={'#242424'}
              labelLeftMargin={'0'}
              labelWeight={'normal'}
            />
            <TextInput
              name={`securityInfo.tenantSigner.lastName`}
              value={formik.values.securityInfo.tenantSigner.lastName}
              onChange={formik.handleChange}
              id={`securityInfo.tenantSigner.lastName`}
              onBlur={formik.handleBlur}
              bgColor="#ededed"
              error={
                formik.errors.securityInfo &&
                formik.touched.securityInfo &&
                formik.errors.securityInfo.tenantSigner &&
                formik.touched.securityInfo.tenantSigner &&
                typeof formik.errors.securityInfo.tenantSigner.lastName ===
                  'string'
                  ? Boolean(formik.errors.securityInfo.tenantSigner.lastName) &&
                    formik.touched.securityInfo.tenantSigner.lastName
                  : undefined
              }
              helperText={
                formik.errors.securityInfo &&
                formik.touched.securityInfo &&
                formik.errors.securityInfo.tenantSigner &&
                formik.touched.securityInfo.tenantSigner &&
                formik.touched.securityInfo.tenantSigner.lastName &&
                typeof formik.errors.securityInfo.tenantSigner.lastName ===
                  'string'
                  ? formik.errors.securityInfo.tenantSigner.lastName
                  : undefined
              }
              label={'Etternavn leietaker signatar'}
              labelColor={'#242424'}
              labelLeftMargin={'0'}
              labelWeight={'normal'}
            />
          </Stack>
          <TextInput
            name={`securityInfo.tenantSigner.email`}
            value={formik.values.securityInfo.tenantSigner.email}
            onChange={formik.handleChange}
            id={`securityInfo.tenantSigner.email`}
            onBlur={formik.handleBlur}
            bgColor="#ededed"
            error={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner &&
              typeof formik.errors.securityInfo.tenantSigner.email === 'string'
                ? Boolean(formik.errors.securityInfo.tenantSigner.email) &&
                  formik.touched.securityInfo.tenantSigner.email
                : undefined
            }
            helperText={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner.email &&
              typeof formik.errors.securityInfo.tenantSigner.email === 'string'
                ? formik.errors.securityInfo.tenantSigner.email
                : undefined
            }
            label={'Epost leietaker signatar'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
          />
          <PhoneInput
            id="securityInfo.tenantSigner.cellphone"
            name="securityInfo.tenantSigner.cellphone"
            label="Telefonnummer leietaker signatar"
            labelColor={'#242424'}
            labelWeight={'normal'}
            bgColor="#ededed"
            labelLeftMargin={'0'}
            placeholder="Minimum 8 tegn"
            value={formik.values.securityInfo.tenantSigner.cellphone}
            onChange={(e) => {
              formik.setFieldValue(
                'securityInfo.tenantSigner.cellphone',
                `+${e}`
              );
            }}
            onBlur={() => {
              formik.setFieldTouched(
                'securityInfo.tenantSigner.cellphone',
                true
              );
            }}
            error={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner &&
              typeof formik.errors.securityInfo.tenantSigner.cellphone ===
                'string'
                ? Boolean(formik.errors.securityInfo.tenantSigner.cellphone) &&
                  formik.touched.securityInfo.tenantSigner.cellphone
                : undefined
            }
            helperText={
              formik.errors.securityInfo &&
              formik.touched.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner.cellphone &&
              typeof formik.errors.securityInfo.tenantSigner.cellphone ===
                'string'
                ? formik.errors.securityInfo.tenantSigner.cellphone
                : undefined
            }
          />
          <PersonalNumberInput
            id={`securityInfo.tenantSigner.socialSecurityNumber`}
            name={`securityInfo.tenantSigner.socialSecurityNumber`}
            value={formik.values.securityInfo.tenantSigner.socialSecurityNumber}
            placeholder="11 siffer"
            onChange={(e) =>
              formik.setFieldValue(
                'securityInfo.tenantSigner.socialSecurityNumber',
                e.target.value.replaceAll(' ', '')
              )
            }
            onBlur={formik.handleBlur}
            error={
              formik.errors.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo &&
              formik.touched.securityInfo.tenantSigner &&
              typeof formik.errors.securityInfo.tenantSigner
                .socialSecurityNumber === 'string'
                ? Boolean(
                    formik.errors.securityInfo.tenantSigner.socialSecurityNumber
                  ) &&
                  formik.touched.securityInfo.tenantSigner.socialSecurityNumber
                : undefined
            }
            helperText={
              formik.errors.securityInfo &&
              formik.errors.securityInfo.tenantSigner &&
              formik.touched.securityInfo &&
              formik.touched.securityInfo.tenantSigner &&
              formik.touched.securityInfo.tenantSigner.socialSecurityNumber &&
              typeof formik.errors.securityInfo.tenantSigner
                .socialSecurityNumber === 'string'
                ? formik.errors.securityInfo.tenantSigner.socialSecurityNumber
                : undefined
            }
            labelColor="#242424"
            labelLeftMargin="0"
            fontWeight="normal"
            label={'Pers.nr. leietaker signatar '}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default Component;
