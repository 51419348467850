import {
  FormControl,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

import { Container, Label, Option } from './styles';

interface Props {
  setFormik: any;
  value: Date;
  name: string;
  disabled?: boolean;
  disablePast?: boolean;
  onBlur?: () => void;
  label: string;
  fullWidth?: boolean;
  labelColor?: string;
  labelWeight?: string;
  labelLeftMargin?: string;
  helperText: string | false | undefined;
  error?: boolean | undefined;
}

const Component = ({
  setFormik,
  value,
  name,
  label,
  disabled = false,
  disablePast = false,
  onBlur,
  fullWidth = false,
  labelColor = 'white',
  labelWeight = 'bold',
  labelLeftMargin = '10px',
  helperText,
  error = false
}: Props): JSX.Element => {
  return (
    <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
      {label && (
        <Label htmlFor={name}>
          <Typography
            color={labelColor}
            marginLeft={labelLeftMargin}
            fontWeight={labelWeight}
          >
            {label}
          </Typography>
        </Label>
      )}
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            onChange={(value) => {
              setFormik.setFieldValue(name, value);
            }}
            inputFormat="dd/MM/yyyy"
            value={value}
            disabled={disabled}
            disablePast={disablePast}
            onClose={onBlur}
            closeOnSelect={false}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error}
                onChange={() => setFormik.setFieldTouched(name, true, true)}
                onBlur={onBlur} //?? () => setFormik.setFieldTouched(name, true, true)}
                sx={{ backgroundColor: '#ededed', borderRadius: '10px' }}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
      {helperText && (
        <Stack>
          <Typography
            color="red"
            marginLeft="10px"
            sx={{ position: 'absolute', mt: '3px' }}
          >
            {helperText}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Component;
