import { Box, Button, Stack } from '@mui/material';
import { useGetContextQuery } from 'app/context/api/account';
import { PropertyContext } from 'app/context/api/account/types';
import {
  CpiPendingContract,
  GjengesleiePendingContract,
  RegulatedContract
} from 'app/context/api/cpi/types';
import { formatCurrency } from 'app/utils/helpers';
import {
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row
} from 'material-react-table';
import { useMemo } from 'react';

type ColumnOptions = {
  applyFromDate: boolean;
  regulationTypeAvaialable: boolean;
  regulationTypePerformed: boolean;
  lastRegulationDate: boolean;
  productName: boolean;
  clientName: boolean;
  tenantName: boolean;
  propertyAddress: boolean;
  propertyDescription: boolean;
  leaseStart: boolean;
  leaseStop: boolean;
  currentPrice: boolean;
  periodStartDate: boolean;
  periodEndDate: boolean;
  increasePercent: boolean;
  newPrice: boolean;
  increasePrice: boolean;
  document: boolean;
  contractUpdated: boolean;
  oldPrice: boolean;
  lastCustomRegulationDate: boolean;
  lastCpiRegulationDate: boolean;
};

export type PendingAndRegulatedUnion =
  | CpiPendingContract
  | RegulatedContract
  | GjengesleiePendingContract;

export const useContractsColumns = (
  columnsShow: ColumnOptions,
  contracts:
    | CpiPendingContract[]
    | RegulatedContract[]
    | GjengesleiePendingContract[]
    | undefined,
  selectedTemplate?: string | null,
  extraColumns?: MRT_ColumnDef<
    PendingAndRegulatedUnion & { customRegulationAmount: number }
  >[],
  editedContracts?: Record<string, { amount: number; contractId: string }>
) => {
  // Data loading
  const { data: usersAndProperties } = useGetContextQuery();

  const [clients, properties] = useMemo(() => {
    const propertiess: PropertyContext[] = [];
    const clients: { name: string; id: number }[] = [];
    if (!usersAndProperties) return [[], []];
    for (let i = 0; i < usersAndProperties.clients?.length; i++) {
      const client = usersAndProperties.clients[i];
      clients.push({ name: client.clientName, id: client.clientId });
      propertiess.push(...client.properties);
    }
    return [clients, propertiess];
  }, [usersAndProperties]);

  const totals = useMemo<{
    newSum: number;
    todaySum: number;
  }>(() => {
    const sums = { newSum: 0, todaySum: 0 };
    if (contracts && contracts.length > 0) {
      for (let i = 0; i < contracts.length; i++) {
        const contract = contracts[i];
        if ('newPrice' in contract) {
          sums.newSum += contract.newPrice ?? 0;
        }
        if ('currentPrice' in contract) {
          sums.todaySum += contract.currentPrice;
        }
      }
    }
    return {
      newSum: sums.newSum,
      todaySum: sums.todaySum
    };
  }, [contracts]);

  const columns = useMemo<MRT_ColumnDef<PendingAndRegulatedUnion>[]>(() => {
    const columns: MRT_ColumnDef<PendingAndRegulatedUnion>[] = [];

    if (columnsShow.contractUpdated) {
      columns.push({
        id: 'contractUpdated', //simple recommended way to define a column
        header: 'Utført',
        enableSorting: false,
        enableEditing: false,
        enableHiding: true, //disable a feature for this column
        accessorFn: (row) => {
          if ('contractUpdated' in row) {
            const date = row.contractUpdated;
            if (date) {
              return new Date(date);
            }
          }
          return '-';
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => {
          const date = cell.getValue<Date | string>();
          if (typeof date === 'string') {
            return date;
          }
          return date.toLocaleDateString('no-NB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });
        },
        enableColumnFilter: false
      });
    }
    if (columnsShow.applyFromDate) {
      columns.push({
        id: 'applyFromDate', //simple recommended way to define a column
        header: 'Fra',
        enableEditing: false,

        enableHiding: true, //disable a feature for this column
        accessorFn: (row) => new Date(row.applyFromDate),
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) =>
          cell.getValue<Date>()?.toLocaleDateString('no-NB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }),
        enableColumnFilter: false,
        enableSorting: true
      });
    }
    if (columnsShow.lastRegulationDate) {
      columns.push({
        id: 'lastRegulationDate', //simple recommended way to define a column
        header: 'Sist regulert',
        enableEditing: false,
        enableSorting: true,
        enableHiding: true, //disable a feature for this column
        accessorFn: (row) => {
          if ('lastRegulationDate' in row) {
            const date = row.lastRegulationDate;
            if (date) {
              return new Date(date);
            }
          }
          return '-';
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => {
          const date = cell.getValue<Date | string>();
          if (typeof date === 'string') {
            return date;
          }
          return date.toLocaleDateString('no-NB', {
            year: 'numeric',
            month: 'long'
          });
        },
        enableColumnFilter: false
      });
    }
    if (columnsShow.productName) {
      columns.push({
        accessorKey: 'productName', //simple recommended way to define a column
        header: 'Produkt',
        enableEditing: false,
        enableHiding: true, //disable a feature for this column
        enableColumnFilter: false,
        enableSorting: true
      });
    }
    if (columnsShow.tenantName) {
      columns.push({
        accessorKey: 'tenantName', //simple recommended way to define a column
        header: 'Leietaker',
        enableEditing: false,
        enableHiding: true, //disable a feature for this column
        enableColumnFilter: false,
        enableSorting: false
      });
    }
    if (columnsShow.clientName) {
      columns.push({
        accessorKey: 'clientName', //simple recommended way to define a column
        header: 'Klient',
        enableEditing: false,
        enableHiding: true, //disable a feature for this column
        enableColumnFilter: clients?.length > 0,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions:
          clients?.map((client) => ({
            label: client.name,
            value: client.id.toString()
          })) || []
      });
    }
    if (columnsShow.propertyAddress) {
      columns.push({
        accessorKey: 'propertyAddress', //simple recommended way to define a column
        header: 'Eiendom',
        enableEditing: false,
        enableHiding: true, //disable a feature for this column
        enableColumnFilter: properties?.length > 0,
        enableSorting: true,
        filterVariant: 'select',
        filterSelectOptions:
          properties?.map((property) => ({
            label: property.propertyName,
            value: property.propertyId.toString()
          })) || []
      });
    }
    if (columnsShow.propertyDescription) {
      columns.push({
        accessorKey: 'propertyDescription', //simple recommended way to define a column
        header: 'Eiendomsbeskrivelse',
        enableEditing: false,
        enableHiding: true, //disable a feature for this column
        enableColumnFilter: false,
        enableSorting: true
      });
    }
    if (columnsShow.regulationTypeAvaialable) {
      columns.push({
        accessorKey: 'regulationType',
        filterVariant: 'select',
        enableEditing: false,
        enableSorting: false,
        filterSelectOptions: [
          { label: 'Kpi', value: '0' },
          { label: 'Gjengsleie | Kpi', value: '1' }
        ],
        header: 'Reguleringstype',
        size: 200,
        //custom conditional format and styling
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => (
          <>
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue<number>() === 1
                    ? theme.palette.primary.dark
                    : theme.palette.info.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '9ch',
                p: '0.25rem'
              })}
            >
              {cell.getValue<number>() === 0 ? 'Kpi' : 'Kpi / gjengsleie'}
            </Box>
          </>
        )
      });
    }
    if (columnsShow.regulationTypePerformed) {
      columns.push({
        accessorKey: 'regulationType',
        filterVariant: 'select',
        enableEditing: false,
        enableSorting: false,
        filterSelectOptions: [
          { label: 'Kpi', value: '0' },
          { label: 'Gjengsleie', value: '1' }
        ],
        header: 'Reguleringstype',
        size: 200,
        //custom conditional format and styling
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => (
          <>
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue<number>() === 1
                    ? theme.palette.primary.dark
                    : theme.palette.info.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '9ch',
                p: '0.25rem'
              })}
            >
              {cell.getValue<number>() === 0 ? 'Kpi' : 'Gjengsleie'}
            </Box>
          </>
        )
      });
    }
    if (columnsShow.leaseStart) {
      columns.push({
        id: 'leaseStart', //simple recommended way to define a column
        header: 'Kontrakt start',
        enableSorting: true,
        enableEditing: false,
        enableHiding: true, //disable a feature for this column
        accessorFn: (row) => new Date(row.leaseStart),
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) =>
          cell.getValue<Date>()?.toLocaleDateString('no-NB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }),
        enableColumnFilter: false
      });
    }
    if (columnsShow.leaseStop) {
      columns.push({
        id: 'leaseStop', //simple recommended way to define a column
        header: 'Kontrakt stopp',
        enableSorting: true,
        enableHiding: true, //disable a feature for this column
        enableColumnFilter: false,
        enableEditing: false,
        accessorFn: (row) => {
          if (row.leaseStop) {
            return new Date(row.leaseStop).toLocaleDateString('no-NB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            });
          } else {
            return '-';
          }
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => cell.getValue<string>()
      });
    }
    if (columnsShow.oldPrice) {
      columns.push({
        id: 'oldPrice', //simple recommended way to define a column
        header: 'Tidligere sum',
        enableSorting: false,
        enableColumnFilter: false,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: 'right'
        },
        muiTableFooterCellProps: {
          align: 'right'
        },
        muiTableHeadCellProps: {
          align: 'right'
        },
        enableHiding: true, //disable a feature for this column
        accessorFn: (row) => {
          if ('oldPrice' in row) {
            return row.oldPrice;
          }
          return 0;
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => formatCurrency(cell.getValue<number>() ?? 0)
      });
    }
    if (columnsShow.currentPrice) {
      columns.push({
        id: 'currentPrice', //simple recommended way to define a column
        header: 'Sum i dag',
        enableSorting: true,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: 'right'
        },
        muiTableFooterCellProps: {
          align: 'right'
        },
        muiTableHeadCellProps: {
          align: 'right'
        },
        enableHiding: true,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if ('currentPrice' in row) {
            return row.currentPrice;
          }
          return 0;
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => formatCurrency(cell.getValue<number>() ?? 0),
        Footer: () => (
          <Stack>
            <Box
              color={
                totals.todaySum > 0
                  ? 'green'
                  : totals.todaySum === 0
                  ? 'black'
                  : 'red'
              }
            >
              {formatCurrency(totals.todaySum ?? 0)}
            </Box>
          </Stack>
        )
      });
    }
    if (columnsShow.periodStartDate) {
      columns.push({
        id: 'periodStartDate', //simple recommended way to define a column
        header: 'Indeksperiode fra',
        enableSorting: false,
        enableEditing: false,
        enableColumnFilter: false,
        enableHiding: true, //disable a feature for this column
        accessorFn: (row) => {
          if ('periodStartDate' in row) {
            return new Date(row.periodStartDate);
          }
          return '-';
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) =>
          cell
            .getValue<Date>()
            ?.toLocaleDateString('no-NB', { year: 'numeric', month: 'long' })
      });
    }
    if (columnsShow.periodEndDate) {
      columns.push({
        header: 'Indeksperiode til',
        enableHiding: true, //disable a feature for this column
        id: 'periodEndDate',
        enableEditing: false,
        enableSorting: true,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if ('periodEndDate' in row) {
            return new Date(row.periodEndDate);
          }
          return '-';
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) =>
          cell
            .getValue<Date>()
            ?.toLocaleDateString('no-NB', { year: 'numeric', month: 'long' })
      });
    }
    if (extraColumns) {
      //eslint-disable-next-line
      //@ts-ignore
      columns.push(...extraColumns);
    }
    if (columnsShow.increasePercent) {
      columns.push({
        id: 'increasePercent', //simple recommended way to define a column
        header: '%',
        enableSorting: false,
        enableEditing: false,
        enableHiding: true, //disable a feature for this column
        enableColumnFilter: false,
        accessorFn: (row) => {
          if ('increasePercent' in row) {
            return row.increasePercent;
          }
          return 0;
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => (
          <p
            style={{
              color:
                cell.getValue<number>() > 0
                  ? 'green'
                  : cell.getValue<number>() === 0
                  ? 'black'
                  : 'red'
            }}
          >
            {cell.getValue<number>()} %
          </p>
        )
      });
    }
    if (columnsShow.newPrice) {
      columns.push({
        id: 'newPrice', //simple recommended way to define a column
        header: 'Ny sum',
        enableColumnFilter: false,
        enableSorting: false,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: 'right'
        },
        muiTableFooterCellProps: {
          align: 'right'
        },
        muiTableHeadCellProps: {
          align: 'right'
        },
        enableHiding: true, //disable a feature for this column
        accessorFn: (row) => {
          if ('newPrice' in row) {
            return row.newPrice;
          }
          return 0;
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => formatCurrency(cell.getValue<number>() ?? 0),
        Footer: () => (
          <Stack>
            <Box
              color={
                totals.newSum > 0
                  ? 'green'
                  : totals.newSum === 0
                  ? 'black'
                  : 'red'
              }
            >
              {formatCurrency(totals.newSum ?? 0)}
            </Box>
          </Stack>
        )
      });
    }
    if (columnsShow.increasePrice) {
      columns.push({
        id: 'increasePrice', //simple recommended way to define a column
        header: 'Endring',
        enableSorting: false,
        enableColumnFilter: false,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: 'right'
        },
        muiTableFooterCellProps: {
          align: 'right'
        },
        muiTableHeadCellProps: {
          align: 'right'
        },
        accessorFn: (row) => {
          if ('increasePrice' in row) {
            return row.increasePrice;
          }
          return 0;
        },
        Cell: ({
          cell
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
        }) => (
          <p
            style={{
              color:
                cell.getValue<number>() > 0
                  ? 'green'
                  : cell.getValue() === 0
                  ? 'black'
                  : 'red'
            }}
          >
            {formatCurrency(cell.getValue<number>() ?? 0)}
          </p>
        ),
        enableHiding: true //disable a feature for this column
      });
    }
    if (columnsShow.document) {
      columns.push({
        id: 'document', //simple recommended way to define a column
        header: 'Dokument',
        enableSorting: false,
        enableEditing: false,
        enableColumnFilter: false,
        Cell: ({
          cell,
          row
        }: {
          cell: MRT_Cell<PendingAndRegulatedUnion, unknown>;
          row: MRT_Row<PendingAndRegulatedUnion>;
        }) => {
          let documentLink = '';
          if ('cpiLetterId' in row.original) {
            documentLink = `${process.env.REACT_APP_API_URL}v1/cpi/letter?letterId=${row.original.cpiLetterId}`;
          } else if (!('newPrice' in row.original)) {
            const amount = editedContracts?.[row.original.id]?.amount ?? 0;
            documentLink = `${
              process.env.REACT_APP_API_URL
            }v1/cpi/letter/preview/custom?templateId=${
              selectedTemplate ?? 0
            }&contractId=${row.original.id}&amount=${amount}`;
          } else {
            if ('id' in row.original) {
              documentLink = `${
                process.env.REACT_APP_API_URL
              }v1/cpi/letter/preview?templateId=${
                selectedTemplate ?? 0
              }&contractId=${row.original.id}`;
            }
          }
          return (
            <Button
              target="_blank"
              //@ts-ignor
              href={documentLink}
              variant="outlined"
            >
              Dokument
            </Button>
          );
        },
        enableHiding: true //disable a feature for this column
      });
    }

    return columns;
  }, [contracts, columnsShow, clients]);

  return { columns };
};
