import { Stack, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { Dialog } from 'app/components/dialog';
import { useState } from 'react';

import { AddAccountNumber } from '../AddAccountNumber';
import { AddProperty } from '../AddProperty';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Component = ({ isOpen, setIsOpen }: Props): JSX.Element => {
  const [step, setStep] = useState(0);

  const steps = [
    {
      title: 'Legg til informasjon',
      content: <AddAccountNumber callback={() => setStep(step + 1)} />
    },
    {
      title: 'Vil du legge til eiendom?',
      content: (
        <Stack
          width="100%"
          padding={{ xs: 10, md: 20 }}
          sx={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h4">Vil du legge til eiendom nå?</Typography>
          <Stack
            direction="row"
            paddingTop={4}
            sx={{
              justifyContent: 'space-evenly',
              width: '65%'
            }}
          >
            <SecondaryButton filled addIcon onClick={() => setStep(step + 1)}>
              Legg til eiendom
            </SecondaryButton>
            <PrimaryButton
              chevron="right"
              filled
              onClick={() => setIsOpen(false)}
            >
              Se din oversikt
            </PrimaryButton>
          </Stack>
        </Stack>
      )
    },
    {
      title: 'Legg til eiendom',
      content: (
        <>
          <AddProperty
            isPrivate
            callback={() => setStep(step + 1)}
            goBack={() => setStep(step - 1)}
          />
        </>
      )
    },
    {
      title: 'Hva vil du gjøre nå?',
      content: (
        <Stack
          width="100%"
          padding={{ xs: 10, md: 10 }}
          sx={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Stack
            marginBottom={4}
            sx={{
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Stack
              padding={5}
              sx={{
                backgroundColor: '#E7E0DB',
                borderRadius: 3,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography variant="h6">Eiendommen er registrert</Typography>
              <Typography variant="subtitle1">
                Du finner eiendommen i din oversikt
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="h4">Hva vil du gjøre nå?</Typography>
          <Stack
            direction="row"
            paddingTop={4}
            sx={{
              width: '50%',
              justifyContent: 'space-evenly'
            }}
          >
            <SecondaryButton filled addIcon onClick={() => setStep(step + 1)}>
              Legg til eiendom
            </SecondaryButton>
            <PrimaryButton
              chevron="right"
              filled
              onClick={() => setIsOpen(false)}
            >
              Se din oversikt
            </PrimaryButton>
          </Stack>
        </Stack>
      )
    }
  ];

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} forceOpen={step === 0}>
      {steps[step].content}
    </Dialog>
  );
};

export default Component;
