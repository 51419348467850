import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { closeModal } from 'app/context/addModal/addModalSlice';
import { reset } from 'app/features/AddTenant/tenantStepSlide';
import { useAppDispatch } from 'app/hooks';

import { XIcon } from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  removeIcon?: boolean;
  children: JSX.Element;
  forceOpen?: boolean;
  redirect?: string;
  resetOnClose?: boolean;
  fullwidth?: boolean;
}

const Component = ({
  isOpen,
  setIsOpen,
  removeIcon = false,
  children,
  forceOpen = false,
  redirect,
  resetOnClose,
  fullwidth = true
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  function close() {
    if (resetOnClose) {
      dispatch(reset());
    }
    dispatch(closeModal());
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (!matches) {
          if (redirect) {
            window.location.replace(redirect);
          } else {
            !forceOpen && (setIsOpen ? setIsOpen(false) : close());
          }
        }
      }}
      maxWidth="lg"
      scroll="body"
      fullWidth={fullwidth}
      PaperProps={{
        style: {
          minHeight: matches ? '80vh' : undefined
        }
      }}
    >
      {removeIcon || forceOpen ? (
        <></>
      ) : (
        <XIcon
          xmlns="http://www.w3.org/2000/svg"
          width="30.406"
          height="30.406"
          viewBox="0 0 30.406 30.406"
          onClick={() => {
            if (redirect) {
              window.location.replace(redirect);
            } else {
              setIsOpen ? setIsOpen(false) : close();
            }
          }}
        >
          <g
            id="Group_79"
            data-name="Group 79"
            transform="translate(5154.703 16853.203)"
          >
            <rect
              id="Rectangle_350"
              data-name="Rectangle 350"
              width="3"
              height="40"
              rx="1.5"
              transform="translate(-5126.418 -16853.203) rotate(45)"
            />
            <rect
              id="Rectangle_351"
              data-name="Rectangle 351"
              width="3"
              height="40"
              rx="1.5"
              transform="translate(-5154.703 -16851.082) rotate(-45)"
            />
          </g>
        </XIcon>
      )}
      {children}
    </Dialog>
  );
};

export default Component;
