import TableRowsIcon from '@mui/icons-material/TableRows';
import WindowIcon from '@mui/icons-material/Window';
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { white } from 'app/theme/colors';
import { useEffect, useRef, useState } from 'react';

import { DataGridColumns, DataGridRow } from '../types';
import CustomDataGrid from './components/DataGridContainer';

interface Props {
  columns: DataGridColumns;
  rows: DataGridRow[];
  totalRows: number;
  onRowClick?: (row: DataGridRow) => void;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onChangeOrder?: (
    orderBy: keyof DataGridColumns,
    order: 'asc' | 'desc'
  ) => void;
}

export default function Component({
  columns,
  rows,
  totalRows,
  onChangePage,
  onChangeRowsPerPage,
  onRowClick,
  onChangeOrder
}: Props) {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.up('lg'));

  const [view, setView] = useState<'card' | 'table'>('card');

  const totalRowTrigger = useRef(false);

  useEffect(() => {
    if (media) {
      setView('table');
    } else {
      setView('card');
      totalRowTrigger.current = true;
    }
  }, [media]);

  useEffect(() => {
    if (totalRows > 6 && totalRowTrigger.current === false) {
      setView('table');
    }
  }, [totalRows]);

  return (
    <Stack spacing={view === 'card' ? 2 : 0}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent={'end'}
      >
        {view === 'card' ? (
          <IconButton
            sx={{
              backgroundColor: white[100]
            }}
            onClick={() => setView('table')}
          >
            <TableRowsIcon fontSize="small" />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              backgroundColor: white[100]
            }}
            onClick={() => setView('card')}
          >
            <WindowIcon fontSize="small" />
          </IconButton>
        )}
      </Stack>

      <CustomDataGrid
        columns={columns}
        rows={rows}
        totalRows={totalRows}
        view={view}
        onRowClick={onRowClick}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangeOrder={onChangeOrder}
      />
    </Stack>
  );
}
