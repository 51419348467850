import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TableFooter,
  TableSortLabel
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import { white } from 'app/theme/colors';
import { useState } from 'react';

import { DataGridColumns, DataGridContainerProps } from '../../types';
import { Card } from './Card';
import { Pagination } from './pagination/Pagination';

type Order = 'asc' | 'desc';

export default function DataGridContainer({
  view,
  columns,
  rows,
  totalRows,
  onChangePage,
  onChangeRowsPerPage,
  onRowClick,
  onChangeOrder
}: DataGridContainerProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof DataGridColumns>();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    onChangePage && onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onChangeRowsPerPage &&
      onChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const createSortHandler =
    (property: keyof DataGridColumns) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property || 'none');
      onChangeOrder && onChangeOrder(property, isAsc ? 'desc' : 'asc');
    };

  if (view === 'table') {
    return (
      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0 15px'
          }}
        >
          <TableHead>
            <TableRow>
              {Object.keys(columns).map((key) => (
                <TableCell
                  key={key}
                  sx={{
                    fontSize: 18
                  }}
                >
                  {order ? (
                    <TableSortLabel
                      active={orderBy === key}
                      direction={orderBy === key ? order : 'asc'}
                      onClick={createSortHandler(key)}
                    >
                      {columns[key].label}

                      {orderBy === key ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    columns[key].label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  // outline: '5px solid white',
                  backgroundColor: white[100]
                }}
              >
                {Object.keys(columns).map((key, i) => (
                  <TableCell
                    key={key}
                    sx={{
                      fontSize: 14,
                      borderTopLeftRadius: i === 0 ? '15px' : '0px',
                      borderTopRightRadius:
                        i === Object.keys(columns).length - 1 ? '15px' : '0px',
                      borderBottomLeftRadius: i === 0 ? '15px' : '0px',
                      borderBottomRightRadius:
                        i === Object.keys(columns).length - 1 ? '15px' : '0px'
                    }}
                  >
                    {onRowClick && i === Object.keys(columns).length - 1 ? (
                      <Stack direction="row" spacing={1}>
                        <Box>{row[key]}</Box>
                        <IconButton
                          onClick={() => onRowClick && onRowClick(row)}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </Stack>
                    ) : (
                      <Stack>{row[key]}</Stack>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <Pagination
                count={totalRows || rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                columnCount={Object.keys(columns)?.length || 1}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Box aria-label="Table with mobile-optimized cards">
      <Grid container spacing={2}>
        {rows?.map((row) => (
          <Grid item key={row.id} xs={12} sm={6} md={6} lg={4}>
            <Box>
              <Card columns={columns} row={row} onRowClick={onRowClick} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <TableContainer>
        <Table
          aria-label="Container table for pagination option"
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0 15px'
          }}
        >
          <TableFooter>
            <TableRow>
              <Pagination
                count={totalRows || rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}
