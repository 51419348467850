import { Tooltip } from '@mui/material';
import { Stack, Typography } from '@myrent/myrent-ui';
import { SecondaryButton } from 'app/components/button';
import { MaskedInput, TextInput } from 'app/components/input';
import {
  useGetContextQuery,
  useSubmitComplementaryInfoMutation
} from 'app/context/api/account';
import { accountNumberSchema } from 'app/utils/schemas/accountNumber';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as yup from 'yup';

import { InfoIcon } from './styles';

const validationSchema = yup.object({
  accountNumber: accountNumberSchema,
  address: yup.string().required('Skriv inn addresse'),
  city: yup.string().required('Skriv inn poststed'),
  zipCode: yup
    .string()
    .min(4, '4 siffer')
    .max(4, '4 siffer')
    .required('Skriv inn postnummer')
});

interface Props {
  callback?: () => void;
}

const Component = ({ callback }: Props): JSX.Element => {
  const { data: userContext } = useGetContextQuery();
  const [sendForm, { isLoading: isSendingForm }] =
    useSubmitComplementaryInfoMutation();
  const formik = useFormik({
    initialValues: {
      address: '',
      city: '',
      zipCode: '',
      accountNumber: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      async function submitForm() {
        const request = {
          firstName: userContext?.firstName,
          lastName: userContext?.lastName,
          invoiceAccountNumber: values.accountNumber,
          clientAddress: values.address,
          clientCity: values.city,
          clientZipCode: values.zipCode,
          clientVatRegistered: false,
          organizationNumber: null,
          clientType: 2,
          clientName: userContext?.firstName + ' ' + userContext?.lastName
        };

        try {
          const formResult = await sendForm(request).unwrap();
          if (!formResult.success) {
            throw 'Feil i oppdatering av profil.';
          }

          callback && callback();
        } catch (err) {
          console.log(err);
        }
      }
      submitForm();
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack padding={{ xs: 2, md: 5 }} direction={{ xs: 'column', md: 'row' }}>
        <Stack padding={3}>
          <Typography variant="h5" fontWeight="bold">
            Legg til informasjon
          </Typography>
          <Typography>
            Vennligst fyll inn din adresse og kontonummeret hvor du ønsker å
            motta innbetalinger.
          </Typography>
        </Stack>
        <Stack
          width={{ xs: '100%', md: '60%' }}
          alignItems="center"
          justifyContent="center"
          height={{ xs: 'auto', md: '45vh' }}
        >
          <Stack
            spacing={3}
            width={{ xs: '100%', md: 400 }}
            padding={2}
            paddingLeft={5}
            paddingRight={10}
            paddingBottom={5}
            sx={{
              backgroundColor: '#E7E0DB',
              borderRadius: '10px'
            }}
          >
            <TextInput
              id="address"
              name="address"
              label="Adresse"
              labelColor="black"
              labelLeftMargin="0px"
              placeholder="Gatenavn 1"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
            <Stack
              width={{ xs: 165, lg: 200 }}
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
            >
              <TextInput
                id="zipCode"
                name="zipCode"
                label="Postnummer"
                labelColor="black"
                labelLeftMargin="0px"
                placeholder="4 siffer"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                maxLength={4}
              />
              <TextInput
                id="city"
                name="city"
                label="Sted"
                labelColor="black"
                labelLeftMargin="0px"
                placeholder="Skriv inn"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Stack>
            <Stack sx={{ position: 'relative' }} paddingRight={5}>
              <MaskedInput
                mask="#### ## #####"
                fullWidth
                id="accountNumber"
                name="accountNumber"
                label="Kontonummer"
                labelColor="black"
                labelLeftMargin="0px"
                placeholder="11 siffer"
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.accountNumber &&
                  Boolean(formik.errors.accountNumber)
                }
                helperText={
                  formik.touched.accountNumber && formik.errors.accountNumber
                }
                maxLength={13}
              />
              <Tooltip
                title={
                  <Typography>
                    Kontonummeret du ønsker å få utbetalt husleien til.
                  </Typography>
                }
                placement="bottom-end"
                sx={{ position: 'absolute', right: 0, top: 28 }}
              >
                <InfoIcon
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <g
                    id="Group_291"
                    data-name="Group 291"
                    transform="translate(-311 -473)"
                  >
                    <circle
                      id="Ellipse_70"
                      data-name="Ellipse 70"
                      cx="15"
                      cy="15"
                      r="15"
                      transform="translate(311 473)"
                      fill="#003d43"
                    />
                    <text
                      id="i"
                      transform="translate(324 494)"
                      fill="#fff"
                      fontSize="20"
                      fontFamily="Graphie-Bold, Graphie"
                      fontWeight="700"
                    >
                      <tspan x="0" y="0">
                        i
                      </tspan>
                    </text>
                  </g>
                </InfoIcon>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginBottom={5}
      >
        <SecondaryButton filled type="submit">
          Bekreft
        </SecondaryButton>
      </Stack>
    </form>
  );
};

export default Component;
