import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Divider, Stack, Typography } from '@myrent/myrent-ui';
import { Checkbox, Select, TextInput } from 'app/components/input';
import { DatePicker } from 'app/components/input/date-picker';
import { MultiSelectChip } from 'app/components/input/select';
import { LoadingPopup } from 'app/components/loading';
import { DateTracker } from 'app/components/progress-tracker/date-tracker';
import { useInvoiceDatesMutation } from 'app/context/api/invoice';
import { InvoiceDate } from 'app/context/api/invoice/types';
import { useGetProductsMutation } from 'app/context/api/product';
import { Product } from 'app/context/api/product/types';
import { format } from 'date-fns';
import React from 'react';
import { useEffect, useState } from 'react';

interface Props {
  formik: any;
}

interface Option {
  id: number;
  label: string;
  unitPrice: number;
  backgroundColor?: string;
}

export const invoiceIntervalOptions = [
  { label: 'Månedlig', id: 0 },
  { label: 'Kvartalsvis', id: 1 },
  { label: 'Halvårlig', id: 2 },
  { label: 'Årlig', id: 3 }
];

const Component = ({ formik }: Props): JSX.Element => {
  const paymentDayOptions = [
    { label: '1', id: 1 },
    { label: '2', id: 2 },
    { label: '3', id: 3 },
    { label: '4', id: 4 },
    { label: '5', id: 5 },
    { label: '6', id: 6 },
    { label: '7', id: 7 },
    { label: '8', id: 8 },
    { label: '9', id: 9 },
    { label: '10', id: 10 },
    { label: '11', id: 11 },
    { label: '12', id: 12 },
    { label: '13', id: 13 },
    { label: '14', id: 14 },
    { label: '15', id: 15 },
    { label: '16', id: 16 },
    { label: '17', id: 17 },
    { label: '18', id: 18 },
    { label: '19', id: 19 },
    { label: '20', id: 20 },
    { label: '21', id: 21 },
    { label: '22', id: 22 },
    { label: '23', id: 23 },
    { label: '24', id: 24 },
    { label: '25', id: 25 },
    { label: '26', id: 26 },
    { label: '27', id: 27 },
    { label: '28', id: 28 },
    { label: '29', id: 29 },
    { label: '30', id: 30 },
    { label: '31', id: 31 }
  ];

  const [getProducts, { isLoading: isFetchingProducts }] =
    useGetProductsMutation();

  const products = async () => {
    try {
      const result = await getProducts(
        formik.values.propertyInfo.clientId
      ).unwrap();
      formik.setFieldValue('rentInfo.products', result.products);
    } catch (err) {
      console.log(err);
      formik.setFieldValue('rentInfo.products', []);
    }
  };

  useEffect(() => {
    products();
  }, []);

  const getProductIndex = (idList: number[]) => {
    const indexs: number[] = [];
    idList.forEach((id) => {
      const index = formik.values.rentInfo.products.findIndex(
        (p: Option) => p.id == id
      );
      if (index > -1) {
        indexs.push(index);
      }
    });
    return indexs;
  };

  const [getInvoiceDate, { isLoading: isLoadingInvoiceDates }] =
    useInvoiceDatesMutation();
  const [invoiceDates, setInvoiceDates] = useState<InvoiceDate[] | undefined>();

  function mapInvoiceDates() {
    const invoiceDatesText = [
      [
        {
          text: 'Første faktura sendes',
          date: new Date()
        },
        {
          text: 'Første faktura forfaller',
          date: new Date()
        }
      ],
      [
        {
          text: 'Automatisk fakturering starter',
          date: new Date()
        },
        {
          text: 'Automatisk fakturering forfaller',
          date: new Date()
        }
      ],
      [
        {
          text: 'Automatisk fakturering',
          date: new Date()
        },
        {
          text: 'Automatisk fakturering forfaller',
          date: new Date()
        }
      ]
    ];
    invoiceDates?.forEach((invoice, index) => {
      invoiceDatesText[index][0].date = new Date(invoice.sendDate);
      invoiceDatesText[index][1].date = new Date(invoice.dueDate);
    });
    return invoiceDatesText.flatMap((x) => x);
  }

  const isDisabled = () => {
    if (
      !(formik.errors && formik.errors.rentInfo) &&
      formik.touched &&
      formik.touched.rentInfo
    )
      return false;
    return (
      Boolean(formik.errors.rentInfo?.paymentDay) ||
      Boolean(formik.errors.rentInfo?.invoiceInterval) ||
      Boolean(formik.errors.rentInfo?.startDate) ||
      Boolean(formik.errors.rentInfo?.rentAmount) ||
      !formik.touched.rentInfo?.rentAmount ||
      !formik.touched.rentInfo?.startDate ||
      !formik.touched.rentInfo?.invoiceInterval ||
      !formik.touched.rentInfo?.paymentDay
    );
  };

  const fetchInvoiceDates = async () => {
    try {
      if (!(formik.values.rentInfo.rentAmount > 0))
        throw 'Fyll inn riktige verdier';

      const result = await getInvoiceDate({
        monthlyFee: formik.values.rentInfo.rentAmount,
        invoiceDayOfMonth: formik.values.rentInfo.paymentDay,
        invoiceDayOfMonthOffset: 0,
        invoicePeriodType: formik.values.rentInfo.invoiceInterval,
        nextInvoiceDate: format(formik.values.rentInfo.startDate, 'yyyy-MM-dd'),
        useProrate: true
      }).unwrap();
      setInvoiceDates(result);

      formik.setFieldValue('rentInfo.firstInvoiceAmount', result[0].invoiceSum);
    } catch (err) {
      //formik.setFieldValue('rentInfo.autoCalcFirstInvoice', false);
      console.log(err);
    }
  };

  useEffect(() => {
    const chosenProductIndexs = getProductIndex(
      formik.values.rentInfo.extraProduct
    );
    formik.setFieldValue('rentInfo.chosenExtraProduct', chosenProductIndexs);
  }, [formik.values.rentInfo.extraProduct]);

  useEffect(() => {
    if (formik.values.rentInfo.prevRegulationDate instanceof Date) {
      const nextYear = new Date(
        formik.values.rentInfo.prevRegulationDate.getFullYear() + 1,
        formik.values.rentInfo.prevRegulationDate.getMonth(),
        formik.values.rentInfo.prevRegulationDate.getDate()
      );
      if (formik.values.rentInfo.isExternalRegulated) {
        formik.setFieldValue('rentInfo.nextRegulationDate', nextYear);
      } else {
        formik.setFieldValue(
          'rentInfo.nextRegulationDate',
          formik.values.rentInfo.contractstartDate
        );
      }
    }
  }, [
    formik.values.rentInfo.prevRegulationDate,
    formik.values.rentInfo.isExternalRegulated
  ]);

  useEffect(() => {
    if (formik.values.rentInfo.mainProduct == 0) {
      const rentProduct = formik.values?.rentInfo?.products?.find(
        (p: Product) => p.name.toLowerCase().includes('husleie')
      );
      if (rentProduct) {
        formik.setFieldValue('rentInfo.mainProduct', rentProduct.id);
      } else if (formik.values?.rentInfo?.products?.length > 0) {
        formik.setFieldValue(
          'rentInfo.mainProduct',
          formik.values.rentInfo.products[0].id
        );
      }
    }
  }, [formik.values.rentInfo.mainProduct, formik.values.rentInfo.products]);

  const timerRef = React.useRef<number>();

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = window.setTimeout(() => {
      fetchInvoiceDates();
    }, 1000);
  }, [
    formik.values.rentInfo.rentAmount,
    formik.values.rentInfo.autoCalcFirstInvoice,
    formik.values.rentInfo.paymentDay,
    formik.values.rentInfo.invoiceInterval
  ]);

  return (
    <Stack
      p={{ xs: 2, sm: 10 }}
      pr={{ xs: 2, md: 55 }}
      pb={{ xs: 10 }}
      spacing={4}
    >
      <Stack spacing={2}>
        <Typography variant="h1">Husleie</Typography>
        <Divider
          sx={{
            borderBottomWidth: 3,
            backgroundColor: '#242424'
          }}
        />
      </Stack>
      <TextInput
        name={'rentInfo.rentAmount'}
        value={formik.values.rentInfo.rentAmount}
        onChange={formik.handleChange}
        id={'rentInfo.rentAmount'}
        onBlur={formik.handleBlur}
        placeholder="Skriv inn beløp"
        error={
          formik.errors.rentInfo &&
          formik.touched.rentInfo &&
          typeof formik.errors.rentInfo.rentAmount === 'string'
            ? Boolean(formik.errors.rentInfo.rentAmount) &&
              formik.touched.rentInfo.rentAmount
            : undefined
        }
        helperText={
          formik.errors.rentInfo &&
          formik.touched.rentInfo &&
          typeof formik.errors.rentInfo.rentAmount === 'string'
            ? formik.errors.rentInfo.rentAmount
            : undefined
        }
        label={'Leiesum per måned (i NOK)'}
        labelColor={'#242424'}
        labelLeftMargin={'0'}
        labelWeight={'normal'}
        bgColor={'#ededed'}
      />
      <DatePicker
        value={formik.values.rentInfo.startDate}
        setFormik={formik}
        disablePast
        name={'rentInfo.startDate'}
        labelColor="#242424"
        labelWeight="normal"
        labelLeftMargin="0"
        onBlur={fetchInvoiceDates}
        error={
          formik.touched.rentInfo?.startDate &&
          Boolean(formik.errors.rentInfo?.startDate)
        }
        helperText={
          formik.touched.rentInfo?.startDate &&
          formik.errors.rentInfo?.startDate
        }
        label={'Startdato for fakturering (fom)'}
      />

      <Select
        fullWidth={false}
        id="rentInfo.invoiceInterval"
        name="rentInfo.invoiceInterval"
        label="Fakturaintervall"
        placeholder="Velg intervall"
        value={formik.values.rentInfo.invoiceInterval}
        onChange={formik.handleChange}
        options={invoiceIntervalOptions}
        error={
          formik.touched.rentInfo?.invoiceInterval &&
          Boolean(formik.errors.rentInfo?.invoiceInterval)
        }
        helperText={
          formik.touched.rentInfo?.invoiceInterval &&
          formik.errors.rentInfo?.invoiceInterval
        }
        labelColor="#242424"
        onBlur={formik.handleBlur}
        labelLeftMargin={'0'}
        labelWeight={'0'}
        bgColor={'#EDEDED'}
      />
      <Stack>
        <Stack
          direction={'row'}
          justifyContent="flex-start"
          alignItems={'flex-end'}
          spacing={2}
        >
          <Select
            fullWidth={false}
            id="rentInfo.paymentDay"
            name="rentInfo.paymentDay"
            label="Leie betales forskuddsvis hver.."
            placeholder="Velg dag"
            value={formik.values.rentInfo.paymentDay}
            onChange={formik.handleChange}
            options={paymentDayOptions}
            error={
              formik.touched.rentInfo?.paymentDay &&
              Boolean(formik.errors.rentInfo?.paymentDay)
            }
            helperText={
              formik.touched.rentInfo?.paymentDay &&
              formik.errors.rentInfo?.paymentDay
            }
            labelColor="#242424"
            onBlur={formik.handleBlur}
            labelLeftMargin={'0'}
            labelWeight={'0'}
            bgColor={'#EDEDED'}
          />
          <Typography width={300}>..dag i måneden</Typography>
        </Stack>
      </Stack>
      <Select
        fullWidth={false}
        id="rentInfo.mainProduct"
        name="rentInfo.mainProduct"
        label="Hovedprodukt på faktura"
        value={formik.values.rentInfo.mainProduct}
        onChange={formik.handleChange}
        options={
          formik.values?.rentInfo?.products?.length > 0
            ? formik.values.rentInfo.products.map((p: Product) => ({
                ...p,
                label: p.name
              }))
            : []
        }
        error={
          formik.touched.rentInfo?.mainProduct &&
          Boolean(formik.errors.rentInfo?.mainProduct)
        }
        helperText={
          formik.touched.rentInfo?.mainProduct &&
          formik.errors.rentInfo?.mainProduct
        }
        labelColor="#242424"
        onBlur={formik.handleBlur}
        labelLeftMargin={'0'}
        labelWeight={'0'}
        bgColor={'#EDEDED'}
      />
      <MultiSelectChip
        fullWidth={false}
        id="rentInfo.extraProduct"
        name="rentInfo.extraProduct"
        placeholder="Velg ekstraprodukt"
        label="Ekstraprodukt på faktura"
        value={formik.values.rentInfo.extraProduct}
        onChange={formik.handleChange}
        options={
          formik.values?.rentInfo?.products?.length > 0
            ? formik.values.rentInfo.products.map((p: Product) => ({
                ...p,
                id: p.id,
                label: p.name
              }))
            : []
        }
        error={
          formik.touched.rentInfo?.extraProduct &&
          Boolean(formik.errors.rentInfo?.extraProduct)
        }
        helperText={
          formik.touched.rentInfo?.extraProduct &&
          formik.errors.rentInfo?.extraProduct
        }
        labelColor="#242424"
        onBlur={formik.handleBlur}
        labelLeftMargin={'0'}
        labelWeight={'0'}
        bgColor={'#EDEDED'}
      />
      {formik.values.rentInfo.chosenExtraProduct.map((index: number) => (
        <Stack key={index} direction={'row'} alignItems={'flex-end'}>
          <TextInput
            name={`rentInfo.products[${index}].unitPrice`}
            value={formik.values.rentInfo.products[index].unitPrice}
            onChange={formik.handleChange}
            id={`rentInfo.products[${index}].unitPrice`}
            onBlur={formik.handleBlur}
            error={undefined}
            helperText={undefined}
            label={`${formik.values.rentInfo.products[index].name} (i kr)`}
            labelColor={'black'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor={'#ededed'}
          />
        </Stack>
      ))}
      <Box>
        {!isDisabled() && (
          <>
            <Checkbox
              id={'rentInfo.autoCalcFirstInvoice'}
              name={'rentInfo.autoCalcFirstInvoice'}
              value={formik.values.rentInfo.autoCalcFirstInvoice}
              fontSize={'14px'}
              fontColor={'#242424'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={undefined}
              helperText={undefined}
              label={'Beregn beløp for første faktura automatisk'}
            />
            <>
              {formik.values &&
                invoiceDates &&
                formik.values.rentInfo &&
                formik.values.rentInfo.autoCalcFirstInvoice && (
                  <Stack
                    sx={{
                      borderColor: '#000000',
                      border: 1,
                      borderRadius: 1,
                      padding: 5
                    }}
                  >
                    <Typography variant="h6">
                      Utregning første faktura
                    </Typography>
                    <Typography variant="body1">
                      {`Fakturerer ${invoiceDates[0].invoiceSum},- NOK for ${invoiceDates[0].days} dager.`}
                    </Typography>
                    <Typography variant="body2" sx={{ paddingY: 2 }}>
                      {`Etter første faktura er sendt, faktureres det ${invoiceIntervalOptions
                        ?.find(
                          (o) => o.id == formik.values.rentInfo.invoiceInterval
                        )
                        ?.label.toLowerCase()} fra
              ${format(new Date(invoiceDates[1].sendDate), 'dd.MM.yyyy')}.`}
                    </Typography>
                    <DateTracker items={mapInvoiceDates()} active={1} />
                  </Stack>
                )}
            </>
          </>
        )}
      </Box>
      <Stack>
        <Typography variant="h6" fontWeight={'bold'}>
          Har regulering blitt gjort utenom Myrent for dette leieforholdet?
        </Typography>
        <Typography variant="body1">
          Dersom dette er tilfelle må vi vite dato for denne reguleringen, for å
          beregne korrekt tidspunkt for neste regulering.
        </Typography>
        <RadioGroup
          aria-labelledby="rentInfo.isExternalRegulated"
          name="rentInfo.isExternalRegulated"
          value={formik.values.rentInfo.isExternalRegulated}
          onChange={(e) =>
            formik.setFieldValue(
              'rentInfo.isExternalRegulated',
              e.target.value == 'true' ? true : false
            )
          }
        >
          <FormControlLabel
            value={true}
            control={
              <Radio
                sx={{
                  color: '#09444A',
                  '&.Mui-checked': {
                    color: '#09444A'
                  }
                }}
              />
            }
            label="Ja"
          />
          <FormControlLabel
            value={false}
            control={
              <Radio
                sx={{
                  color: '#09444A',
                  '&.Mui-checked': {
                    color: '#09444A'
                  }
                }}
              />
            }
            label="Nei"
          />
        </RadioGroup>
      </Stack>
      {formik.values.rentInfo?.isExternalRegulated && (
        <DatePicker
          value={formik.values.rentInfo.prevRegulationDate}
          setFormik={formik}
          name={'rentInfo.prevRegulationDate'}
          labelColor="#242424"
          labelWeight="normal"
          labelLeftMargin="0"
          error={
            formik.touched.rentInfo?.prevRegulationDate &&
            Boolean(formik.errors.rentInfo?.prevRegulationDate)
          }
          helperText={
            formik.touched.rentInfo?.prevRegulationDate &&
            formik.errors.rentInfo?.prevRegulationDate
          }
          label={'Forrige reguleringsdato'}
        />
      )}
      <DatePicker
        value={formik.values.rentInfo.nextRegulationDate}
        disabled={true}
        setFormik={formik}
        name={'rentInfo.nextRegulationDate'}
        labelColor="#242424"
        labelWeight="normal"
        labelLeftMargin="0"
        error={
          formik.errors.rentInfo &&
          Boolean(formik.errors.rentInfo.nextRegulationDate)
        }
        helperText={
          formik.errors.rentInfo && formik.errors.rentInfo.nextRegulationDate
        }
        label={'Neste reguleringsdato'}
      />
      <LoadingPopup open={isFetchingProducts || isLoadingInvoiceDates} />
    </Stack>
  );
};

export default Component;
