import styled from 'styled-components';

export const IconContainer = styled.div`
  height: 2rem;
  width: 2rem;
  color: white;
`;

export const Button = styled.div`
  color: 'white';
  text-decoration: 'underline';
  text-underline-offset: '5px';
  text-decoration-thickness: '2px';
`;
