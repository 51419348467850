import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';

interface menuState {
  open: boolean;
}

const initialState: menuState = {
  open: true
};

export const toggleMenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggle: (state) => {
      state.open = !state.open;
    }
  }
});

export const { toggle } = toggleMenuSlice.actions;

export const openMenu = (state: RootState) => state.menu.open;

export default toggleMenuSlice.reducer;
