import { apiSlice } from '../index';
import {
  AuthResponse,
  Context,
  LoginResponse,
  WarningListResponse
} from './types';

const accountApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    logout: builder.mutation<Response, void>({
      query: () => ({
        url: 'logout',
        method: 'GET'
      }),
      invalidatesTags: ['Authentication']
    }),
    isAuthenticated: builder.query<AuthResponse, void>({
      query: () => ({
        url: 'Account/IsAuthenticated',
        method: 'POST'
      }),
      providesTags: ['Authentication']
    }),
    addLogin: builder.mutation<LoginResponse, any>({
      query: (payload) => ({
        url: 'portal-login',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      }),
      invalidatesTags: ['Authentication']
    }),
    sendForgotPassword: builder.mutation<any, any>({
      query: (payload) => ({
        url: 'Account/ForgetPassword',
        method: 'POST',
        body: payload
      })
    }),
    register: builder.mutation({
      query: (payload) => ({
        url: 'register',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      }),
      invalidatesTags: ['Authentication']
    }),
    submitComplementaryInfo: builder.mutation({
      query: (body) => ({
        url: 'SimpleWizard/ComplementaryInfoSubmit',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body
      }),
      invalidatesTags: ['Context']
    }),
    getMenu: builder.query({
      query: () => ({
        url: 'v1/Menu'
      })
    }),
    getContext: builder.query<Context, void>({
      query: () => ({
        url: 'v1/User/GetUserContext'
      }),
      providesTags: ['Context']
    }),
    getWarningList: builder.query<WarningListResponse, void>({
      query: () => ({
        url: 'Home/GetWarningList'
      })
    })
  }),

  overrideExisting: false
});

export const {
  useLogoutMutation,
  useIsAuthenticatedQuery,
  useAddLoginMutation,
  useRegisterMutation,
  useSendForgotPasswordMutation,
  useSubmitComplementaryInfoMutation,
  useGetMenuQuery,
  useGetContextQuery,
  useGetWarningListQuery
} = accountApi;
