import EastIcon from '@mui/icons-material/East';
import { Box, Stack, Typography } from '@mui/material';
import { dark, green, netural, white } from 'app/theme/colors';
import { Link } from 'react-router-dom';

import { SecondaryButton } from '../button';
import { ExternalLink } from '../link';

interface Props {
  title: string;
  count: number;
  countColor?: string;
  link?: string | (() => void);
  linkType?: 'external' | 'internal';
  body?: 'body1' | 'body2';
}

const Component = ({
  title,
  count,
  countColor,
  link,
  linkType = 'external',
  body = 'body1'
}: Props): JSX.Element => {
  const content = (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={2}
      sx={{ height: '50px' }}
    >
      <Typography
        fontWeight={'bold'}
        fontSize={'20px'}
        sx={{
          height: '40px',
          minWidth: '50px',
          borderRadius: '25px',
          flexShrink: 0,
          backgroundColor: countColor ?? white[100],
          marginLeft: '10px'
        }}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {count}
      </Typography>
      <Typography variant={body} fontWeight={'normal'} sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
      {link && (
        <EastIcon
          sx={{
            backgroundColor: white[20],
            height: '50px',
            padding: '15px',
            width: '50px',
            borderRadius: '25px',
            cursor: 'pointer'
          }}
        />
      )}
    </Stack>
  );

  if (typeof link === 'string') {
    return (
      <Box
        sx={{
          backgroundColor: white[20],
          height: '50px',
          borderRadius: '100px'
        }}
      >
        {linkType === 'internal' ? (
          <Link to={`${link}`}>{content}</Link>
        ) : (
          <ExternalLink to={`${link}`}>{content}</ExternalLink>
        )}
      </Box>
    );
  }

  if (link) {
    return (
      <Box
        sx={{
          backgroundColor: white[20],
          height: '50px',
          borderRadius: '100px'
        }}
      >
        <Stack onClick={link} sx={{ cursor: 'pointer' }}>
          {content}
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: white[20],
        height: '50px',
        borderRadius: '100px'
      }}
    >
      {content}
    </Box>
  );
};

export default Component;
