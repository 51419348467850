import { Stack, Typography } from '@myrent/myrent-ui';
import { MaskedInput, TextInput } from 'app/components/input';
import { Client } from 'app/context/api/client/types';
import { useState } from 'react';

import { XIcon } from './styles';

interface Props {
  // Issues with using FormikProps here. Could use advice.
  formik: any;
  unitIndex: number;
}

const Component = ({ formik, unitIndex }: Props): JSX.Element => {
  const [expand, setExpand] = useState(true);
  const values = formik.values.subs[unitIndex];
  const touched = formik.touched?.subs && formik.touched?.subs[unitIndex];
  const errors = formik.errors?.subs && formik.errors?.subs[unitIndex];

  return (
    <Stack
      width="100%"
      padding={2}
      spacing={2}
      sx={{
        backgroundColor: '#f7edd2',
        position: 'relative'
      }}
    >
      <XIcon
        xmlns="http://www.w3.org/2000/svg"
        width="30.406"
        height="30.406"
        viewBox="0 0 30.406 30.406"
        onClick={() =>
          formik.setFieldValue(
            'subs',
            formik.values.subs.filter(
              (item: Client, index: number) => index !== unitIndex
            )
          )
        }
      >
        <g
          id="Group_79"
          data-name="Group 79"
          transform="translate(5154.703 16853.203)"
        >
          <rect
            id="Rectangle_350"
            data-name="Rectangle 350"
            width="3"
            height="40"
            rx="1.5"
            transform="translate(-5126.418 -16853.203) rotate(45)"
          />
          <rect
            id="Rectangle_351"
            data-name="Rectangle 351"
            width="3"
            height="40"
            rx="1.5"
            transform="translate(-5154.703 -16851.082) rotate(-45)"
          />
        </g>
      </XIcon>
      <MaskedInput
        mask="### ### ###"
        id={`subs[${unitIndex}].organizationNumber`}
        name={`subs[${unitIndex}].organizationNumber`}
        label="Organisasjonsnummer"
        labelColor="black"
        labelLeftMargin="0px"
        placeholder="12345668"
        value={values.organizationNumber}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          touched?.organizationNumber && Boolean(errors?.organizationNumber)
        }
        helperText={touched?.organizationNumber && errors?.organizationNumber}
      />
      <TextInput
        id={`subs[${unitIndex}].name`}
        name={`subs[${unitIndex}].name`}
        label="Selskapsnavn"
        labelColor="black"
        labelLeftMargin="0px"
        placeholder="Eksempel AS"
        value={values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched?.name && Boolean(errors?.name)}
        helperText={touched?.name && errors?.name}
      />
      {expand && (
        <Stack spacing={2}>
          <TextInput
            id={`subs[${unitIndex}].address`}
            name={`subs[${unitIndex}].address`}
            label="Addresse"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder="Gatenavn 1"
            value={values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={touched?.address && Boolean(errors?.address)}
            helperText={touched?.address && errors?.address}
          />
          <TextInput
            id={`subs[${unitIndex}].zipCode`}
            name={`subs[${unitIndex}].zipCode`}
            label="Postnummer"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder="4 siffer"
            value={values.zipCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={touched?.zipCode && Boolean(errors?.zipCode)}
            helperText={touched?.zipCode && errors?.zipCode}
          />
          <TextInput
            id={`subs[${unitIndex}].city`}
            name={`subs[${unitIndex}].city`}
            label="Sted"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder="Skriv inn"
            value={values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={touched?.city && Boolean(errors?.city)}
            helperText={touched?.city && errors?.city}
          />
          <MaskedInput
            mask="#### ## #####"
            id={`subs[${unitIndex}].invoiceAccountNumber`}
            name={`subs[${unitIndex}].invoiceAccountNumber`}
            label="Fakturakontonummer"
            labelColor="black"
            labelLeftMargin="0px"
            placeholder="11 siffer"
            value={values.invoiceAccountNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              touched?.invoiceAccountNumber &&
              Boolean(errors?.invoiceAccountNumber)
            }
            helperText={
              touched?.invoiceAccountNumber && errors?.invoiceAccountNumber
            }
          />
        </Stack>
      )}

      <Stack direction="row" justifyContent="end">
        <div onClick={() => setExpand(!expand)} style={{ cursor: 'pointer' }}>
          <Typography>
            {!expand ? 'Vis flere detaljer' : 'Vis mindre'}
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
};

export default Component;
