import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  MenuList,
  Typography
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { green } from 'app/theme/colors';
import { useState } from 'react';

import { ContextMenuItem } from '../component';
import { setContext } from '../contextSlice';
import { ContextItem } from '.';

interface Props {
  item: ContextMenuItem;
}

const Component = ({ item }: Props): JSX.Element => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const contextState = useAppSelector((state) => state.context);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <Grid
      container
      sx={{ px: '25px', py: '5px' }}
      overflow={'hidden'}
      rowSpacing={1}
    >
      <Grid item xs={10}>
        <MenuItem
          selected={contextState.context.id == item.id}
          onClick={() =>
            dispatch(
              setContext({ id: item.id, label: item.label, type: item.type })
            )
          }
          sx={{
            '&:hover': {
              backgroundColor: `${green[80]} !important`
            },
            '&.Mui-selected': {
              backgroundColor: green[80]
            },
            '&.Mui-selected:hover': {
              backgroundColor: green[80]
            },
            borderRadius: '10px'
          }}
          key={item.id}
        >
          <Typography>{item.label}</Typography>
        </MenuItem>
      </Grid>
      <Grid item xs={2}>
        <Box display="flex" justifyContent="flex-end" alignItems={'center'}>
          {open ? (
            <ExpandLessIcon
              fontSize="large"
              onClick={handleClick}
              sx={{
                backgroundColor: green[80],
                borderRadius: '25px',
                cursor: 'pointer'
              }}
            />
          ) : (
            <ExpandMoreIcon
              fontSize="large"
              onClick={handleClick}
              sx={{
                backgroundColor: green[80],
                borderRadius: '25px',
                cursor: 'pointer'
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={open}>
          <MenuList>
            {children &&
              children.map((child: ContextMenuItem, index: number) => (
                <ContextItem key={index} item={child} />
              ))}
          </MenuList>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default Component;
