import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

import Select from '.';
import { Container, Label, Option } from './styles';

function Search() {
  return <SearchIcon fontSize="medium" sx={{ marginRight: '10px' }} />;
}

interface Option {
  id: number;
  label: string;
  status: string;
}

interface Props {
  setFormik: any;
  id: string;
  name: string;
  value: Option;
  helperText: string | false | undefined;
  options: Option[];
  label: string;
  labelColor?: string;
  labelWeight?: string;
  labelLeftMargin?: string;
  placeholder?: string;
  error: boolean | undefined;
  bgColor?: string;
}

const Component = ({
  setFormik,
  id,
  name,
  label,
  labelColor = 'white',
  labelWeight = 'bold',
  labelLeftMargin = '10px',
  bgColor = 'white',
  placeholder,
  value,
  helperText,
  error,
  options
}: // sx
Props): JSX.Element => {
  return (
    <Stack spacing="1px">
      {label && (
        <Label htmlFor={name}>
          <Typography
            color={labelColor}
            marginLeft={labelLeftMargin}
            fontWeight={labelWeight}
          >
            {label}
          </Typography>
        </Label>
      )}
      <FormControl size="small">
        <Autocomplete
          popupIcon={<Search />}
          isOptionEqualToValue={(option, value) => {
            if (!value) {
              return true;
            }
            return value && option.id === value.id;
          }}
          id={id}
          size={'small'}
          options={options}
          value={value}
          PaperComponent={({ children }) => (
            <Paper style={{ background: '#ededed' }}>
              <Stack>
                <Divider
                  sx={{
                    border: 0.5,
                    margin: '10px',
                    backgroundColor: '#003D43'
                  }}
                />
                <Typography sx={{ paddingLeft: '20px' }} fontWeight={'bold'}>
                  Velg leieobjekt:
                </Typography>
              </Stack>
              {children}
            </Paper>
          )}
          onChange={(e, value) => {
            setFormik.setFieldValue(name, value);
          }}
          sx={{
            width: '80%',
            '.MuiOutlinedInput-notchedOutline': {
              border: '0 !important'
            },
            [`& .${autocompleteClasses.popupIndicator}`]: {
              transform: 'none'
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={id}
              id={id}
              placeholder={placeholder}
              error={error}
              sx={{ backgroundColor: '#ededed', borderRadius: '10px' }}
            />
          )}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              key={option.id}
              value={option.id}
              sx={{
                backgroundColor: '#ededed'
              }}
            >
              <Grid container>
                <Grid item xs>
                  <Typography>{option.label}</Typography>
                </Grid>
                <Grid item xs={3} textAlign={'right'}>
                  <Typography
                    color={option.status == 'Ledig' ? 'green' : '#242424'}
                  >
                    {option.status}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          )}
        />
      </FormControl>
      {helperText && (
        <Container>
          <Typography
            color="red"
            marginLeft="10px"
            sx={{ position: 'absolute', mt: '3px' }}
          >
            {helperText}
          </Typography>
        </Container>
      )}
    </Stack>
  );
};

export default Component;
