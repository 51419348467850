import { Stack } from '@mui/material';
import { dark } from 'app/theme/colors';

interface Props {
  children: React.ReactNode;
}

export function PillStyledCell({ children }: Props) {
  return (
    <Stack
      color="white"
      sx={{
        backgroundColor: dark[100],
        borderRadius: '15px',
        padding: 1,
        width: 'fit-content'
      }}
    >
      {children}
    </Stack>
  );
}
