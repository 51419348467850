import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useUploadContractMutation } from 'app/context/api/contract';
import { useAppDispatch } from 'app/hooks';
import { useState } from 'react';

import { increment } from '../tenantStepSlide';

interface Props {
  formik: any;
}

const Component = ({ formik }: Props): JSX.Element => {
  const [file, setFile] = useState<Blob | string>('');
  const [fileValue, setFileValue] = useState('');
  const dispatch = useAppDispatch();
  const [uploadContract] = useUploadContractMutation();
  return (
    <Stack
      p={{ xs: 2, sm: 14 }}
      pt={{ sm: 4, md: 14 }}
      pb={{ xs: 10 }}
      spacing={6}
    >
      {formik.values.finalAction == 'existingContract' && (
        <Stack spacing={2}>
          <Typography variant="h4" fontWeight="bold">
            Last opp eksiterende kontrakt
          </Typography>
          <input
            type={'file'}
            accept={'.pdf'}
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              setFileValue(e.target.value);
              if (target.files) {
                const newFile: File = Object.values(target.files)[0];
                setFile(newFile);
              }
            }}
            value={fileValue}
          />
          <button
            type="button"
            onClick={async () => {
              try {
                const data = new FormData();
                data.append('contractId', formik.values.contractId);
                data.append('file', file);
                const result = await uploadContract(data).unwrap();
                dispatch(increment());
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Send inn
          </button>
        </Stack>
      )}
      {formik.values.finalAction == 'digitalContract' && (
        <Stack textAlign={'center'}>
          <Typography variant="h4">Rediger kontrakten</Typography>
          <button type="button" onClick={() => dispatch(increment())}>
            Send inn
          </button>
        </Stack>
      )}
      {formik.values.finalAction == 'noContract' && (
        <Stack spacing={4} padding={8} alignItems={'center'}>
          <CheckCircleOutlineIcon sx={{ fontSize: '150px' }} />
          <Typography variant="h5">Leietaker er nå registert</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Component;
