import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';

export interface SnackBarState {
  details: {
    status?: 'error' | 'warning' | 'info' | 'success';
    message?: string;
  };
  open: boolean;
}

const initialState: SnackBarState = {
  details: {
    status: undefined,
    message: undefined
  },
  open: false
};

export const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      state.details = action.payload;
      state.open = true;
    },
    closeSnackBar: (state) => {
      state.open = false;
      state.details = {
        status: undefined,
        message: undefined
      };
    }
  }
});

export const { openSnackbar, closeSnackBar } = snackBarSlice.actions;

export const getSnackbarState = (state: RootState) => state.snackBar;

export default snackBarSlice.reducer;
