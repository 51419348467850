import { apiSlice } from '../index';
import { ProductResponse } from './types';

const productApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLateFeeProduct: builder.query({
      query: (payload) => ({
        url: 'v1/Product/GetLateFeeProduct',
        params: payload
      })
    }),
    getProducts: builder.mutation<ProductResponse, number>({
      query: (data) => ({
        url: 'v1/Product/GetProducts',
        method: 'GET',
        params: {
          clientId: data
        }
      })
    })
  }),
  overrideExisting: false
});

export const { useGetLateFeeProductQuery, useGetProductsMutation } = productApi;
