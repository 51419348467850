/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import { api } from 'app/infrastructure/api';
import {
  AddExampleRequest,
  ExampleResponse,
  UpdateExampleRequest
} from 'app/infrastructure/api/contract';
import { RootState } from 'app/store';
import { ProblemDocument } from 'http-problem-details';
import { NavigateFunction } from 'react-router-dom';

const SLICE_NAME = 'example';

export interface State {
  example: ExampleResponse | null;
  fetching: boolean;
  adding: boolean;
  updating: boolean;
  error?: string;
}

const initialState: State = {
  example: null,
  fetching: false,
  adding: false,
  updating: false
};

export const updateAndThenNavigate =
  (payload: UpdateExampleRequest, navigate: NavigateFunction) =>
  async (dispatch: any) => {
    dispatch(update(payload))
      .unwrap()
      .then(() => {
        navigate('/Somewhere');
      })
      .catch(() => {
        // handle error here
      });
  };

export const chainDispatch =
  (payload: UpdateExampleRequest) => async (dispatch: any) => {
    await dispatch(update(payload)).unwrap();

    await dispatch(fetch('1')).unwrap();
    await dispatch(fetch('2')).unwrap();
    await dispatch(fetch('3')).unwrap();
  };

export const fetch = createAsyncThunk<
  ExampleResponse | undefined | null,
  string,
  { rejectValue: ProblemDocument }
>(
  `${SLICE_NAME}/fetch`,
  async (id: string, { rejectWithValue }) =>
    await api.example.fetch(id, rejectWithValue)
);

export const add = createAsyncThunk<
  ExampleResponse | undefined,
  AddExampleRequest,
  { rejectValue: ProblemDocument }
>(`${SLICE_NAME}/add`, async (request, { rejectWithValue }) => {
  return await api.example.add(request, rejectWithValue);
});

export const update = createAsyncThunk<
  ExampleResponse | undefined,
  UpdateExampleRequest,
  { rejectValue: ProblemDocument }
>(
  `${SLICE_NAME}/update/`,
  async (request: UpdateExampleRequest, { rejectWithValue }) =>
    await api.example.update(request, rejectWithValue)
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetching = false;
      state.example = action.payload ?? null;
    });
    builder.addCase(add.pending, (state) => {
      state.adding = true;
    });
    builder.addCase(add.fulfilled, (state, action) => {
      state = { ...state, adding: false, ...action.payload };
    });
    builder.addCase(update.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.updating = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.error.message;
    });
  }
});

export const selectEditBranch = (state: RootState): State => state.home;

export const selectExample = (state: RootState): ExampleResponse | null =>
  selectEditBranch(state).example;

export const selectFetching = (state: RootState): boolean =>
  selectEditBranch(state).fetching;

export const selectUpdating = (state: RootState): boolean =>
  selectEditBranch(state).updating;

export const selectAdding = (state: RootState): boolean =>
  selectEditBranch(state).updating;

export default slice.reducer;
