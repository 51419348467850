import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';

import { Container } from './styles';

interface Props {
  title: string;
  valueText: string[];
  values: string[][];
  open: boolean[];
  onClick: () => void;
  index: number;
}

const Component = ({
  title,
  valueText,
  values,
  open,
  onClick,
  index
}: Props): JSX.Element => {
  return (
    <Stack>
      <ListItemButton
        onClick={onClick}
        sx={{
          backgroundColor: '#ededed',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }}
      >
        <ListItemText
          primary={
            <Typography variant={'h5'} fontWeight={'bold'}>
              {title}
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open[index]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Container>
            {values.map((item) =>
              item.map((value: string, listIndex: number) => (
                <Stack padding={2} spacing={1} key={listIndex}>
                  {value?.toString()?.length > 0 &&
                    valueText[listIndex].toString().length > 0 && (
                      <>
                        <Typography variant={'h6'} fontWeight={'bold'}>
                          {valueText[listIndex]}
                        </Typography>
                        <Typography>{value}</Typography>

                        <Divider sx={{ backgroundColor: '#003D43' }} />
                      </>
                    )}
                </Stack>
              ))
            )}
          </Container>
        </List>
      </Collapse>
    </Stack>
  );
};

export default Component;
