import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Alert, AlertTitle, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { white } from 'app/theme/colors';
import { Outlet, useLocation, useNavigate } from 'react-router';

export default function index() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Stack spacing={2}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <h1>Gjengsleie regulering</h1>
          <LogoutOutlinedIcon
            sx={{
              backgroundColor: white[100],
              height: '40px',
              width: '40px',
              padding: '10px',
              borderRadius: '25px',
              cursor: 'pointer',
              boxShadow: '0px 3px 6px lightgray'
            }}
            onClick={() => navigate('/logout')}
          />
        </Stack>
        <Alert severity="info">
          <AlertTitle>Hva er gjengsleie</AlertTitle>
          Gjengsleie er en regulering til markedspris, dette kan gjøres etter 3
          års leieforhold. Og må meldes 6 mnd før. Velg ny leiepris (ved å
          dobbeltklikke på ny sum feltet) og utfør. Brev om regulering blir da
          sendt til leietaker. Koster 59,- pr regulering.
        </Alert>
        <TabContext value={location.pathname}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              aria-label="lab API tabs example"
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab
                value="/customregulation/pending"
                onClick={() => navigate('pending')}
                label="Til regulering"
              />
              <Tab
                value="/customregulation/regulated"
                label="Fullførte regulerings"
                onClick={() => navigate('regulated')}
              />
            </TabList>
          </Box>
        </TabContext>
        <Outlet />
      </Stack>
    </>
  );
}
