import { Alert, Stack } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import {
  useGetPostponedContractsQuery,
  useUnPostponeContractsMutation
} from 'app/context/api/cpi';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { MRT_Localization_NO } from 'material-react-table/locales/no';

import { useContractsColumns } from '../useContractColumns';
import { useTableParams } from '../useTableParms';

export default function CpiPostponed() {
  // Custom hooks
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    queryParams
  } = useTableParams();

  // Data loading
  const {
    data: postponedContracts,
    isError,
    isLoading: isContractsLoading
  } = useGetPostponedContractsQuery(queryParams, {
    refetchOnMountOrArgChange: true
  });

  const [
    unPostponeContracts,
    { isLoading: isUnpostponingContracts, isError: isErrorWithUnPostponing }
  ] = useUnPostponeContractsMutation();

  // Handlers
  const handleMoveToPending = () => {
    unPostponeContracts({
      contractIds: [...Object.keys(rowSelection)].map((selection) =>
        parseInt(selection, 10)
      )
    })
      .unwrap()
      .then(() => {
        table.resetRowSelection(true);
      });
  };

  const { columns } = useContractsColumns(
    {
      applyFromDate: true,
      regulationTypeAvaialable: true,
      regulationTypePerformed: false,
      lastRegulationDate: true,
      productName: true,
      clientName: true,
      tenantName: true,
      propertyAddress: true,
      propertyDescription: true,
      leaseStart: true,
      leaseStop: true,
      currentPrice: true,
      periodStartDate: true,
      periodEndDate: true,
      increasePercent: true,
      newPrice: true,
      increasePrice: true,
      document: true,
      contractUpdated: false,
      oldPrice: false,
      lastCpiRegulationDate: false,
      lastCustomRegulationDate: false
    },
    postponedContracts?.records ?? []
  );

  //Pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data: postponedContracts?.records ?? [],
    manualPagination: true,
    manualFiltering: true,
    rowCount: postponedContracts?.totalCount ?? 0, // total records in backend data -> not in current table view
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableSelectAll: true,
    getRowId: (row) => row.id?.toString() ?? undefined,

    state: {
      columnFilters,
      globalFilter,
      isLoading: isContractsLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isContractsLoading,
      sorting,
      rowSelection,
      isSaving: isUnpostponingContracts
    },
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableStickyFooter: true,
    editDisplayMode: 'cell',
    localization: {
      ...MRT_Localization_NO,
      selectedCountOfRowCountRowsSelected:
        '{selectedCount} av {rowCount} rader valgt',
      noRecordsToDisplay: 'Ingen utsatte regulering'
    }
  });

  if (isError) {
    return (
      <Alert severity="error">
        Kunne ikke laste — sjekk din nettforbindelse!
      </Alert>
    );
  }

  return (
    <Stack spacing={2}>
      {isErrorWithUnPostponing && (
        <Alert severity="error">
          Kunne ikke utsette regulering — sjekk din nettforbindelse!
        </Alert>
      )}
      <MaterialReactTable table={table} />

      {Object.keys(rowSelection).length > 0 && (
        <Stack
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <PrimaryButton
            onClick={handleMoveToPending}
            filled={true}
            type="button"
          >
            Flytt til regulering
          </PrimaryButton>
        </Stack>
      )}
    </Stack>
  );
}
