import { Stack, Typography } from '@mui/material';
import React from 'react';

import { Container, Input, Label } from './textStyles';

interface Props {
  id: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<any>) => void;
  onBlur: (event: React.ChangeEvent<any>) => void;
  error: boolean | undefined;
  helperText: string | false | undefined;
  type?: string;
  placeholder?: string;
  label: string;
  labelColor?: string;
  labelWeight?: string;
  labelLeftMargin?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  bgColor?: string;
  maxLength?: number;
  autofocus?: boolean;
}

const Component = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  error,
  type = 'text',
  placeholder,
  label,
  labelColor = 'white',
  labelLeftMargin = '10px',
  labelWeight = 'bold',
  helperText,
  disabled = false,
  bgColor = 'white',
  fullWidth = false,
  maxLength,
  autofocus = false
}: // sx
Props): JSX.Element => {
  return (
    <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
      {label && (
        <Label htmlFor={name}>
          <Typography
            color={labelColor}
            marginLeft={labelLeftMargin}
            fontWeight={labelWeight}
          >
            {label}
          </Typography>
        </Label>
      )}
      <Input
        id={id}
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoFocus={autofocus}
        type={type}
        placeholder={placeholder}
        error={error}
        bgColor={bgColor}
        maxLength={maxLength ? maxLength : undefined}
      />
      {helperText && (
        <Container>
          <Typography
            color="red"
            marginLeft="10px"
            sx={{ position: 'absolute', mt: '3px' }}
          >
            {helperText}
          </Typography>
        </Container>
      )}
    </Stack>
  );
};

export default Component;
