import { ContractRequest } from 'app/context/api/cpi/types';
import {
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_RowSelectionState,
  type MRT_SortingState
} from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';

export function useTableParams() {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const queryParams = useMemo<ContractRequest>((): ContractRequest => {
    const clientId: string = columnFilters.find((f) => f.id === 'clientName')
      ?.value as string;
    const regulationType: string = columnFilters.find(
      (f) => f.id === 'regulationType'
    )?.value as string;
    const propertyId: string = columnFilters.find(
      (f) => f.id === 'propertyAddress'
    )?.value as string;

    return {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      ...(sorting[0] ? { orderBy: sorting[0].id } : {}),
      ...(sorting[0] ? { direction: sorting[0].desc ? 1 : 0 } : {}),
      ...(globalFilter ? { search: globalFilter } : {}),
      ...(clientId ? { clientId: parseInt(clientId, 10) } : {}),
      ...(regulationType
        ? {
            regulationType: regulationType === '0' ? 0 : 1
          }
        : {}),
      ...(propertyId ? { propertyId: parseInt(propertyId, 10) } : {})
    };
  }, [pagination, sorting, globalFilter, columnFilters]);

  return {
    pagination,
    setPagination,
    sorting,
    setSorting,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    queryParams
  };
}
