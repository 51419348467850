import { Divider, Stack, Typography } from '@myrent/myrent-ui';
import { Checkbox } from 'app/components/input';

interface Props {
  formik: any;
}

const Component = ({ formik }: Props): JSX.Element => {
  return (
    <Stack p={{ xs: 2, sm: 10 }} pr={{ md: 55 }} pb={{ xs: 10 }} spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Administrativt</Typography>
        <Divider
          sx={{
            borderBottomWidth: 3,
            backgroundColor: '#242424'
          }}
        />
      </Stack>
      <Checkbox
        id={'administrativeInfo.sendTenantProfile'}
        name={'administrativeInfo.sendTenantProfile'}
        value={formik.values.administrativeInfo.sendTenantProfile}
        fontSize={'14px'}
        fontColor={'#242424'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={undefined}
        helperText={undefined}
        label={'Send leietakerprofil'}
      />
      <Checkbox
        id={'administrativeInfo.createAqusitionForm'}
        name={'administrativeInfo.createAqusitionForm'}
        value={formik.values.administrativeInfo.createAqusitionForm}
        fontSize={'14px'}
        fontColor={'#242424'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={undefined}
        helperText={undefined}
        label={'Opprett overtakelsesprotokoll'}
      />
      <Checkbox
        id={'administrativeInfo.sendInvoiceBeforeSignedContract'}
        name={'administrativeInfo.sendInvoiceBeforeSignedContract'}
        value={formik.values.administrativeInfo.sendInvoiceBeforeSignedContract}
        fontSize={'14px'}
        fontColor={'#242424'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={undefined}
        helperText={undefined}
        label={'Send faktura før kontrakten er signert'}
      />
    </Stack>
  );
};

export default Component;
