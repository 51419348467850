import { Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  subtitle: string;
  contactinfo: string;
  children: JSX.Element | string;
}

export function TitleCard({
  title,
  subtitle,
  contactinfo,
  children
}: Props): JSX.Element {
  return (
    <Stack
      spacing={1}
      sx={{
        backgroundColor: '#074A50',
        padding: 5,
        paddingLeft: 10,
        width: '100%'
      }}
    >
      <Typography variant="h5" color="white" fontWeight={'bold'}>
        {title}
      </Typography>
      <Typography variant="subtitle1" color={'gold'}>
        {subtitle}
      </Typography>
      <Typography color="white" variant="body1">
        {children}
      </Typography>
      <Typography color="white" variant="body2">
        {contactinfo}
      </Typography>
    </Stack>
  );
}
