import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';
import { useEffect, useState } from 'react';

import { useClient } from '../client';

export interface Auth {
  loginRequest?: { scopes: string[] };
  msalInstance?: PublicClientApplication;
}

// for use in non-hookable environments
export const Auth = {
  auth: {} as Auth
};

export const useAuthentication = () => {
  const [loginRequest, setLoginRequest] = useState<{ scopes: string[] }>();
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
  const { client } = useClient();

  useEffect(() => {
    if (client === undefined) {
      return;
    }

    const lr = {
      scopes: [
        ...['openid', 'email', 'profile', 'offline_access'],
        ...(client.scopes ?? [])
      ]
    };
    Auth.auth.loginRequest = lr;
    setLoginRequest(lr);

    const msal = new PublicClientApplication({
      auth: {
        clientId: client.clientId ?? '',
        authority:
          'https://login.microsoftonline.com/aa041025-ad66-491f-b117-929458960abd',
        redirectUri: window.location.protocol + '//' + window.location.host
      }
    });

    //use the first account found - may be use case dependent
    const accounts = msal.getAllAccounts();
    if (accounts.length > 0) {
      msal.setActiveAccount(accounts[0]);
    }

    //set active account on login
    msal.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msal.setActiveAccount(account);
      }
    });
    Auth.auth.msalInstance = msal;
    setMsalInstance(msal);
  }, [client]);

  return { client, loginRequest, msalInstance };
};

export default useAuthentication;
