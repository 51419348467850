import { ContextMenuItem } from '../component';
import MultiLevel from './MultiLevel';
import SingleLevel from './SingleLevel';

interface Props {
  item: ContextMenuItem;
}

function hasChildren(item: ContextMenuItem) {
  const { items: children } = item;
  if (children === undefined) {
    return false;
  }
  if (children.length === 0) {
    return false;
  }
  return true;
}

const Component = ({ item }: Props): JSX.Element => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

export default Component;
