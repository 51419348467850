import { apiSlice } from '../index';
import {
  ClientOutstandingInvoiceStatisticsResponse,
  InvoiceDate,
  KeyNumbersRequest,
  KeyNumbersResponse,
  OutstandingInvoiceStatisticsResponse,
  PaymentsByInvoiceDueStatusRequest,
  PaymentsByInvoiceDueStatusResponse,
  PaymentsRequest,
  PaymentsResponse
} from './types';

const invoiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    invoiceDates: builder.mutation<InvoiceDate[], any>({
      query: (payload) => ({
        url: 'v1/Invoice/Timeline',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        params: {
          invoiceCount: 3
        },
        body: payload
      })
    }),
    getOutstandingInvoiceStatistics: builder.query<
      OutstandingInvoiceStatisticsResponse,
      any
    >({
      query: () => ({
        url: 'v1/Invoice/GetOutstandingInvoicesStatistics'
      })
    }),
    getClientOutstandingInvoiceStatistics: builder.query<
      ClientOutstandingInvoiceStatisticsResponse[],
      any
    >({
      query: () => ({
        url: 'v1/Invoice/GetClientsOutstandingInvoicesStatistic?take=5'
      })
    }),
    getKeyNumbers: builder.query<KeyNumbersResponse, KeyNumbersRequest>({
      query: (data) => ({
        url: 'Home/GetInvoiceOverview',
        params: data
      })
    }),
    getPaymentsByInvoiceDueStatus: builder.query<
      PaymentsByInvoiceDueStatusResponse[],
      PaymentsByInvoiceDueStatusRequest
    >({
      query: (data) => ({
        url: 'v1/Payment/GetPaymentsByInvoiceDueStatus',
        params: data
      })
    }),
    getPayments: builder.query<PaymentsResponse[], PaymentsRequest>({
      query: (data) => ({
        url: 'v1/Payment/GetPayments',
        params: data
      })
    })
  }),
  overrideExisting: false
});

export const {
  useInvoiceDatesMutation,
  useGetOutstandingInvoiceStatisticsQuery,
  useGetClientOutstandingInvoiceStatisticsQuery,
  useGetKeyNumbersQuery,
  useGetPaymentsByInvoiceDueStatusQuery,
  useGetPaymentsQuery
} = invoiceApi;
