import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PrimaryButton } from 'app/components/button';
import { Editor } from 'app/components/editor';
import { Select } from 'app/components/input';
import {
  useGetContractTemplateListQuery,
  useGetContractTemplateQuery,
  useGetPreviewPdfMutation
} from 'app/context/api/contract';
import { ContractTemplateList } from 'app/context/api/contract/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';

import { setContractTemplateId, setError } from '../contractPathSlice';

interface Props {
  editorContent: string;
  sendContent: (content: string) => void;
}

const Component = ({ editorContent, sendContent }: Props): JSX.Element => {
  const contract = useAppSelector((state) => state.contract);
  const dispatch = useAppDispatch();

  const [getPreviewPdf] = useGetPreviewPdfMutation();

  const [skip, setSkip] = useState(true);
  const [initContent, setInitContent] = useState('');

  const { data: templateList } = useGetContractTemplateListQuery();
  const { data: contractTemplate } = useGetContractTemplateQuery(
    {
      standardType: '1',
      contractTemplateId: contract.contractTemplateId.toString()
    },
    { skip }
  );

  useEffect(() => {
    if (contractTemplate && editorContent.length == 0) {
      sendContent(contractTemplate.htmlContent);
      setInitContent(contractTemplate.htmlContent);
    } else {
      setInitContent(editorContent);
    }
  }, [contractTemplate]);

  useEffect(() => {
    if (templateList) {
      if (contract.contractTemplateId == -1) {
        dispatch(setContractTemplateId(templateList[0].id));
      }
      setSkip(false);
    }
  }, [templateList]);

  const contractTemplateOptions = (templateList: ContractTemplateList[]) => {
    return templateList.map((template) => ({
      id: template.id,
      label: template.title
    }));
  };

  const chooseTemplate = (id: number) => {
    if (templateList) {
      const chosenTemplate = templateList.find((t) => t.id == id);
      if (chosenTemplate) {
        dispatch(setContractTemplateId(chosenTemplate.id));
        sendContent('');
      }
    }
  };

  const previewPdf = async () => {
    try {
      const result = await getPreviewPdf({
        html: editorContent,
        contractId: contract.contractId,
        download: 1
      }).unwrap();
    } catch (err) {
      dispatch(setError(err));
    }
  };

  return (
    <>
      {contractTemplate && templateList && (
        <Stack spacing={4}>
          <Typography>
            Se gjennom kontrakten, gjør endringer om du ønsker, last ned og
            signer kontrakt.
          </Typography>
          <Stack
            spacing={3}
            alignItems={{ sm: 'flex-start', md: 'flex-end' }}
            justifyContent={{ sm: 'normal', md: 'space-between' }}
            direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
          >
            <Box display={'inline-flex'}>
              <Select
                id={'select'}
                name={'select'}
                options={contractTemplateOptions(templateList)}
                value={contract.contractTemplateId}
                onChange={(e) => chooseTemplate(e.target.value)}
                onBlur={(e) => void e}
                error={undefined}
                helperText={undefined}
                label={'Kontraktsmal'}
                labelColor={'black'}
                bgColor={'#F6F6F6'}
              />
            </Box>
            <Box alignItems="flex-end">
              <PrimaryButton
                filled
                onClick={() => previewPdf()}
                icon={<VisibilityIcon />}
              >
                Forhåndsvis PDF
              </PrimaryButton>
            </Box>
          </Stack>
          <Editor initContent={initContent} sendContent={sendContent} />
        </Stack>
      )}
    </>
  );
};

export default Component;
