import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';

export interface addModalState {
  modal?:
    | 'contract'
    | 'client'
    | 'property'
    | 'tenant'
    | 'onboardPrivate'
    | 'onboardCompany'
    | 'accountnumber'
    | 'upsell'
    | 'depositAccount'
    | 'fikenAccounts';
}

const initialState: addModalState = {
  modal: undefined
};

export const addModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.modal = action.payload;
    },
    closeModal: (state) => {
      state.modal = undefined;
    }
  }
});

export const { openModal, closeModal } = addModalSlice.actions;

export const getModal = (state: RootState) => state.modal;

export default addModalSlice.reducer;
