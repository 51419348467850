import EastIcon from '@mui/icons-material/East';
import { Box, Stack, Typography } from '@mui/material';
import { white } from 'app/theme/colors';

import { ExternalLink } from '../link';

interface Props {
  title: string;
  value: number;
  link?: string | (() => void);
}

const Numberdisplay = ({ title, value, link }: Props): JSX.Element => {
  const content = (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ height: '50px' }}
      //sx={{ flexGrow: 1 }}
    >
      <Stack spacing={-0.5} sx={{ height: '50px' }} justifyContent={'center'}>
        <Typography variant="body1">
          {value
            .toLocaleString(undefined, { maximumFractionDigits: 0 })
            .replaceAll(',', ' ') + ',-'}
        </Typography>
        <Typography variant="body2">{title}</Typography>
      </Stack>
      <Stack justifyContent={'center'}>
        <EastIcon
          sx={{
            backgroundColor: white[20],
            height: '50px',
            padding: '15px',
            width: '50px',
            borderRadius: '25px',
            cursor: 'pointer'
          }}
        />
      </Stack>
    </Stack>
  );

  if (typeof link === 'string') {
    return (
      <Box
        sx={{
          backgroundColor: white[20],
          height: '50px',
          borderRadius: '100px',
          pl: '25px'
        }}
      >
        <ExternalLink to={link}>{content}</ExternalLink>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: white[20],
        height: '50px',
        borderRadius: '100px',
        pl: '25px'
      }}
    >
      <Stack onClick={link} sx={{ cursor: 'pointer' }}>
        {content}
      </Stack>
    </Box>
  );
};

export default Numberdisplay;
