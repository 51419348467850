import { Heading3, styled } from '@myrent/myrent-ui';

export const DialogHeading = styled(Heading3)`
  margin-bottom: 0;
`;

export const Container = styled('div')`
  position: relative;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  width: 90vw;
  min-height: 85vh;
  border-radius: 25px;
  padding: 25px;
`;

export const BackgroundColor = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #f5f5f0;
`;
