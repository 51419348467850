import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Select, TextInput } from 'app/components/input';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ChangeEvent, useEffect, useState } from 'react';

import {
  initTenantBankIdTypes,
  setCurrentUserBankIdType,
  setEmailMsg,
  setTenantBankIdType
} from '../contractPathSlice';

interface Tenant {
  userId: number;
  name: string;
}

interface Props {
  tenants: Tenant[];
}

const Component = (): JSX.Element => {
  const contract = useAppSelector((state) => state.contract);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      initTenantBankIdTypes(
        contract.tenants.map((tenant) => ({
          userId: tenant.id,
          bankIdType: 1
        }))
      )
    );
  }, []);

  return (
    <Stack spacing={4} paddingY={5}>
      <TextInput
        id={'email-msg'}
        name={'email-msg'}
        value={contract.sign.EmailMessage}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          dispatch(setEmailMsg(e.target.value))
        }
        onBlur={(e: ChangeEvent<HTMLInputElement>) =>
          dispatch(setEmailMsg(e.target.value))
        }
        error={undefined}
        helperText={undefined}
        label={'Melding som vises i e-post til leietaker'}
        labelColor={'black'}
        bgColor={'#F6F6F6'}
      />

      <Stack spacing={2}>
        <Typography variant="h3">{`Utleier: ${contract.clientName}`}</Typography>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={contract.sign.currentUserBankIdType}
          onChange={(e) => dispatch(setCurrentUserBankIdType(e.target.value))}
        >
          <FormControlLabel
            value={1}
            control={<Radio />}
            label="Norsk BankID"
          />
        </RadioGroup>
      </Stack>
      <>
        {contract.sign.tenantBankIdTypes.length > 0 &&
          contract.tenants.map((tenant, index) => {
            return (
              <Stack key={index} spacing={2}>
                <Typography variant="h3">{`Leietaker: ${tenant.name}`}</Typography>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={contract.sign.tenantBankIdTypes[index].bankIdType}
                  onChange={(e) => {
                    dispatch(
                      setTenantBankIdType({
                        index: index,
                        bankIdType: parseInt(e.target.value)
                      })
                    );
                  }}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Norsk BankID"
                  />
                </RadioGroup>
              </Stack>
            );
          })}
      </>
    </Stack>
  );
};

export default Component;
