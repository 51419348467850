import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { MenuButton } from 'app/components/button';
import { ContextSwitcher } from 'app/components/context-switcher';
import { Dialog } from 'app/components/dialog';
import { ExternalLink } from 'app/components/link';
import { closeModal, openModal } from 'app/context/addModal/addModalSlice';
import {
  useGetContextQuery,
  useGetWarningListQuery
} from 'app/context/api/account';
import { WarningListResponse } from 'app/context/api/account/types';
import { useGetComplementaryInfoNeededQuery } from 'app/context/api/client';
import { AddAccountNumber } from 'app/features/AddAccountNumber';
import { AddTenant } from 'app/features/AddTenant';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { white } from 'app/theme/colors';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { AddCompany } from '../AddCompany';
import { AddContract } from '../AddContract';
import { OnboardCompany } from '../OnboardCompany';
import { OnboardPrivate } from '../OnboardPrivate';
import { ImportantWarnings } from './ImportantWarnings';
import { KeyNumbers } from './KeyNumbers';
import { ObjectSummary } from './ObjectSummary';
import { OutstandingInvoices } from './OutstandingInvoices';
import { Warnings } from './Warnings';

const isImportantWarnings = (data: WarningListResponse | undefined) => {
  if (data) {
    return (
      data.CountUnsentDebtCollections > 0 ||
      data.CountNavGuaranteeExpires > 0 ||
      data.PreviousMonthUnpaidInvoicesCount > 0
    );
  }
  return false;
};

const isWarnings = (data: WarningListResponse | undefined) => {
  if (data) {
    return (
      data.CountUnSignedContracts > 0 ||
      data.CountOverpaidInvoices > 0 ||
      data.CountOcrFailedFiles > 0 ||
      data.ContractsToRegulateCount > 0 ||
      data.CountContractsInSigningProcess > 0 ||
      (data.InvoicesToDeliverCount != null &&
        data.InvoicesToDeliverCount > 0) ||
      data.CountUnsentInvoices > 0 ||
      (data.DuplicateInvoicesCount != null && data.DuplicateInvoicesCount > 0)
    );
  }
  return false;
};

const Component = (): JSX.Element => {
  const modal = useAppSelector((state) => state.modal.modal);
  const dispatch = useAppDispatch();
  const [addAccountNumber, setAddAccountNumber] = useState(false);
  const [onboardCompany, setOnboardCompany] = useState(false);
  const [onboardPrivate, setOnboardPrivate] = useState(false);

  const { data: complementaryInfoNeededResponse } =
    useGetComplementaryInfoNeededQuery();

  const { data: userContext } = useGetContextQuery();
  const navigate = useNavigate();

  const { data: warningList } = useGetWarningListQuery();

  useEffect(() => {
    if (
      complementaryInfoNeededResponse?.complementaryInfoNeeded &&
      userContext?.clients[0]
    ) {
      if (userContext.clients[0].clientType === 1) {
        setOnboardCompany(true);
      } else {
        setOnboardPrivate(true);
      }
    }
  }, [complementaryInfoNeededResponse, userContext]);

  return (
    <>
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={2} alignItems={'flex-end'}>
          <Typography variant="h1">Oversikt</Typography>
          <Typography variant="h3">for</Typography>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          display={'flex'}
          flexWrap={'wrap'}
          spacing={4}
        >
          <ContextSwitcher />
          <Stack spacing={2} direction={'row'}>
            {userContext?.clients.length == 0 && (
              <ExternalLink to="Account/SignInAsUser">
                <SupervisorAccountOutlinedIcon
                  sx={{
                    backgroundColor: white[100],
                    height: '40px',
                    width: '40px',
                    padding: '10px',
                    borderRadius: '25px',
                    boxShadow: '0px 3px 6px lightgray'
                  }}
                />
              </ExternalLink>
            )}
            <LogoutOutlinedIcon
              sx={{
                backgroundColor: white[100],
                height: '40px',
                width: '40px',
                padding: '10px',
                borderRadius: '25px',
                cursor: 'pointer',
                boxShadow: '0px 3px 6px lightgray'
              }}
              onClick={() => navigate('/logout')}
            />
          </Stack>
        </Stack>
        <Divider sx={{ height: '2px' }} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Stack spacing={2}>
                <KeyNumbers />
                <Box sx={{ flexGrow: 1 }}>
                  <Grid spacing={2} container>
                    <Grid item xs={12} lg={isWarnings(warningList) ? 6 : 12}>
                      <ImportantWarnings
                        isWarnings={isImportantWarnings(warningList)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={isImportantWarnings(warningList) ? 6 : 12}
                    >
                      <Warnings isWarnings={isWarnings(warningList)} />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={2}>
                <OutstandingInvoices />
                <ObjectSummary />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0
        }}
      >
        <MenuButton
          filled
          choices={[
            {
              text: 'Legg til leietaker',
              onClick: () => dispatch(openModal('tenant'))
            },
            {
              text: 'Legg til eiendom',
              onClick: () => dispatch(openModal('property'))
            },
            {
              text: 'Legg til selskap',
              onClick: () => dispatch(openModal('client')),
              hidden: userContext?.clients[0]?.clientType === 2
            }
          ]}
        >
          Legg til
        </MenuButton>
      </Box>
      <div>
        <OnboardPrivate isOpen={onboardPrivate} setIsOpen={setOnboardPrivate} />
        <OnboardCompany isOpen={onboardCompany} setIsOpen={setOnboardCompany} />
        <Dialog isOpen={addAccountNumber} setIsOpen={setAddAccountNumber}>
          <AddAccountNumber callback={() => setAddAccountNumber(false)} />
        </Dialog>
        <Dialog
          isOpen={modal === 'tenant'}
          removeIcon={true}
          resetOnClose={true}
        >
          <AddTenant />
        </Dialog>
        <Dialog isOpen={modal === 'client'}>
          <AddCompany callback={() => dispatch(closeModal)} />
        </Dialog>
        <Dialog isOpen={modal === 'contract'}>
          <AddContract />
        </Dialog>
      </div>
    </>
  );
};

export default Component;
