import './phone.css';

import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import PhoneInput from 'react-phone-input-2';

interface Props {
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: (event: React.ChangeEvent<any>) => void;
  error: boolean | undefined;
  helperText: string | false | undefined;
  placeholder?: string;
  label: string;
  fullWidth?: boolean;
  labelColor?: string;
  labelWeight?: string;
  bgColor?: string;
  labelLeftMargin?: string;
  disabled?: boolean;
  autoFocus?: boolean;
}

const Component = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  label,
  helperText,
  fullWidth = false,
  labelColor = 'white',
  labelWeight = 'bold',
  bgColor = 'white',
  labelLeftMargin = '10px',
  disabled = false,
  autoFocus = false
}: // sx
Props): JSX.Element => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
        {label && (
          <label htmlFor={name}>
            <Typography
              color={labelColor}
              marginLeft={labelLeftMargin}
              fontWeight={labelWeight}
            >
              {label}
            </Typography>
          </label>
        )}
        <PhoneInput
          searchPlaceholder="Søk etter land"
          country={'no'}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          inputStyle={{
            height: mobileScreen ? '35px' : '40px',
            fontSize: mobileScreen ? '12px' : '16px',
            borderRadius: '10px',
            backgroundColor: bgColor,
            boxShadow: error ? '0 0 0 2px #ff0000' : 'none'
          }}
          buttonStyle={{
            borderRadius: '10px 0 0 10px'
          }}
          enableSearch
          inputProps={{
            id: 'phone',
            name: 'phone',
            autoFocus: autoFocus
          }}
          disabled={disabled}
        />
        {helperText && (
          <div>
            <Typography
              color="red"
              marginLeft="10px"
              sx={{ position: 'absolute', mt: '3px' }}
            >
              {helperText}
            </Typography>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default Component;
