import { Hidden } from '@mui/material';
import { Divider, Stack, Typography } from '@myrent/myrent-ui';
import { Select } from 'app/components/input';
import { SearchSelect } from 'app/components/input/select';
import { LoadingPopup } from 'app/components/loading';
import { useGetClientsQuery } from 'app/context/api/client';
import { useGetLateFeeProductQuery } from 'app/context/api/product';
import {
  useGetAvaliableObjectsQuery,
  useGetObjectsQuery
} from 'app/context/api/property';
import { useEffect, useState } from 'react';

interface Props {
  formik: any;
}

interface Client {
  id: number;
  name: string;
}

interface AvaliableObject {
  objectId: number;
}

const Component = ({ formik }: Props): JSX.Element => {
  const [skip, setSkip] = useState(true);
  const { data: clients, isLoading: isClientsLoading } = useGetClientsQuery();

  const { data: objects, isLoading: isObjectsLoading } = useGetObjectsQuery(
    { clientId: formik.values.propertyInfo.clientId },
    { skip }
  );
  const { data: avaliableObjects, isLoading: isAvaliableObjectsLoading } =
    useGetAvaliableObjectsQuery(
      { clientId: formik.values.propertyInfo.clientId },
      { skip }
    );
  const { data: lateFeeProduct } = useGetLateFeeProductQuery(
    { clientId: formik.values.propertyInfo.clientId },
    { skip }
  );

  useEffect(() => {
    if (formik.values.propertyInfo.clientId > 0) {
      setSkip(false);
      if (
        objects &&
        !objects.contractObjects.includes(formik.values.propertyInfo.object)
      ) {
        formik.values.propertyInfo.object = null;
      }
    } else {
      setSkip(true);
    }
  }, [formik.values.propertyInfo.clientId]);

  useEffect(() => {
    if (lateFeeProduct) {
      formik.values.propertyInfo.lateFeeProductId = lateFeeProduct.id;
    }
  }, [lateFeeProduct]);

  return (
    <Stack p={{ xs: 2, sm: 10 }} pr={{ md: 55 }} pb={{ xs: 10 }} spacing={8}>
      <Stack spacing={2}>
        <Typography variant="h1">Leieobjektet</Typography>
        <Divider
          sx={{
            borderBottomWidth: 3,
            backgroundColor: '#242424'
          }}
        />
        <Hidden mdUp>
          <Typography>
            Fyll ut de nødvendige detaljene og kom i gang med ditt leieforhold.
          </Typography>
        </Hidden>
      </Stack>
      <Select
        fullWidth={false}
        id="propertyInfo-clientId"
        name="propertyInfo.clientId"
        label="Utleier"
        placeholder="Velg utleier"
        value={formik.values.propertyInfo.clientId}
        onChange={formik.handleChange}
        options={clients?.map((c: Client) => ({ ...c, label: c.name })) || []}
        error={
          formik.touched.propertyInfo?.clientId &&
          Boolean(formik.errors.propertyInfo?.clientId)
        }
        helperText={
          formik.touched.propertyInfo?.clientId &&
          formik.errors.propertyInfo?.clientId
        }
        labelColor="#003D43"
        onBlur={formik.handleBlur}
        labelLeftMargin={'0'}
        labelWeight={'0'}
        bgColor={'#EDEDED'}
      />
      <SearchSelect
        setFormik={formik}
        id="propertyInfo.object"
        name="propertyInfo.object"
        label="Leieobjekt"
        placeholder="Søk og velg leieobjekt"
        value={formik.values.propertyInfo.object}
        options={
          objects && avaliableObjects
            ? objects.contractObjects?.map((o) => ({
                id: o.id,
                label: `${o.propertyAddress}${
                  o.description ? ` - ${o.description}` : ''
                }`,
                status: avaliableObjects.find(
                  (ao: AvaliableObject) => ao.objectId === o.id
                )
                  ? 'Ledig'
                  : 'Utleid'
              }))
            : []
        }
        error={
          formik.touched.propertyInfo?.object &&
          Boolean(formik.errors.propertyInfo?.object)
        }
        helperText={
          formik.touched.propertyInfo?.object &&
          formik.errors.propertyInfo?.object
        }
        labelColor="#003D43"
        labelLeftMargin={'0'}
        labelWeight={'0'}
        bgColor={'#EDEDED'}
      />
      <LoadingPopup
        open={isClientsLoading || isObjectsLoading || isAvaliableObjectsLoading}
      />
    </Stack>
  );
};

export default Component;
