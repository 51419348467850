import { Box, Grid, Stack, Typography } from '@myrent/myrent-ui';
import { green, netural, white } from 'app/theme/colors';

interface Props {
  mainIcon: JSX.Element;
  iconColor?: string;
  title: string;
  select?: JSX.Element;
  firstColumn: JSX.Element;
  secondColumn?: JSX.Element;
}

const Component = ({
  mainIcon,
  iconColor,
  title,
  select,
  firstColumn,
  secondColumn
}: Props): JSX.Element => {
  return (
    <Box
      sx={{
        padding: '25px',
        borderRadius: '25px',
        backgroundColor: white[100]
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          display={'flex'}
          flexWrap={'wrap'}
        >
          <Box
            sx={{
              backgroundColor: iconColor ?? netural[60],
              height: '40px',
              width: '40px',
              padding: '10px',
              borderRadius: '25px'
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {mainIcon}
          </Box>
          {select ?? <></>}
        </Stack>
        <Box>
          <Grid container direction={'row'} spacing={4}>
            <Grid item xs={12} sm={12} md={secondColumn ? 6 : 12}>
              <Typography variant="h3" pb={2}>
                {title}
              </Typography>
              {firstColumn}
            </Grid>
            {secondColumn && (
              <Grid item xs={12} sm={12} md={6}>
                {secondColumn}
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default Component;
