import { Stack, Typography } from '@myrent/myrent-ui';
import { PrimaryButton } from 'app/components/button';
import { TextInput } from 'app/components/input';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  firstName: yup.string().required('Fornavn er påkrevd'),
  lastName: yup.string().required('Etternavn er påkrevd')
});

interface Schema {
  firstName: string;
  lastName: string;
}

interface Props {
  callback?: (request: Schema) => void;
}

const Component = ({ callback }: Props): JSX.Element => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      async function submitForm() {
        const request = {
          ...values
        };

        try {
          callback && callback(request);
        } catch (err) {
          console.log(err);
        }
      }
      submitForm();
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack padding={{ xs: 2, md: 5 }} direction={{ xs: 'column', md: 'row' }}>
        <Stack padding={3}>
          <Typography variant="h5" fontWeight="bold">
            Informasjon om meg
          </Typography>
          <Typography>Vennligst fyll inn informasjon om deg selv.</Typography>
        </Stack>
        <Stack
          width={{ xs: '100%', md: '60%' }}
          alignItems="center"
          justifyContent="center"
          height={{ xs: 'auto', md: '45vh' }}
        >
          <Stack
            spacing={3}
            width={{ xs: '100%', md: 400 }}
            padding={2}
            paddingLeft={4}
            paddingRight={10}
            paddingBottom={5}
            sx={{
              backgroundColor: '#E7E0DB',
              borderRadius: '10px',
              position: 'relative'
            }}
          >
            <Stack direction="row" sx={{ width: 200 }} spacing={2}>
              <TextInput
                id="firstName"
                name="firstName"
                label="Fornavn"
                labelColor="black"
                placeholder="Ola"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                maxLength={13}
              />
              <TextInput
                id="lastName"
                name="lastName"
                label="Etternavn"
                labelColor="black"
                placeholder="Normann"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                maxLength={13}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: '#D9CBC0',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 1
        }}
      >
        <Stack direction="row" spacing={2} marginLeft={20}>
          <Typography>Steg 1 av 3</Typography>
        </Stack>
        <Stack direction="row" spacing={2} marginRight={20}>
          <PrimaryButton filled chevron="right" type="submit">
            Neste steg
          </PrimaryButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default Component;
