import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { globalReducer } from 'app/context/global';
import tenantStepReducer from 'app/features/AddTenant/tenantStepSlide';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import contextSlice from './components/context-switcher/contextSlice';
import snackBarSlice from './components/snackbar/snackBarSlice';
import addModalSlice from './context/addModal/addModalSlice';
import { apiSlice } from './context/api';
import userSlice from './context/user/userSlice';
import contractSlice from './features/AddContract/contractPathSlice';
import toggleMenuSlice from './features/Menu/toggleMenuSlice';
import { homeReducer } from './screens/home';

const persistConfig = {
  version: 1,
  storage
};

const persistedGlobalReducer = persistReducer(
  { ...persistConfig, key: 'global' },
  globalReducer
);

export const store = configureStore({
  reducer: {
    global: persistedGlobalReducer,
    home: homeReducer,
    user: userSlice,
    tenantStep: tenantStepReducer,
    menu: toggleMenuSlice,
    context: contextSlice,
    contract: contractSlice,
    modal: addModalSlice,
    snackBar: snackBarSlice,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(apiSlice.middleware)
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
