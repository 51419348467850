import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Container,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography
} from '@mui/material';
import { green } from 'app/theme/colors';
import { useRef, useState } from 'react';

import { PrimaryButton } from '.';

interface Button {
  onClick: () => void;
  text: string;
  hidden?: boolean;
}

interface Props {
  children: string;
  filled?: boolean;
  addIcon?: boolean;
  chevron?: string;
  disabled?: boolean;
  choices: Button[];
  type?: 'button' | 'submit' | 'reset';
}

const Component = ({
  children,
  filled = true,
  choices
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>();
  const divRef = useRef<HTMLDivElement>(null);

  function handleClose() {
    setAnchorEl(undefined);
  }

  function handleClick() {
    setAnchorEl(divRef.current);
  }

  const open = Boolean(anchorEl);

  return (
    <Container
      sx={{ width: '200px', position: 'fixed', bottom: 30, right: 30 }}
    >
      <div ref={divRef}>
        <PrimaryButton
          fullWidth
          addIcon
          filled={filled}
          onClick={() => handleClick()}
        >
          {children}
        </PrimaryButton>
        <Menu
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={() => handleClose()}
          PaperProps={{
            style: {
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '0px 1px 2px gray'
            }
          }}
        >
          <MenuList>
            <Stack spacing={2}>
              {choices
                .filter((item) => !item.hidden)
                .map((button, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      '&:hover': {
                        backgroundColor: `${green[80]} !important`
                      },
                      borderRadius: '10px'
                    }}
                    onClick={() => {
                      handleClose();
                      button.onClick();
                    }}
                  >
                    <Stack
                      spacing={6}
                      sx={{
                        width: '100%'
                      }}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Typography>{button.text}</Typography>
                      <AddIcon fontSize="small" />
                    </Stack>
                  </MenuItem>
                ))}
              <Stack spacing={2} alignItems={'flex-end'}>
                <MenuItem
                  sx={{
                    '&:hover': {
                      backgroundColor: `${green[80]} !important`
                    },
                    borderRadius: '10px'
                  }}
                  onClick={() => handleClose()}
                >
                  <Stack spacing={2} direction={'row'} alignItems={'center'}>
                    <Typography>Avbryt</Typography>
                    <CloseIcon fontSize="small" />
                  </Stack>
                </MenuItem>
              </Stack>
            </Stack>
          </MenuList>
        </Menu>
      </div>
    </Container>
  );
};

export default Component;
