import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { TextInput } from 'app/components/input';
import { LoadingPopup } from 'app/components/loading';
import { useSendForgotPasswordMutation } from 'app/context/api/account';
import { dark, white } from 'app/theme/colors';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router';

const Component = (): JSX.Element => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [isSent, setIsSent] = useState(false);

  const [send, { isLoading }] = useSendForgotPasswordMutation();

  async function sendUserName() {
    try {
      const formResult = await send({ userName: username }).unwrap();
      if (!formResult.success) throw 'Feil i sending av brukernavn';
    } catch (error) {
      console.log(error);
    }
    setIsSent(true);
  }

  return (
    <Box margin={5}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: '90vh',
          backgroundColor: dark[100],
          borderRadius: 25
        }}
      >
        <Grid item xs={3} paddingX={{ xs: 5, sm: 15, md: 35, lg: 55 }}>
          <Stack spacing={3}>
            <Typography variant="h1" color={white[20]}>
              Glemt passord?
            </Typography>
            {isSent ? (
              <Typography color={white[20]}>Nytt passord er sendt.</Typography>
            ) : (
              <>
                <Typography color={white[20]}>
                  {`Skriv inn ditt brukernavn, som regel din e-post eller ditt fornavn
              etterfulgt av et nummmer, og klikk på "Send aktivering på nytt".`}
                </Typography>
                <Typography color={white[20]}>
                  Dersom dette ikke fungerer, kontakt kundeservice@myrent.no
                  eller chat med oss.
                </Typography>
                <TextInput
                  id={'username'}
                  name={'username'}
                  placeholder={'For eksempel navn@epost.no'}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onBlur={(e) => setUsername(e.target.value)}
                  error={undefined}
                  helperText={undefined}
                  label={'Brukernavn'}
                  labelColor={white[20]}
                />
              </>
            )}
            <Stack direction={'row-reverse'}>
              <Box>
                {isSent ? (
                  <PrimaryButton filled onClick={() => navigate('/login')}>
                    Til innlogging
                  </PrimaryButton>
                ) : (
                  <PrimaryButton filled onClick={() => sendUserName()}>
                    Send aktivering på nytt
                  </PrimaryButton>
                )}
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <LoadingPopup open={isLoading} />
    </Box>
  );
};

export default Component;
