import { Divider, Grid, MenuItem, MenuList, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { green } from 'app/theme/colors';

import { ContextMenuItem } from '../component';
import { setContext } from '../contextSlice';

interface Props {
  item: ContextMenuItem;
}

const Component = ({ item }: Props): JSX.Element => {
  const contextState = useAppSelector((state) => state.context);
  const dispatch = useAppDispatch();
  return (
    <Grid
      container
      sx={{ px: '25px', py: '5px' }}
      overflow={'hidden'}
      rowSpacing={1}
    >
      <Grid item xs={12}>
        <MenuItem
          selected={contextState.context.id == item.id}
          onClick={() =>
            dispatch(
              setContext({ id: item.id, label: item.label, type: item.type })
            )
          }
          sx={{
            '&:hover': {
              backgroundColor: `${green[80]} !important`
            },
            '&.Mui-selected': {
              backgroundColor: green[80]
            },
            '&.Mui-selected:hover': {
              backgroundColor: green[80]
            },
            borderRadius: '10px'
          }}
          key={item.id}
        >
          <Typography>{item.label}</Typography>
        </MenuItem>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Component;
