export const dark = {
  100: '#003D43',
  80: '#336368',
  60: '#668A8E',
  40: '#99B1B3',
  20: '#CCD8D9'
};

export const red = {
  100: '#EC8874',
  80: '#EF9F8F',
  60: '#F3B7AB',
  40: '#F7CFC7',
  20: '#FBE7E3'
};

export const green = {
  100: '#59E8B2',
  80: '#7AECC1',
  60: '#98F1D1',
  40: '#BCF6E0',
  20: '#DEFBF0'
};

export const netural = {
  100: '#DBC5B6',
  80: '#E2D0C4',
  60: '#E9DCD3',
  40: '#F1E8E2',
  20: '#F8F4F0'
};

export const white = {
  100: '#F0F0F0',
  80: '#F3F3F3',
  60: '#F6F6F6',
  40: '#F9F9F9',
  20: '#FCFCFC'
};
