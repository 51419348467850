import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { Divider, Stack, Typography } from '@mui/material';
import { SecondaryButton } from 'app/components/button';
import { Select } from 'app/components/input';
import { openModal } from 'app/context/addModal/addModalSlice';
import {
  useCompaniesQuery,
  useCompanyConnectMutation,
  useCompanyDisconnectMutation,
  useCompanyMappingMutation,
  useCompanyMappingsQuery
} from 'app/context/api/integrations/fiken';
import {
  FikenCompany,
  FikenCompanyMapping
} from 'app/context/api/integrations/fiken/types';
import { useAppDispatch } from 'app/hooks';
import { green } from 'app/theme/colors';
import { useCallback, useState } from 'react';

import { AccountMappingDialog } from './AccountMappingDialog';
import { TextPill } from './TextPill';

const Component = (): JSX.Element => {
  const { data: fikenCompanies } = useCompaniesQuery();
  const { data: fikenMappings, refetch } = useCompanyMappingsQuery();
  const [mutate] = useCompanyMappingMutation();
  const [connect] = useCompanyConnectMutation();
  const [disconnect] = useCompanyDisconnectMutation();

  console.log(fikenMappings);

  const [selectedClient, setSelectedClient] =
    useState<FikenCompanyMapping | null>(null);

  const dispatch = useAppDispatch();

  const handleConnectCompanies = useCallback(
    async (clientId: number, slug: string) => {
      await mutate({
        clientId,
        slug
      });
      refetch();
    },
    []
  );

  return (
    <>
      {selectedClient && (
        <AccountMappingDialog
          clientId={selectedClient?.clientId}
          name={selectedClient?.name}
        />
      )}
      <Stack marginX={2} marginY={2} justifyContent="center" spacing={2}>
        <Typography variant="h1">Fiken</Typography>
        <Divider sx={{ height: '2px' }} />
        <Stack spacing={2}>
          <Typography variant="h3">Dine selskaper</Typography>
          <Typography variant="body1">
            Koble sammen selskapene i MyRent og Fiken.
          </Typography>
          <Stack spacing={2} paddingTop={4}>
            {fikenMappings?.map((x: FikenCompanyMapping, idx: number) => (
              <Stack
                key={x.clientId}
                direction="row"
                spacing={2}
                alignItems={'center'}
              >
                <Stack width={'30%'} position={'relative'}>
                  {idx === 0 && (
                    <Typography
                      variant="h4"
                      sx={{
                        top: '-40px',
                        position: 'absolute'
                      }}
                    >
                      MyRent
                    </Typography>
                  )}
                  <TextPill title={x.name} />
                </Stack>
                <Stack
                  direction="row"
                  alignItems={'center'}
                  justifyContent={'start'}
                  width="5%"
                >
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: 40
                    }}
                  />
                </Stack>
                <Stack width="30%" position={'relative'}>
                  {idx === 0 && (
                    <Typography
                      variant="h4"
                      sx={{
                        top: '-40px',
                        position: 'absolute'
                      }}
                    >
                      Fiken
                    </Typography>
                  )}
                  <Select
                    id="fikenKonto"
                    name="fikenKonto"
                    value={x.slug || ''}
                    onChange={(e) =>
                      handleConnectCompanies(x.clientId, e.target.value)
                    }
                    error={false}
                    bgColor={green[100]}
                    options={fikenCompanies?.map((x: FikenCompany) => {
                      return {
                        id: x.slug,
                        label: x.name
                      };
                    })}
                    fullWidth
                  />
                </Stack>
                <SettingsIcon
                  onClick={() => {
                    setSelectedClient(x);
                    dispatch(openModal('fikenAccounts'));
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                />
                <Stack>
                  <SecondaryButton
                    filled={x.connected}
                    disabled={!x.readyToConnect}
                    onClick={() => {
                      if (x.connected) {
                        disconnect(x.clientId);
                      } else {
                        connect(x.clientId);
                      }
                    }}
                  >
                    {x.connected ? 'Deaktiver' : 'Aktiver'}
                  </SecondaryButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export { Component as Overview };
