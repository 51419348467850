import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Dialog, Grid, Stack, Typography } from '@mui/material';
import { dark, green, netural, white } from 'app/theme/colors';

import { SecondaryButton } from '../button';

interface LineProps {
  text: string;
  color?: string;
  value?: string;
  valueDesc?: string;
}

const Line = ({ text, color, value, valueDesc }: LineProps): JSX.Element => (
  <Stack spacing={1} direction={'row'} alignItems={'center'}>
    {color ? (
      <Box
        sx={{
          height: '25px',
          width: '25px',
          backgroundColor: color,
          borderRadius: '20px'
        }}
        alignContent={'center'}
        justifyContent={'center'}
      >
        <CheckIcon />
      </Box>
    ) : (
      <RadioButtonUncheckedIcon style={{ color: dark[40] }} />
    )}
    <Typography
      sx={{ flexGrow: 1 }}
      variant={'body2'}
      color={color ? 'black' : dark[60]}
    >
      {text}
    </Typography>
    {value && valueDesc && (
      <Stack justifyContent={'flex-start'} alignItems={'flex-end'}>
        <Typography variant={'body1'} fontWeight={'bold'}>
          {value}
        </Typography>
        <Typography variant={'body2'} fontWeight={'normal'}>
          {valueDesc}
        </Typography>
      </Stack>
    )}
  </Stack>
);

const Component = (): JSX.Element => {
  return (
    <Stack spacing={3} padding={{ xs: 1, sm: 5, md: 10, lg: 15 }}>
      <Typography variant="h1">Abonnoment</Typography>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <Stack
              spacing={4}
              sx={{ backgroundColor: white[100], borderRadius: 5, padding: 4 }}
            >
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="h2">Fri</Typography>
                <Typography variant="h2">0,- /mnd</Typography>
              </Stack>
              <Stack spacing={2}>
                <Line
                  color={green[60]}
                  text={
                    'Administrasjon av selskap, eiendommmer, enheter og leietakere'
                  }
                />
                <Line
                  color={green[60]}
                  text={'Digital kontrakt med BankID-signering'}
                />
                <Line
                  color={green[60]}
                  text={'Automatisk fakturering av leie via epost'}
                />
                <Line color={green[60]} text={'Innflytningsprotokoll'} />
                <Line
                  color={dark[20]}
                  text={'Kredittsjekk (tilgjengelig for kjøp)'}
                />
                <Line text={'KPI-regulering'} />
                <Line
                  text={
                    'Valgfri faktureringsmetode (via SMS, eFaktura/Vipps, EHF, post eller AvtaleGiro)'
                  }
                />
                <Line text={'4-18 varsel (utkastelse)'} />
                <Line text={'Egen profilering på fakturaer'} />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h3">Pris per antall</Typography>
                <Line
                  color={dark[20]}
                  text={'Kredittsjekk'}
                  value={'49,-'}
                  valueDesc={'pris per sjekk'}
                />
                <Line
                  color={dark[20]}
                  text={'Depositumskonto'}
                  value={'149,-'}
                  valueDesc={'pris per konto'}
                />
                <Line
                  text={'Inkasso'}
                  value={'-'}
                  valueDesc={'ikke tilgjengelig'}
                />
                <Line
                  text={'Regnskapsintegrasjon'}
                  value={'-'}
                  valueDesc={'ikke tilgjengelig'}
                />
                <Line
                  text={'Ekstra bruker(e) i MyRent'}
                  value={'-'}
                  valueDesc={'ikke tilgjengelig'}
                />
              </Stack>
              <Typography
                textAlign="center"
                variant="body2"
                fontWeight={'normal'}
              >
                Alle priser inkluderer mva.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack
              spacing={4}
              sx={{ backgroundColor: white[100], borderRadius: 5, padding: 4 }}
            >
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="h2">Pluss</Typography>
                <Stack>
                  <Typography variant="h2">39,- /mnd</Typography>
                  <Typography
                    variant="body2"
                    fontWeight={'normal'}
                    textAlign={'end'}
                  >
                    per leietaker/måned
                  </Typography>
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Line
                  color={green[60]}
                  text={
                    'Administrasjon av selskap, eiendommmer, enheter og leietakere'
                  }
                />
                <Line
                  color={green[60]}
                  text={'Digital kontrakt med BankID-signering'}
                />
                <Line
                  color={green[60]}
                  text={'Automatisk fakturering av leie via epost'}
                />
                <Line color={green[60]} text={'Innflytningsprotokoll'} />
                <Line
                  color={green[60]}
                  text={'Kredittsjekk (tilgjengelig for kjøp)'}
                />
                <Line color={green[60]} text={'KPI-regulering'} />
                <Line
                  color={green[60]}
                  text={
                    'Valgfri faktureringsmetode (via SMS, eFaktura/Vipps, EHF, post eller AvtaleGiro)'
                  }
                />
                <Line color={green[60]} text={'4-18 varsel (utkastelse)'} />
                <Line
                  color={green[60]}
                  text={'Egen profilering på fakturaer'}
                />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h3">Pris per antall</Typography>
                <Line
                  color={dark[20]}
                  text={'Depositumskonto'}
                  value={'149,-'}
                  valueDesc={'pris per konto'}
                />
                <Line
                  color={dark[20]}
                  text={'Inkasso'}
                  value={'49,-'}
                  valueDesc={'pris per sak'}
                />
                <Line
                  color={dark[20]}
                  text={'Regnskapsintegrasjon'}
                  value={'299,-'}
                  valueDesc={'pris per selskap'}
                />
                <Line
                  color={dark[20]}
                  text={'Ekstra bruker(e) i MyRent'}
                  value={'299,-'}
                  valueDesc={'pris per bruker'}
                />
              </Stack>
              <Typography
                textAlign="center"
                variant="body2"
                fontWeight={'normal'}
              >
                Alle priser inkluderer mva.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack
              spacing={3}
              sx={{ backgroundColor: netural[60], borderRadius: 5, padding: 4 }}
            >
              <Typography variant="h3">Mer enn 50 enheter?</Typography>
              <Typography>
                Ta kontakt for å få skreddersydd abonnoment til ditt behov.
              </Typography>
              <Box textAlign="center">
                <SecondaryButton filled>+47 21492077</SecondaryButton>
              </Box>
              <Box textAlign="center">
                <SecondaryButton filled>
                  {'kundeservice@myrent.no'}
                </SecondaryButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Component;
