import { Box, Stack, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'app/components/button';
import { Editor } from 'app/components/editor';
import { TextInput } from 'app/components/input';
import Select from 'app/components/input/select';
import { ExternalLink } from 'app/components/link';
import { LoadingPopup } from 'app/components/loading';
import {
  useCreateContractTemplateMutation,
  useGetContractTemplateQuery,
  useUpdateContractTemplateMutation
} from 'app/context/api/contract';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const contractTypeOptions = [
  {
    label: 'Kontraktsmaler',
    id: 1
  },
  {
    label: 'KPI Maler',
    id: 2
  },
  {
    label: 'Gjengs leie',
    id: 3
  },
  {
    label: 'Mislighold',
    id: 4
  }
];

const Component = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [skip, setSkip] = useState<boolean>(true);
  const newTemplate: boolean = useLocation().pathname.includes('Create');
  const [contractType, setContractType] = useState(contractTypeOptions[0].id);
  const [createTemplate, { isLoading: isCreateTemplate }] =
    useCreateContractTemplateMutation();
  const [updateTemplate, { isLoading: isUpdateTemplate }] =
    useUpdateContractTemplateMutation();

  const contractTemplateId = searchParams.get('contractTemplateId');
  const standardType = searchParams.get('standardType');

  const { data, isLoading } = useGetContractTemplateQuery(
    {
      contractTemplateId: contractTemplateId ?? '0',
      standardType: standardType ?? '0'
    },
    { skip }
  );

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setContent(data.htmlContent);
    }
  }, [data]);

  const sendContent = (content: string) => {
    setContent(content);
  };

  async function send() {
    try {
      let formResult;
      if (newTemplate) {
        formResult = await createTemplate({
          title: title,
          htmlContent: content,
          type: contractType,
          isActive: true
        }).unwrap();
      } else if (data) {
        formResult = await updateTemplate({
          id: data.id,
          title: title,
          htmlContent: content,
          type: data.type,
          isActive: data.isActive
        }).unwrap();
      }

      if (formResult.message) {
        throw formResult.message;
      }
      window.location.href =
        process.env.REACT_APP_API_URL + 'ContractTemplate/Index';
    } catch (err) {
      setError('Kunne ikke lagre kontraktmal');
    }
  }

  useEffect(() => {
    if (standardType && contractTemplateId) {
      setSkip(false);
    }
  }, [standardType, contractTemplateId]);

  return (
    <Stack
      spacing={4}
      padding={{ xs: 0, md: 10, xl: 15 }}
      display="flex"
      sx={{ backgroundColor: 'white', borderRadius: 5 }}
    >
      <Stack direction={'row'} pb={2} justifyContent={'space-between'}>
        <Typography variant="h1">
          {newTemplate ? 'Lag ny mal' : 'Rediger mal'}
        </Typography>
        <ExternalLink to="ContractTemplate/Index">
          <SecondaryButton chevron={'left'}>Tilbake</SecondaryButton>
        </ExternalLink>
      </Stack>
      <Box>
        <TextInput
          id={'title'}
          name={'Title'}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={(e) => setTitle(e.target.value)}
          error={undefined}
          helperText={undefined}
          label={'Tittel'}
          labelColor={'black'}
          bgColor={'#F6F6F6'}
        />
      </Box>
      {newTemplate && (
        <Box>
          <Select
            id={'type'}
            name={'type'}
            value={contractType}
            options={contractTypeOptions}
            onChange={(e) => setContractType(e.target.value)}
            onBlur={(e) => setContractType(e.target.value)}
            error={undefined}
            helperText={undefined}
            label={'Kontraktstype'}
            labelColor={'black'}
            bgColor={'#F6F6F6'}
          />
        </Box>
      )}
      {newTemplate && <Editor sendContent={sendContent} />}
      {data && (
        <Editor initContent={data.htmlContent} sendContent={sendContent} />
      )}
      {error && <Typography sx={{ color: 'red' }}>{error}</Typography>}
      <Box>
        <PrimaryButton filled fullWidth={false} onClick={() => send()}>
          Lagre
        </PrimaryButton>
      </Box>
      <LoadingPopup open={isLoading || isCreateTemplate || isUpdateTemplate} />
    </Stack>
  );
};

export default Component;
