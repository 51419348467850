import styled from 'styled-components';

export const XIcon = styled('svg')`
  position: absolute;
  top: 3vh;
  right: 2vw;
  width: 25px;
  cursor: pointer;
`;

export const CustomXIcon = styled('svg')`
  position: fixed;
  top: 2vh;
  right: 16vw;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: #ededed;
  padding: 5px;
  border-radius: 25px;
  z-index: 1;
`;
