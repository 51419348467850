import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';

interface tenantState {
  step: number;
}

const initialState: tenantState = {
  step: 0
};

export const tenantStepSlice = createSlice({
  name: 'tenantStep',
  initialState,
  reducers: {
    increment: (state) => {
      state.step += 1;
    },
    decrement: (state) => {
      state.step -= 1;
    },
    reset: (state) => {
      state.step = 0;
    }
  }
});

export const { increment, decrement, reset } = tenantStepSlice.actions;

export const selectStep = (state: RootState) => state.tenantStep.step;

export default tenantStepSlice.reducer;
