import { styled } from '@myrent/myrent-ui';

export const InfoIcon = styled('svg')`
  position: absolute;
  right: 9%;
  top: 18%;
`;

export const XIcon = styled('svg')`
  position: absolute;
  top: 3%;
  right: 2%;
  width: 25px;
  cursor: pointer;
`;
