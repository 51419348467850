import { Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

import {
  ClickableContainer,
  Container,
  StepConnector,
  StepConnectorContainer,
  StepContainer,
  StepIcon
} from './styles';

interface Items {
  date: Date;
  text: string;
}

interface Props {
  items: Items[];
  active: number;
  popActive?: boolean;
}

const Component = ({
  active,
  items,
  popActive = false
}: Props): JSX.Element => {
  return (
    <Container>
      {items?.map((item, index) => (
        <ClickableContainer key={index}>
          <Grid container alignItems={'flex-start'}>
            <Grid item xs={1} alignItems={'flex-end'} textAlign={'right'}>
              <Typography
                color={index > items.length - 3 ? '#C9C9C9' : '#242424'}
                variant="body1"
              >
                {format(item.date, 'dd.MM')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Stack alignItems={'center'}>
                <StepContainer key={index}>
                  <StepIcon active={index > items.length - 3} />
                </StepContainer>
                <StepConnectorContainer active={active === index}>
                  <StepConnector active={index > items.length - 3} />
                </StepConnectorContainer>
                {index == items.length - 3 && (
                  <StepConnectorContainer active={index > items.length - 4}>
                    <StepConnector active={index > items.length - 4} />
                  </StepConnectorContainer>
                )}
              </Stack>
            </Grid>
            <Grid
              item
              xs={8}
              alignItems={'center'}
              justifyContent={'center'}
              alignContent={'center'}
              textAlign={'left'}
            >
              <Typography
                color={index > items.length - 3 ? '#C9C9C9' : '#242424'}
                variant="body2"
              >
                {item.text}
              </Typography>
            </Grid>
          </Grid>
        </ClickableContainer>
      ))}
    </Container>
  );
};

export default Component;
