import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import { useCallback, useState } from 'react';

interface Props {
  label: string;
  options: {
    label: string;
    value: string | number;
  }[];
  defaultValue?: string | number;
  onChange: (e: string | number) => void;
}

export default function Component({
  label,
  defaultValue,
  options,
  onChange
}: Props) {
  const [value, setValue] = useState(defaultValue || options[0].value || '');

  const handleChange = useCallback((e: string | number) => {
    setValue(e);
    onChange(e);
  }, []);

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <FormControl
        sx={{
          minWidth: 150
        }}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={(e) => handleChange(e.target.value)}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
