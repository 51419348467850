import styled from 'styled-components';
interface InputProps {
  error: boolean | undefined;
}
export const Container = styled.div<InputProps>`
  box-shadow: ${({ error }) => error && '0 0 0 2px red'};
  &:focus {
    box-shadow: 0 0 0 2px #9aeabc;
  }
`;

export const Label = styled.label``;

export const Select = styled.select<InputProps>`
  -webkit-appearance: none;
  opacity: 90%;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border-width: 0px;
  box-shadow: ${({ error }) => error && '0 0 0 2px red'};
  &:focus {
    box-shadow: 0 0 0 2px #9aeabc;
  }
`;

export const Option = styled.option`
  padding: 2px;
`;
