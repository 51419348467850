import { Box, Stack, Typography } from '@mui/material';
import { white } from 'app/theme/colors';

interface Props {
  title: string;
  children: React.ReactNode;
}

export function CountTypeStyledCell({ title, children }: Props) {
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{
        padding: 0.5,
        backgroundColor: 'white',
        borderRadius: '15px',
        width: 'fit-content(5)'
      }}
    >
      <Box
        textAlign="center"
        sx={{
          backgroundColor: white[100],
          padding: 1,
          paddingLeft: 1.5,
          paddingRight: 1.5,
          borderRadius: '15px',
          width: '40px'
        }}
      >
        <Typography>{children}</Typography>
      </Box>
      <Typography>{title}</Typography>
    </Stack>
  );
}
