import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Stack, Typography } from '@mui/material';
import { DashboardCard } from 'app/components/card';
import { CountDisplay } from 'app/components/display';
import { useGetWarningListQuery } from 'app/context/api/account';
import { netural } from 'app/theme/colors';

interface Props {
  isWarnings: boolean;
}

const Component = ({ isWarnings }: Props): JSX.Element => {
  const { data } = useGetWarningListQuery();

  return (
    <>
      {data && isWarnings && (
        <DashboardCard
          mainIcon={<PriorityHighIcon />}
          iconColor={'#FFF3AE'}
          title={'Varsel/gjøremål'}
          firstColumn={
            <Stack spacing={1}>
              {data.CountUnSignedContracts > 0 && (
                <CountDisplay
                  title={'Kontrakter som avventer signatur'}
                  count={data.CountUnSignedContracts}
                  link={'Contract/Contracts?ContractStatuses=7'}
                  body={'body2'}
                />
              )}
              {data.CountOverpaidInvoices > 0 && (
                <CountDisplay
                  title={'Faktura som er overbetalt'}
                  count={data.CountOverpaidInvoices}
                  link={'Invoice/SentInvoices?statuses=13'}
                  body={'body2'}
                />
              )}
              {data.CountOcrFailedFiles > 0 && (
                <CountDisplay
                  title={'OCR innbetalinger feilet'}
                  count={data.CountOcrFailedFiles}
                  link={'OcrPayment/Overview?markFailed=True'}
                  body={'body2'}
                />
              )}
              {data.ContractsToRegulateCount > 0 && (
                <CountDisplay
                  title={'Leieforhold klar for regulering'}
                  count={data.ContractsToRegulateCount}
                  link={'cpi/pending'}
                  linkType={'internal'}
                  body={'body2'}
                />
              )}
              {data.CountContractsInSigningProcess > 0 && (
                <CountDisplay
                  title={'Kontrakter som avventer signatur fra leietaker'}
                  count={data.CountContractsInSigningProcess}
                  link={
                    'Contract/Contracts?ContractStatuses=5&ContractStatuses=6'
                  }
                  body={'body2'}
                />
              )}
              {data.InvoicesToDeliverCount != null &&
                data.InvoicesToDeliverCount > 0 && (
                  <CountDisplay
                    title={'Superadmin: Faktura som ikke er sendt'}
                    count={data.InvoicesToDeliverCount}
                    body={'body2'}
                  />
                )}
              {data.CountUnsentInvoices > 0 && (
                <CountDisplay
                  title={'Faktura produsert, klar for utsending'}
                  count={data.CountUnsentInvoices}
                  link={'Invoice/NewInvoices'}
                  body={'body2'}
                />
              )}
              {data.DuplicateInvoicesCount != null &&
                data.DuplicateInvoicesCount > 0 && (
                  <CountDisplay
                    title={'Superadmin: Dupliserte faktura'}
                    count={data.DuplicateInvoicesCount}
                    body={'body2'}
                  />
                )}
            </Stack>
          }
        />
      )}
      {data && !isWarnings && (
        <DashboardCard
          mainIcon={<PriorityHighIcon />}
          iconColor={netural[80]}
          title={'Varsel/gjøremål'}
          firstColumn={
            <Stack paddingY={6} alignItems="center">
              <ThumbUpOffAltIcon />
              <Typography textAlign={'center'}>
                Ingen varsler / gjøremål
              </Typography>
            </Stack>
          }
        />
      )}
    </>
  );
};

export default Component;
