import { LinearProgress } from '@mui/material';
import { Box, Grid, Stack, Typography } from '@myrent/myrent-ui';
import { green, red } from 'app/theme/colors';
import { JsxElement } from 'typescript';

interface Props {
  title: string;
  subTitle: string;
  numerator: number;
  denomerator: number;
  height?: number;
}

const Component = ({
  title,
  subTitle,
  numerator,
  denomerator,
  height = 10
}: Props): JSX.Element => {
  const isZero = numerator == 0 && denomerator == 0;
  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Stack spacing={2}>
        <Stack>
          <Typography variant="h3" fontWeight={'bold'}>
            {numerator
              .toLocaleString(undefined, { maximumFractionDigits: 0 })
              .replaceAll(',', ' ') + title}
          </Typography>
          <Typography variant="body2" fontWeight={'normal'}>
            {'av ' +
              denomerator
                .toLocaleString(undefined, { maximumFractionDigits: 0 })
                .replaceAll(',', ' ') +
              subTitle}
          </Typography>
        </Stack>
        {!isZero && (
          <LinearProgress
            sx={{
              height: { height },
              borderRadius: '15px',
              backgroundColor: red[40],
              '& .MuiLinearProgress-bar': {
                backgroundColor: green[60],
                borderRadius: '15px'
              }
            }}
            variant={'determinate'}
            value={(numerator / denomerator) * 100}
          />
        )}
      </Stack>
    </Box>
  );
};

export default Component;
