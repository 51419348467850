import { Stack, Typography } from '@mui/material';
import { ExternalLink } from 'app/components/link';

const Component = (): JSX.Element => {
  return (
    <Stack spacing={3} p={{ xs: 2, sm: 8, md: 16 }}>
      <Typography variant="h1" sx={{ textAlign: 'center' }}>
        AVTALE
      </Typography>
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        OM KJØP AV BRUKSRETT TIL IT-SYSTEM BEREGNET FOR FORVALTNING, DRIFT OG
        UTLEIE AV FAST EIENDOM SAMT KJØP AV TJENESTER
      </Typography>
      <Stack>
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          MELLOM
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          MYRENT AS
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          {'(ORG. NR. 998 257 360, HERETTER ”MYRENT”)'}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          OG DEG SOM BRUKER
        </Typography>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          {'(HERETTER ”KUNDEN”)'}
        </Typography>
      </Stack>
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        AVTALEN OMFATTER VALGT PRODUKT OG REGULERES AV MYRENTS ALMINNELIGE
        KUNDEVILKÅR FOR BRUKSRETT TIL IT- SYSTEM OG KJØP AV TJENESTER MED
        VEDLEGG, SOM UTGJØR EN DEL AV DENNE AVTALEN
      </Typography>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        Avtalen gjelder:
      </Typography>
      <Typography sx={{ textAlign: 'center' }}>
        MYRENT IT-SYSTEM MED VALGT MODUL VED REGISTRERING. OPPGRADERING VED
        SENERE TIDSPUNKT ER TILLATT. VED EVENTUELL NEDGRADERING I PAKKE MÅ
        KUNDESERVICE KONTAKTES.
      </Typography>
      <Typography variant="h2">
        ALMINNELIGE KUNDEVILKÅR FOR BRUKSRETT TIL IT-SYSTEM OG KJØP AV TJENESTER
      </Typography>
      <Typography variant="h2">1. DEFINISJONER </Typography>
      <Typography>
        Følgende definisjoner benyttes i disse alminnelige kundevilkårene:
      </Typography>
      <Typography>
        Avtalen: Avtalen om kjøp av bruksrett til It-system og kjøp av tjenester
        sammen med Alminnelige kundevilkår for bruksrett til It-system og kjøp
        av tjenester, samt eventuelle vedlegg som er vedtatt av Partene.
      </Typography>
      <Typography>
        Bruksrett: Kundens begrensede rett til bruk av Systemet slik denne er
        beskrevet i Avtalen.
      </Typography>
      <Typography>Brukertilganger: Antallet samtidige Kundebrukere.</Typography>
      <Typography>
        Dokumentasjon: All skriftlig eller elektronisk dokumentasjon, herunder
        manualer, brukerveiledninger og andre fremstillinger av hvordan
        Systemet/Modulen benyttes av Kunden, uavhengig av om dette er utarbeidet
        av MYRENT eller MYRENTs underleverandører.
      </Typography>
      <Typography>
        {`Kunden: Den fysiske eller juridiske person som er benevnt "Kunden" i
        avtale om kjøp av bruksrett til It-system og kjøp av tjenester.`}
      </Typography>
      <Typography>
        Kundebruker: Person som er ansatt i eller opptrer på vegne av Kunden og
        som har mulighet for å få adgang til Systemet gjennom en Kundeprofil.
      </Typography>
      <Typography>
        Kundeprofil: Profil med brukernavn og passord som Kunden har i Systemet
        og som gir tilgang til den Modulen Kunden har kjøpt bruksrett til av
        MYRENT.
      </Typography>
      <Typography>
        Leiefaktura: Faktura som produseres i Systemet og som gjelder leie
        Leietaker skal betale Kunden for leie av fast eiendom.
      </Typography>
      <Typography>
        Leietaker: Juridisk eller fysisk person som har fått tildelt
        Leietakerprofil og som blir fakturert av Kunden for leie av fast
        eiendom.
      </Typography>
      <Typography>
        Leietakerprofil: Profil med brukernavn og passord som Leietaker har i
        Systemet og som gir tilgang til en definert del av Systemet.
      </Typography>
      <Typography>
        Modul: En definert del av Systemet med definerte tjenester som Kunden
        kan få tilgang til gjennom Kundeprofilen.
      </Typography>
      <Typography>Partene/Parter: MYRENT og Kunden i fellesskap.</Typography>
      <Typography>
        Systemet: It-system levert av MYRENT beregnet for forvaltning, drift og
        utleie av fast eiendom, og som består av flere ulike Moduler.
      </Typography>
      <Typography variant="h2">2. HVA AVTALEN GJELDER</Typography>
      <Typography>
        Denne avtalen gjelder for Kundens kjøp av bruksrett til
        Systemet/Modulen, slik dette er spesifisert ovenfor. Avtalen gjelder
        også for senere kjøp av nye/andre Moduler til Systemet, med mindre noe
        annet er skriftlig avtalt mellom Partene. MYRENT har rett til ensidig å
        endre disse alminnelige kundevilkårene som er en del av Avtalen. Siste
        versjon av denne Avtalen og disse alminnelige kundevilkårene har forrang
        over alle tidligere versjoner.
      </Typography>
      <Typography>
        Kunden aksepterer siste versjon av Avtalen og de alminnelige
        kundevilkårene dersom Kunden ikke har protestert senest en uke etter at
        Kunden er gjort kjent med at MYRENT har endret eller vedtatt nye
        betingelser/vilkår, typisk gjennom at den nye Avtalen og de alminnelige
        kundevilkårene er gjort tilgjengelig for Kunden i Systemet og/eller at
        Kunden mottar varsel om endringen på e-post.
      </Typography>
      <Typography>
        Avtalen utgjør hele avtalen mellom MYRENT og Kunden. Tillegg eller
        endringer skal foretas skriftlig og være signert av begge Parter.
        Bestemmelsene i Avtalen erstatter og går foran alle betingelser og
        vilkår som avtales muntlig eller som finnes i andre dokumenter og som
        står i motstrid til denne Avtalen.
      </Typography>
      <Typography variant="h2">3. AVTALEPERIODEN</Typography>
      <Typography>
        Avtaleperioden løper fra signering, og frem til Avtalen opphører på
        grunn av oppsigelse eller mislighold fra en av Partene. Det er ingen
        bindingstid eller oppsigelsestid. Signering skjer elektronisk via
        MYRENTS nettsider.
      </Typography>
      <Typography variant="h2">4. PRIS OG BETALING</Typography>
      <Typography>
        Tilgang til Systemet prises med en løpende månedlig avgift, avhengig av
        hvilke Moduler som omfattes av Kundens abonnement. Betaling for
        tilleggstjenester kommer i tillegg til den faste månedsavgift. De til
        enhver tid gjeldende priser vil være tilgjengelig i Systemet og/eller på
        MYRENTs nettsider. MYRENT forbeholder seg retten til å gjøre endringer i
        innholdet og prisen til de ulike abonnement. Kunden vil få varsel før
        slike endringer iverksettes, jf. Avtalens punkt 2. Betaling skjer
        månedlig ved faktura, som sendes ut per e-post til registrert e-post
        adresse for Kunden.
      </Typography>
      <Typography variant="h2">5. BRUKSRETT TIL SYSTEMET</Typography>
      <Typography>
        Avtalen gir Kunden en ikke-eksklusiv, ikke-overførbar bruksrett til en
        Kundeprofil i Avtaleperioden til bruk i Kundens virksomhet, med et slikt
        antall Brukertilganger som fremgår av Avtalen eller som senere avtales.
        Bruksretten gir Kunden rett til å motta nye versjoner av Modulen som
        blir lansert i Avtaleperioden.
      </Typography>
      <Typography variant="h2">6. TILGANG TIL SYSTEMET</Typography>
      <Typography>
        Kunden har tilgang til Systemet gjennom sin Kundeprofil. Ved denne
        tilgangen skal Kunden kunne opprette nye Kundebrukere og
        Leietakerprofiler for Leietakere. I Kundeprofilen får Kunden også
        tilgang til Modulen og Dokumentasjonen. Leietakerne skal ha tilgang til
        Systemet gjennom sine Leietakerprofiler.
      </Typography>
      <Typography variant="h2">7. MISBRUK AV SYSTEMET</Typography>
      <Typography>
        Kunden er kjent med og aksepterer at MYRENT har rett til å utføre
        ethvert tiltak som MYRENT finner nødvendig for å forhindre eller stanse
        enhver form for misbruk av Systemet. Herunder aksepterer Kunden at
        MYRENT kan midlertidig stenge, blokkere tilgang til hele eller deler av
        Systemet, samt slette brukerdata, dersom MYRENT, etter egen vurdering,
        anser at slikt misbruk finner sted.
      </Typography>
      <Typography>
        Dette vil for eksempel kunne være aktuelt hvis MYRENT finner at bruken
        av Systemet er i strid med denne Avtalen. Ved mistanke om misbruk av
        Systemet, vil MYRENT normalt først midlertidig stenge/sperre tilgangen
        til Systemet etter å ha varslet Kunden. Som en del av driftsrutinene
        forbeholder MYRENT seg også retten til å overvåke sine systemer for å
        identifisere eventuelle trusler i sikkerhetssystemet og å iverksette
        tekniske og andre tiltak som vurderes som nødvendig for å sikre driften.
      </Typography>
      <Typography>
        Enhver bruk av Tjenesten foretatt av andre enn Kunden selv eller de
        personer Kunden har bemyndiget med Kundebrukere, er å anse som misbruk
        av Systemet.
      </Typography>
      <Typography variant="h2">8. IMMATERIELLE RETTIGHETER</Typography>
      <Typography>
        Kunden anerkjenner at MYRENT eller MYRENTs underleverandører er eier av
        alle rettigheter til Systemet enten direkte eller gjennom avtale med
        andre rettighetshavere. Det er forbudt å helt eller delvis kopiere,
        distribuere, leie ut, lease eller overføre Systemet eller deler av dette
        til noen tredjepart. Ved krenkelse av immaterielle rettigheter til
        Systemet eller deler av dette, skal Kunden betale til MYRENT et vederlag
        stort NOK 75 000,- per krenkelse, uavhengig av om MYRENT har lidt tap
        som følge av krenkelsen.
      </Typography>
      <Typography>
        Dersom MYRENT som følge av krenkelsen lider tap, eller Kunden oppnår
        uberettiget fordel, som til sammen er større enn NOK 75 000,-, kan
        MYRENT også kreve erstattet den fordel og det tap som overstiger NOK 75
        000,-. MYRENT har rett, men ingen plikt, til på egen bekostning å tre
        inn i enhver tvist eller rettssak hvor tredjemann påstår at Kundens bruk
        av Systemet/Modulen under Avtalen er i strid med tredjemannens patent,
        opphavsrett eller andre immaterielle rettigheter.
      </Typography>
      <Typography>
        Kunden er forpliktet til umiddelbart å underrette MYRENT skriftlig om
        enhver slik påstand- eller krav. Bestemmelsen fortsetter å gjelde også
        etter Avtalens opphør, uansett opphørsgrunn.
      </Typography>
      <Typography variant="h2">9. FORBUD MOT SAMMENBLANDING</Typography>
      <Typography>
        MYRENT plikter til enhver tid å holde midler til forvaring, herunder
        midler som er betrodd/innfordret av/for Kunden, strengt adskilt fra egne
        midler på en egen konto som MYRENT sin bankforbindelse skal ha
        fraskrevet seg motregningsrett i.
      </Typography>
      <Typography variant="h2">10. MOTREGNING</Typography>
      <Typography>
        Dersom Kunden ikke overholder sin betalingsplikt, og den manglende
        betaling av systemleie overskrider [60] dager, forbeholder MYRENT seg
        retten til å motregne utestående fakturaer, regnet fra tidspunktet
        misligholdet oppstod, fra midler innfordret for Kunden. Slik motregning
        kan ikke finne sted dersom berettigelsen av kravet er omstridt mellom
        Partene.
      </Typography>
      <Typography variant="h2">
        11. MINIMUMSKRAV TIL KUNDENS SOFTWARE
      </Typography>
      <Typography>
        Google Chrome eller Firefox oppdatert til seneste versjon.
      </Typography>
      <Typography variant="h2">12. MYRENTS FORPLIKTELSER</Typography>
      <Typography>
        MYRENT plikter å sørge for at Systemet/Modulen i det vesentlige er i
        stand til å utføre funksjonene som er beskrevet i Dokumentasjonen. Både
        Kunden og MYRENT er innforstått med at Systemet/Modulen ikke vil være
        helt feilfri i noen versjon og at vedlikehold av Systemet og/Modulen vil
        være påkrevd med ujevne mellomrom. MYRENT er ikke ansvarlig for feil som
        følge av manglende oppfyllelse av minimumskrav til software, bruk av
        Systemet/Modulen av Kundebrukere med manglende opplæring eller feil som
        følge av handlinger som innebærer krenkelse av MYRENT eller MYRENTs
        underleverandørers rettigheter til Systemet eller deler av dette.
      </Typography>
      <Typography variant="h2">13. FRI SUPPORT</Typography>
      <Typography>
        Ved feil/avvik i Systemet skal tilby MYRENT support og adgang til bruk
        av nødnummer kostnadsfritt i perioden hverdager mellom 09:00 og 16:00.
        For annen type support enn det som er omfattet av Fri Support, jf.
        ovenfor, belastes Kunden MYRENTs til enhver tid gjeldende satser for
        supporttjenester slik de fremgår i Systemet og/eller på MYRENTs
        nettsider.
      </Typography>
      <Typography variant="h2">
        14. VEDLIKEHOLD OG OPPDATERINGER AV SYSTEMET/MODULENE
      </Typography>
      <Typography>
        MYRENTs underleverandører er ansvarlig ovenfor MYRENT for løpende
        vedlikehold og oppgraderinger av Systemet, slik at dette fungerer
        tilfredsstillende. Underleverandøren er forpliktet til fortløpende å
        videreutvikle Systemet. Kunden får vederlagsfri tilgang til
        oppdateringer av Kundens Modul(er). MYRENT har rett til å gjøre
        endringer/tillegg uten særskilt forhåndsvarsel, dog slik at Kunden
        normalt skal varsles rimelig tid i forkant, typisk ved at det legges inn
        melding i Systemet.
      </Typography>
      <Typography variant="h2">15. FEIL/AVVIK I SYSTEMET</Typography>
      <Typography>
        Kunden skal på forespørsel fra MYRENT gi MYRENT og/eller MYRENTs
        underleverandører nødvendig fysisk/elektronisk tilgang til Kundens
        Kundeprofil, dersom dette er nødvendig for at MYRENT skal kunne oppfylle
        sine plikter etter Avtalen. Kunden skal rapportere om feil og/eller
        avvik ved Systemet som Kunden blir kjent med. Slik rapportering skal
        fortrinnsvis skje ved bruk av MYRENTs web-baserte support, alternativt
        ved at Kunden sender e-post til MYRENT.
      </Typography>
      <Typography variant="h2">16. NEDETID</Typography>
      <Typography>
        MYRENT skal bestrebe at Kunden og Leietakerne har tilgang til
        Systemet/Modulen gjennom sine Brukertilganger og Leietakerprofiler 24
        timer i døgnet, men MYRENT gir ingen oppetidsgaranti. Kunden anerkjenner
        at det vil kunne påløpe nedetid, uten at MYRENT kan holdes økonomisk
        ansvarlig for slik nedetid.
      </Typography>
      <Typography variant="h2">17. KUNDENS MISLIGHOLD</Typography>
      <Typography>
        Ved mislighold av Avtalen fra Kundens side kan MYRENT, i tillegg til
        andre virkemidler som måtte følge av Avtalen, holde tilbake sine ytelser
        inntil misligholdet har opphørt. Tilbakeholdsretten gjelder både
        utførelsen av tjenester og tilgang til Systemet/Modulen. Ved manglende
        betaling fra Kunden av krav fra MYRENT, påløper forsinkelsesrente fra
        forfall til betaling skjer. Forsinkelsesrentesatsen skal være den til
        enhver tid gjeldende rentesats i henhold til lov om renter ved forsinket
        betaling.
      </Typography>
      <Typography>
        Ved vesentlig mislighold fra Kundens side kan MYRENT heve avtalen med
        umiddelbar virkning. Betalingsmislighold ut over 30 dager regnes alltid
        som vesentlig mislighold. Ved heving opphører Kundens Bruksrett til
        Systemet/Modulen. MYRENT kan kreve erstatning for tap som følge av
        Kundens mislighold. Selv om Avtalen er hevet kan Kunden etter særskilt
        skriftlig avtale med MYRENT i en tidsbegrenset periode gis tilgang for å
        kunne avslutte årsregnskap, eller hente frem historiske opplysninger ved
        bokettersyn eller ved datatap som følge av ulykke eller liknende.
      </Typography>
      <Typography>
        Ved slik nærmere avtale får Kunden utelukkende en begrenset bruksrett
        til Systemet/Modulen for dette formål. I den utstrekning slik tilgang
        medfører ekstra kostnader for MYRENT, herunder kostnader ved
        underleverandører, skal slike belastes Kunden.
      </Typography>
      <Typography variant="h2">18. MYRENTS MISLIGHOLD</Typography>
      <Typography>
        Dersom Systemet/Modulen ikke fungerer i henhold til Avtalen, og dette
        ikke skyldes forhold som ligger utenfor MYRENTS kontroll, har MYRENT
        både en rett og en plikt til for egen eller underleverandørers regning å
        rette verifiserte feil eller avvik ved Systemet/Modulen. Ved vellykket
        retting kan Kunden ikke gjøre gjeldende noe krav på økonomisk eller
        annen kompensasjon som følge av feilen eller avviket. Kunden kan heve
        avtalen dersom MYRENT vesentlig misligholder Avtalen og misligholdet
        ikke har opphørt innen 21 dager etter at Kunden skriftlig har varslet
        MYRENT om misligholdet og at Kunden vurderer å heve Avtalen.
      </Typography>
      <Typography>
        Ved heving opphører Kundens Bruksrett til Systemet/Modulen. Selv om
        Avtalen er hevet kan Kunden etter særskilt skriftlig avtale med MYRENT i
        en tidsbegrenset periode gis tilgang for å kunne avslutte årsregnskap,
        eller hente frem historiske opplysninger ved bokettersyn eller ved
        datatap som følge av ulykke eller liknende. Ved slik nærmere avtale får
        Kunden utelukkende en begrenset bruksrett til Systemet/Modulen for dette
        formål, som ikke omfatter noen annen rett.
      </Typography>
      <Typography>
        MYRENTs samlede økonomiske ansvar er begrenset til direkte,
        dokumenterbart tap som ikke overstiger det beløp MYRENT har fått betalt
        av Kunden for bruk av Systemet siste 12 måneder. Kunden kan ikke gjøre
        gjeldende noen andre krav mot MYRENT utover dette og MYRENT er ikke
        under noen omstendighet ansvarlig for indirekte tap og kostnader,
        følgeskader eller tap som oppstår som følge av eller i forbindelse med
        tap av data, produksjon, inntekt eller fortjeneste, med videre.
      </Typography>
      <Typography variant="h2">19. EIENDOMSFORBEHOLD</Typography>
      <Typography>
        Avtalen innebærer ingen overføring av eiendomsrett av noen del av
        Systemet fra MYRENT til Kunden.
      </Typography>
      <Typography variant="h2">
        20. MOTREGNINGS- OG TILBAKEHOLDSFORBUD
      </Typography>
      <Typography>
        Kunden kan ikke holde tilbake betaling av fakturaer eller foreta
        motregning i fakturaer fra MYRENT som følge av påstått kontraktsbrudd
        fra MYRENTs side.
      </Typography>
      <Typography variant="h2">21. KONFIDENSIALITET</Typography>
      <Typography>
        Hver Part aksepterer å holde hemmelig og ikke fremlegge eller bruke i
        noen annen sammenheng informasjon om eller fra den annen Part som er
        gjort tilgjengelig i forbindelse med avtaleforholdet, med mindre det
        foreligger skriftlig forhåndstillatelse fra den annen Part. MYRENT har
        uansett slik rett til markedsføring som følger av punkt Bestemmelsen her
        fortsetter å gjelde mellom Partene etter Avtalens opphør uavhengig av
        opphørsgrunn.
      </Typography>
      <Typography variant="h2">
        22. TILBAKELEVERING VED OPPHØR AV AVTALEN
      </Typography>
      <Typography>
        Ved opphør av Avtalen, skal Kunden gis rett til å kopiere alle data som
        Kunden har lagt inn i Systemet gjennom sin Kundeprofil
      </Typography>
      <Typography variant="h2">
        23. REFERANSER OG ELEKTRONISK MARKEDSFØRING
      </Typography>
      <Typography>
        Kunden aksepterer at MYRENT kan referere til Kundens bruk av Systemet i
        sine generelle markedsføringsaktiviteter ved bruk av Kundens navn og
        logo. Kunden er innforstått med at Kunden under avtaleperioden anses for
        å være i et eksisterende kundeforhold som tillater MYRENT å ta kontakt
        med Kunden via elektronisk kommunikasjon som e-post etc. for
        markedsføringsformål.
      </Typography>
      <Typography>
        {`
        MYRENT anser kundeforholdet som aktivt så lenge Kunden er registrert som
        bruker av Systemet. Dersom Kunden samtykker, kan markedsføringen
        inneholde tilbud og markedsføring fra tredjeparter. Dersom Kunden ikke
        lenger ønsker å motta slik markedsføring, må MYRENT varsles gjennom
        telefon 233 58 233 eller epost: kundeservice@myrent.no eller gjennom
        bruk av "stopp abonnements"- linken i den mottatte e-posten. Kunden er
        innforstått med at MYRENT kan rette markedsføring for egne og
        tredjeparters tjenester direkte til Leietaker, såfremt Leietaker selv
        har samtykket til dette.`}
      </Typography>
      <Typography variant="h2">24. ELEKTRONISK KOMMUNIKASJON</Typography>
      <Typography>
        Informasjon relatert til Kundens kundeforhold med MYRENT (f.eks.
        endringer i passord, bekreftelser, varsel) vil kun sendes til Kunden i
        elektronisk form, for eksempel til den registrerte e-postadresse.
      </Typography>
      <Typography variant="h2">25. ANGRERETT</Typography>
      <Typography>
        Kunden er innforstått med at MYRENTs tjenester er unntatt angrerett
        etter angrerettloven, og aksepterer at levering av tjenesten starter
        umiddelbart etter signering av Avtalen.
      </Typography>
      <Typography variant="h2">26. PERSONVERN</Typography>
      <Typography>
        Ved å inngå Avtalen aksepterer Kunden at MYRENT kan behandle
        personopplysninger knyttet til Kunden i tråd med MYRENTs
        Personvernerklæring, tilgjengelig her:{' '}
        <ExternalLink to="Account/PrivacyPolicy">
          Personvernerklæring
        </ExternalLink>
        .
      </Typography>
      <Typography variant="h2">27. FORCE MAJEURE</Typography>
      <Typography>
        Ved manglende leveranser fra MYRENT som har sin årsak i forhold som
        MYRENT ikke rår over, suspenderes MYRENTs plikter i den utstrekning
        forholdet fortsatt er relevant, og for så lang tid som forholdet varer.
        Slike forhold inkluderer naturkatastrofe, sabotasje, hærverk, streik,
        lockout, blokade, eksplosjon, terrorhandling, krig eller inngrep av
        offentlig myndighet og ethvert annet forhold som for øvrig etter norsk
        rett vil bli ansett som en force majeure-situasjon.
      </Typography>
      <Typography variant="h2">28. OVERDRAGELSE AV AVTALEN</Typography>
      <Typography>
        Kunden kan ikke overdra avtalen helt eller delvis uten skriftlig
        forhåndssamtykke fra MYRENT. Endringer i kontrollerende eierforhold,
        fusjon, fisjon eller virksomhetsoverdragelse regnes som overdragelse av
        Avtalen. MYRENT kan uten samtykke fra Kunden overføre avtaleforholdet
        ved salg av virksomheten, eller til selskap i samme konsern.
      </Typography>
      <Typography variant="h2">29. UGYLDIGHET</Typography>
      <Typography>
        Dersom Avtalen delvis skulle anses ugyldig eller i strid med tvingene
        lovgivning skal resterende deler av Avtalen likevel anses som gyldig og
        bindende for Partene.
      </Typography>
      <Typography variant="h2">30. LOVVALG OG TVISTELØSNING</Typography>
      <Typography>
        Avtalen reguleres av norsk rett. Tvister om forståelsen av Avtalen skal
        først søkes løst i minnelighet. Dersom dette ikke fører frem skal saken
        bringes inn for de ordinære domstoler med MYRENTs til enhver tid
        registrerte forretningsadresse som Partenes vedtatte verneting. Dette er
        ikke til hinder for at Kunder som er å anse som forbrukere kan reise
        søksmål ved sitt alminnelige verneting i henhold til tvisteloven.
      </Typography>
    </Stack>
  );
};

export default Component;
