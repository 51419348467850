import { Divider, Stack, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import { MaskedInput, PhoneInput, TextInput } from 'app/components/input';
import { organizationNumberSchema } from 'app/utils/schemas/organizationNumber';
import { FormikProps } from 'formik';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

import { FormikRegisterValues } from '../../component';

interface Props {
  formik: FormikProps<FormikRegisterValues>;
  nextPageTitle: string;
  nextPagePath: string;
  invoiceEmail?: boolean;
  title?: string;
}

export const privateInfoValidationSchema = yup.object({
  firstName: yup
    .string()
    .min(2, 'Skriv inn fornavn')
    .required('Skriv inn fornavn'),
  lastName: yup
    .string()
    .min(2, 'Skriv inn etternavn')
    .required('Skriv inn etternavn'),
  phone: yup
    .string()
    .min(10, 'Skriv inn et gyldig telefonnummer')
    .required('Skriv inn telefonnummer')
});
export const companyInfoValidationSchema = yup.object({
  organizationNumber: organizationNumberSchema,
  companyName: yup
    .string()
    .min(2, 'Skriv inn bedriftsnavn')
    .required('Skriv inn bedriftsnavn'),
  invoiceEmail: yup
    .string()
    .email('Skriv inn en gyldig e-post adresse')
    .required('Skriv inn epost'),
  phone: yup
    .string()
    .min(10, 'Skriv inn et gyldig telefonnummer')
    .required('Skriv inn telefonnummer')
});

const Component = ({
  formik,
  nextPageTitle,
  invoiceEmail,
  nextPagePath,
  title
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const getCompany = useCallback(async (orgNumber: string) => {
    const result = await fetch(
      `https://data.brreg.no/enhetsregisteret/api/enheter/${orgNumber}`
    );
    const parsed = await result.json();
    formik.setFieldValue('contactInfo.companyName', parsed.navn);
  }, []);

  useEffect(() => {
    const orgNumber = formik.values.contactInfo.organizationNumber.replaceAll(
      ' ',
      ''
    );
    if (orgNumber.length == 9 && /^[0-9]*$/.test(orgNumber)) {
      getCompany(orgNumber);
    }
  }, [formik.values.contactInfo.organizationNumber]);

  const getPerson = useCallback(async (phone: string) => {
    const result = await fetch(
      `https://services.api1881.no/lookup/phonenumber/${phone}`,
      {
        method: 'GET',
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_1881_KEY || ''
        }
      }
    );
    const parsed = await result.json();
    if (parsed?.contacts?.length > 0) {
      formik.setFieldValue(
        'contactInfo.firstName',
        parsed?.contacts?.[0]?.firstName
      );
      formik.setFieldValue(
        'contactInfo.lastName',
        parsed?.contacts?.[0]?.lastName
      );
    }
  }, []);

  useEffect(() => {
    const phoneNumber = formik.values.contactInfo.phone.slice(3);
    if (
      phoneNumber.length == 8 &&
      /^[0-9]*$/.test(phoneNumber) &&
      formik.values.OrganizationType == 4
    ) {
      getPerson(phoneNumber);
    }
  }, [formik.values.contactInfo.phone]);

  return (
    <div>
      <Typography variant="h6" color="white">
        {title}
      </Typography>
      <Divider color="white" />

      <Stack spacing={5} paddingTop={2} width={{ md: '70%' }}>
        {formik.values.OrganizationType == 2 && (
          <>
            <MaskedInput
              id="organizationNumber"
              name="contactInfo.organizationNumber"
              mask="### ### ###"
              label="Organisasjonsnummer"
              placeholder="Fyll inn org.nr"
              value={formik.values.contactInfo.organizationNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactInfo &&
                formik.errors.contactInfo &&
                formik.touched.contactInfo.organizationNumber &&
                Boolean(formik.errors.contactInfo.organizationNumber)
              }
              helperText={
                formik.touched.contactInfo &&
                formik.errors.contactInfo &&
                formik.touched.contactInfo.organizationNumber &&
                formik.errors.contactInfo.organizationNumber
              }
            />
            {formik.touched.contactInfo &&
              formik.touched.contactInfo.organizationNumber && (
                <TextInput
                  id="companyName"
                  name="contactInfo.companyName"
                  label="Bedriftsnavn"
                  value={formik.values.contactInfo.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactInfo &&
                    formik.errors.contactInfo &&
                    formik.touched.contactInfo.companyName &&
                    Boolean(formik.errors.contactInfo.companyName)
                  }
                  helperText={
                    formik.touched.contactInfo &&
                    formik.errors.contactInfo &&
                    formik.touched.contactInfo.companyName &&
                    formik.errors.contactInfo.companyName
                  }
                />
              )}
            <TextInput
              id="invoiceEmail"
              name="contactInfo.invoiceEmail"
              label="E-post for faktura"
              placeholder="For eksempel regnskap@bedriftsnavn.no"
              value={formik.values.contactInfo.invoiceEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactInfo &&
                formik.errors.contactInfo &&
                formik.touched.contactInfo.invoiceEmail &&
                Boolean(formik.errors.contactInfo.invoiceEmail)
              }
              helperText={
                formik.touched.contactInfo &&
                formik.errors.contactInfo &&
                formik.touched.contactInfo.invoiceEmail &&
                formik.errors.contactInfo.invoiceEmail
              }
              fullWidth
            />
            <PhoneInput
              id="phone"
              name="contactInfo.phone"
              label="Telefonnummer"
              placeholder="Minimum 8 tegn"
              value={formik.values.contactInfo.phone}
              onChange={(e) => {
                formik.setFieldValue('contactInfo.phone', `+${e}`);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactInfo &&
                formik.errors.contactInfo &&
                formik.touched.contactInfo.phone &&
                Boolean(formik.errors.contactInfo.phone)
              }
              helperText={
                formik.touched.contactInfo &&
                formik.errors.contactInfo &&
                formik.touched.contactInfo.phone &&
                formik.errors.contactInfo.phone
              }
            />
          </>
        )}
        {formik.values.OrganizationType == 4 && (
          <>
            <PhoneInput
              id="phone"
              name="contactInfo.phone"
              label="Telefonnummer"
              placeholder="Minimum 8 tegn"
              value={formik.values.contactInfo.phone}
              onChange={(e) => {
                formik.setFieldValue('contactInfo.phone', `+${e}`);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactInfo &&
                formik.errors.contactInfo &&
                formik.touched.contactInfo.phone &&
                Boolean(formik.errors.contactInfo.phone)
              }
              helperText={
                formik.touched.contactInfo &&
                formik.errors.contactInfo &&
                formik.touched.contactInfo.phone &&
                formik.errors.contactInfo.phone
              }
              autoFocus
            />
            {formik.values.contactInfo?.phone?.slice(3).length > 7 && (
              <>
                <TextInput
                  id="contactInfo.firstName"
                  name="contactInfo.firstName"
                  label="Fornavn"
                  placeholder="Fyll inn fornavn"
                  value={formik.values.contactInfo.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactInfo &&
                    formik.errors.contactInfo &&
                    formik.touched.contactInfo.firstName &&
                    Boolean(formik.errors.contactInfo.firstName)
                  }
                  helperText={
                    formik.touched.contactInfo &&
                    formik.errors.contactInfo &&
                    formik.touched.contactInfo.firstName &&
                    formik.errors.contactInfo.firstName
                  }
                />
                <TextInput
                  id="contactInfo.lastName"
                  name="contactInfo.lastName"
                  label="Etternavn"
                  placeholder="Fyll inn etternavn"
                  value={formik.values.contactInfo.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactInfo &&
                    formik.errors.contactInfo &&
                    formik.touched.contactInfo.lastName &&
                    Boolean(formik.errors.contactInfo.lastName)
                  }
                  helperText={
                    formik.touched.contactInfo &&
                    formik.errors.contactInfo &&
                    formik.touched.contactInfo.lastName &&
                    formik.errors.contactInfo.lastName
                  }
                />
              </>
            )}
          </>
        )}
        <Stack direction="row" justifyContent="space-between">
          <PrimaryButton
            filled
            onClick={() => {
              if (
                Boolean(formik.errors.contactInfo) ||
                !formik.touched.contactInfo
              ) {
                formik.setFieldTouched('contactInfo.organizationNumber', true);
                formik.setFieldTouched('contactInfo.companyName', true);
                formik.setFieldTouched('contactInfo.firstName', true);
                formik.setFieldTouched('contactInfo.lastName', true);
                formik.setFieldTouched('contactInfo.invoiceEmail', true);
                formik.setFieldTouched('contactInfo.phone', true);
              } else {
                navigate(nextPagePath);
              }
            }}
            chevron={'right'}
          >
            {nextPageTitle}
          </PrimaryButton>
        </Stack>
      </Stack>
    </div>
  );
};

export default Component;
