import { Grid, Stack, Typography } from '@myrent/myrent-ui';
import { PrimaryButton } from 'app/components/button';
import { Checkbox, MaskedInput, PhoneInput } from 'app/components/input';
import { TextInput } from 'app/components/input';
import { LoadingPopup } from 'app/components/loading';
import { useCompanyCreditCheckMutation } from 'app/context/api/tenant';
import { OrganizationSubScriptionType } from 'app/context/user/userSlice';
import { RootState } from 'app/store';
import { green, red, white } from 'app/theme/colors';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface Props {
  formik: any;
  index: number;
}

interface CreditScore {
  score: number;
}

const Component = ({ formik, index }: Props): JSX.Element => {
  const [getCreditCheck, { isLoading }] = useCompanyCreditCheckMutation();
  const [creditCheckError, setCreditCheckError] = useState('');

  const subscriptionType = useSelector(
    (state: RootState) => state.user.Client?.Organization.SubscriptionType
  );

  return (
    <Stack key={index} spacing={3}>
      <Stack spacing={3}>
        <TextInput
          name={`companyTenants[${index}].companyName`}
          value={formik.values.companyTenants[index].companyName}
          onChange={formik.handleChange}
          id={`companyTenants[${index}].companyName`}
          onBlur={formik.handleBlur}
          error={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            typeof formik.errors.companyTenants[index].companyName === 'string'
              ? Boolean(formik.errors.companyTenants[index].companyName) &&
                formik.touched.companyTenants[index].companyName
              : undefined
          }
          helperText={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            formik.touched.companyTenants[index].companyName &&
            typeof formik.errors.companyTenants[index].companyName === 'string'
              ? formik.errors.companyTenants[index].companyName
              : undefined
          }
          label={'Bedriftsnavn'}
          labelColor={'#242424'}
          labelLeftMargin={'0'}
          labelWeight={'normal'}
          bgColor="#ededed"
        />
        <MaskedInput
          id={`companyTenants[${index}].organizationNumber`}
          name={`companyTenants[${index}].organizationNumber`}
          mask="### ### ###"
          label="Organisasjonsnummer"
          placeholder="Fyll inn org.nr"
          value={formik.values.companyTenants[index].organizationNumber}
          onChange={(e) => {
            formik.setFieldValue(
              `companyTenants[${index}].organizationNumber`,
              e.target.value.replaceAll(' ', '')
            );
          }}
          onBlur={() => {
            formik.setFieldTouched(
              `companyTenants[${index}].organizationNumber`,
              true
            );
          }}
          error={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            typeof formik.errors.companyTenants[index].organizationNumber ===
              'string'
              ? Boolean(
                  formik.errors.companyTenants[index].organizationNumber
                ) && formik.touched.companyTenants[index].organizationNumber
              : undefined
          }
          helperText={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            formik.touched.companyTenants[index].organizationNumber &&
            typeof formik.errors.companyTenants[index].organizationNumber ===
              'string'
              ? formik.errors.companyTenants[index].organizationNumber
              : undefined
          }
          labelColor={'#242424'}
          labelLeftMargin={'0'}
          bgColor="#ededed"
        />
      </Stack>
      <Stack>
        <Typography variant="h6">Kredittsjekk</Typography>
        <Grid container alignItems={'center'} rowGap={2}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Typography>
              For din sikkerhet kan vi foreta en kredittsjekk av leietaker, via
              samarbeidspartner. Vi gir deg da en rapport på scoring.
            </Typography>
            {subscriptionType === OrganizationSubScriptionType.Free && (
              <Typography variant="body1">
                Det koster 49,- for hver kredittsjekk på ditt abonnement.
              </Typography>
            )}
          </Grid>
          {formik.values.companyTenants[index].isCreditChecked ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  display: 'inline-flex',
                  borderRadius: '15px',
                  height: '100%',
                  p: '10px',
                  px: '25px',
                  backgroundColor: white[100]
                }}
              >
                {formik.values.companyTenants[index].creditScore > 19 &&
                formik.values.companyTenants[index].creditScore < 101 ? (
                  <Typography fontWeight={'bold'} color={green[100]}>
                    Godkjent
                  </Typography>
                ) : (
                  <Typography fontWeight={'bold'} color={red[100]}>
                    Ikke godkjent
                  </Typography>
                )}
                <Typography>{`Score: ${formik.values.companyTenants[index].creditScore}`}</Typography>
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <PrimaryButton
                filled
                disabled={
                  (formik.errors &&
                    formik.errors.companyTenants &&
                    formik.errors.companyTenants[index] &&
                    Boolean(
                      formik.errors.companyTenants[index].organizationNumber
                    )) ||
                  !(
                    formik.touched &&
                    formik.touched.companyTenants &&
                    formik.touched.companyTenants[index] &&
                    formik.touched.companyTenants[index].organizationNumber
                  )
                }
                onClick={async () => {
                  try {
                    const result = await getCreditCheck({
                      organizationNumber: formik.values.companyTenants[
                        index
                      ].organizationNumber.replaceAll(' ', '')
                    }).unwrap();

                    if (!result.success) throw result?.errorMessage;

                    setCreditCheckError('');

                    formik.setFieldValue(
                      `companyTenants[${index}].isCreditChecked`,
                      true
                    );
                    formik.setFieldValue(
                      `companyTenants[${index}].creditScore`,
                      result.payload.score
                    );
                    formik.setFieldValue(
                      `companyTenants[${index}].address`,
                      result.payload.address
                    );
                    formik.setFieldValue(
                      `companyTenants[${index}].zipCode`,
                      result.payload.postNr
                    );
                    formik.setFieldValue(
                      `companyTenants[${index}].city`,
                      result.payload.postalTown
                    );
                    console.log(result);
                  } catch (err) {
                    setCreditCheckError('' + err);
                  }
                }}
              >
                Ta kredittsjekk
              </PrimaryButton>
            </Grid>
          )}
        </Grid>
        {creditCheckError.length > 0 && (
          <Typography color="red">{creditCheckError}</Typography>
        )}
      </Stack>
      <Stack>
        <TextInput
          name={`companyTenants[${index}].address`}
          value={formik.values.companyTenants[index].address}
          onChange={formik.handleChange}
          id={`companyTenants[${index}].address`}
          onBlur={formik.handleBlur}
          error={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            typeof formik.errors.companyTenants[index].address === 'string'
              ? Boolean(formik.errors.companyTenants[index].address) &&
                formik.touched.companyTenants[index].address
              : undefined
          }
          helperText={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            formik.touched.companyTenants[index].address &&
            typeof formik.errors.companyTenants[index].address === 'string'
              ? formik.errors.companyTenants[index].address
              : undefined
          }
          label={'Adresse'}
          labelColor={'#242424'}
          labelLeftMargin={'0'}
          labelWeight={'normal'}
          bgColor="#ededed"
        />
      </Stack>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
      >
        <Stack>
          <TextInput
            name={`companyTenants[${index}].zipCode`}
            value={formik.values.companyTenants[index].zipCode}
            onChange={formik.handleChange}
            id={`companyTenants[${index}].zipCode`}
            onBlur={formik.handleBlur}
            error={
              formik.errors.companyTenants &&
              formik.errors.companyTenants[index] &&
              formik.touched.companyTenants &&
              formik.touched.companyTenants[index] &&
              typeof formik.errors.companyTenants[index].zipCode === 'string'
                ? Boolean(formik.errors.companyTenants[index].zipCode) &&
                  formik.touched.companyTenants[index].zipCode
                : undefined
            }
            helperText={
              formik.errors.companyTenants &&
              formik.errors.companyTenants[index] &&
              formik.touched.companyTenants &&
              formik.touched.companyTenants[index] &&
              formik.touched.companyTenants[index].zipCode &&
              typeof formik.errors.companyTenants[index].zipCode === 'string'
                ? formik.errors.companyTenants[index].zipCode
                : undefined
            }
            label={'Postnummer'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor="#ededed"
          />
        </Stack>
        <Stack>
          <TextInput
            name={`companyTenants[${index}].city`}
            value={formik.values.companyTenants[index].city}
            onChange={formik.handleChange}
            id={`companyTenants[${index}].city`}
            onBlur={formik.handleBlur}
            error={
              formik.errors.companyTenants &&
              formik.errors.companyTenants[index] &&
              formik.touched.companyTenants &&
              formik.touched.companyTenants[index] &&
              typeof formik.errors.companyTenants[index].city === 'string'
                ? formik.errors.companyTenants[index].city &&
                  formik.touched.companyTenants[index].city
                : undefined
            }
            helperText={
              formik.errors.companyTenants &&
              formik.errors.companyTenants[index] &&
              formik.touched.companyTenants &&
              formik.touched.companyTenants[index] &&
              formik.touched.companyTenants[index].city &&
              typeof formik.errors.companyTenants[index].city === 'string'
                ? formik.errors.companyTenants[index].city
                : undefined
            }
            label={'Poststed'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor="#ededed"
          />
        </Stack>
      </Stack>
      <Typography variant="h5">Kontaktperson</Typography>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
      >
        <Stack>
          <TextInput
            name={`companyTenants[${index}].firstName`}
            value={formik.values.companyTenants[index].firstName}
            onChange={formik.handleChange}
            id={`companyTenants[${index}].firstName`}
            onBlur={formik.handleBlur}
            error={
              formik.errors.companyTenants &&
              formik.errors.companyTenants[index] &&
              formik.touched.companyTenants &&
              formik.touched.companyTenants[index] &&
              typeof formik.errors.companyTenants[index].firstName === 'string'
                ? Boolean(formik.errors.companyTenants[index].firstName) &&
                  formik.touched.companyTenants[index].firstName
                : undefined
            }
            helperText={
              formik.errors.companyTenants &&
              formik.errors.companyTenants[index] &&
              formik.touched.companyTenants &&
              formik.touched.companyTenants[index] &&
              formik.touched.companyTenants[index].firstName &&
              typeof formik.errors.companyTenants[index].firstName === 'string'
                ? formik.errors.companyTenants[index].firstName
                : undefined
            }
            label={'Fornavn'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor="#ededed"
          />
        </Stack>
        <Stack>
          <TextInput
            name={`companyTenants[${index}].lastName`}
            value={formik.values.companyTenants[index].lastName}
            onChange={formik.handleChange}
            id={`companyTenants[${index}].lastName`}
            onBlur={formik.handleBlur}
            error={
              formik.errors.companyTenants &&
              formik.errors.companyTenants[index] &&
              formik.touched.companyTenants &&
              formik.touched.companyTenants[index] &&
              typeof formik.errors.companyTenants[index].lastName === 'string'
                ? formik.errors.companyTenants[index].lastName &&
                  formik.touched.companyTenants[index].lastName
                : undefined
            }
            helperText={
              formik.errors.companyTenants &&
              formik.errors.companyTenants[index] &&
              formik.touched.companyTenants &&
              formik.touched.companyTenants[index] &&
              formik.touched.companyTenants[index].lastName &&
              typeof formik.errors.companyTenants[index].lastName === 'string'
                ? formik.errors.companyTenants[index].lastName
                : undefined
            }
            label={'Etternavn'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor="#ededed"
          />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <TextInput
          name={`companyTenants[${index}].email`}
          value={
            !formik.values.companyTenants[index].noEmail
              ? formik.values.companyTenants[index].email
              : ''
          }
          onChange={formik.handleChange}
          id={`companyTenants[${index}].email`}
          onBlur={formik.handleBlur}
          disabled={formik.values.companyTenants[index].noEmail}
          error={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            typeof formik.errors.companyTenants[index].email === 'string'
              ? formik.errors.companyTenants[index].email &&
                formik.touched.companyTenants[index].email
              : undefined
          }
          helperText={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            formik.touched.companyTenants[index].email &&
            typeof formik.errors.companyTenants[index].email === 'string'
              ? formik.errors.companyTenants[index].email
              : undefined
          }
          label={'E-post'}
          labelColor={'#242424'}
          labelLeftMargin={'0'}
          labelWeight={'normal'}
          bgColor="#ededed"
        />
        <Checkbox
          id={`companyTenants[${index}].noEmail`}
          name={`companyTenants[${index}].noEmail`}
          value={formik.values.companyTenants[index].noEmail}
          fontSize={'14px'}
          fontColor={'#003D43'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={undefined}
          helperText={undefined}
          label={'Mangler e-post'}
        />
      </Stack>
      <Stack spacing={2}>
        <PhoneInput
          id={`companyTenants[${index}].cellphone`}
          name={`companyTenants[${index}].cellphone`}
          placeholder=""
          value={
            !formik.values.companyTenants[index].noCellPhone
              ? formik.values.companyTenants[index].cellphone
              : ''
          }
          onChange={(e) => {
            formik.setFieldValue(`companyTenants[${index}].cellphone`, `+${e}`);
          }}
          onBlur={() => {
            formik.setFieldTouched(`companyTenants[${index}].cellphone`, true);
          }}
          disabled={formik.values.companyTenants[index].noCellPhone}
          error={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            typeof formik.errors.companyTenants[index].cellphone === 'string'
              ? formik.errors.companyTenants[index].cellphone &&
                formik.touched.companyTenants[index].cellphone
              : undefined
          }
          helperText={
            formik.errors.companyTenants &&
            formik.errors.companyTenants[index] &&
            formik.touched.companyTenants &&
            formik.touched.companyTenants[index] &&
            formik.touched.companyTenants[index].cellphone &&
            typeof formik.errors.companyTenants[index].cellphone === 'string'
              ? formik.errors.companyTenants[index].cellphone
              : undefined
          }
          label={'Mobilnummer'}
          labelColor={'#242424'}
          labelLeftMargin={'0'}
          labelWeight={'normal'}
          bgColor="#ededed"
        />
        <Checkbox
          id={`companyTenants[${index}].noCellPhone`}
          name={`companyTenants[${index}].noCellPhone`}
          value={formik.values.companyTenants[index].noCellPhone}
          fontSize={'14px'}
          fontColor={'#003D43'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={undefined}
          helperText={undefined}
          label={'Mangler mobilnummer'}
        />
      </Stack>
      <LoadingPopup open={isLoading} />
    </Stack>
  );
};

export default Component;
