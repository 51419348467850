import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BusinessIcon from '@mui/icons-material/Business';
import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { DashboardCard } from 'app/components/card';
import { Dialog } from 'app/components/dialog';
import { CountDisplay, SelectDisplay } from 'app/components/display';
import Select from 'app/components/input/select';
import { Table } from 'app/components/table';
import { TableColumn, TableRow } from 'app/components/table/component';
import { useUpdateObjectStatusMutation } from 'app/context/api/object';
import { lightFormat, parseISO } from 'date-fns';
import { ChangeEvent, useEffect, useState } from 'react';

const objectStatusOptions = [
  {
    id: 1,
    label: 'Kontrakt skal fornyes'
  },
  {
    id: 2,
    label: 'Vedlikehold / Ute av drift'
  },
  {
    id: 3,
    label: 'Annonsert'
  },
  {
    id: 4,
    label: 'Visning'
  },
  {
    id: 5,
    label: 'Utleid'
  }
];

interface Props {
  id: number;
  currentValue: number | undefined;
}

const Component = ({ id, currentValue }: Props): JSX.Element => {
  const [value, setValue] = useState(currentValue);

  const [postStatus] = useUpdateObjectStatusMutation();

  const updateValue = async (event: SelectChangeEvent) => {
    try {
      const result = await postStatus({
        objectId: id,
        objectStatus: event.target.value
      }).unwrap();

      if (!result.success) {
        throw 'Feil i oppdatering av objektstatus.';
      }
      setValue(parseInt(event.target.value));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Select
        id={id.toString()}
        name={id.toString()}
        value={value}
        placeholder={'Velg status'}
        onChange={updateValue}
        onBlur={updateValue}
        error={undefined}
        options={objectStatusOptions}
        helperText={undefined}
        label={''}
        variant={'body2'}
      />
    </>
  );
};

export default Component;
