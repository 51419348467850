import { Stack } from '@mui/material';
import { TextInput } from 'app/components/input';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { TableColumn, TableRow } from '../component';
import { TableCard } from './card';

interface Props {
  data: {
    columns: TableColumn[];
    rows: TableRow[][];
  };
}

const Component = ({ data }: Props): JSX.Element => {
  const [searchText, setSearchText] = useState('');

  function containsSearchText(row: TableRow[]) {
    if (searchText.length == 0) return true;
    const search = row.findIndex((cell) => {
      return typeof cell.value == 'string' && cell.value.includes(searchText);
    });

    if (search == -1) {
      return false;
    }

    return true;
  }

  const getFilteredRows = useCallback(() => {
    if (data) {
      const filter = [...data.rows];
      return filter.filter((r) => containsSearchText(r));
    }
    return [];
  }, [searchText]);

  return (
    <Stack spacing={2} sx={{ display: { xs: 'block', md: 'none' } }}>
      <TextInput
        id={'search'}
        name={'search'}
        value={searchText}
        placeholder={'Søk etter'}
        onChange={(e) => setSearchText(e.target.value)}
        onBlur={(e) => setSearchText(e.target.value)}
        error={undefined}
        helperText={undefined}
        label={''}
        bgColor={'#F6F3F3'}
      />
      {getFilteredRows().map((row, index) => (
        <TableCard key={index} row={row} index={index} column={data.columns} />
      ))}
    </Stack>
  );
};

export default Component;
