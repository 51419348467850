import { Alert, Stack } from '@mui/material';
import { useGetRegulatedContractsQuery } from 'app/context/api/cpi';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { MRT_Localization_NO } from 'material-react-table/locales/no';

import { useContractsColumns } from '../useContractColumns';
import { useTableParams } from '../useTableParms';

export default function CpiRegulated() {
  // Custom hooks
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    queryParams
  } = useTableParams();

  // Data loading
  const {
    data: regulatedContracts,
    isError,
    isLoading: isContractsLoading
  } = useGetRegulatedContractsQuery(queryParams, {
    refetchOnMountOrArgChange: true
  });

  const { columns } = useContractsColumns(
    {
      applyFromDate: true,
      regulationTypeAvaialable: false,
      regulationTypePerformed: true,
      lastRegulationDate: false,
      productName: true,
      clientName: true,
      tenantName: true,
      propertyAddress: true,
      propertyDescription: true,
      leaseStart: true,
      leaseStop: true,
      currentPrice: false,
      periodStartDate: true,
      periodEndDate: true,
      increasePercent: true,
      newPrice: true,
      increasePrice: true,
      document: true,
      contractUpdated: true,
      oldPrice: true,
      lastCpiRegulationDate: false,
      lastCustomRegulationDate: false
    },
    regulatedContracts?.records ?? []
  );

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data: regulatedContracts?.records ?? [],
    manualPagination: true,
    manualFiltering: true,
    rowCount: regulatedContracts?.totalCount ?? 0, // total records in backend data -> not in current table view
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableSelectAll: true,
    state: {
      columnFilters,
      globalFilter,
      isLoading: isContractsLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isContractsLoading,
      sorting,
      rowSelection
    },
    enableColumnOrdering: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableStickyFooter: true,
    editDisplayMode: 'cell',
    localization: {
      ...MRT_Localization_NO,
      selectedCountOfRowCountRowsSelected:
        '{selectedCount} av {rowCount} rader valgt',
      noRecordsToDisplay: 'Ingen regulerte kontrakter'
    }
  });

  if (isError) {
    return (
      <Alert severity="error">
        Kunne ikke laste — sjekk din internetforbindelse
      </Alert>
    );
  }

  return (
    <Stack spacing={2}>
      <MaterialReactTable table={table} />
    </Stack>
  );
}
