import { Box, Stack, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import { Dialog } from 'app/components/dialog';
import { LoadingPopup } from 'app/components/loading';
import { closeModal, openModal } from 'app/context/addModal/addModalSlice';
import {
  useCreateDepositAccountMutation,
  useGetTenantContactInfoQuery
} from 'app/context/api/tenant';
import { DepositAccountRequest } from 'app/context/api/tenant/types';
import { AddContract } from 'app/features/AddContract';
import { MyrentSecurity } from 'app/features/AddTenant/AddSecurity/myrentSecurity';
import { validateAddSecurity } from 'app/features/AddTenant/validationschemas';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { red } from 'app/theme/colors';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const Component = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { hash } = useLocation();
  const modal = useAppSelector((state) => state.modal.modal);
  const dispatch = useAppDispatch();

  const userId = searchParams.get('userId');
  const contractId = searchParams.get('contractId');
  const clientId = searchParams.get('clientId');
  const redirectLink = `${
    process.env.REACT_APP_API_URL
  }User/EditTenant?userId=${userId}${
    contractId ? '&contractId=' + contractId : ''
  }${hash ?? '#edit-contract'}`;

  const [skip, setSkip] = useState(true);
  const { data: tenantInfo, isLoading: tenantIsLoading } =
    useGetTenantContactInfoQuery(userId ? parseInt(userId) : 0, { skip });

  const [sendDepositForm, { isLoading: isSendingDepositForm }] =
    useCreateDepositAccountMutation();

  const formik = useFormik({
    initialValues: {
      clientId: 0,
      tenantType: tenantInfo?.companyType == '2' ? 'company' : 'private',
      securityInfo: {
        securityType: 2,
        deposit: '',
        SPCreditCheck: false,
        myrentOwner: {
          clientType: '2',
          companyName: '',
          companyNumber: '',
          firstName: '',
          lastName: '',
          email: '',
          cellPhone: '',
          socialSecurityNumber: ''
        },
        tenantSigner: {
          companyName: tenantInfo?.companyName ?? '',
          organizationNumber: tenantInfo?.companyNumber ?? '',
          firstName: tenantInfo?.firstName ?? '',
          lastName: tenantInfo?.lastName ?? '',
          email: tenantInfo?.email ?? '',
          cellphone: tenantInfo?.phoneNumber ?? '',
          socialSecurityNumber: tenantInfo?.ssn?.replaceAll('-', '') ?? ''
        },
        monthSP: '',
        navDate: '',
        otherSecurity: '',
        doNotSendOutAuthenticationInfo: false,
        isSecurityAccountDisabled: true
      },
      errorMsg: ''
    },
    validationSchema: validateAddSecurity,
    onSubmit: async (values) => {
      try {
        if (!contractId || parseInt(contractId) < 1)
          throw 'Fant ikke gyldig kontrakt';

        const depositInfo: DepositAccountRequest = {
          contractId: contractId,
          amount: parseInt(values.securityInfo.deposit),
          waitOnSigning: values.securityInfo.doNotSendOutAuthenticationInfo,
          landlordSigner: {
            companyName:
              values.securityInfo.myrentOwner.companyName.length == 0
                ? undefined
                : values.securityInfo.myrentOwner.companyName,
            companyNumber:
              values.securityInfo.myrentOwner.companyNumber.length == 0
                ? undefined
                : values.securityInfo.myrentOwner.companyNumber,
            ssn: values.securityInfo.myrentOwner.socialSecurityNumber,
            firstName: values.securityInfo.myrentOwner.firstName,
            lastName: values.securityInfo.myrentOwner.lastName,
            email: values.securityInfo.myrentOwner.email,
            phoneNumber: values.securityInfo.myrentOwner.cellPhone
          },
          tenantSigner: {
            companyName:
              values.securityInfo.tenantSigner.companyName.length == 0
                ? undefined
                : values.securityInfo.tenantSigner.organizationNumber,
            companyNumber:
              values.securityInfo.tenantSigner.organizationNumber.length == 0
                ? undefined
                : values.securityInfo.tenantSigner.organizationNumber,
            ssn: values.securityInfo.tenantSigner.socialSecurityNumber,
            firstName: values.securityInfo.tenantSigner.firstName,
            lastName: values.securityInfo.tenantSigner.lastName,
            email: values.securityInfo.tenantSigner.email,
            phoneNumber: values.securityInfo.tenantSigner.cellphone
          }
        };

        await sendDepositForm(depositInfo)
          .unwrap()
          .then(() => window.location.replace(redirectLink))
          .catch(() => {
            formik.setFieldValue(
              'errorMsg',
              'Vi kunne ikke opprette depositumskonto. Kontakt kundeservice.'
            );
          });
      } catch (err) {
        formik.setFieldValue('errorMsg', err);
      }
    }
  });

  const getDepositAccountErrors = () => {
    const warnings: string[] = [];
    if (formik.values.tenantType == 'company') {
      if (formik.errors.securityInfo?.tenantSigner?.companyName) {
        warnings.push('Fyll inn bedriftsnavn');
      }
      if (formik.errors.securityInfo?.tenantSigner?.organizationNumber) {
        warnings.push('Fyll inn organisasjonsnummer');
      }
      return warnings;
    }
    if (formik.errors?.securityInfo?.tenantSigner) {
      for (const error of Object.values(
        formik.errors.securityInfo.tenantSigner
      )) {
        warnings.push(error);
      }
      return warnings;
    }
    return [];
  };

  const editTenantViews = () => {
    switch (hash) {
      case '#deposit-tab':
        return (
          <>
            <Dialog
              isOpen={modal === 'depositAccount'}
              redirect={redirectLink}
              fullwidth={false}
            >
              <Stack spacing={8} padding={{ xs: 2, sm: 5, md: 10 }}>
                <Typography variant="h1">Depositumskonto</Typography>
                {clientId && tenantInfo && (
                  <MyrentSecurity
                    formik={formik}
                    clientId={parseInt(clientId)}
                    errors={getDepositAccountErrors()}
                    errorMsg={
                      'Vennligst gå tilbake og gjør endringer for å bestille depositumskonto.'
                    }
                  />
                )}
                {formik.values.errorMsg && (
                  <Typography color={red[100]}>
                    {formik.values.errorMsg}
                  </Typography>
                )}
                <Box display={'flex'} flexDirection={'row-reverse'}>
                  <PrimaryButton
                    filled
                    disabled={Boolean(formik.errors.securityInfo)}
                    onClick={formik.handleSubmit}
                  >
                    Bestill
                  </PrimaryButton>
                </Box>
              </Stack>
            </Dialog>
            <LoadingPopup open={tenantIsLoading || isSendingDepositForm} />
          </>
        );
      default:
        return (
          <Dialog isOpen={modal === 'contract'} redirect={redirectLink}>
            <AddContract
              contractId={contractId ? parseInt(contractId) : undefined}
              tenantId={userId ? parseInt(userId) : undefined}
              onClose={redirectLink}
            />
          </Dialog>
        );
    }
  };

  useEffect(() => {
    switch (hash) {
      case '#deposit-tab':
        if (userId) {
          setSkip(false);
        }
        dispatch(openModal('depositAccount'));
        break;
      default:
        dispatch(openModal('contract'));
    }
  }, []);

  const prefillTenantInfo = (field: string, value?: string) => {
    if (value) {
      formik.setFieldValue(`securityInfo.tenantSigner.${field}`, value);
    }
  };

  useEffect(() => {
    if (tenantInfo) {
      if (tenantInfo.companyType == '2') {
        formik.setFieldValue('tenantType', 'company');
      }
      prefillTenantInfo('companyName', tenantInfo.companyName);
      prefillTenantInfo('organizationNumber', tenantInfo.companyNumber);
      prefillTenantInfo('firstName', tenantInfo.firstName);
      prefillTenantInfo('lastName', tenantInfo.lastName);
      prefillTenantInfo('email', tenantInfo.email);
      prefillTenantInfo('cellphone', tenantInfo.phoneNumber);
      prefillTenantInfo(
        'socialSecurityNumber',
        tenantInfo.ssn?.replaceAll('-', '')
      );
    }
  }, [tenantInfo]);

  return <>{editTenantViews()}</>;
};

export default Component;
