import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Link,
  Stack
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { PrimaryButton } from 'app/components/button';
import { SecondaryButton } from 'app/components/button';
import { ExternalLink } from 'app/components/link';
import {
  useCpiRegulateContractsMutation,
  useGetCpiTemplatesQuery,
  useGetPendingContractsQuery,
  usePostponeContractsMutation
} from 'app/context/api/cpi';
import { isPaidSubscription } from 'app/context/user/userSlice';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { MRT_Localization_NO } from 'material-react-table/locales/no';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CustomSelect from '../../../components/display/Select';
import { useContractsColumns } from '../useContractColumns';
import { useTableParams } from '../useTableParms';

export default function CpiPending() {
  // Custom hooks
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    queryParams
  } = useTableParams();

  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const isPaidUser = useSelector(isPaidSubscription);
  const [showRegulateDialog, setShowRegulateDialog] = useState(false);

  // Data loading
  const {
    data: contracts,
    isError,
    isLoading: isContractsLoading
  } = useGetPendingContractsQuery(queryParams, {
    refetchOnMountOrArgChange: true
  });

  const { columns } = useContractsColumns(
    {
      applyFromDate: true,
      regulationTypeAvaialable: true,
      regulationTypePerformed: false,
      lastRegulationDate: true,
      productName: true,
      clientName: true,
      tenantName: true,
      propertyAddress: true,
      propertyDescription: true,
      leaseStart: true,
      leaseStop: true,
      currentPrice: true,
      periodStartDate: true,
      periodEndDate: true,
      increasePercent: true,
      newPrice: true,
      increasePrice: true,
      document: true,
      contractUpdated: false,
      oldPrice: false,
      lastCpiRegulationDate: false,
      lastCustomRegulationDate: false
    },
    contracts?.records ?? [],
    selectedTemplate
  );

  const { data: cpiTemplates, isError: errorWithLoadingTemplates } =
    useGetCpiTemplatesQuery();

  // Data mutation
  const [
    regulateContracts,
    { isLoading: isRegulating, isError: isErrorWithRegulation }
  ] = useCpiRegulateContractsMutation();

  const [
    postponeContracts,
    { isLoading: isPostponing, isError: isErrorWithPostponing }
  ] = usePostponeContractsMutation();

  useEffect(() => {
    if (cpiTemplates && cpiTemplates.length > 0) {
      setSelectedTemplate(cpiTemplates[0].id.toString());
    }
  }, [cpiTemplates]);

  // Handlers
  const handleRegulateContracts = () => {
    if (selectedTemplate === null) return null;
    regulateContracts({
      templateId: selectedTemplate,
      contractIds: Object.keys(rowSelection)
    })
      .unwrap()
      .then(() => {
        setShowRegulateDialog(false);
        table.resetRowSelection(true);
      });
  };

  const handlePostponeRegulation = () => {
    postponeContracts({ contractIds: Object.keys(rowSelection) })
      .unwrap()
      .then(() => {
        table.resetRowSelection(true);
      });
  };

  const handleOpenRegulateDialog = () => {
    setShowRegulateDialog(true);
  };

  const handleTemplateChange = (event: SelectChangeEvent) => {
    setSelectedTemplate(event.target.value as string);
  };

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data: contracts?.records ?? [],
    manualPagination: true,
    manualFiltering: true,
    rowCount: contracts?.totalCount ?? 0, // total records in backend data -> not in current table view
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableSelectAll: true,
    getRowId: (row) => row.id?.toString() ?? undefined,
    state: {
      columnFilters,
      globalFilter,
      isLoading: isContractsLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isContractsLoading,
      sorting,
      rowSelection,
      isSaving: isRegulating || isPostponing
    },
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableStickyFooter: true,
    editDisplayMode: 'cell',
    localization: {
      ...MRT_Localization_NO,
      selectedCountOfRowCountRowsSelected:
        '{selectedCount} av {rowCount} rader valgt',
      noRecordsToDisplay: 'Ingen kontrakter å regulere'
    }
  });

  if (isError) {
    return (
      <Alert severity="error">
        Kunne ikke laste data — sjekk din internetforbindelse!
      </Alert>
    );
  }

  return (
    <Stack spacing={2}>
      {isErrorWithPostponing && (
        <Alert severity="error">
          Kunne ikke utsette regulering — sjekk din internetforbindelse!
        </Alert>
      )}
      {cpiTemplates && cpiTemplates.length > 0 && (
        <>
          <CustomSelect
            value={selectedTemplate ?? ''}
            onChange={handleTemplateChange}
            labelId="select-cpi-template"
            backgroundColor="#F0F0F0"
            options={cpiTemplates.map((template) => ({
              value: template.id,
              label: template.title
            }))}
          />
        </>
      )}
      <MaterialReactTable table={table} />
      {isPaidUser && Object.keys(rowSelection).length > 0 && (
        <Stack
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <PrimaryButton
            filled={true}
            onClick={handleOpenRegulateDialog}
            type="button"
          >
            Reguler leie
          </PrimaryButton>
          <SecondaryButton onClick={handlePostponeRegulation}>
            Utsett regulering
          </SecondaryButton>
        </Stack>
      )}

      {!isPaidUser && (
        <Alert severity="warning">
          <AlertTitle> MyRent pluss funksjonalitet</AlertTitle>
          Kontakt <Link href="mailto:kundeservice@myrent.no">
            kundeservice
          </Link>{' '}
          for å oppgrader ditt abbonement
        </Alert>
      )}
      <Dialog fullWidth open={showRegulateDialog}>
        <DialogTitle>Kpi regulering</DialogTitle>
        <DialogContent>
          {isErrorWithRegulation && (
            <Alert severity="error">
              Kunne ikke regulere — sjekk din internetforbindelse
            </Alert>
          )}
          {isRegulating && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
          {!isRegulating && !isErrorWithRegulation && (
            <Stack direction="column" spacing={2}>
              <InputLabel id="select-cpi-template">
                Velg regulerings mal
              </InputLabel>

              {errorWithLoadingTemplates && (
                <Alert severity="error">
                  Kunne ikke laster maler — sjekk din internetforbindelse
                </Alert>
              )}
              {cpiTemplates && cpiTemplates.length > 0 && (
                <CustomSelect
                  value={selectedTemplate ?? ''}
                  onChange={handleTemplateChange}
                  labelId="select-cpi-template"
                  backgroundColor="#F0F0F0"
                  options={cpiTemplates.map((template) => ({
                    value: template.id,
                    label: template.title
                  }))}
                />
              )}
              <Stack spacing={1} direction="row">
                <ExternalLink to="ContractTemplate/Index">
                  Rediger maler
                </ExternalLink>
                {selectedTemplate !== null && <p>|</p>}
                {selectedTemplate !== null && (
                  <ExternalLink
                    target="_blank"
                    to={`ContractTemplate/Download?contractTemplateId=${selectedTemplate}&standardType=2`}
                  >
                    Vis pdf
                  </ExternalLink>
                )}
              </Stack>
            </Stack>
          )}
        </DialogContent>
        {!isRegulating && (
          <DialogActions>
            {isErrorWithRegulation && (
              <SecondaryButton onClick={() => setShowRegulateDialog(false)}>
                Lukk
              </SecondaryButton>
            )}
            {!isErrorWithRegulation && (
              <SecondaryButton onClick={() => setShowRegulateDialog(false)}>
                Avbryt
              </SecondaryButton>
            )}
            {selectedTemplate !== null && !isErrorWithRegulation && (
              <PrimaryButton filled={true} onClick={handleRegulateContracts}>
                Reguler
              </PrimaryButton>
            )}
          </DialogActions>
        )}
      </Dialog>
    </Stack>
  );
}
