import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.label``;

interface InputProps {
  error: boolean | undefined;
  bgColor: string;
}

export const Input = styled.input<InputProps>`
  -webkit-appearance: none;
  opacity: 90%;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  background-color: ${({ bgColor }) =>
    bgColor == 'white' ? 'white' : '#ededed'};
  border-width: 0px;
  box-shadow: ${({ error }) => error && '0 0 0 2px red'};
  &:focus {
    box-shadow: 0 0 0 2px #9aeabc;
  }
`;
