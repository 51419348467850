export interface ApiConfig {
  host?: string;
  version: string;
}

export interface AppConfig {
  publicUrl: string;
  defaultLanguage: string;
  logDebug: boolean;
}

export interface Deployment {
  branch: string;
  commit: string;
  environment: string;
}

export interface ClientConfig {
  clientId: string;
  scopes: string[];
}

export enum Environment {
  Development,
  Dev,
  Test,
  QA,
  Prod
}
