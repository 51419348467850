import { client } from 'app/infrastructure/client';
import { useEffect, useState } from 'react';
import { Environment } from 'types';

export const useEnvironment = () => {
  const [environment, setEnvironment] = useState<Environment>();

  // environment is resolved from the client deployment configuration
  useEffect(() => {
    const getDeployment = async () => {
      // using client deployment to resolve environment
      const deployment = await client.fetchDeployment();
      if (!deployment) {
        throw Error('Unable to resolve environment from deployment');
      }
      const env =
        Environment[deployment.environment as keyof typeof Environment]; // prob not entirely safe
      setEnvironment(env);
    };
    getDeployment();
  }, []);

  return { environment };
};

export default useEnvironment;
