import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { Divider, Stack, Typography } from '@myrent/myrent-ui';
import NavLogo from 'app/assets/imgs/nav.svg';
import SoderbergLogo from 'app/assets/imgs/soderberg.svg';
import { Select, TextInput } from 'app/components/input';
import { DatePicker } from 'app/components/input/date-picker';
import { useGetContextQuery } from 'app/context/api/account';
import { red } from 'app/theme/colors';
import { useEffect } from 'react';

import { MyrentSecurity } from './myrentSecurity';

interface Props {
  formik: any;
}

const Component = ({ formik }: Props): JSX.Element => {
  const { data: userContext } = useGetContextQuery();

  const prefillTenantField = (field: string, type: string) => {
    formik.setFieldTouched(`securityInfo.tenantSigner.${field}`, true);
    formik.setFieldValue(
      `securityInfo.tenantSigner.${field}`,
      formik.values[type][0][field]
    );
  };

  useEffect(() => {
    let tenantType = 'companyTenants';
    if (formik.values.tenantType == 'private') {
      tenantType = 'privateTenants';
      formik.setFieldValue('securityInfo.tenantSigner.companyName', '');
      formik.setFieldValue('securityInfo.tenantSigner.organizationNumber', '');
      if (
        tenantType == 'privateTenants' &&
        formik.values[tenantType][0].socialSecurityNumber.length > 0
      ) {
        prefillTenantField('socialSecurityNumber', tenantType);
      }
    } else {
      prefillTenantField('companyName', tenantType);
      prefillTenantField('organizationNumber', tenantType);
    }
    if (
      !formik.touched?.securityInfo?.tenantSigner ||
      tenantType == 'privateTenants'
    ) {
      prefillTenantField('firstName', tenantType);
      prefillTenantField('lastName', tenantType);
      if (!formik.values[tenantType][0].noEmail) {
        prefillTenantField('email', tenantType);
      }
      if (!formik.values[tenantType][0].noCellPhone) {
        prefillTenantField('cellphone', tenantType);
      }
    }
  }, []);

  const optionsSP = [
    {
      label: '3',
      id: 3
    },
    {
      label: '4',
      id: 4
    },
    {
      label: '5',
      id: 5
    },
    {
      label: '6',
      id: 6
    }
  ];

  useEffect(() => {
    if (formik.values.tenantType == 'private') {
      formik.setFieldValue(
        'securityInfo.SPCreditCheck',
        formik.values.privateTenants[0].isCreditChecked &&
          formik.values.privateTenants[0].creditScore > 19 &&
          formik.values.privateTenants[0].creditScore < 101
      );
    } else {
      formik.setFieldValue(
        'securityInfo.SPCreditCheck',
        formik.values.companyTenants[0].isCreditChecked &&
          formik.values.companyTenants[0].creditScore > 19 &&
          formik.values.companyTenants[0].creditScore < 101
      );
    }
  }, [formik.values.privateTenants, formik.values.companyTenants]);

  const getDepositAccountErrors = () => {
    if (formik.values.tenantType == 'company') return [];
    const warnings: string[] = [];
    if (formik.values.privateTenants[0]?.socialSecurityNumber.length == 0) {
      warnings.push('Personnummer');
    }
    if (formik.values.privateTenants[0]?.noEmail) {
      warnings.push('E-post');
    }
    if (formik.values.privateTenants[0]?.noCellPhone) {
      warnings.push('Telefonnummer');
    }
    return warnings;
  };

  const securityTypeOptions = [
    {
      label: 'MyRent Depositumskonto',
      id: 2,
      render: (
        <MyrentSecurity
          formik={formik}
          clientId={formik.values.propertyInfo.clientId}
          errors={getDepositAccountErrors()}
          errorMsg={
            'Vennligst gå tilbake og gjør endringer eller velg en annen sikkerhetsløsning for å fortsette.'
          }
        />
      ),
      disabled: formik.values.securityInfo.isSecurityAccountDisabled
    },
    {
      label: 'Leiegaranti S&P',
      id: 3,
      render: (
        <Stack spacing={3}>
          <Box
            component={'img'}
            src={SoderbergLogo}
            sx={{
              height: 74,
              width: 249
            }}
            alt="Söderberg og Partners"
          />
          <Typography variant="body2">
            Godkjent kredittsjekk og signatur via BankID må foreligge. Dokument
            for underskrift blir automatisk lagt til kontrakten.
          </Typography>
          <Typography fontWeight={'bold'} color={red[100]}>
            {!formik.values.securityInfo.SPCreditCheck &&
              'Du har ikke foretatt en gydlig kredittsjekk. Godkjent kredittsjekk må foreligge av leietaker.'}
          </Typography>
          <Select
            fullWidth={false}
            id="securityInfo.monthSP"
            name="securityInfo.monthSP"
            label="Garantiperiode (antall måneder)"
            value={formik.values.securityInfo.monthSP}
            onChange={formik.handleChange}
            options={optionsSP}
            error={
              formik.touched.securityInfo?.monthSP &&
              Boolean(formik.errors.securityInfo?.monthSP)
            }
            helperText={
              formik.touched.securityInfo?.monthSP &&
              formik.errors.securityInfo?.monthSP
            }
            labelColor="black"
            onBlur={formik.handleBlur}
            labelLeftMargin={'0'}
            labelWeight={'0'}
            bgColor={'#EDEDED'}
          />
          {formik.values.rentInfo.rentAmount &&
            formik.values.securityInfo.monthSP.length != '' && (
              <Stack
                spacing={1}
                sx={{
                  backgroundColor: '#BCF6E0',
                  padding: '25px',
                  borderRadius: '25px'
                }}
              >
                <Typography>{`Denne leiegarantien vil dekke tilsvarende depositum på ${
                  parseInt(formik.values.securityInfo.monthSP) *
                  parseInt(formik.values.rentInfo.rentAmount)
                } kr for hele 3 års leieperioden.`}</Typography>
                <Typography>{`Leietaker betaler kun ${
                  parseInt(formik.values.securityInfo.monthSP) *
                  parseInt(formik.values.rentInfo.rentAmount) *
                  0.15
                } kr, som engangsbeløp.`}</Typography>
              </Stack>
            )}
        </Stack>
      )
    },
    {
      label: 'Annen depositumskonto',
      id: 4,
      render: (
        <Stack spacing={2}>
          <Typography variant="h6">Depositumskonto i annen bank</Typography>
          <TextInput
            id={'securityInfo.deposit'}
            name={'securityInfo.deposit'}
            value={formik.values.securityInfo.deposit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.securityInfo?.deposit &&
              Boolean(formik.errors.securityInfo?.deposit)
            }
            helperText={
              formik.touched.securityInfo?.deposit &&
              formik.errors.securityInfo?.deposit
            }
            label={'Depositum'}
            labelColor={'#242424'}
            labelLeftMargin={'0'}
            labelWeight={'normal'}
            bgColor={'#ededed'}
          />
        </Stack>
      )
    },
    {
      label: 'NAV-garanti',
      id: 5,
      render: (
        <Stack spacing={4}>
          <Box
            component={'img'}
            src={NavLogo}
            sx={{
              height: 73,
              width: 117
            }}
            alt="Söderberg og Partners"
          />
          <Typography>
            Hvis NAV-garanti benyttes for depositum vil du motta varsel dersom
            garantien nærmer seg utløpsdato.
          </Typography>
          <DatePicker
            setFormik={formik}
            value={formik.values.securityInfo.navDate}
            name={'securityInfo.navDate'}
            label={'Utløpsdato for garanti'}
            labelColor="#242424"
            labelWeight="normal"
            labelLeftMargin="0"
            error={
              formik.touched.securityInfo?.navDate &&
              Boolean(formik.errors.securityInfo?.navDate)
            }
            helperText={
              formik.touched.securityInfo?.navDate &&
              formik.errors.securityInfo?.navDate
            }
          />
        </Stack>
      )
    },
    {
      label: 'Annen sikkerhetsløsning',
      id: 6,
      render: (
        <Stack>
          <Typography variant="h6">Annen sikkerhetsløsning</Typography>
          <TextField
            id={'securityInfo.otherSecurity'}
            multiline
            rows={4}
            value={formik.values.securityInfo.otherSecurity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{
              backgroundColor: '#ededed'
            }}
          />
        </Stack>
      )
    },
    {
      label: 'Ingen sikkerhetsløsning',
      id: 1,
      render: (
        <Typography>
          Er du helt sikker på at du ikke ønsker å sikre ditt leieforhold?
        </Typography>
      )
    }
  ];

  useEffect(() => {
    if (userContext) {
      const client = userContext.clients?.find(
        (c) => c.clientId == formik.values.propertyInfo.clientId
      );
      const disabled = client?.clientType == 2;
      formik.setFieldValue('securityInfo.isSecurityAccountDisabled', disabled);
    }
  }, [userContext]);

  return (
    <Stack p={{ xs: 2, sm: 10 }} pr={{ md: 55 }} pb={{ xs: 10 }} spacing={6}>
      <Stack spacing={2}>
        <Typography variant="h1">Sikkerhet</Typography>
        <Divider
          sx={{
            borderBottomWidth: 3,
            backgroundColor: '#242424'
          }}
        />
      </Stack>
      <Typography variant="h5" fontWeight="bold">
        Hvilken sikkerhetsløsning skal brukes?
      </Typography>
      <Stack spacing={3}>
        <Select
          fullWidth={false}
          id="securityInfo.securityType"
          name="securityInfo.securityType"
          label="Sikkerhetsløsning"
          value={formik.values.securityInfo.securityType}
          onChange={formik.handleChange}
          options={
            formik.values.securityInfo.isSecurityAccountDisabled
              ? securityTypeOptions.filter((o) => o.id != 2)
              : securityTypeOptions
          }
          error={
            formik.touched.securityInfo?.securityType &&
            Boolean(formik.errors.securityInfo?.securityType)
          }
          helperText={
            formik.touched.securityInfo?.securityType &&
            formik.errors.securityInfo?.securityType
          }
          labelColor="#242424"
          onBlur={formik.handleBlur}
          labelLeftMargin={'0'}
          labelWeight={'0'}
          bgColor={'#EDEDED'}
        />
        {formik.values.securityInfo.securityType == null && (
          <Typography variant="body2">
            MyRent tilbyr depositumskonto via SR-Bank til Kr. 149,-. Klikk her
            for å lese mer.
          </Typography>
        )}
      </Stack>
      <Stack>
        {formik.values.securityInfo.securityType &&
          securityTypeOptions.filter(
            (label) => label.id == formik.values.securityInfo.securityType
          )[0].render}
      </Stack>
    </Stack>
  );
};

export default Component;
