import { CircularProgress, Dialog } from '@mui/material';

interface Props {
  open: boolean;
}

const Component = ({ open }: Props): JSX.Element => (
  <Dialog
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'visible'
      }
    }}
    BackdropProps={{
      style: {
        backgroundColor: 'rgba(255,255,255,0.1)'
      }
    }}
    open={open}
  >
    <CircularProgress
      thickness={7}
      sx={{
        color: '#003D43',
        strokeLinecap: 'round'
      }}
      size={150}
    />
  </Dialog>
);

export default Component;
