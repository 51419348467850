import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { dark } from 'app/theme/colors';
import React from 'react';

import {
  Container,
  Input,
  Item,
  Label,
  RadioButtonRef,
  RadioButtonView
} from './checkboxStyles';

interface Props {
  id: string;
  name: string;
  value: boolean;
  fontSize?: string;
  fontColor?: string;
  onChange: (event: React.ChangeEvent<any>) => void;
  onBlur: (event: React.ChangeEvent<any>) => void;
  error: boolean | undefined;
  helperText: string | false | undefined;
  label: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const Component = ({
  id,
  name,
  value,
  onChange,
  error,
  label,
  fontSize = '12px',
  fontColor = 'white',
  helperText,
  fullWidth = false,
  disabled = false
}: // sx
Props): JSX.Element => {
  return (
    <Container>
      <Stack spacing="1px" width={fullWidth ? '100%' : '80%'}>
        {/* {label && (
          <Label htmlFor={name}>
            <Typography color="white" marginLeft="10px" fontWeight="bold">
              {label}
            </Typography>
          </Label>
        )} */}
        <Item>
          <Checkbox
            name={name}
            checked={value}
            disabled={disabled}
            onChange={onChange}
            icon={
              <Box
                sx={{
                  backgroundColor: 'white',
                  width: '28px',
                  height: '28px',
                  borderRadius: '10px',
                  border: `1px solid ${dark[100]}`
                }}
              />
            }
            checkedIcon={
              <CheckRoundedIcon
                sx={{
                  backgroundColor: 'white',
                  width: '28px',
                  height: '28px',
                  padding: '1px',
                  borderRadius: '10px',
                  border: `1px solid ${dark[100]}`,
                  color: dark[100]
                }}
              />
            }
            sx={{
              '& .MuiSvgIcon-root:hover': { backgroundColor: 'white' }
            }}
          />
          <Typography color={fontColor} fontSize={fontSize}>
            {label}
          </Typography>
        </Item>
        <span className="checkmark" />
        {helperText && (
          <Container>
            <Typography color="red" marginLeft="10px">
              {helperText}
            </Typography>
          </Container>
        )}
      </Stack>
    </Container>
  );
};

export default Component;
