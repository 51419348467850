import KeyIcon from '@mui/icons-material/Key';
import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/button';
import { DashboardCard } from 'app/components/card';
import { Dialog } from 'app/components/dialog';
import {
  Numberdisplay,
  SelectDisplay,
  TextDisplay
} from 'app/components/display';
import { ExternalLink } from 'app/components/link';
import { Table } from 'app/components/table';
import { TableColumn, TableRow } from 'app/components/table/component';
import {
  useGetClientOutstandingInvoiceStatisticsQuery,
  useGetOutstandingInvoiceStatisticsQuery,
  useGetPaymentsByInvoiceDueStatusQuery
} from 'app/context/api/invoice';
import { PaymentsByInvoiceDueStatusResponse } from 'app/context/api/invoice/types';
import { red } from 'app/theme/colors';
import { useState } from 'react';

const options = [
  {
    label: 'Forfalt mer enn 30 dager',
    value: 4
  },
  {
    label: 'Forfalt mer enn 14 dager',
    value: 3
  },
  {
    label: 'Nylig forfalt',
    value: 2
  },
  {
    label: 'Ikke forfalt',
    value: 1
  }
];

const invoiceDisplayOptions = [
  {
    label: 'Aldersfordelt',
    value: 0
  },
  {
    label: 'Selskaper',
    value: 1
  }
];

const columns: TableColumn[] = [
  {
    title: 'Leietaker',
    order: 1
  },
  {
    title: 'Adresse',
    order: 5
  },
  {
    title: 'Objekt',
    order: 6
  },
  {
    title: 'Fakturanr',
    align: 'right',
    order: 3
  },
  {
    title: 'Forfall',
    align: 'right',
    order: 7
  },
  {
    title: 'Betalt',
    align: 'right',
    order: 8
  },
  {
    title: 'Rest',
    align: 'right',
    order: 4
  }
];

const getRows = (data: PaymentsByInvoiceDueStatusResponse[]) => {
  const rows: TableRow[][] = [];
  data.map((payment) => {
    rows.push([
      {
        value: payment.user,
        order: 1
      },
      {
        value: payment.address,
        order: 5
      },
      {
        value: payment.objectDescription,
        order: 6
      },
      {
        value: payment.invoiceNumber,
        order: 3
      },
      {
        value: payment.invoiceDueDate,
        order: 7
      },
      {
        value: payment.amount + ' kr',
        order: 8
      },
      {
        value: payment.leftToPay + ' kr',
        order: 4
      }
    ]);
  });
  return rows;
};

const Component = (): JSX.Element => {
  const { data: invoiceData } = useGetOutstandingInvoiceStatisticsQuery(2);
  const { data: clientInvoiceData } =
    useGetClientOutstandingInvoiceStatisticsQuery(2);

  const [invoiceDueStatus, setInvoiceDueStatus] = useState(1);
  const [invoiceDisplay, setInvoiceDisplay] = useState(0);
  const { data: paymentData, isLoading } =
    useGetPaymentsByInvoiceDueStatusQuery({
      invoiceDueStatus: invoiceDueStatus
    });
  const [open, setOpen] = useState(false);

  const updateInvoiceDueStatus = (event: SelectChangeEvent) => {
    setInvoiceDueStatus(parseInt(event.target.value));
  };

  const updateInvoiceDisplay = (event: SelectChangeEvent) => {
    setInvoiceDisplay(parseInt(event.target.value));
  };

  return (
    <>
      {invoiceData && (
        <DashboardCard
          mainIcon={<KeyIcon />}
          title={'Utestående fakturaer'}
          select={
            <SelectDisplay
              value={invoiceDisplay.toString()}
              onChange={updateInvoiceDisplay}
              options={invoiceDisplayOptions}
            />
          }
          firstColumn={
            invoiceDisplay == 0 ? (
              <Stack spacing={1}>
                <Numberdisplay
                  value={invoiceData.expiredMoreThe30Days}
                  title={'Forfalt med mer enn 30 dager'}
                  link={() => {
                    setInvoiceDueStatus(4);
                    setOpen(true);
                  }}
                />
                <Numberdisplay
                  value={invoiceData.expiredLessThe30Days}
                  title={'Forfalt med mer enn 14 dager'}
                  link={() => {
                    setInvoiceDueStatus(3);
                    setOpen(true);
                  }}
                />
                <Numberdisplay
                  value={invoiceData.expiredLessThen15Days}
                  title={'Nylig forfalt'}
                  link={() => {
                    setInvoiceDueStatus(2);
                    setOpen(true);
                  }}
                />
              </Stack>
            ) : (
              <Stack spacing={1} display={'flex'}>
                {clientInvoiceData &&
                  clientInvoiceData.map((item, index) => (
                    <TextDisplay
                      key={index}
                      title={item.clientName}
                      value={item.amount}
                      valueColor={red[100]}
                    />
                  ))}
                <ExternalLink to="Invoice/SentInvoices">
                  <Typography
                    fontWeight={'bold'}
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Se flere oppføringer
                  </Typography>
                </ExternalLink>
              </Stack>
            )
          }
        />
      )}
      <Dialog isOpen={open} setIsOpen={() => setOpen(!open)}>
        <Stack spacing={2} py={10} px={{ xs: 1, sm: 3, md: 15 }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            display={'flex'}
            flexWrap={'wrap'}
          >
            <Typography variant="h1" sx={{ pb: 2 }}>
              Utestående faktura
            </Typography>
            <SelectDisplay
              value={invoiceDueStatus.toString()}
              onChange={updateInvoiceDueStatus}
              options={options}
              backgroundColor={'#F0F0F0'}
            />
          </Stack>
          {paymentData && (
            <Table
              data={{
                columns: columns,
                rows: getRows(paymentData)
              }}
            />
          )}
          <Stack direction={'row-reverse'}>
            <ExternalLink to="v1/Payment/ExportPaymentsByInvoiceDueStatus">
              <PrimaryButton filled>Last ned Excel-fil</PrimaryButton>
            </ExternalLink>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default Component;
