import { Dialog } from 'app/components/dialog';
import { LoadingPopup } from 'app/components/loading';
import { openModal } from 'app/context/addModal/addModalSlice';
import { useGetTenantsContractInfoQuery } from 'app/context/api/tenant';
import { AddContract } from 'app/features/AddContract';
import { AddTenant } from 'app/features/AddTenant';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Component = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const modal = useAppSelector((state) => state.modal.modal);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(openModal('tenant'));
  }, []);

  const redirectUrl = `${process.env.REACT_APP_API_URL}User/TenantContactList`;

  const fromCreditCheckId = searchParams.get('fromCreditCheckId');
  const contractId = searchParams.get('contractId');
  const fromContractId = searchParams.get('fromContractId');
  const applicationId = searchParams.get('applicationId');
  const applicationType = searchParams.get('applicationType');

  const [skip, setSkip] = useState(true);
  useEffect(() => {
    if (fromContractId && parseInt(fromContractId) > 0) {
      setSkip(false);
    }
  }, [fromContractId]);

  const { data, isLoading } = useGetTenantsContractInfoQuery(
    {
      fromCreditCheckId: fromCreditCheckId ?? '-1',
      contractId: contractId ?? '-1',
      fromContractId: fromContractId ?? '-1',
      applicationId: applicationId ?? '-1',
      applicationType: applicationType ?? '-1'
    },
    {
      skip
    }
  );

  return (
    <>
      {(skip ||
        (data &&
          data.contracts.length > 0 &&
          data.contracts[0].tenants.length > 0)) && (
        <Dialog
          isOpen={modal === 'tenant'}
          removeIcon
          redirect={redirectUrl}
          resetOnClose={true}
        >
          <AddTenant
            prefillObject={data?.contracts[0]}
            redirect={redirectUrl}
          />
        </Dialog>
      )}
      <Dialog
        isOpen={modal === 'contract'}
        removeIcon={true}
        redirect={redirectUrl}
      >
        <AddContract onClose={redirectUrl} />
      </Dialog>
      <LoadingPopup open={isLoading} />
    </>
  );
};

export default Component;
