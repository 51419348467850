import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { dark, green } from 'app/theme/colors';
import { useEffect, useState } from 'react';

interface Props {
  getFile: (file: Blob | string) => void;
}

const Component = ({ getFile }: Props): JSX.Element => {
  const [fileValue, setFileValue] = useState('');

  return (
    <Stack spacing={4} paddingY={5}>
      <Typography>
        Last opp en allerede eksisterende kontrakt fra din maskin ved å klikke
        på knappen under, velge fil og lagre.
      </Typography>
      <Box>
        {fileValue.length == 0 ? (
          <Button
            variant="contained"
            component="label"
            sx={{
              color: 'black',
              backgroundColor: green[100],
              '&.MuiButtonBase-root:hover': {
                backgroundColor: green[60]
              },
              borderRadius: 50,
              padding: '5px',
              pl: '25px'
            }}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ width: '100%' }}
            >
              <Stack>
                <Typography fontSize={12}>Last opp</Typography>
                <Typography fontSize={16}>Kontrakt</Typography>
              </Stack>
              <FileUploadIcon
                fontSize="large"
                sx={{
                  backgroundColor: 'white',
                  height: '50px',
                  width: '50px',
                  padding: '10px',
                  borderRadius: '25px',
                  ml: '50px'
                }}
              />
            </Stack>
            <input
              type={'file'}
              hidden
              accept={'.pdf'}
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                if (target.files) {
                  const newFile: File = Object.values(target.files)[0];
                  setFileValue(newFile.name);
                  getFile(newFile);
                }
              }}
              value={fileValue}
            />
          </Button>
        ) : (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              display: 'inline-flex',
              backgroundColor: '#F6F6F6',
              padding: '10px',
              borderRadius: '25px'
            }}
          >
            <DescriptionOutlinedIcon fontSize="large" />
            <Typography fontWeight={'bold'} fontSize={16} sx={{ px: '25px' }}>
              {fileValue}
            </Typography>
            <ClearOutlinedIcon
              fontSize="large"
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => {
                setFileValue('');
                getFile('');
              }}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default Component;
