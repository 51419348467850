import {
  Box,
  Divider,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Typography
} from '@mui/material';
import { MenuButton } from 'app/components/button';
import { ContextSwitcher } from 'app/components/context-switcher';
import { Datagrid } from 'app/components/data-grid';
import { DataGridColumns } from 'app/components/data-grid/types';
import { getPropertyIcon } from 'app/components/data-grid/view/functions/getPropertyIcon';
import { Select, TextInput } from 'app/components/input';
import CustomSnackBar from 'app/components/snackbar/snackbar';
import { openSnackbar } from 'app/components/snackbar/snackBarSlice';
import { openModal } from 'app/context/addModal/addModalSlice';
import {
  useArchivePropertyMutation,
  useGetPropertyTypesQuery,
  useLazyGetPropertiesQuery,
  useUnarchivePropertyMutation
} from 'app/context/api/property';
import {
  PropertiesParams,
  PropertiesResponse
} from 'app/context/api/property/types';
import { useAppDispatch } from 'app/hooks';
import { white } from 'app/theme/colors';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { ArchiveOption } from './ArchiveOption';

export default function Component() {
  const navigate = useNavigate();
  const { data: propertyTypes, isLoading: isLoadingPropertyTypes } =
    useGetPropertyTypesQuery();
  const [trigger, result] = useLazyGetPropertiesQuery();
  const [query, setQuery] = useState<PropertiesParams>({
    pageIndex: 0,
    pageSize: 10,
    archived: false
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    trigger({
      ...query
    });
  }, [query]);

  const [archive] = useArchivePropertyMutation();
  const [unarchive] = useUnarchivePropertyMutation();

  async function ArchiveProperty(propertyId: number, name: string) {
    await archive({ propertyId: propertyId })
      .unwrap()
      .then(() =>
        dispatch(
          openSnackbar({ status: 'success', message: `${name} ble arkivert` })
        )
      )
      .catch((e) => {
        dispatch(
          openSnackbar({
            status: 'error',
            message: `${e.data.message ?? 'Noe gikk galt'}`
          })
        );
      });
  }

  async function UnarchiveProperty(propertyId: number, name: string) {
    await unarchive({ propertyId: propertyId })
      .unwrap()
      .then(() =>
        dispatch(
          openSnackbar({
            status: 'success',
            message: `${name} ble gjennomrettet`
          })
        )
      )
      .catch((e) => {
        dispatch(
          openSnackbar({
            status: 'error',
            message: `${e.data.message ?? 'Noe gikk galt'}`
          })
        );
      });
  }

  const mutatedResult = useMemo(() => {
    if (result.data) {
      return {
        ...result.data,
        records: result.data.records.map((row: PropertiesResponse) => ({
          ...row,
          propertyType: getPropertyIcon(
            propertyTypes?.find((type) => type.id === row.propertyTypeId)
              ?.name || 'Bygård'
          ),
          options: row.archived ? (
            <ArchiveOption
              options={[
                {
                  label: 'Gjenopprett',
                  onClick: () => UnarchiveProperty(row.id, row.address)
                }
              ]}
            />
          ) : (
            <ArchiveOption
              options={[
                {
                  label: 'Arkiver',
                  onClick: () => ArchiveProperty(row.id, row.address)
                }
              ]}
            />
          )
        }))
      };
    }
  }, [result.data, propertyTypes]);

  const propertyTypeOptions = useMemo(() => {
    if (propertyTypes) {
      return [
        { label: 'Alle', value: 'all' },
        ...propertyTypes.map((type) => ({ label: type.name, value: type.id }))
      ];
    } else {
      return [
        {
          label: 'Alle',
          value: 'all'
        }
      ];
    }
  }, [propertyTypes]);

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Eiendommer</Typography>
        <ContextSwitcher />
      </Stack>
      <Divider />
      <Stack direction="row" spacing={2}>
        <Datagrid.Search
          label="Søk etter adresse"
          onChange={(e: string) => setQuery((q) => ({ ...q, search: e }))}
        />
        <Datagrid.Select
          label="Type"
          options={propertyTypeOptions}
          defaultValue={0}
          onChange={(e: string | number) => {
            setQuery((q) => ({ ...q, typeId: e === 'all' ? undefined : e }));
          }}
        />
        <Datagrid.Select
          label="Status"
          options={[
            {
              label: 'Aktive',
              value: 'false'
            },
            {
              label: 'Arkiverte',
              value: 'true'
            }
          ]}
          defaultValue="false"
          onChange={(e: string | number) =>
            setQuery((q) => ({ ...q, archived: e === 'true' }))
          }
        />
      </Stack>
      <Stack paddingBottom={5}>
        <Datagrid.View
          columns={{
            propertyType: {
              label: 'Type',
              style: 'pill'
            },
            address: {
              label: 'Adresse',
              filter: {
                type: 'search',
                label: 'Søk på adresse'
              }
            },
            objectCount: {
              label: 'Objekter',
              style: 'count'
            },
            tenantCount: {
              label: 'Leietakere',
              style: 'count'
            },
            options: {
              label: '',
              align: 'right',
              style: 'option'
            }
          }}
          rows={mutatedResult?.records}
          totalRows={result?.data?.totalCount}
          onRowClick={(e) => navigate(`/properties/${e.id}/oversikt`)}
          onChangePage={(e) => setQuery((q) => ({ ...q, pageIndex: e }))}
          onChangeRowsPerPage={(e) => setQuery((q) => ({ ...q, pageSize: e }))}
          onChangeOrder={(
            property: keyof DataGridColumns,
            direction: 'desc' | 'asc'
          ) =>
            setQuery((q) => ({
              ...q,
              orderBy: property.toString(),
              direction: direction === 'asc' ? 'Asc' : 'Desc'
            }))
          }
        />
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0
        }}
      >
        <MenuButton
          filled
          choices={[
            {
              text: 'Legg til eiendom',
              onClick: () => dispatch(openModal('property'))
            }
          ]}
        >
          Legg til
        </MenuButton>
      </Box>
      <CustomSnackBar />
    </Stack>
  );
}
