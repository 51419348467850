import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { closeSnackBar } from './snackBarSlice';

export default function CustomSnackBar() {
  const snackBarState = useAppSelector((state) => state.snackBar);
  const dispatch = useAppDispatch();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeSnackBar());
  };

  const getAlert = () => {
    if (!snackBarState.open) return;

    switch (snackBarState.details.status) {
      case 'success':
        return (
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {snackBarState.details.message}
          </Alert>
        );
      case 'warning':
        return (
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: '100%' }}
          >
            {snackBarState.details.message}
          </Alert>
        );
      case 'info':
        return (
          <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
            {snackBarState.details.message}
          </Alert>
        );
      case 'error':
        return (
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {snackBarState.details.message}
          </Alert>
        );
      default:
        return (
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Noe har gått galt
          </Alert>
        );
    }
  };

  return (
    <Snackbar
      open={snackBarState.open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={snackBarState.details.message}
    >
      {getAlert()}
    </Snackbar>
  );
}
